import findIndex from 'lodash/findIndex';
import TYPE from '../actions/TYPE';

const initialState = {
  promotionUs: [],
  categories: [],
  openVpnMobile: {
    domain: null,
    loading: false
  }
};

const dealngon = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_LIST_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload
      };
    case TYPE.GET_DETAIL_CATEGORY_SUCCESS: {
      const index = findIndex(state.categories, { _id: action.payload._id });

      const newState = { ...state };
      if (index > -1) {
        newState.categories[index].websites = action.payload.websites;
      }
      return newState;
    }
    case TYPE.OPEN_VPN_MOBILE:
      return {
        ...state,
        openVpnMobile: {
          domain: action.payload,
          loading: true
        }
      };
    case TYPE.SYNC_MOBILE_OPENED_VPN:
      if (state.openVpnMobile.domain) {
        window.open(`https://${state.openVpnMobile.domain}`, '_self');
      }
      return {
        ...state,
        openVpnMobile: {
          domain: null,
          loading: false
        }
      };
    case TYPE.GET_LIST_PROMOTION_US_WEBSITE_SUCCESS:
      return {
        ...state,
        promotionUs: action.payload
      };
    default:
      return state;
  }
};

export default dealngon;
