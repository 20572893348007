import { array, bool, object, string } from 'prop-types';
import React from 'react';
import NotificationItemList from '../NotificationItemList';

export default function NotifyListContainer({ dataList, ...props }) {
  const { loading } = props;
  const list = loading ? new Array(3).fill(0) : dataList;
  return <NotificationItemList dataList={list} {...props} />;
}

NotifyListContainer.propTypes = {
  dataList: array,
  bottomAction: object,
  loading: bool,
  isInDetail: bool,
  from: string
};
NotifyListContainer.defaultProps = {
  dataList: [],
  bottomAction: {},
  loading: false,
  isInDetail: false,
  from: 'navigate'
};
