import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getListBank(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListBank' });
    const { data } = yield call(services.bank.getList, action.payload);

    let actionType = '';

    switch (action.payload) {
      case 'buyngon/order':
      case 'buyngon/withdrawal':
        actionType = TYPE.GET_LIST_BANK_SUCCESS;
        break;

      case 'customer':
      case 'customer/withdrawal':
        actionType = TYPE.GET_LIST_CUSTOMER_BANK_SUCCESS;
        break;

      case 'global':
      case 'global/withdrawal':
        actionType = TYPE.GET_LIST_GLOBAL_BANK_SUCCESS;
        break;

      default:
        actionType = action.payload;
        break;
    }

    yield put({ type: actionType, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_BANK_FAILED });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListBank' });
    action?.callback?.();
  }
}

function* syncListBank(action) {
  try {
    // yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'syncListBank' });
    const { data } = yield call(services.bank.getList, action.payload);
    yield put({ type: TYPE.GET_LIST_BANK_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_BANK_FAILED });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'syncListBank' });
  }
}
export default function* root() {
  yield all([
    takeEvery(TYPE.GET_LIST_BANK, getListBank),
    takeLatest(TYPE.SYNC_LIST_BANK, syncListBank)
  ]);
}
