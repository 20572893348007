import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function FacebookIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7132 1.3335H3.28613C2.20927 1.3335 1.33301 2.20976 1.33301 3.28662V12.7137C1.33301 13.7906 2.20927 14.6668 3.28613 14.6668H7.21842V9.95329H5.65592V7.60954H7.21842V6.021C7.21842 4.72858 8.26976 3.67725 9.56217 3.67725H11.932V6.021H9.56217V7.60954H11.932L11.5413 9.95329H9.56217V14.6668H12.7132C13.7901 14.6668 14.6663 13.7906 14.6663 12.7137V3.28662C14.6663 2.20976 13.7901 1.3335 12.7132 1.3335Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
