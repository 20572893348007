import { all, call, put, takeLatest } from 'redux-saga/effects';
import { VN_TRACKING_STATUS } from '../../contents/Constants';
import services from '../../services';
import { messageSuccess } from '../../utils/alertMessage';
import TYPE from '../actions/TYPE';

function* getListVNTracking() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListVNTracking' });
    const { data } = yield call(services.tracking.getListVNTracking);

    const trackingList = data.reduce(
      (acc, cur) => {
        const { state } = cur;
        if (state) {
          switch (state) {
            case VN_TRACKING_STATUS.draft.value:
              return { ...acc, DRAFT: [...acc.DRAFT, cur] };

            case VN_TRACKING_STATUS.shipping.value:
              return { ...acc, SHIPPING: [...acc.SHIPPING, cur] };

            case VN_TRACKING_STATUS.delivered.value:
              return { ...acc, DELIVERED: [...acc.DELIVERED, cur] };

            default:
              return acc;
          }
        }
        return acc;
      },
      { DRAFT: [], SHIPPING: [], DELIVERED: [] }
    );

    yield put({ type: TYPE.GET_LIST_VN_TRACKING_SUCCESS, data: trackingList });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_VN_TRACKING_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListVNTracking' });
  }
}

function* getDetailVNTracking(action) {
  try {
    // yield put({
    //   type: TYPE.ENABLE_LOADING_IMPROVE,
    //   target: 'getDetailTracking'
    // });
    const { data } = yield call(
      services.saleOrder.getVnTrackingDetail,
      action.payload
    );
    yield put({ type: TYPE.GET_DETAIL_VN_TRACKING_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_VN_TRACKING_FAILED, error });
  } finally {
    // yield put({
    //   type: TYPE.DISABLE_LOADING_IMPROVE,
    //   target: 'getDetailTracking'
    // });
  }
}

function* confirmVNTracking(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'confirmVNTracking' });

    yield call(services.tracking.confirmVNTracking, action.payload);
    yield put({ type: TYPE.CONFIRM_VN_TRACKING_SUCCESS });

    messageSuccess('Xác nhân thanh toán thành công');
  } catch (error) {
    yield put({ type: TYPE.CONFIRM_VN_TRACKING_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'confirmVNTracking' });
    yield put({ type: TYPE.RESET_LOADING, target: 'confirmVNTracking' });
  }
}

function* getTransferRequest() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getTransferRequest' });
    const {
      data: { results }
    } = yield call(services.tracking.getTransferRequest);
    yield put({ type: TYPE.GET_TRANSFER_REQUEST_SUCCESS, data: results });
  } catch (error) {
    yield put({ type: TYPE.GET_TRANSFER_REQUEST_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getTransferRequest' });
  }
}

function* detailConfirmVNTracking(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'detailConfirmVNTracking'
    });
    const { data } = yield call(
      services.tracking.detailConfirmVNTracking,
      action.payload
    );

    yield put({ type: TYPE.DETAIL_CONFIRM_VN_TRACKING_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.DETAIL_CONFIRM_VN_TRACKING_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'detailConfirmVNTracking'
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_VN_TRACKING, getListVNTracking),
    takeLatest(TYPE.GET_DETAIL_VN_TRACKING, getDetailVNTracking),
    takeLatest(TYPE.CONFIRM_VN_TRACKING, confirmVNTracking),
    takeLatest(TYPE.DETAIL_CONFIRM_VN_TRACKING, detailConfirmVNTracking),
    takeLatest(TYPE.GET_TRANSFER_REQUEST, getTransferRequest)
  ]);
}
