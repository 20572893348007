import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';
import { history } from '../../App';
import {
  BUY_DEAL_SUCCESS,
  FLASH_SALE_CATEGORY,
  Path
} from '../../contents/Constants';

function* getListDealMangement() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getListDealManagement'
    });
    const { data } = yield call(services.deal.getListDealManagement);

    yield put({ type: TYPE.GET_LIST_DEAL_MANAGEMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_DEAL_MANAGEMENT_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getListDealManagement'
    });
  }
}
function* confirmDeal(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'confirmDeal' });
    const { amount, product_deal_id, payment, totalVND } = action.data;
    const { data } = yield call(
      services.deal.confirmDeal,
      amount,
      product_deal_id,
      payment
    );
    window.localStorage.setItem(BUY_DEAL_SUCCESS, JSON.stringify(true));
    if (payment?.type === 'banking')
      history.push(`${Path.REVIEW_BUY_DEAL_DEFAULT_PATH}/purchase/${data._id}`);
    if (payment?.type === 'coin') {
      history.push(
        `${Path.REVIEW_BUY_DEAL_DEFAULT_PATH}/deal-gift-card/${data?.giftCardAfterCreated?._id}`
      );
      yield put({ type: TYPE.UPDATE_BALANCE, payload: totalVND });
    }
  } catch (error) {
    yield put({ type: TYPE.CONFIRM_DEAL_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'confirmDeal' });
  }
}

function* getBoughtDealDetail(action) {
  const { deal_id } = action;
  try {
    const { data } = yield call(services.deal.dealBoughtDetail, deal_id);
    yield put({
      type: TYPE.GET_BOUGHT_DEAL_DETAIL_SUCCESS,
      data
    });
  } catch (error) {
    yield put({ type: TYPE.GET_BOUGHT_DEAL_DETAIL_FAILED, error });
  }
}

function* getListDeal({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListDeal' });
    const { data } = yield call(services.deal.getListDeal, payload);
    let type = TYPE.GET_LIST_DEAL_SUCCESS;
    if (payload.type === FLASH_SALE_CATEGORY.value)
      type = TYPE.GET_LIST_DEAL_FLASH_SALE_SUCCESS;

    yield put({ type, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_DEAL_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListDeal' });
  }
}

function* getDetailDealOrder(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getDetailDealOrder' });
    const { data } = yield call(services.deal.getDetailDealOrder, action.payload);
    yield put({ type: TYPE.GET_DETAIL_DEAL_ORDER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_DEAL_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getDetailDealOrder' });
  }
}

function* addProductToOrderDeal(action) {
  const dealItmes = action.payload.quotation_list.map(item => ({
    link: item?.link,
    name: item?.name,
    image: item?.image,
    price: +item?.price,
    color: item?.color,
    size: item.size,
    quantity: item?.quantity,
    sale_price: +item?.sale_price,
    sale_price_type: item?.sale_price_type,
    promotion_code: item?.note
  }));
  const requestData = {
    deal_id: action.payload.idDeal,
    deal_items: dealItmes
  };
  try {
    const { data } = yield call(services.deal.addProductToOrderDeal, requestData);
    yield put({ type: TYPE.ADD_PRODUCT_TO_ORDER_DEAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.ADD_PRODUCT_TO_ORDER_DEAL_FAILED, error });
  }
}

function* editProductToOrderDeal(action) {
  const deals = action.payload.map(item => ({
    link: item?.link,
    name: item?.name,
    image: item?.image,
    price: +item?.price,
    color: item?.color,
    size: item.size,
    quantity: item?.quantity,
    sale_price: +item?.sale_price,
    sale_price_type: item?.sale_price_type,
    promotion_code: item?.note,
    _id: item?._id
  }));
  try {
    const { data } = yield call(services.deal.editProductToOrderDeal, { deals });
    yield put({ type: TYPE.EDIT_PRODUCT_TO_ORDER_DEAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.EDIT_PRODUCT_TO_ORDER_DEAL_FAILED, error });
  }
}

function* getListProductOrderDeal(action) {
  try {
    const { data } = yield call(
      services.deal.getListProductOrderDeal,
      action.payload
    );
    yield put({ type: TYPE.GET_LIST_PRODUCT_ORDER_DEAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_PRODUCT_ORDER_DEAL_FAILED, error });
  }
}
function* getDetailDeal({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getDetailDeal' });
    const { data } = yield call(services.deal.getDetailDeal, payload);
    yield put({ type: TYPE.GET_DETAIL_DEAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_DEAL_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getDetailDeal' });
  }
}

function* getPaymentCode() {
  try {
    const { data } = yield call(services.deal.getPaymentCode);
    yield put({ type: TYPE.GET_PAYMENT_CODE_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_PAYMENT_CODE_FAILED, error });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_DEAL_MANAGEMENT, getListDealMangement),
    takeLatest(TYPE.GET_LIST_DEAL, getListDeal),
    takeLatest(TYPE.GET_LIST_PRODUCT_ORDER_DEAL, getListProductOrderDeal),
    takeLatest(TYPE.GET_DETAIL_DEAL_ORDER, getDetailDealOrder),
    takeLatest(TYPE.ADD_PRODUCT_TO_ORDER_DEAL, addProductToOrderDeal),
    takeLatest(TYPE.EDIT_PRODUCT_TO_ORDER_DEAL, editProductToOrderDeal),
    takeLatest(TYPE.GET_DETAIL_DEAL, getDetailDeal),
    takeLatest(TYPE.CONFIRM_DEAL, confirmDeal),
    takeLatest(TYPE.GET_BOUGHT_DEAL_DETAIL, getBoughtDealDetail),
    takeLatest(TYPE.GET_PAYMENT_CODE, getPaymentCode)
  ]);
}
