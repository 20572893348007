import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CoinIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="19.6667"
        cy="19.6667"
        r="16.6667"
        fill="#FFAA00"
        stroke="#FADA5A"
        strokeWidth="3"
      />
      <path
        d="M24.1299 19.3935C25.0947 19.6614 25.8381 20.15 26.3601 20.8592C26.8978 21.5684 27.1667 22.4353 27.1667 23.4597C27.1667 24.8781 26.6527 25.9892 25.6246 26.793C24.6123 27.5968 23.2126 27.9987 21.4253 27.9987H15.3043C14.8456 27.9987 14.4819 27.8726 14.213 27.6204C13.9599 27.3683 13.8334 27.0137 13.8334 26.5566V12.7741C13.8334 12.3171 13.9599 11.9624 14.213 11.7103C14.4819 11.4581 14.8456 11.332 15.3043 11.332H21.1881C22.9279 11.332 24.2881 11.7182 25.2687 12.4904C26.2652 13.2469 26.7634 14.3108 26.7634 15.6819C26.7634 16.5645 26.5261 17.3289 26.0516 17.9751C25.593 18.6212 24.9524 19.094 24.1299 19.3935ZM16.8227 18.3533H20.6898C22.7934 18.3533 23.8452 17.5732 23.8452 16.0129C23.8452 15.2249 23.5843 14.6417 23.0623 14.2635C22.5404 13.8852 21.7495 13.6961 20.6898 13.6961H16.8227V18.3533ZM21.0932 25.6346C22.2003 25.6346 23.007 25.4376 23.5131 25.0436C24.0192 24.6496 24.2723 24.0349 24.2723 23.1996C24.2723 22.3643 24.0113 21.7418 23.4894 21.332C22.9832 20.9223 22.1845 20.7174 21.0932 20.7174H16.8227V25.6346H21.0932Z"
        fill="white"
      />
    </SvgIcon>
  );
}
