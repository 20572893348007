import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ListAddressIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.33398H4C2.89333 1.33398 2 2.22065 2 3.31398V10.5873C2 11.6807 2.89333 12.5673 4 12.5673H4.50667C5.04 12.5673 5.54667 12.774 5.92 13.1473L7.06 14.274C7.58 14.7873 8.42667 14.7873 8.94667 14.274L10.0867 13.1473C10.46 12.774 10.9733 12.5673 11.5 12.5673H12C13.1067 12.5673 14 11.6807 14 10.5873V3.31398C14 2.22065 13.1067 1.33398 12 1.33398ZM8 3.83398C8.86 3.83398 9.55333 4.52732 9.55333 5.38732C9.55333 6.24732 8.86 6.94065 8 6.94065C7.14 6.94065 6.44667 6.24065 6.44667 5.38732C6.44667 4.52732 7.14 3.83398 8 3.83398ZM9.78667 10.0407H6.21333C5.67333 10.0407 5.36 9.44065 5.66 8.99398C6.11333 8.32065 6.99333 7.86732 8 7.86732C9.00667 7.86732 9.88667 8.32065 10.34 8.99398C10.64 9.44065 10.32 10.0407 9.78667 10.0407Z"
        fill="#292D32"
      />
    </SvgIcon>
  );
}
