/* eslint-disable prefer-template */
/* eslint-disable max-len */
import { makeStyles } from '@material-ui/core/styles';
import React, { Suspense, useState } from 'react';
import { useLocation } from 'react-router';
import Animation from '../../components/icons/new/improvement/animation/Animation';
import useResponsive from '../useResponsive';

const useStyles = makeStyles(theme => ({
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      '& > div': { fontSize: 16 }
    }
  }
}));

export default function useActiveMode({
  path,
  animation,
  icon: DefaultIcon,
  activeIcon: ActiveIcon
}) {
  const classes = useStyles();
  const matches = useResponsive();
  const { pathname } = useLocation();
  const [activeMode, setActiveMode] = useState(false);

  const onActiveMode = () => setActiveMode(true);
  const onInactiveMode = () => setActiveMode(false);

  const IconComponent = () => {
    if (activeMode && !matches) {
      if (!animation) {
        return (
          <Suspense fallback={<div />}>
            <ActiveIcon />
          </Suspense>
        );
      }

      return <Animation animationData={animation} className={classes.icon} />;
    }

    if (ActiveIcon && path === pathname) {
      return (
        <Suspense fallback={<div />}>
          <ActiveIcon />
        </Suspense>
      );
    }

    return (
      <Suspense fallback={<div />}>
        <DefaultIcon />
      </Suspense>
    );
  };

  return { IconComponent, onActiveMode, onInactiveMode, activeMode };
}
