import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function BlogActiveIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H24V24H0V0Z" fill="white" />
      <path d="M0 0H24V24H0V0Z" fill="white" />
      <path
        d="M17.999 2.01562H6.0009C4.90108 2.01562 4.00122 2.91548 4.00122 4.0153V20.0127C4.00122 21.1126 4.90108 22.0124 6.0009 22.0124H17.999C19.0988 22.0124 19.9987 21.1126 19.9987 20.0127V4.0153C19.9987 2.91548 19.0988 2.01562 17.999 2.01562ZM7.00074 5.13513C7.00074 4.51522 7.50066 4.0153 8.12056 4.0153H10.8801C11.5 4.0153 11.9999 4.51522 11.9999 5.13513V7.89468C11.9999 8.51459 11.5 9.0145 10.8801 9.0145H8.12056C7.50066 9.0145 7.00074 8.51459 7.00074 7.89468V5.13513ZM11.1201 20.0227H7.76062C7.34069 20.0227 7.01074 19.6828 7.01074 19.2729C7.01074 18.8529 7.34069 18.523 7.76062 18.523H11.1201C11.54 18.523 11.88 18.8529 11.88 19.2729C11.88 19.6828 11.54 20.0227 11.1201 20.0227ZM16.2193 16.5133H7.72063C7.30069 16.5133 6.97075 16.1834 6.97075 15.7634C6.97075 15.3535 7.30069 15.0135 7.72063 15.0135H16.2193C16.4181 15.0135 16.6089 15.0925 16.7495 15.2332C16.8901 15.3738 16.9691 15.5645 16.9691 15.7634C16.9691 15.9623 16.8901 16.153 16.7495 16.2937C16.6089 16.4343 16.4181 16.5133 16.2193 16.5133ZM16.2193 13.0139H7.73062C7.32069 13.0139 6.98074 12.6739 6.98074 12.264C6.98074 11.8541 7.32069 11.5141 7.73062 11.5141H16.2193C16.6292 11.5141 16.9691 11.8541 16.9691 12.264C16.9691 12.6739 16.6292 13.0139 16.2193 13.0139Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
