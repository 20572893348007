import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import LoginManual from '../../loginManual/LoginManual';
import AuthGoogleFacebook from '../AuthGoogleFacebook';

const useStyles = makeStyles(theme => ({
  divider: {
    position: 'relative'
  },
  or: {
    position: 'absolute',
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
    color: theme.palette.grey[400],
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.common.white,
    padding: theme.spacing(0, 2)
  }
}));

export default function PopupLogin() {
  const classes = useStyles();

  return (
    <Box width={373} padding={3}>
      <Typography align="center" variant="h4">
        Đăng nhập bằng
      </Typography>
      <Box my={3}>
        <AuthGoogleFacebook isFastLogin />
      </Box>
      <Box className={classes.divider}>
        <div className={classes.or}>Hoặc</div>
        <Divider />
      </Box>
      <Box>
        <LoginManual size="small" isFastLogin />
      </Box>
    </Box>
  );
}
