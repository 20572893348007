import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import keys from 'lodash/keys';
import size from 'lodash/size';
import { func, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { ORDER_TRACKING_STATE } from '../../../contents/Constants';

const useStyles = makeStyles({
  tabs: {
    '& .MuiTab-textColorPrimary': {
      color: '#86878F',
      fontWeight: 400
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      color: '#1D50FF',
      fontWeight: 700
    }
  },
  tab: {
    '&.MuiTab-root': {
      minWidth: 'calc(100% / 7)'
    }
  }
});

function Navigation({ handleChangeTab, tabValue }) {
  const classes = useStyles();
  const orders = useSelector(state => state.saleOrder.orders);

  return (
    <Tabs
      value={tabValue}
      onChange={handleChangeTab}
      indicatorColor="primary"
      textColor="primary"
      className={classes.tabs}
      variant="scrollable"
    >
      {keys(ORDER_TRACKING_STATE).map((item, index) => {
        const numProduct = size(
          orders[ORDER_TRACKING_STATE[item].value]?.filter || []
        );
        const numProductText = numProduct > 0 ? `(${numProduct})` : '';
        return (
          <Tab
            className={classes.tab}
            label={`${ORDER_TRACKING_STATE[item].label} ${numProductText}`}
            key={index}
            value={ORDER_TRACKING_STATE[item].value}
          />
        );
      })}
    </Tabs>
  );
}

export default Navigation;

Navigation.propTypes = {
  handleChangeTab: func,
  tabValue: string
};

Navigation.defaultProps = {
  handleChangeTab: () => {},
  tabValue: '1'
};
