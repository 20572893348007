import find from 'lodash/find';
import get from 'lodash/get';
import identity from 'lodash/identity';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import size from 'lodash/size';

import { nanoid } from 'nanoid';
import {
  all,
  call,
  debounce,
  delay,
  put,
  race,
  select,
  takeEvery,
  takeLatest
} from 'redux-saga/effects';
import { history } from '../../App';
import {
  ANONYMOUS_ID,
  CRAWL_DATA_VALUES,
  IS_AUTH,
  MessageContent,
  ORDER_BY_DEAL_SUCCESS,
  ORDER_TRACKING_STATE,
  Path,
  SCRAPED_LIST,
  TYPE_SALE_ORDER_PATH
} from '../../contents/Constants';
import services from '../../services';
import { messageError, messageSuccess } from '../../utils/alertMessage';
import { offLoading } from '../../utils/dispatch';
import { countProductNumber } from '../../utils/extension';
import { convertQuotation } from '../../utils/helpers';
import TYPE from '../actions/TYPE';
import { Labels } from '../../contents/Labels';
import { getRedirectObj } from '../../hooks/2.2.0/useRedirectToAuthPage';

function* handleCreateSaleOrder() {
  try {
    const { data: currentSaleOrder } = yield call(
      services.saleOrder.getCurrentSaleOrder
    );
    if (currentSaleOrder) return;
    const { data } = yield call(services.saleOrder.createSaleOrder);
    yield put({ type: TYPE.CREATE_SALE_ORDER_SUCCESS, data });
  } catch (error) {
    yield put({
      type: TYPE.CREATE_SALE_ORDER_FAILED,
      message: error
    });
  } finally {
    // yield offLoading();
  }
}

function* handleUpdateSaleOrder(action) {
  try {
    const { quotationList } = action;
    const { data } = yield call(services.saleOrder.updateSaleOrder, quotationList);
    yield put({
      type: TYPE.UPDATE_SALE_ORDER_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.UPDATE_SALE_ORDER_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* handleUpdateQuotation(action) {
  try {
    const { data, id } = action;
    const response = yield call(services.saleOrder.updateQuotation, id, data);

    yield put({
      type: TYPE.UPDATE_QUOTATION_SUCCESS,
      data: response.data
    });
  } catch (error) {
    yield put({
      type: TYPE.UPDATE_QUOTATION_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* handleCancelSaleOrder(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'deleteWaitConfirm'
    });

    const { id } = action;
    const { data } = yield call(services.saleOrder.cancelSaleOrder, id);
    yield put({
      type: TYPE.CANCEL_SALE_ORDER_SUCCESS,
      data,
      id
    });

    messageSuccess('Huỷ đơn hàng thành công');
  } catch (error) {
    yield put({
      type: TYPE.CANCEL_SALE_ORDER_FAILED,
      message: error
    });
    messageError('Huỷ đơn hàng thất bại');
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'deleteWaitConfirm'
    });
    // yield offLoading();
  }
}

function* getCurrentSaleOrder() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'currentSaleOrder'
    });

    const { data: quotation_list } = yield call(
      services.saleOrder.getCurrentSaleOrder
    );

    yield put({
      type: TYPE.GET_CURRENT_SALE_ORDER_SUCCESS,
      data: { quotation_list }
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_CURRENT_SALE_ORDER_FAILED,
      message: error
    });
  } finally {
    // yield offLoading();
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'currentSaleOrder'
    });
  }
}

function* confirmSaleOrder({ payload: { optional, ...payment } }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'confirmSaleOrder' });
    const { data, success } = yield call(services.saleOrder.confirmSaleOrder, {
      payment
    });
    window.localStorage.setItem(ORDER_BY_DEAL_SUCCESS, JSON.stringify(true));

    if (!success) return;

    yield put({
      type: TYPE.CONFIRM_SALE_ORDER_SUCCESS,
      data,
      amount: optional?.amount
    });
    if (payment?.type !== 'gift_card') {
      history.push(
        `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft/detail/${data.sale_order._id}`
      );
      yield put({ type: TYPE.UPDATE_USER_PROFILE_SUCCESS, data: data.user });
    }
    if (payment?.type === 'gift_card') {
      history.push(
        `${Path.REVIEW_BUY_DEAL_DEFAULT_PATH}/order-complete/${optional.giftCardId}`
      );
      yield put({ type: TYPE.UPDATE_PRICE_IN_USED, optional });
    }
    if (payment?.type === 'coin') {
      yield put({
        type: TYPE.UPDATE_BALANCE,
        payload: data?.sale_order?.total_price_after_sale_VND
      });
    }
    messageSuccess('Xác nhận đơn hàng thành công');
    yield put({ type: TYPE.IS_TRANSACTION, isTrue: false });
    // yield handleCreateSaleOrder();
  } catch (error) {
    yield put({ type: TYPE.CONFIRM_SALE_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'confirmSaleOrder' });
  }
}

function* deleteQuotation(action) {
  const { id } = action;
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: id });
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    const { data } = yield call(services.saleOrder.deleteQuotation, id);
    yield put({
      type: TYPE.DELETE_QUOTATION_SUCCESS,
      data
    });
    const listChecked = yield select(state =>
      get(state, 'saleOrder.cartOrderChecked', []).filter(item => item !== id)
    );
    yield put({
      type: TYPE.CHECK_SALE_ORDER_DRAFT,
      data: listChecked
    });
  } catch (error) {
    yield put({
      type: TYPE.DELETE_QUOTATION_FAILED
    });
  } finally {
    yield countProductNumber();
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: id });
    // yield disableLoading();
  }
}

function* deleteQuotationAnonymous(actions) {
  const { id } = actions;
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: id });
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    const { data } = yield call(services.saleOrder.deleteShoppingCartAnonymous, {
      id,
      anonymous_id: localStorage.getItem(ANONYMOUS_ID)
    });

    yield put({
      type: TYPE.DELETE_QUOTATION_ANONYMOUS_SUCCESS,
      data
    });
    const listChecked = yield select(state =>
      get(state, 'saleOrder.cartOrderChecked', []).filter(item => item !== id)
    );
    yield put({
      type: TYPE.CHECK_SALE_ORDER_DRAFT,
      data: listChecked
    });
  } catch (error) {
    yield put({
      type: TYPE.DELETE_QUOTATION_ANONYMOUS_FAILED
    });
  } finally {
    yield countProductNumber();
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: id });
  }
}

function* getListSaleOrder(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'orderManagement' });
    const { params } = action;
    const cleanedParams = pickBy(params, identity);

    const { data } = yield call(services.saleOrder.getListSaleOrder, cleanedParams);
    yield put({
      type: TYPE.GET_LIST_SALE_ORDER_SUCCESS,
      data,
      count: size(data)
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_LIST_SALE_ORDER_FAILED
    });
  } finally {
    // yield offLoading();
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'orderManagement'
    });
  }
}

function* getSaleOrder(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'orderDetail' });
    const { saleOrderID, saleOrderType } = action;
    const cleanedParams = pickBy(
      {
        odoo_id: saleOrderType === TYPE_SALE_ORDER_PATH.odoo_id ? saleOrderID : '',
        mongodb_id:
          saleOrderType === TYPE_SALE_ORDER_PATH.mongodb_id ? saleOrderID : ''
      },
      identity
    );
    const { data } = yield call(services.saleOrder.getSaleOrder, cleanedParams);
    yield put({
      type: TYPE.GET_SALE_ORDER_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_SALE_ORDER_FAILED
    });
  } finally {
    // yield offLoading();
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'orderDetail' });
  }
}

function* notificationSaleOrder(action) {
  try {
    const { saleOrderId, saleOrderOdooId } = action;
    yield put({
      type: TYPE.ALERT_MESSAGE_SUCCESS,
      message: MessageContent.notificationSaleOrder
    });
    yield put({
      type: TYPE.NOTIFICATION_SALE_ORDER_SUCCESS,
      saleOrderId,
      saleOrderOdooId
    });
  } catch (err) {
    yield put({
      type: TYPE.NOTIFICATION_SALE_ORDER_FAILED
    });
  }
}

function* uploadFileQuotation(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'importQuotation' });
    const { data } = yield call(services.upload.files, action.file);
    yield put({
      type: TYPE.UPLOAD_FILE_QUOTATION_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.UPLOAD_FILE_QUOTATION_FAILED
    });
    messageError('Vui lòng kiểm tra lại file import');
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'importQuotation'
    });
    // yield disableLoading();
  }
}

function* importQuotationFile({ payload }) {
  const { file } = payload;
  payload?.beforeCallback?.();
  try {
    const { data } = yield call(services.upload.files, file);
    const quotationList = data.map(item => ({
      id: nanoid(),
      link: item.link,
      image: item?.image || '',
      name: item?.name || '',
      price: item?.price < 0 ? '' : item?.price,
      color: item?.color || '',
      size: item?.size || '',
      quantity: item?.quantity < 1 ? 1 : item?.quantity,
      notice: item?.notice || '',
      is_gift: false,
      category: item?.category_obj || {}
    }));

    if (size(quotationList) < 1) {
      messageError('Không có sản phẩm nào trong file');
    }

    yield put({
      type: TYPE.IMPORT_QUOTATION_FILE_SUCCESS,
      data: quotationList
    });
    yield put({
      type: TYPE.PUSH_TO_QUOTATION_LIST,
      payload: quotationList
    });
  } catch (err) {
    yield put({
      type: TYPE.IMPORT_QUOTATION_FILE_FAILED
    });
    messageError('Vui lòng kiểm tra lại file import');
  } finally {
    payload?.afterCallback?.();
  }
}

function* confirmShoppingCart(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'confirmShoppingCart'
    });
    const { data } = yield call(services.saleOrder.confirmShoppingCart, {
      cartItemIds: action.ids
      // coupons: action.coupons
    });
    yield put({
      type: TYPE.CONFIRM_SHOPPING_CART_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.CONFIRM_SHOPPING_CART_FAILED
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'confirmShoppingCart'
    });
    // yield offLoading();
  }
}

function* addQuotation(action) {
  try {
    const requestData = map(action.payload.quotation, item =>
      convertQuotation(item, { skuid: item.skuid })
    );
    const { data } = yield call(services.saleOrder.addShoppingCart, {
      items: requestData
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

function* addQuotationAnonymous(action) {
  try {
    const requestData = map(action.payload.quotation, item =>
      convertQuotation(item)
    );
    const { data } = yield call(services.saleOrder.addShoppingCartAnonymous, {
      quotation_list: requestData,
      anonymous_id: localStorage.getItem(ANONYMOUS_ID)
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
}

function* addShoppingCart(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.ADD_SHOPPING_CART
    });
    const data = yield addQuotation(action);
    messageSuccess('Cho vào giỏ hàng thành công');
    yield put({
      type: TYPE.ADD_SHOPPING_CART_SUCCESS,
      data: { quotation_list: data }
    });
  } catch (error) {
    yield put({
      type: TYPE.ADD_SHOPPING_CART_FAILED,
      error
    });
  } finally {
    yield countProductNumber();
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.ADD_SHOPPING_CART
    });
    yield put({ type: TYPE.ADD_SHOPPING_CART_COMPLETE });
    yield put({ type: TYPE.RESET_QUOTATION_LIST });
  }
}

function* addShoppingCartAnonymous(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.ADD_SHOPPING_CART_ANONYMOUS
    });
    const data = yield addQuotationAnonymous(action);

    yield put({ type: TYPE.ADD_SHOPPING_CART_ANONYMOUS_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.ADD_SHOPPING_CART_ANONYMOUS_FAILED });
  } finally {
    yield countProductNumber();
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.ADD_SHOPPING_CART_ANONYMOUS
    });
    yield put({ type: TYPE.ADD_SHOPPING_CART_ANONYMOUS_COMPLETE });
    yield put({ type: TYPE.RESET_QUOTATION_LIST });
  }
}

function* buynow(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.BUYNOW
    });
    const data = yield addQuotation(action);
    const productId = data[data.length - 1]._id;

    yield put({
      type: TYPE.CHECK_SALE_ORDER_DRAFT,
      data: [productId]
    });

    yield call(confirmShoppingCart, {
      ids: [productId]
    });
    yield put({ type: TYPE.IS_TRANSACTION, isTrue: true });
    history.push(Path.SHOPPING_ORDER);
  } catch (error) {
    messageError('Không thể mua ngay sản phẩm này');
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.BUYNOW
    });
  }
}

function* buynowAnonymous(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.BUYNOW_ANONYMOUS
    });
    yield addQuotationAnonymous(action);
    history.push(getRedirectObj(history.location, Path.SIGN_IN));
    messageError(Labels.UsingShopping);
  } catch (error) {
    messageError('Không thể mua ngay sản phẩm này');
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.BUYNOW_ANONYMOUS
    });
  }
}

function* editSaleOrderImprovement(action) {
  const { id, data, isLoading } = action;
  try {
    if (isLoading) {
      yield put({ type: TYPE.ENABLE_LOADING_CATEGORY });
    }
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: id });
    const requestData = convertQuotation(data);
    const { data: responseData } = yield call(
      services.saleOrder.editSaleOrderImprovement,
      id,
      requestData
    );

    yield put({
      type: TYPE.EDIT_SALE_ORDER_IMPROVEMENT_SUCCESS,
      data: responseData
    });
  } catch (error) {
    yield put({ type: TYPE.EDIT_SALE_ORDER_IMPROVEMENT_FAILED });
  } finally {
    if (isLoading) {
      yield put({ type: TYPE.DISABLE_LOADING_CATEGORY });
    }
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: id });
  }
}

function* editSaleOrderAnonymous(action) {
  const { id, data, isLoading } = action;
  try {
    if (isLoading) {
      yield put({ type: TYPE.ENABLE_LOADING_CATEGORY });
    }
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: id });
    const requestData = convertQuotation(data, {
      created_at: new Date(data.created_at)
    });
    const { data: responseData } = yield call(
      services.saleOrder.editSaleOrderAnonymous,
      id,
      {
        quotation_list: requestData,
        anonymous_id: localStorage.getItem(ANONYMOUS_ID)
      }
    );
    yield put({
      type: TYPE.EDIT_SALE_ORDER_ANONYMOUS_SUCCESS,
      data: responseData
    });
  } catch (error) {
    yield put({ type: TYPE.EDIT_SALE_ORDER_ANONYMOUS_FAILED });
  } finally {
    // yield disableLoading();
    if (isLoading) {
      yield put({ type: TYPE.DISABLE_LOADING_CATEGORY });
    }
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'manipulateSO' });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: id });
  }
}

function* getSelectedSaleOrder() {
  try {
    const { data } = yield call(services.saleOrder.getSelectedSaleOrder);
    yield put({ type: TYPE.GET_SELECTED_SALE_ORDER_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_SELECTED_SALE_ORDER_FAILED, message: error });
  } finally {
    yield offLoading();
  }
}

function* getShippingCategories() {
  try {
    const { data } = yield call(services.saleOrder.getShippingCategories);
    yield put({ type: TYPE.GET_SHIPPING_CATEGORIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_SHIPPING_CATEGORIES_SUCCESS, error });
  }
}

function* getListPromotion() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListPromotion' });
    const { data } = yield call(services.saleOrder.getListPromotion);
    yield put({ type: TYPE.GET_LIST_PROMOTION_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_PROMOTION_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListPromotion' });
  }
}

function* inputPromotion(action) {
  try {
    const curChooseList = yield select(
      state => state?.saleOrder?.listingChoosePromotion || []
    );
    const isExist = curChooseList.some(item => item.program_name === action.code);

    if (isExist) {
      const message = 'Đã chọn mã khuyến mãi này';
      messageError(message);
      throw new Error(message);
    }

    const { data } = yield call(services.saleOrder.inputPromotion, action.code);
    if (size(data) > 0) {
      messageSuccess('Thêm mã khuyến mãi thành công');
      yield put({ type: TYPE.INPUT_PROMOTION_SUCCESS, data });
    } else {
      messageError('Mã khuyến mãi không hợp lệ');
      yield put({
        type: TYPE.INPUT_PROMOTION_FAILED,
        payload: { error: data, foundPromotion: 'not-found' }
      });
    }
  } catch (error) {
    yield put({
      type: TYPE.INPUT_PROMOTION_FAILED,
      payload: { error, foundPromotion: 'not-found' }
    });
  }
}

function* handlePostScraper(action) {
  try {
    const { link } = action;
    const listData = JSON.parse(window.sessionStorage.getItem(SCRAPED_LIST)) || [];
    const data = find(listData, { link });

    if (data) {
      yield put({ type: TYPE.POST_SCRAPER_SUCCESS, data });
      return;
    }

    yield put({ type: TYPE.POST_SCRAPER_LOADING, data });
    // yield call(() => new Promise(resolve => setTimeout(resolve, 5_000)));
    const { response, ...other } = yield race({
      response: call(services.saleOrder.handlePostScraper, link),
      timeout: delay(12000)
    });

    if (other?.timeout) {
      yield put({ type: TYPE.POST_SCRAPER_FAILED, error: 'Timeout' });
      return;
    }

    // const response = yield call(services.saleOrder.handlePostScraper, link);
    const newData = {
      link,
      image: response?.image || '',
      name: response?.name || '',
      price: response?.price || '',
      color: response?.color || '',
      size: response?.size || ''
    };
    const newListData = [...listData, newData];
    window.sessionStorage.setItem(SCRAPED_LIST, JSON.stringify(newListData));
    const isScraper = yield select(state => state.common?.loadingFields?.scraper);
    if (isScraper) {
      yield put({ type: TYPE.POST_SCRAPER_SUCCESS, data: newData });
    }
  } catch (error) {
    yield put({ type: TYPE.POST_SCRAPER_FAILED, error });
  } finally {
    yield put({ type: TYPE.POST_SCRAPER_COMPLETED });
  }
}

function* handleCrawlData({ payload }) {
  const { link } = payload;
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: TYPE.CRAWL_DATA });

    const listData = JSON.parse(window.sessionStorage.getItem(SCRAPED_LIST)) || [];
    const data = find(listData, { link });
    if (data) {
      yield put({ type: TYPE.CRAWL_DATA_SUCCESS, data });
      return;
    }

    const { response, ...other } = yield race({
      response: call(services.saleOrder.handlePostScraper, link),
      timeout: delay(12000)
    });
    if (other?.timeout) throw new Error('Timeout');

    const newData = {
      link,
      image: response?.image || '',
      name: response?.name || '',
      price: response?.price || '',
      color: response?.color || '',
      size: response?.size || ''
    };
    const newListData = [...listData, newData];
    window.sessionStorage.setItem(SCRAPED_LIST, JSON.stringify(newListData));

    yield put({ type: TYPE.CRAWL_DATA_SUCCESS, data: newData });
  } catch (error) {
    yield put({
      type: TYPE.CRAWL_DATA_FAILED,
      data: { ...CRAWL_DATA_VALUES, link },
      error
    });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: TYPE.CRAWL_DATA });
    payload?.callback?.();
  }
}

function* getShoppingCartAnonymous() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getShoppingCartAnonymous'
    });
    const anonymous_id = localStorage.getItem(ANONYMOUS_ID);
    const { data: quotation_list } = yield call(
      services.saleOrder.getShoppingCartAnonymous,
      anonymous_id
    );

    yield put({
      type: TYPE.GET_SHOPPING_CART_ANONYMOUS_SUCCESS,
      data: { quotation_list }
    });
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getShoppingCartAnonymous'
    });
  } catch (error) {
    yield put({ type: TYPE.GET_SHOPPING_CART_ANONYMOUS_FAILED });
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getShoppingCartAnonymous'
    });
  }
}

function* putToLoggedCart({ anonymous_id }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'putToLoggedCart' });
    const results = yield call(services.saleOrder.putToLoggedCart, anonymous_id);

    yield put({
      type: TYPE.PUT_TO_LOGGED_CART_SUCCESS,
      data: { quotation_list: Array.isArray(results?.data) ? results?.data : [] }
    });
  } catch (error) {
    yield put({ type: TYPE.PUT_TO_LOGGED_CART_FAILED });
  } finally {
    yield countProductNumber();
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'putToLoggedCart' });
  }
}

function* callListPagination({ payload }) {
  try {
    const { data: listTracking } = yield call(
      services.saleOrder.getListOrderTracking,
      { type: payload.include }
    );

    const status = payload.value;

    yield put({
      type: TYPE.GET_LIST_ORDER_MANAGEMENT_SUCCESS,
      data: listTracking.rows,
      status
    });

    return listTracking;
  } catch (error) {
    throw Error(error);
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getListOrderManagement'
    });
  }
}

function* getListOrderManagement() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getListOrderManagement'
    });
    yield put({
      type: TYPE.CALL_SALE_ORDER_PAGINATION,
      payload: ORDER_TRACKING_STATE.DRAFT
    });
    yield put({
      type: TYPE.CALL_SALE_ORDER_PAGINATION,
      payload: ORDER_TRACKING_STATE.CONFIRMED
    });
    yield put({
      type: TYPE.CALL_SALE_ORDER_PAGINATION,
      payload: ORDER_TRACKING_STATE.TRACKING
    });
    yield put({
      type: TYPE.CALL_SALE_ORDER_PAGINATION,
      payload: ORDER_TRACKING_STATE.PURCHASING
    });
    yield put({
      type: TYPE.CALL_SALE_ORDER_PAGINATION,
      payload: ORDER_TRACKING_STATE.DELIVERY
    });
    yield put({
      type: TYPE.CALL_SALE_ORDER_PAGINATION,
      payload: ORDER_TRACKING_STATE.DONE
    });
    yield put({
      type: TYPE.CALL_SALE_ORDER_PAGINATION,
      payload: ORDER_TRACKING_STATE.CANCEL
    });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_ORDER_MANAGEMENT_FAILED, error });
  }
}

function* getDetailOrderManagement(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getDetailOrderManagement'
    });
    const { data } = yield call(
      services.saleOrder.getDetailOrderTracking,
      action.id
    );
    yield put({ type: TYPE.GET_DETAIL_ORDER_MANAGEMENT_SUCCESS, data });
    yield delay(500);
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_ORDER_MANAGEMENT_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getDetailOrderManagement'
    });
  }
}

function* getDetailTracking(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getDetailTracking'
    });
    const { data } = yield call(services.saleOrder.getTrackingDetail, action.id);
    yield put({ type: TYPE.GET_DETAIL_TRACKING_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_TRACKING_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getDetailTracking'
    });
  }
}

function* getTrackingJourney(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getTrackingJourney'
    });
    const {
      payload: { id, type }
    } = action;
    const { data } = yield call(services.saleOrder.getTrackingJourney, id, type);
    yield put({ type: TYPE.GET_TRACKING_JOURNEY_SUCCESS, data });
    yield delay(500);
  } catch (error) {
    yield put({ type: TYPE.GET_TRACKING_JOURNEY_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getTrackingJourney'
    });
  }
}

function* reorderSaleOrder({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: payload.subname });
    const { data } = yield call(services.saleOrder.reorderSaleOrder, payload);
    yield put({ type: TYPE.REORDER_SALE_ORDER_SUCCESS, data });
    history.push(Path.SHOPPING_CART);
  } catch (error) {
    yield put({ type: TYPE.REORDER_SALE_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: payload.subname });
  }
}

function* updateWaitingSaleOrder({ payload }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'updateWaitingSaleOrder'
    });
    yield call(services.saleOrder.updateWaitingSaleOrder, payload);
    yield put({ type: TYPE.UPDATE_WAITING_SALE_ORDER_SUCCESS, payload });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_WAITING_SALE_ORDER_FAILED });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'updateWaitingSaleOrder'
    });
  }
}

function* getPromotion() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.GET_PROMOTION
    });
    const isAuth = window.localStorage.getItem(IS_AUTH);
    const anonymous_id = window.localStorage.getItem(ANONYMOUS_ID);
    const getPromotionService = isAuth
      ? services.saleOrder.getPromotion
      : services.saleOrder.getPromotionAnonymous;
    const getPromotionPayload = isAuth ? null : anonymous_id;
    const { data } = yield call(getPromotionService, getPromotionPayload);
    yield put({
      type: TYPE.GET_PROMOTION_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_PROMOTION_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.GET_PROMOTION
    });
  }
}

function* createNewPromotion({ payload, callback }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.CREATE_NEW_PROMOTION
    });
    const anonymous_id = window.localStorage.getItem(ANONYMOUS_ID);
    const requestData = {
      ...payload,
      ...(anonymous_id && { anonymous_id })
    };
    const { data } = yield call(services.saleOrder.createNewPromotion, requestData);

    yield put({
      type: TYPE.CREATE_NEW_PROMOTION_SUCCESS,
      data
    });
    const cartOrderChecked = yield select(state => state.saleOrder.cartOrderChecked);
    const newSelected = [...cartOrderChecked, ...payload.quotation_ids];
    yield put({ type: TYPE.CHECK_SALE_ORDER_DRAFT, data: newSelected });
  } catch (error) {
    yield put({
      type: TYPE.CREATE_NEW_PROMOTION_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.CREATE_NEW_PROMOTION
    });
    callback?.();
  }
}

function* deletePromotion({ payload, callback }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.DELETE_PROMOTION
    });
    const anonymous_id = window.localStorage.getItem(ANONYMOUS_ID);
    const requestData = {
      ...payload,
      ...(anonymous_id && { anonymous_id })
    };
    const {
      data: { cart_items }
    } = yield call(services.saleOrder.deletePromotion, requestData);
    yield put({
      type: TYPE.DELETE_PROMOTION_SUCCESS,
      data: cart_items
    });
  } catch (error) {
    yield put({
      type: TYPE.DELETE_PROMOTION_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.DELETE_PROMOTION
    });
    callback?.();
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.CREATE_SALE_ORDER, handleCreateSaleOrder),
    takeLatest(TYPE.REORDER_SALE_ORDER, reorderSaleOrder),
    takeLatest(TYPE.UPDATE_SALE_ORDER, handleUpdateSaleOrder),
    takeLatest(TYPE.UPDATE_QUOTATION, handleUpdateQuotation),
    takeLatest(TYPE.CANCEL_SALE_ORDER, handleCancelSaleOrder),
    takeLatest(TYPE.GET_CURRENT_SALE_ORDER, getCurrentSaleOrder),
    takeLatest(TYPE.CONFIRM_SALE_ORDER, confirmSaleOrder),

    takeLatest(TYPE.BUYNOW, buynow),
    takeLatest(TYPE.BUYNOW_ANONYMOUS, buynowAnonymous),

    takeEvery(TYPE.ADD_SHOPPING_CART, addShoppingCart),
    takeEvery(TYPE.ADD_SHOPPING_CART_ANONYMOUS, addShoppingCartAnonymous),

    takeLatest(TYPE.DELETE_QUOTATION, deleteQuotation),
    takeLatest(TYPE.DELETE_QUOTATION_ANONYMOUS, deleteQuotationAnonymous),
    takeLatest(TYPE.GET_LIST_SALE_ORDER, getListSaleOrder),
    takeLatest(TYPE.GET_SALE_ORDER, getSaleOrder),
    takeLatest(TYPE.NOTIFICATION_SALE_ORDER, notificationSaleOrder),
    takeLatest(TYPE.UPLOAD_FILE_QUOTATION, uploadFileQuotation),
    takeLatest(TYPE.IMPORT_QUOTATION_FILE, importQuotationFile),
    takeLatest(TYPE.CONFIRM_SHOPPING_CART, confirmShoppingCart),
    takeLatest(TYPE.EDIT_SALE_ORDER_IMPROVEMENT, editSaleOrderImprovement),
    takeLatest(TYPE.GET_SELECTED_SALE_ORDER, getSelectedSaleOrder),
    takeLatest(TYPE.CRAWL_DATA, handleCrawlData),
    debounce(500, TYPE.POST_SCRAPER, handlePostScraper),
    takeLatest(TYPE.GET_SHIPPING_CATEGORIES, getShippingCategories),
    takeLatest(TYPE.GET_LIST_PROMOTION, getListPromotion),
    takeLatest(TYPE.INPUT_PROMOTION, inputPromotion),
    takeLatest(TYPE.EDIT_SALE_ORDER_ANONYMOUS, editSaleOrderAnonymous),
    takeLatest(TYPE.GET_SHOPPING_CART_ANONYMOUS, getShoppingCartAnonymous),
    takeLatest(TYPE.GET_LIST_ORDER_MANAGEMENT, getListOrderManagement),
    takeLatest(TYPE.PUT_TO_LOGGED_CART, putToLoggedCart),
    takeLatest(TYPE.GET_DETAIL_ORDER_MANAGEMENT, getDetailOrderManagement),
    takeLatest(TYPE.GET_DETAIL_TRACKING, getDetailTracking),
    takeLatest(TYPE.GET_TRACKING_JOURNEY, getTrackingJourney),
    takeLatest(TYPE.GET_PROMOTION, getPromotion),
    takeLatest(TYPE.CREATE_NEW_PROMOTION, createNewPromotion),
    takeLatest(TYPE.DELETE_PROMOTION, deletePromotion),
    takeLatest(TYPE.UPDATE_WAITING_SALE_ORDER, updateWaitingSaleOrder),
    takeEvery(TYPE.CALL_SALE_ORDER_PAGINATION, callListPagination)
  ]);
}
