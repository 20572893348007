import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import filter from 'lodash/filter';
import get from 'lodash/get';
import includes from 'lodash/includes';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Path } from '../../../contents/Constants';
import { MenuProfiles } from '../../../contents/DynamicConstant';
import { Labels } from '../../../contents/Labels';
import SideBarItem from './SideBarItem';

const useStyles = makeStyles(theme => ({
  root: {},
  listItemText: {
    '&:hover': {
      backgroundColor: '#fff'
    },
    '& .MuiTypography-body1': {
      fontSize: 12,
      marginLeft: theme.spacing(3)
    },
    '&.MuiListItem-gutters': {
      padding: 0,
      marginBottom: 18
    }
  },
  active: {
    color: '#1D50FF',
    borderLeft: '1px solid #1D50FF'
  },
  childrenList: {
    borderLeft: '1px solid #EAECEF',
    marginLeft: theme.spacing(3)
  }
}));

export default function SideBar() {
  const classes = useStyles();
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [open, setOpen] = useState(true);
  const [hovering, setHover] = useState('');

  const isWholesale = useSelector(state =>
    get(state, 'profile.deposit.is_wholesale', false)
  );

  const haveChildren = includes(
    [...MenuProfiles[0].childrens.map(i => i.path), MenuProfiles[0].path],
    currentPath
  );

  const handleCurrentPath = path => {
    if (path.startsWith(`${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/`))
      return `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft`;
    if (path.startsWith(`${Path.VN_TRACKING_DEFAULT_PATH}/`))
      return `${Path.VN_TRACKING_DEFAULT_PATH}/list`;
    return path;
  };

  const handleChange = item => {
    if (item.path === Path.EXTENSION_INSTALL) {
      window.open(item.path, '_blank');
      return;
    }
    history.push(item?.path);
    setCurrentPath(item?.path);
    if (item?.childrens) {
      setOpen(!open);
    }
  };

  const MenuProfileList = filter(MenuProfiles, item => {
    if (!isWholesale)
      return item.label !== Labels.Exit && item.label !== Labels.MyDeal;
    return item.label !== Labels.Exit;
  });

  useEffect(() => {
    setCurrentPath(handleCurrentPath(currentPath));
  }, [currentPath]);

  return (
    <List component="nav" className={classes.root}>
      {MenuProfileList.map(item => (
        <>
          <SideBarItem
            open={open}
            item={item}
            currentPath={currentPath}
            onChange={() => handleChange(item)}
          />
          <Collapse in={haveChildren && open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.childrenList}>
              {item?.childrens?.map((c, i) => (
                <ListItem
                  onClick={() => handleChange(c)}
                  onMouseOver={() => setHover(c.path)}
                  onMouseLeave={() => setHover('')}
                  key={`${i}`}
                  className={clsx([
                    classes.listItemText,
                    (c.path === currentPath || hovering === c.path) && classes.active
                  ])}
                >
                  <ListItemText primary={c.label} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </>
      ))}
    </List>
  );
}
