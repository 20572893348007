import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function GoogleIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.28796 9.39085L3.82384 11.1235L2.12751 11.1593C1.62056 10.2191 1.33301 9.14325 1.33301 8.00002C1.33301 6.89453 1.60186 5.85202 2.07842 4.93408H2.07879L3.589 5.21096L4.25056 6.7121C4.1121 7.11578 4.03663 7.54911 4.03663 8.00002C4.03668 8.4894 4.12533 8.95828 4.28796 9.39085Z"
        fill="#FBBB00"
      />
      <path
        d="M14.5501 6.75488C14.6267 7.15816 14.6666 7.57465 14.6666 8.0003C14.6666 8.47759 14.6164 8.94316 14.5208 9.39225C14.1963 10.9205 13.3483 12.2549 12.1736 13.1992L12.1732 13.1988L10.271 13.1018L10.0018 11.4212C10.7813 10.9641 11.3904 10.2487 11.7113 9.39225H8.14648V6.75488H11.7633H14.5501Z"
        fill="#518EF8"
      />
      <path
        d="M12.1733 13.1988L12.1737 13.1992C11.0312 14.1175 9.57994 14.6669 8.00009 14.6669C5.46126 14.6669 3.25395 13.2479 2.12793 11.1596L4.28837 9.39111C4.85137 10.8937 6.30082 11.9633 8.00009 11.9633C8.73048 11.9633 9.41475 11.7658 10.0019 11.4211L12.1733 13.1988Z"
        fill="#28B446"
      />
      <path
        d="M12.2547 2.86829L10.095 4.63641C9.48729 4.25657 8.76896 4.03714 7.99938 4.03714C6.26164 4.03714 4.78508 5.15582 4.25029 6.71225L2.07849 4.93423H2.07812C3.18766 2.79503 5.42281 1.3335 7.99938 1.3335C9.61695 1.3335 11.1001 1.90969 12.2547 2.86829Z"
        fill="#F14336"
      />
    </SvgIcon>
  );
}
