import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { element } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Path } from '../contents/Constants';
import Images from '../contents/Images';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    minHeight: theme.spacing(110),
    [theme.breakpoints.down('xs')]: {
      minHeight: 'unset'
    }
  },
  banner: {
    position: 'relative',
    padding: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logo: {
    position: 'absolute',
    top: 80,
    left: 80,
    width: theme.spacing(14.75),
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(14.75)
    },
    '@media (min-width: 1280px)': {
      width: theme.spacing(14.75)
    },
    '@media (min-width: 1440px)': {
      width: theme.spacing(14.75)
    }
  },
  children: {
    padding: theme.spacing(10, 19.3, 0, 19.3),
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(10, 0, 0, 0)
    },
    '@media (min-width: 1280px)': {
      padding: theme.spacing(10, 10, 0, 10)
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 20px 0'
    }
  },
  title: {
    position: 'absolute',
    top: theme.spacing(16.5),
    left: theme.spacing(14.75),
    fontWeight: 900,
    color: theme.palette.common.white,

    [theme.breakpoints.down('md')]: {
      fontSize: theme.spacing(5.25)
    },
    '@media (min-width: 1280px)': {
      fontSize: theme.spacing(5.25)
    },
    '@media (min-width: 1440px)': {
      fontSize: theme.spacing(5.25)
    }
  },
  container: {
    width: '100%',
    height: '100%'
  },
  brand: {
    width: '100%'
  },
  body: {
    position: 'absolute',
    bottom: 80,
    left: 80,
    right: 80,
    maxWidth: 610
  },
  action: {
    fontWeight: 900,
    height: theme.spacing(7.75),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5, 1, 3.75),
    background: 'rgba(255, 255, 255, 0.1)',
    boxShadow: `0px 20px 90px rgba(0, 0, 0, 0.115449)`,
    backdropFilter: `blur(31.2602px)`,
    borderRadius: theme.spacing(5.625),
    '& > p': {
      fontWeight: 700,
      fontSize: theme.spacing(2),
      color: '#FFFFFF'
    },

    '& > button': {
      width: theme.spacing(17),
      height: theme.spacing(5.625),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#1D50FF',
      color: '#FFFFFF',
      borderRadius: theme.spacing(2.8)
    }
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}));

const OutSideLayout = ({ children }) => {
  const classes = useStyles();
  const { pathname, ...location } = useLocation();
  const history = useHistory();
  const [isSignInPage, setIsSignInPage] = useState(true);
  const v3bg = useSelector(state => state?.config?.images?.desktop?.url);
  const bg = useSelector(state => state?.config?.images?.desktop?.bg);

  useEffect(() => {
    if (pathname === Path.SIGN_UP) {
      setIsSignInPage(false);
    }
  }, [pathname]);

  const onClick = () => {
    if (isSignInPage) {
      history.push({ ...location, pathname: Path.SIGN_UP });
      setIsSignInPage(false);
      return;
    }
    history.push({ ...location, pathname: Path.SIGN_IN });
    setIsSignInPage(true);
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        className={classes.banner}
        style={{
          backgroundColor: bg,
          backgroundImage: `url(${v3bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
        lg={6}
        md={6}
        sm={0}
      >
        <a href={Path.HOME} className={classes.link}>
          <img src={Images.buyngonLogoWhite} className={classes.logo} alt="logo" />
        </a>

        <Box className={classes.body}>
          <Box className={classes.action}>
            <Typography>{`Bạn ${
              isSignInPage ? 'chưa' : 'đã'
            } có tài khoản`}</Typography>
            <Button onClick={onClick}>
              {isSignInPage ? 'Đăng kí ngay' : 'Đăng nhập ngay'}
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item className={classes.children} lg={6} md={6} sm={12}>
        {children}
      </Grid>
    </Grid>
  );
};

OutSideLayout.propTypes = {
  children: element
};

OutSideLayout.defaultProps = {
  children: null
};

export default OutSideLayout;
