import { SvgIcon } from '@material-ui/core';
import React from 'react';

function SuccessIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1.66602H5C3.61667 1.66602 2.5 2.77435 2.5 4.14102V13.2327C2.5 14.5993 3.61667 15.7077 5 15.7077H5.63333C6.3 15.7077 6.93333 15.966 7.4 16.4327L8.825 17.841C9.475 18.4827 10.5333 18.4827 11.1833 17.841L12.6083 16.4327C13.075 15.966 13.7167 15.7077 14.375 15.7077H15C16.3833 15.7077 17.5 14.5993 17.5 13.2327V4.14102C17.5 2.77435 16.3833 1.66602 15 1.66602ZM10.2333 12.466C10.1083 12.5077 9.9 12.5077 9.76667 12.466C8.68333 12.091 6.25 10.5493 6.25 7.92435C6.25833 6.76602 7.18333 5.83268 8.33333 5.83268C9.01667 5.83268 9.61667 6.15768 10 6.66602C10.3833 6.15768 10.9833 5.83268 11.6667 5.83268C12.8167 5.83268 13.75 6.76602 13.75 7.92435C13.7417 10.5493 11.3167 12.091 10.2333 12.466Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default SuccessIcon;
