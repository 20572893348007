import isEqual from 'lodash/isEqual';
import { useSnackbar } from 'notistack';
import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import PopupRealtime from '../../components/2.2.0/Notification/PopupRealtime';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { typeIconAndRedirect } from '../../utils/dynamicHelpers';

export default function useRealtimeNotify() {
  const history = useHistory();
  const { enqueueSnackbar, ...snackbarMethods } = useSnackbar();
  const { closeSnackbar } = snackbarMethods;
  const receiveNotification = useSelector(
    state => state.notification.receive,
    isEqual
  );

  const handleShowDetail = useCallback(
    snackbarId => () => {
      const { action, _id, type } = receiveNotification;
      const { redirect } = typeIconAndRedirect(type, action);
      if (action?.type === 'redirect' && redirect) {
        history.push(redirect);
      }
      dispatch(actions.markAsReadNotification({ id: _id }));
      closeSnackbar(snackbarId);
    },
    [history, receiveNotification, closeSnackbar]
  );

  useEffect(() => {
    if (receiveNotification?._id) {
      enqueueSnackbar(receiveNotification, {
        // autoHideDuration: null,
        content: (key, payload) => (
          <div>
            <PopupRealtime
              snackbarId={key}
              {...payload}
              {...snackbarMethods}
              onDetail={handleShowDetail}
            />
          </div>
        )
      });
      dispatch(actions.clearReceiveNotification());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receiveNotification._id]);
}
