import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ProfileIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9002 3.75869C15.9602 1.91869 13.4302 0.938694 10.7602 0.998694C8.09016 1.06869 5.60016 2.16869 3.76016 4.09869C1.92016 6.03869 0.940159 8.56869 1.00016 11.2387C1.07016 13.9087 2.17016 16.3987 4.10016 18.2387C4.18016 18.3187 4.26016 18.3887 4.35016 18.4587C6.19016 20.1087 8.52016 20.9987 10.9902 20.9987H11.2402C13.6302 20.9387 15.8802 20.0487 17.6502 18.4587C17.8502 18.2887 18.0502 18.0987 18.2402 17.8987C20.0302 16.0187 21.0002 13.5787 21.0002 10.9887V10.7587C20.9302 8.08869 19.8302 5.59869 17.9002 3.75869ZM11.2102 19.4987C9.19016 19.5387 7.26016 18.8887 5.71016 17.6387C6.16016 16.3787 7.39016 15.5087 8.75016 15.5087H13.2502C14.6102 15.5087 15.8402 16.3787 16.2902 17.6387C14.8502 18.8087 13.0902 19.4487 11.2102 19.4987ZM17.4402 16.5287C16.6302 15.0187 15.0302 14.0087 13.2502 14.0087H8.75016C6.97016 14.0087 5.36016 15.0187 4.56016 16.5387C3.27016 15.0587 2.55016 13.1987 2.50016 11.2087C2.45016 8.93869 3.28016 6.77869 4.85016 5.13869C6.41016 3.48869 8.52016 2.55869 10.7902 2.49869H11.0102C13.2002 2.49869 15.2702 3.32869 16.8602 4.84869C17.6802 5.62869 18.3302 6.54869 18.7802 7.54869C19.2202 8.55869 19.4702 9.65869 19.5002 10.7887C19.5502 12.9087 18.8302 14.9387 17.4402 16.5287Z"
        fill="#121331"
      />
      <path
        d="M13.75 12.5H10.75C8.96 12.5 7.5 11.04 7.5 9.25V7.25C7.5 5.46 8.96 4 10.75 4H11.25C13.04 4 14.5 5.46 14.5 7.25V11.75C14.5 12.16 14.16 12.5 13.75 12.5ZM10.75 5.5C9.79 5.5 9 6.29 9 7.25V9.25C9 10.21 9.79 11 10.75 11H13V7.25C13 6.29 12.21 5.5 11.25 5.5H10.75Z"
        fill="#121331"
      />
    </SvgIcon>
  );
}
