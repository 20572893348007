import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { bool, number } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.grey[300],
    backgroundColor: 'transparent',

    fontSize: theme.spacing(1.25),
    fontWeight: 900
  },
  completed: {
    border: 'none',
    backgroundColor: theme.palette.grey[500],
    color: theme.palette.common.white
  }
}));

export default function StepperIcon(props) {
  const classes = useStyles();
  const { icon, completed } = props;

  return (
    <Box
      className={clsx(classes.root, {
        [classes.completed]: completed
      })}
    >
      {icon}
    </Box>
  );
}

StepperIcon.propTypes = {
  icon: number,
  completed: bool
};

StepperIcon.defaultProps = {
  icon: 1,
  completed: false
};
