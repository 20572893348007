import React from 'react';

const SignOutIcon = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70753 12.0218H20.7485"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6353 9.1058L8.70725 12.0218L11.6353 14.9378"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0156 7.389V6.456C16.0156 4.421 14.3666 2.772 12.3306 2.772H7.44662C5.41662 2.772 3.77162 4.417 3.77162 6.447V17.587C3.77162 19.622 5.42162 21.272 7.45662 21.272H12.3416C14.3706 21.272 16.0156 19.626 16.0156 17.597V16.655"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SignOutIconContained = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70753 12.0218H20.7485"
      stroke="#1D50FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6353 9.1058L8.70725 12.0218L11.6353 14.9378"
      stroke="#1D50FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0156 7.389V6.456C16.0156 4.421 14.3666 2.772 12.3306 2.772H7.44662C5.41662 2.772 3.77162 4.417 3.77162 6.447V17.587C3.77162 19.622 5.42162 21.272 7.45662 21.272H12.3416C14.3706 21.272 16.0156 19.626 16.0156 17.597V16.655"
      stroke="#1D50FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SignOutIcon;
