import { all, call } from 'redux-saga/effects';

function* promiseHandler(generator) {
  try {
    const value = yield generator;
    return { status: 'fulfilled', value };
  } catch (error) {
    return { status: 'rejected', reason: error.toString() };
  }
}

function* allSettled(list = []) {
  return yield all(list.map(generator => call(promiseHandler, generator)));
}

export { allSettled };
