import get from 'lodash/get';
import omit from 'lodash/omit';
import { ALERT_MESSAGE_DURATION_TIME } from '../../contents/Constants';
import TYPE from '../actions/TYPE';

const initialState = {
  isLogged: false,
  messageError: '',
  messageSuccess: '',
  messageDurationTime: ALERT_MESSAGE_DURATION_TIME,
  loading: 0,
  loadingImprove: {},
  localQuotationList: [],
  saleOrderDetailID: '',
  errors: {},
  stepLogin: 1,
  isTransaction: false,
  isVerifyPhone: '',
  isVerifyPhoneFromReferral: false,
  isUserAgentAccess: true,
  loadingCategory: 0,
  mobileAppToken: '',
  debtPaymentId: -1,
  referralCode: '',
  mobileAppTokenDN: '',
  statusRequest: {},
  loadingFields: {
    scraper: false
  }
};

const common = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.LOGIN_SUCCESS:
      return {
        ...state,
        isLogged: true
      };

    case TYPE.LOGOUT_SUCCESS:
      return {
        ...state,
        isLogged: false
      };

    case TYPE.CHECK_AUTH:
      return {
        ...state,
        isLogged: action.payload
      };

    case TYPE.ACCESS_FROM_MOBILE_APP:
      return {
        ...state,
        mobileAppToken: state.mobileAppToken || action.payload
      };

    case TYPE.REFERRAL_CODE:
      return {
        ...state,
        referralCode: action.referral_code
      };

    case TYPE.SEND_MONEY_WITHDRAW_REQUEST_FAILED:
      return {
        ...state,
        statusRequest: {
          ...state.statusRequest,
          [action.target]: false
        }
      };

    case TYPE.TOGGLE_POPUP_DEBT:
      return {
        ...state,
        debtPaymentId: action.payment_id
      };

    case TYPE.ACCESS_FROM_MOBILE_APP_DN:
      return {
        ...state,
        mobileAppTokenDN: state.mobileAppTokenDN || action.payload
      };

    case TYPE.ENABLE_LOADING_CATEGORY: {
      return {
        ...state,
        loadingCategory: state.loadingCategory + 1
      };
    }

    case TYPE.DISABLE_LOADING_CATEGORY: {
      return {
        ...state,
        loadingCategory: state.loadingCategory - 1
      };
    }

    case TYPE.POST_SCRAPER_LOADING:
      return {
        ...state,
        loadingFields: {
          ...state.loadingFields,
          scraper: true
        }
      };

    case TYPE.POST_SCRAPER_COMPLETED:
      return {
        ...state,
        loadingFields: {
          ...state.loadingFields,
          scraper: false
        }
      };

    case TYPE.BLOCK_LOGIN_INAPP:
      const { block } = action;
      return {
        ...state,
        isUserAgentAccess: !block
      };

    case TYPE.STEP_LOGIN:
      return {
        ...state,
        stepLogin: action.stepLogin
      };

    case TYPE.ENABLE_LOADING_IMPROVE:
      return {
        ...state,
        loadingImprove: { ...state.loadingImprove, [action.target]: true }
      };
    case TYPE.DISABLE_LOADING_IMPROVE:
      return {
        ...state,
        loadingImprove: { ...state.loadingImprove, [action.target]: false }
      };

    case TYPE.RESET_LOADING:
      return {
        ...state,
        loadingImprove: omit(state.loadingImprove, [action.target]),
        statusRequest: omit(state.statusRequest, [action.target])
      };

    case TYPE.VERIFY_PHONE_SUCCESS:
    case TYPE.IGNORE_VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        isVerifyPhone: ''
      };

    case TYPE.HANDLE_VERIFY_PHONE:
      return {
        ...state,
        isVerifyPhone: action.phone
      };

    case TYPE.HANDLE_VERIFY_PHONE_FROM_REFERRAL:
      return {
        ...state,
        isVerifyPhoneFromReferral: action.isVerify
      };

    case TYPE.IS_TRANSACTION:
      return {
        ...state,
        isTransaction: action.isTrue
      };

    case TYPE.CLEAR_VALIDATE:
      return {
        ...state,
        errors: {}
      };
    case TYPE.VALIDATE_FORM:
      return {
        ...state,
        errors: action.errors
      };
    case TYPE.ALERT_MESSAGE_ERROR:
      return {
        ...state,
        messageError: get(action, 'message', ''),
        messageSuccess: '',
        messageDurationTime: get(action, 'duration', ALERT_MESSAGE_DURATION_TIME)
      };
    case TYPE.ALERT_MESSAGE_SUCCESS:
      return {
        ...state,
        messageError: '',
        messageSuccess: get(action, 'message', ''),
        messageDurationTime: get(action, 'duration', ALERT_MESSAGE_DURATION_TIME)
      };
    case TYPE.ALERT_MESSAGE_CLEAR:
      return {
        ...state,
        messageError: '',
        messageSuccess: '',
        messageDurationTime: ALERT_MESSAGE_DURATION_TIME
      };
    case TYPE.ENABLE_LOADING:
      return {
        ...state,
        loading: state.loading + 1
      };
    case TYPE.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    case TYPE.SET_LOCAL_DATA_SUCCESS:
      return {
        ...state,
        localQuotationList: action.quotationList
      };
    case TYPE.GET_SALE_ORDER_DETAIL:
      return {
        ...state,
        saleOrderDetailID: action.id
      };

    default:
      return state;
  }
};

export default common;
