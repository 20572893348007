/* eslint-disable max-len */
import React from 'react';

export default function LockConfirmIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6862 7.87321V6.08404C13.6862 3.98987 11.9879 2.29154 9.89369 2.29154C7.79952 2.28237 6.09452 3.97237 6.08536 6.06737V6.08404V7.87321"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0694 17.7081H6.70187C4.95687 17.7081 3.54187 16.294 3.54187 14.5481V10.974C3.54187 9.22813 4.95687 7.81396 6.70187 7.81396H13.0694C14.8144 7.81396 16.2294 9.22813 16.2294 10.974V14.5481C16.2294 16.294 14.8144 17.7081 13.0694 17.7081Z"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5002 12.5002L9.16733 14.1668L12.5002 10.8335"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
