import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { bool, oneOf } from 'prop-types';
import EmailIcon from '../icons/new/EmailIcon';
import LockIcon from '../icons/new/LockIcon';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';
import FormInput from '../FormInput';
import ToggleIcon from '../icons/new/ToggleIcon';
import ToggleCheckedIcon from '../icons/new/ToggleCheckedIcon';
import { Path } from '../../contents/Constants';
import useResponsive from '../../hooks/useResponsive';
import useRedirectToAuthPage from '../../hooks/2.2.0/useRedirectToAuthPage';

const useStyles = makeStyles(theme => ({
  login: {
    marginTop: theme.spacing(4.25),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4)
    }
  },
  input: {
    width: '100%',
    height: theme.spacing(6),
    margin: theme.spacing(1, 0)
  },
  icstart: {
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: theme.palette.grey[200],
    height: theme.spacing(5),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5)
  },
  loginbtn: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    minHeight: theme.spacing(5),

    margin: ({ isFastLogin, matches }) => {
      if (isFastLogin) {
        return theme.spacing(1, 0);
      }
      if (matches) {
        return theme.spacing(1, 0, 3, 0);
      }
      return theme.spacing(1, 0, 5, 0);
    },
    color: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6.25),
      height: theme.spacing(6)
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6)
    }
  },
  signup: {
    textDecoration: 'none',
    marginLeft: theme.spacing(1)
  },
  forgotPassword: {
    textDecoration: 'none',
    color: theme.palette.grey[500]
  },
  checkbox: {
    height: ({ size }) => (size === 'small' ? theme.spacing(2) : 'inherit')
  },
  resRegister: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 4.625)
  },
  signupRes: {
    textDecoration: 'none',
    borderRadius: theme.spacing(2.6725),
    height: theme.spacing(4),
    width: theme.spacing(14),
    backgroundColor: '#EBF1FF',
    color: theme.palette.primary.main,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const schema = yup.object().shape({
  username: yup
    .string()
    .email('Email không hợp lệ, vui lòng nhập lại!')
    .required('Vui lòng nhập email!'),
  password: yup.string().required('Vui lòng nhập password!')
});

const LoginManual = ({ size, isFastLogin }) => {
  const matches = useResponsive();
  const classes = useStyles({ size, isFastLogin, matches });
  const [checked, setChecked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ resolver: yupResolver(schema) });
  const handleChecked = ({ target }) => {
    setChecked(target.checked);
  };

  const onLogin = data => {
    dispatch(actions.signInManual(data), true);
  };

  const { redirectObj } = useRedirectToAuthPage();

  return (
    <Box className={classes.login}>
      <form onSubmit={handleSubmit(onLogin)}>
        <FormInput
          {...register('username')}
          placeholder="Email"
          Icon={EmailIcon}
          error={errors?.username?.message}
          size={size}
        />
        <FormInput
          {...register('password')}
          type="password"
          placeholder="Mật khẩu"
          Icon={LockIcon}
          error={errors?.password?.message}
          size={size}
        />
        <Box mt={1.25}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item>
              <FormControlLabel
                onChange={handleChecked}
                checked={checked}
                control={
                  <Checkbox
                    icon={<ToggleIcon className={classes.checkbox} />}
                    checkedIcon={<ToggleCheckedIcon className={classes.checkbox} />}
                  />
                }
                label={<Typography variant="body2">Ghi nhớ tài khoản</Typography>}
              />
            </Grid>
            <Grid item>
              <Link to={Path.IDENTIFY} className={classes.forgotPassword}>
                <Typography variant="body2">Quên mật khẩu</Typography>
              </Link>
            </Grid>
          </Grid>
        </Box>
        <Button
          className={classes.loginbtn}
          variant="contained"
          color="primary"
          type="submit"
        >
          <Typography variant="subtitle2">Đăng nhập</Typography>
        </Button>
      </form>
      {isFastLogin && (
        <Box textAlign="center" width={1}>
          <Typography display="inline" variant="body2">
            Bạn chưa có tài khoản?
          </Typography>
          <Link to={redirectObj} className={classes.signup}>
            <Typography display="inline" color="primary" variant="subtitle1">
              Đăng ký ngay
            </Typography>
          </Link>
        </Box>
      )}
      {matches && (
        <Box className={classes.resRegister}>
          <Typography variant="body2">Bạn chưa có tài khoản?</Typography>
          <Link to={redirectObj} className={classes.signupRes}>
            Đăng ký ngay
          </Link>
        </Box>
      )}
    </Box>
  );
};

LoginManual.propTypes = {
  size: oneOf(['large', 'small']),
  isFastLogin: bool
};

LoginManual.defaultProps = {
  size: 'large',
  isFastLogin: false
};

export default LoginManual;
