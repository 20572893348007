import Grid from '@material-ui/core/Grid';
import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Advertisement from '../components/2.2.0/DealNgon/Advertisement';
import Footer from '../components/2.2.0/DealNgon/Footer';
import ExtensionSnackBar from '../components/Improvements/ExtensionSnackBar';
import VerifyEmailPopup from '../components/VerifyEmailPopup';
import { IS_AUTH, Path } from '../contents/Constants';
import useRealtimeNotify from '../hooks/2.2.0/useRealtimeNotify';
import useRedirectTransaction from '../hooks/2.2.0/useRedirectTransaction';
import useResponsive from '../hooks/useResponsive';
import actions from '../redux/actions';
import dispatch from '../utils/dispatch';

export default function MainLayout({ children }) {
  useRealtimeNotify();

  const matches = useResponsive();
  const { pathname } = useLocation();
  const isAuth = useSelector(state => {
    const auth = window.localStorage.getItem(IS_AUTH);
    const loading = state.common?.loadingImprove?.getUserProfile;
    return Boolean(auth || (!loading && state?.profile?._id));
  });
  // const quotationList = useSelector(state =>
  //   get(state, 'saleOrder.draft.quotation_list', [])
  // );
  const pathIncludeFooter = [
    Path.FLASH_SALE,
    Path.PROMOTION_US,
    Path.WEBSITE_US,
    Path.CONTACT,
    Path.SELLER_PROFILE
  ];
  const showFooterPolicy = pathname.includes(Path.POLICY_DEFAULT_PATH);
  const showFooter = pathIncludeFooter.includes(pathname) || showFooterPolicy;
  const body = useRedirectTransaction(children);

  useEffect(() => {
    // if (isEmpty(quotationList)) {
    if (isAuth) {
      dispatch(actions.getCurrentSaleOrder());
    } else {
      dispatch(actions.getShoppingCartAnonymous());
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <>
      <Grid container>{body}</Grid>
      {!isAuth && showFooter && <Advertisement />}
      {showFooter && <Footer />}

      {!matches && <ExtensionSnackBar />}
      {isAuth && <VerifyEmailPopup />}
    </>
  );
}

MainLayout.propTypes = {
  children: any
};

MainLayout.defaultProps = {
  children: null
};
