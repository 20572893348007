import TYPE from '../actions/TYPE';

const initialState = {
  receive: {},
  list: {
    ALL: [],
    GENERAL: [],
    PURCHASING: [],
    AT_US: [],
    ClEARANCE: [],
    DELIVERY: [],
    PAYMENT: [],
    PROMOTION: [],
    ORDER: []
  }
};

const notification = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.LOGOUT_SUCCESS:
      return initialState;
    case TYPE.GET_NOTIFICATION_SUCCESS:
      return { ...state, list: action.data };
    case TYPE.MARK_AS_READ_NOTIFICATION:
      return {
        ...state,
        //     list: state.list?.map(item => {
        //       if (action.payload?.id && action.payload?.id === item._id)
        //         return { ...item, unread: false };
        //       if (action.payload?.id) return { ...item, unread: false };
        //       return item;
        //     })
        list: Object.entries(state.list).reduce((acc, cur) => {
          // cur = [key, []]
          const [key, value] = cur;

          return {
            ...acc,
            [key]: value.map(item => ({
              ...item,
              unread: ((data, payload) => {
                if (payload?.id && payload?.id === data._id) return false;
                if (!payload?.id) return false;
                return data.unread;
              })(item, action.payload)
            }))
          };
        }, {})
        // list: Object.entries(state.list)?.forEach(key =>
        //   state.list[key].map(item => {
        //     if (action.payload?.id && action.payload?.id === item._id)
        //       return { ...item, unread: false };
        //     if (action.payload?.id) return { ...item, unread: false };
        //     return item;
        //   })
        // )
      };

    case TYPE.RECEIVE_NOTIFICATION:
      return {
        ...state,
        receive: action.payload
      };
    case TYPE.CLEAR_RECEIVE_NOTIFICATION:
      return {
        ...state,
        receive: {}
      };
    default:
      return state;
  }
};

export default notification;
