import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function SpainIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29341_368992)">
        <path d="M23 4.5H1V19.5H23V4.5Z" fill="#DD2C00" />
        <path d="M23 8.5H1V15.5H23V8.5Z" fill="#FFC107" />
      </g>
      <defs>
        <clipPath id="clip0_29341_368992">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
