import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function PaymentIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#FFAA00" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6056 9.73863H18.25C18.25 7.61537 16.9778 6.375 14.8222 6.375H9.17778C7.02222 6.375 5.75 7.61537 5.75 9.71154V14.2885C5.75 16.3846 7.02222 17.625 9.17778 17.625H14.8222C16.9778 17.625 18.25 16.3846 18.25 14.2885V14.0935H15.6056C14.3783 14.0935 13.3833 13.1235 13.3833 11.9269C13.3833 10.7303 14.3783 9.76029 15.6056 9.76029V9.73863ZM15.6057 10.6703H17.7835C18.0412 10.6703 18.2501 10.874 18.2501 11.1252V12.7068C18.2471 12.9569 18.04 13.1589 17.7835 13.1618H15.6557C15.0344 13.17 14.4911 12.7552 14.3501 12.1652C14.2796 11.7989 14.3786 11.421 14.6208 11.1326C14.863 10.8443 15.2235 10.675 15.6057 10.6703ZM15.7001 12.3331H15.9057C16.1696 12.3331 16.3835 12.1246 16.3835 11.8673C16.3835 11.61 16.1696 11.4015 15.9057 11.4015H15.7001C15.5739 11.4 15.4524 11.4479 15.3626 11.5344C15.2729 11.6209 15.2224 11.7388 15.2224 11.8619C15.2224 12.12 15.4354 12.3301 15.7001 12.3331ZM8.71099 9.73862H12.2388C12.5026 9.73862 12.7165 9.53006 12.7165 9.2728C12.7165 9.01554 12.5026 8.80698 12.2388 8.80698H8.71099C8.44928 8.80697 8.23626 9.01224 8.23322 9.26738C8.2332 9.52554 8.44623 9.73565 8.71099 9.73862Z"
        fill="white"
      />
    </SvgIcon>
  );
}
