import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function OrderIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25 18H1.75C0.78 18 0 17.21 0 16.25V11.75C0 10.79 0.78 10 1.75 10H6.25C7.21 10 8 10.79 8 11.75V16.25C8 17.21 7.21 18 6.25 18ZM1.75 11.5C1.61 11.5 1.5 11.61 1.5 11.75V16.25C1.5 16.39 1.61 16.5 1.75 16.5H6.25C6.39 16.5 6.5 16.39 6.5 16.25V11.75C6.5 11.61 6.39 11.5 6.25 11.5H1.75Z"
        fill="#121331"
      />
      <path
        d="M6.25 8H1.75C0.78 8 0 7.21 0 6.25V1.75C0 0.79 0.78 0 1.75 0H6.25C7.21 0 8 0.79 8 1.75V6.25C8 7.21 7.21 8 6.25 8ZM1.75 1.5C1.61 1.5 1.5 1.61 1.5 1.75V6.25C1.5 6.39 1.61 6.5 1.75 6.5H6.25C6.39 6.5 6.5 6.39 6.5 6.25V1.75C6.5 1.61 6.39 1.5 6.25 1.5H1.75Z"
        fill="#121331"
      />
      <path
        d="M16.25 8H11.75C10.78 8 10 7.21 10 6.25V1.75C10 0.79 10.78 0 11.75 0H16.25C17.21 0 18 0.79 18 1.75V6.25C18 7.21 17.21 8 16.25 8ZM11.75 1.5C11.61 1.5 11.5 1.61 11.5 1.75V6.25C11.5 6.39 11.61 6.5 11.75 6.5H16.25C16.39 6.5 16.5 6.39 16.5 6.25V1.75C16.5 1.61 16.39 1.5 16.25 1.5H11.75Z"
        fill="#121331"
      />
      <path
        d="M18 14C18 14.41 17.66 14.75 17.25 14.75H14.75V17.25C14.75 17.66 14.41 18 14 18C13.59 18 13.25 17.66 13.25 17.25V14.75H10.75C10.34 14.75 10 14.41 10 14C10 13.59 10.34 13.25 10.75 13.25H13.25V10.75C13.25 10.34 13.59 10 14 10C14.41 10 14.75 10.34 14.75 10.75V13.25H17.25C17.66 13.25 18 13.59 18 14Z"
        fill="#121331"
      />
    </SvgIcon>
  );
}
