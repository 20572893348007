import { all, call, put, takeLatest } from 'redux-saga/effects';
import { NotifyTypes } from '../../contents/Constants';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getNotification() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getNotification' });
    const { data } = yield call(services.notification.getNotification);
    const notiList = data.reduce(
      (acc, cur) => {
        const { type } = cur;
        if (type) {
          switch (type) {
            case NotifyTypes.CUSTOMER_CREATE_SO:
            case NotifyTypes.CUSTOMER_REJECT_SO:
            case NotifyTypes.SYSTEM_REJECT_SO_24H:
            case NotifyTypes.ADMIN_CONFIRM_SO:
            case NotifyTypes.ADMIN_REJECT_SO:
            case NotifyTypes.SALE_CREATE_SO:
            case NotifyTypes.CUSTOMER_CREATE_DEAL:
            case NotifyTypes.ADMIN_CONFIRM_DEAL:
            case NotifyTypes.ADMIN_REJECT_DEAL:
            case NotifyTypes.CUSTOMER_CREATE_SO_BY_DEAL:
              return {
                ...acc,
                ORDER: [...acc.ORDER, cur],
                GENERAL: [...acc.GENERAL, cur],
                ALL: [...acc.ALL, cur]
              };

            case NotifyTypes.PURCHASED_WATTING_TRACK:
            case NotifyTypes.PURCHASE_FAILED:
            case NotifyTypes.PURCHASE_AND_CANCEL_A_PART:
            case NotifyTypes.PURCHASED_AND_WEB_CANCEL:
              return {
                ...acc,
                ORDER: [...acc.ORDER, cur],
                PURCHASING: [...acc.PURCHASING, cur],
                ALL: [...acc.ALL, cur]
              };

            case NotifyTypes.RECEIVED_US_INVENTORY:
              return {
                ...acc,
                ORDER: [...acc.ORDER, cur],
                AT_US: [...acc.AT_US, cur],
                ALL: [...acc.ALL, cur]
              };
            case NotifyTypes.CLEARANCE_VN:
              return {
                ...acc,
                ORDER: [...acc.ORDER, cur],
                CLEARANCE_VN: [...acc.CLEARANCE_VN, cur],
                ALL: [...acc.ALL, cur]
              };
            case NotifyTypes.DELIVERED:
              return {
                ...acc,
                DELIVERED: [...acc.DELIVERED, cur],
                ALL: [...acc.ALL, cur]
              };

            case NotifyTypes.RECHARGE_SUCCESS:
            case NotifyTypes.WITHDRAWL_SUCCESS:
            case NotifyTypes.RECHARGE_REQUEST_SUCCESS:
            case NotifyTypes.WITHDRAWL_REQUEST_SUCCESS:
              return {
                ...acc,
                PAYMENT: [...acc.PAYMENT, cur],
                ALL: [...acc.ALL, cur]
              };

            default:
              return acc;
          }
        }
        return acc;
      },
      {
        PAYMENT: [],
        GENERAL: [],
        PURCHASING: [],
        AT_US: [],
        CLEARANCE_VN: [],
        DELIVERED: [],
        ALL: [],
        ORDER: []
      }
    );
    yield put({
      type: TYPE.GET_NOTIFICATION_SUCCESS,
      data: notiList
    });
  } catch (error) {
    yield put({ type: TYPE.GET_NOTIFICATION_FAILED, msg: error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getNotification' });
  }
}

function* receiveNotification(action) {
  const type = action?.payload?.type;
  if (
    [
      NotifyTypes.WITHDRAWL_SUCCESS,
      NotifyTypes.RECHARGE_SUCCESS,
      NotifyTypes.ADMIN_CONFIRM_SO
    ].includes(type)
  ) {
    yield put({ type: TYPE.GET_USER_PROFILE });
    yield put({ type: TYPE.GET_REFERRAL });
  }
  if ([NotifyTypes.ADMIN_CONFIRM_DEAL].includes(type)) {
    yield put({
      type: TYPE.GET_GIFT_CARD_UPDATE,
      target: 'receiveNotification',
      payload: action?.payload
    });
  }
  if ([NotifyTypes.ADMIN_REJECT_DEAL].includes(type)) {
    yield put({
      type: TYPE.GET_GIFT_CARD_REJECT,
      target: 'receiveNotification'
    });
  }
  yield getNotification();
}

function* markAsReadNotification(action) {
  try {
    const { data } = yield call(
      services.notification.markAsReadNotification,
      action.payload
    );
    yield put({ type: TYPE.MARK_AS_READ_NOTIFICATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.MARK_AS_READ_NOTIFICATION_FAILED, msg: error });
  } finally {
    // yield offLoading();
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_NOTIFICATION, getNotification),
    takeLatest(TYPE.RECEIVE_NOTIFICATION, receiveNotification),
    takeLatest(TYPE.MARK_AS_READ_NOTIFICATION, markAsReadNotification)
  ]);
}
