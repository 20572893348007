import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function PlanPrimaryIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M24 0H0V24H24V0Z" fill="transparent" />
      <path d="M0 0H24V24H0V0Z" fill="transparent" />
      <path
        d="M4.81227 16.0297C5.54226 16.4497 6.35232 16.6596 7.1723 16.6596C7.59235 16.6596 8.00232 16.6096 8.41228 16.4996L20.7322 13.1994C21.4923 12.9994 21.9922 12.3094 21.9922 11.5594C21.9922 11.4093 21.9722 11.2594 21.9322 11.1094C21.6722 10.1294 21.2022 9.49939 20.5022 9.20943C19.9021 8.94942 19.1721 8.95942 18.1721 9.21941L16.2422 9.73947L11.0821 4.23958C10.8921 4.03957 10.612 3.95965 10.3421 4.02964L7.1121 4.88966C6.88213 4.94967 6.70213 5.11969 6.61209 5.32969C6.52214 5.54967 6.53213 5.78963 6.65213 5.98964L9.86218 11.5296L7.25224 12.2496L5.53222 10.2997C5.35217 10.0897 5.08217 10.0097 4.81217 10.0597L2.88219 10.4697C2.48216 10.5497 2.23218 10.9297 2.29219 11.3298L2.59227 13.1397C2.92224 14.3697 3.71229 15.3897 4.81227 16.0297Z"
        fill="#1D50FF"
      />
      <path
        d="M21.0024 18H3.00243C2.45244 18 2.00244 18.45 2.00244 19C2.00244 19.55 2.45244 20 3.00243 20H21.0024C21.5524 20 22.0024 19.55 22.0024 19C22.0024 18.45 21.5524 18 21.0024 18Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
