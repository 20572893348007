import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { ACCESS_KEY } from '../../../contents/Constants';
import PopupButton from '../Common/PopupButton';
import MenuLogin from './MenuLogin';
import PopupLogin from './PopupLogin';
import UserProfile from './UserProfile';

const useStyles = makeStyles({
  profile: {
    maxWidth: 155,
    '&:hover #expand-icon': {
      transform: 'rotate(180deg)'
    }
  }
});

export default function ProfileButton() {
  const classes = useStyles();
  const isAuth = localStorage.getItem(ACCESS_KEY);
  const loading = useSelector(
    state => !!state.common.loadingImprove?.getUserProfile
  );
  const ProfileButtonBody = () => {
    if (loading) return null;
    if (isAuth) return <MenuLogin />;
    return <PopupLogin />;
  };

  return (
    <PopupButton className={classes.profile} button={<UserProfile />} popover>
      <ProfileButtonBody />
    </PopupButton>
  );
}
