import { SvgIcon } from '@material-ui/core';
import React from 'react';

function OrderCreatedIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Bold/Paper">
        <g id="Paper">
          <path
            id="Paper_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.43748 13.6577H11.9259C12.2642 13.6577 12.5447 13.3744 12.5447 13.0327C12.5447 12.6911 12.2642 12.4161 11.9259 12.4161H7.43748C7.09919 12.4161 6.81867 12.6911 6.81867 13.0327C6.81867 13.3744 7.09919 13.6577 7.43748 13.6577ZM10.2263 8.24937H7.43748C7.09919 8.24937 6.81867 8.5327 6.81867 8.87437C6.81867 9.21603 7.09919 9.49103 7.43748 9.49103H10.2263C10.5645 9.49103 10.8451 9.21603 10.8451 8.87437C10.8451 8.5327 10.5645 8.24937 10.2263 8.24937ZM16.1144 7.52069C16.3083 7.51845 16.5195 7.51602 16.7114 7.51602C16.9177 7.51602 17.0827 7.68268 17.0827 7.89102V14.591C17.0827 16.6577 15.4243 18.3327 13.3781 18.3327H6.81041C4.66519 18.3327 2.91602 16.5743 2.91602 14.4077V5.42435C2.91602 3.35768 4.58268 1.66602 6.63714 1.66602H11.0431C11.2576 1.66602 11.4226 1.84102 11.4226 2.04935V4.73268C11.4226 6.25768 12.6685 7.50768 14.1784 7.51602C14.5311 7.51602 14.842 7.51865 15.1141 7.52096C15.3258 7.52275 15.514 7.52435 15.68 7.52435C15.7975 7.52435 15.9497 7.52259 16.1144 7.52069ZM16.3419 6.30447C15.6637 6.30697 14.8642 6.30447 14.2891 6.29864C13.3766 6.29864 12.6249 5.53947 12.6249 4.6178V2.42114C12.6249 2.06197 13.0565 1.88364 13.3032 2.1428C13.7501 2.61222 14.3646 3.25767 14.976 3.89998C15.5846 4.53931 16.1903 5.17553 16.6249 5.63197C16.8659 5.88447 16.6893 6.30364 16.3419 6.30447Z"
            fill="white"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default OrderCreatedIcon;
