import React, { Suspense, useState } from 'react';
import { useHistory } from 'react-router';
import { arrayOf, elementType, object, oneOfType, shape, string } from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Divider } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import DehazeIcon from '@material-ui/icons/Dehaze';
import dispatch from '../utils/dispatch';
import actions from '../redux/actions';
import UserProfile from './2.2.0/Layout/UserProfile';
import { IS_AUTH, Path } from '../contents/Constants';
import { Labels } from '../contents/Labels';
import CloseIcon from './2.2.0/Icons/Improve/CloseIcon';

const useStyles = makeStyles(theme => ({
  card: {
    padding: 0,
    '& .MuiCardHeader-avatar': { marginRight: 10 }
  },
  avatar: {
    width: 48,
    height: 48
  },
  bars: {
    padding: 0,
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: ({ open }) => (open ? '#EBF1FF' : '#F5F5F7'),
    borderRadius: '50%'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0.75, 3),
    boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)',
    "& img[alt='buyngon-logo']": {
      height: theme.spacing(4)
    }
  },
  button: {
    border: '1px solid #E1E2E5',
    borderRadius: 12,
    height: 32
  },
  close: {
    backgroundColor: '#F5F5F7',
    width: 24,
    height: 24
  },
  list: {
    padding: 0,
    paddingBottom: 16
  },
  listItem: {
    '& .MuiListItemText-primary': {
      fontSize: 14,
      fontWeight: 400
    },
    '& .MuiListItemIcon-root': {
      marginLeft: '8px'
    },
    minHeight: 'unset'
  }
}));

const StyledMenu = withStyles({
  paper: {
    '&.MuiPopover-paper': {
      outline: 'none',
      top: '0 !important',
      left: '0 !important',
      right: '0 !important',
      bottom: '0 !important',
      maxHeight: '100%',
      maxWidth: '100%'
    },
    height: '100vh',
    boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'left',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'left',
      horizontal: 'left'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(() => ({
  root: {
    '&:focus': {
      backgroundColor: 'transparent',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#1D50FF',
        fontWeight: 700
      }
    }
  }
}))(MenuItem);

const tabPropTypes = arrayOf(
  shape({
    label: string,
    icon: oneOfType([elementType, object]),
    path: string
  })
);

function UseProfile() {
  const isAuth = localStorage.getItem(IS_AUTH);
  const history = useHistory();

  return (
    <Box
      py={2}
      px={3}
      position="relative"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      border="2px solid #FFFFFF"
      borderRadius="8px"
      boxShadow="0px 2px 16px rgba(222, 222, 222, 0.16)"
      style={{
        background: `${
          isAuth
            ? 'linear-gradient(122.15deg, #F0F1F5 41.49%, #F8F8FA 86.43%)'
            : 'linear-gradient(180deg, #1D50FF 0%, #1D92FF 100%)'
        }`
      }}
      mx={2}
      {...(isAuth && { onClick: () => history.push(Path.USER_PROFILE) })}
    >
      <Box width={isAuth ? '60%' : '100%'}>
        <UserProfile isResponsive />
      </Box>
      <KeyboardArrowRight
        style={{
          position: 'absolute',
          right: 24,
          top: '50%',
          transform: 'translateY(-50%)',
          color: isAuth ? '#000' : '#fff',
          fontSize: 18
        }}
      />
    </Box>
  );
}

export default function MenuHeader({ navTabs, profileMenus }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ open });
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const isAuth = localStorage.getItem(IS_AUTH);

  const handleOpenDrawer = () => setOpen(!open);
  const handleClick = item => {
    if (item.path === Path.HOME) {
      window.location.href = Path.HOME;
      return;
    }
    if (item.path === Path.BLOG) {
      window.location.href = Path.BLOG;
      return;
    }
    if (Path.USER_LOGOUT === item.path) {
      dispatch(actions.logout());
    } else {
      history.push(item.path);
      setCurrentPath(item.path);
    }
    setOpen(false);
  };

  return (
    <>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        className={classes.bars}
        onClick={handleOpenDrawer}
      >
        <DehazeIcon style={{ color: open ? '#1D50FF' : '#252629', fontSize: 16 }} />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        keepMounted
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box fontWeight={700} fontSize={18} px={3} py={2}>
            Menu
          </Box>
          <Box px={3} py={2}>
            <CloseIcon onClick={() => setOpen(false)} />
          </Box>
        </Box>
        {navTabs.map((item, index) => (
          <StyledMenuItem
            key={`${index}`}
            selected={item.path === currentPath}
            onClick={() => handleClick(item)}
            className={classes.listItem}
          >
            <ListItemIcon>
              {item.path === currentPath ? (
                <item.activeIcon style={{ fontSize: 20 }} />
              ) : (
                <Suspense fallback={<div />}>
                  <item.icon style={{ fontSize: 20, color: '#000' }} />
                </Suspense>
              )}
            </ListItemIcon>
            <ListItemText primary={item?.label} />
          </StyledMenuItem>
        ))}
        <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
        <Box mb={2}>
          <UseProfile onClose={() => setOpen(false)} />
        </Box>
        {isAuth &&
          profileMenus
            .filter(
              tab =>
                tab.label !== Labels.MyAccount &&
                tab.label !== Labels.ExtensionInstall
            )
            .map((item, index) => (
              <StyledMenuItem
                key={`${index}`}
                selected={item.path === currentPath}
                onClick={() => handleClick(item)}
                className={classes.listItem}
              >
                <ListItemIcon>
                  <Suspense fallback={<div />}>
                    {item.path === currentPath ? (
                      <item.activeIcon style={{ fontSize: 20 }} />
                    ) : (
                      <item.icon style={{ fontSize: 20 }} />
                    )}
                  </Suspense>
                </ListItemIcon>
                <ListItemText primary={item?.label} />
              </StyledMenuItem>
            ))}
      </StyledMenu>
      {/* <Drawer anchor="top" open={open} onClose={handleCloseDrawer}>
        <Box className={classes.header}>
          <img alt="buyngon-logo" src={Images.buyngonLogoColor} />
          <IconButton className={classes.close} onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>

       

        <ListTabs onClose={handleCloseDrawer} list={navTabs} />

        <Divider />

        <UseProfile onClose={handleCloseDrawer} />

        {isAuth && <ListTabs onClose={handleCloseDrawer} list={profileMenus} />}
      </Drawer> */}
    </>
  );
}

MenuHeader.propTypes = {
  navTabs: tabPropTypes,
  profileMenus: tabPropTypes
};

MenuHeader.defaultProps = {
  navTabs: [],
  profileMenus: []
};
