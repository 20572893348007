import CancelledIcon from '../components/2.2.0/Icons/NotiIcon/CancelledIcon';
import DealIcon from '../components/2.2.0/Icons/NotiIcon/DealIcon';
import ErrorIcon from '../components/2.2.0/Icons/NotiIcon/ErrorIcon';
import FinishedIcon from '../components/2.2.0/Icons/NotiIcon/FinishedIcon';
import OrderConfirmedIcon from '../components/2.2.0/Icons/NotiIcon/OrderConfirmedIcon';
import OrderingIcon from '../components/2.2.0/Icons/NotiIcon/OrderingIcon';
import PaymentIcon from '../components/2.2.0/Icons/NotiIcon/PaymentIcon';
import WarehouseIcon from '../components/2.2.0/Icons/NotiIcon/WarehouseIcon';
import { NotifyTypes, Path } from '../contents/Constants';

export function typeIconAndRedirect(type, action) {
  switch (type) {
    case NotifyTypes.CUSTOMER_CREATE_SO:
      return {
        Image: OrderConfirmedIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft/detail/${action?.order_id}`
      };

    case NotifyTypes.CUSTOMER_CREATE_SO_BY_DEAL:
      return {
        Image: OrderConfirmedIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/confirmed/detail/${action?.order_id}`
      };

    case NotifyTypes.ADMIN_CONFIRM_SO:
    case NotifyTypes.SALE_CREATE_SO:
      return {
        Image: OrderConfirmedIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/confirmed/detail/${action?.order_id}`
      };

    case NotifyTypes.PURCHASED_WATTING_TRACK:
      return {
        Image: OrderingIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/purchasing/detail/${action?.order_id}`
      };

    case NotifyTypes.PURCHASE_AND_CANCEL_A_PART:
      return {
        Image: OrderingIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/tracking/detail/${action?.order_id}`
      };

    case NotifyTypes.RECEIVED_US_INVENTORY:
      return {
        Image: WarehouseIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/tracking/detail/${
          action?.order_id
        }/tracking?tracking=${(action?.us_id || []).join()}`
      };

    case NotifyTypes.CLEARANCE_VN:
      return {
        Image: WarehouseIcon,
        redirect: `${Path.VN_TRACKING_DEFAULT_PATH}/detail/${(
          action?.vn_id || []
        ).join()}`
      };

    case NotifyTypes.DELIVERED:
      return {
        Image: FinishedIcon,
        redirect: `${Path.VN_TRACKING_DEFAULT_PATH}/detail/${(
          action?.vn_id || []
        ).join()}`
      };

    case NotifyTypes.RECHARGE_SUCCESS:
    case NotifyTypes.WITHDRAWL_SUCCESS:
    case NotifyTypes.RECHARGE_REQUEST_SUCCESS:
    case NotifyTypes.WITHDRAWL_REQUEST_SUCCESS:
      return {
        Image: PaymentIcon,
        redirect: Path.NOTIFICATION
      };

    case NotifyTypes.PURCHASED_AND_WEB_CANCEL:
      return {
        Image: ErrorIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/tracking/detail/${action?.order_id}`
      };

    case NotifyTypes.CUSTOMER_REJECT_SO:
    case NotifyTypes.SYSTEM_REJECT_SO_24H:
    case NotifyTypes.PURCHASE_FAILED:
    case NotifyTypes.ADMIN_REJECT_SO:
      return {
        Image: CancelledIcon,
        redirect: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/cancel/detail/${action?.order_id}`
      };

    case NotifyTypes.CUSTOMER_CREATE_DEAL:
    case NotifyTypes.ADMIN_REJECT_DEAL:
      return {
        Image: DealIcon,
        redirect: `${Path.DEAL_MANAGEMENT}/purchase/${action?.purchase_id}`
      };

    case NotifyTypes.ADMIN_CONFIRM_DEAL:
      return {
        Image: DealIcon,
        redirect: `${Path.DEAL_MANAGEMENT}/deal-gift-card/${action?.deal_gift_card_id}`
      };

    default:
      return {
        Image: 'div',
        redirect: null
      };
  }
}
