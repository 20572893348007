import React from 'react';

export default function ChangePasswordIcon(props) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4228 9.44804V7.30104C16.4228 4.78804 14.3848 2.75004 11.8718 2.75004C9.35876 2.73904 7.31276 4.76704 7.30176 7.28104V7.30104V9.44804"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.683 21.248H8.042C5.948 21.248 4.25 19.551 4.25 17.456V13.167C4.25 11.072 5.948 9.375 8.042 9.375H15.683C17.777 9.375 19.475 11.072 19.475 13.167V17.456C19.475 19.551 17.777 21.248 15.683 21.248Z"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.8623 14.2031V16.4241"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
