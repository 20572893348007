import { any } from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IS_AUTH, Path } from '../contents/Constants';

export default function PrivateRoute({ component: Component, ...rest }) {
  const isAuth = localStorage.getItem(IS_AUTH);
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) return <Component {...props} />;
        return (
          <Redirect
            to={{
              pathname: Path.SIGN_IN,
              state: { prevPath: rest.path }
            }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: any
};

PrivateRoute.defaultProps = {
  component: null
};
