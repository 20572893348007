import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ErrorIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F51D2C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5483 7.27631L18.0913 15.1608C18.1913 15.3961 18.235 15.5875 18.2475 15.7863C18.2725 16.2507 18.11 16.7022 17.7913 17.0497C17.4726 17.3959 17.0414 17.6002 16.5728 17.625H7.42432C7.2306 17.6132 7.03688 17.5693 6.85566 17.5011C5.94956 17.1357 5.51214 16.1077 5.88083 15.2165L10.4551 7.27073C10.6113 6.99142 10.8487 6.75051 11.1424 6.59568C11.9923 6.12439 13.0734 6.43404 13.5483 7.27631ZM12.5421 12.4723C12.5421 12.7696 12.2984 13.0179 11.9985 13.0179C11.6985 13.0179 11.4485 12.7696 11.4485 12.4723V10.7203C11.4485 10.4224 11.6985 10.1815 11.9985 10.1815C12.2984 10.1815 12.5421 10.4224 12.5421 10.7203V12.4723ZM11.9985 15.136C11.6985 15.136 11.4485 14.8876 11.4485 14.591C11.4485 14.2931 11.6985 14.0454 11.9985 14.0454C12.2984 14.0454 12.5421 14.2875 12.5421 14.5842C12.5421 14.8876 12.2984 15.136 11.9985 15.136Z"
        fill="white"
      />
    </SvgIcon>
  );
}
