import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function DollarIconContained(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 6.5H17.06C16.53 5.67 15.83 4.98 15 4.47V1.25C15 0.84 14.66 0.5 14.25 0.5C12.4 0.5 10.82 1.78 10.37 3.51H8.43002C5.47002 3.51 2.98002 5.51 2.24002 8.23C2.11002 7.96 2.02002 7.65 2.02002 7.33V6C2.02002 5.45 1.57002 5 1.02002 5C0.47002 5 0.0200195 5.45 0.0200195 6V7.33C0.0200195 8.74 0.840019 10.29 2.04002 10.81C2.04002 10.81 2.04002 10.81 2.04002 10.82C2.24002 12.53 3.13002 14.09 4.50002 15.14V18.29C4.50002 18.7 4.84002 19.04 5.25002 19.04H8.04002C8.45002 19.04 8.79002 18.7 8.79002 18.29V16.5H11.24V18.29C11.24 18.7 11.58 19.04 11.99 19.04H14.75C15.16 19.04 15.5 18.7 15.5 18.29V15.2C16.58 14.4 17.36 13.29 17.77 12H19.25C19.66 12 20 11.66 20 11.25V7.25C20 6.84 19.66 6.5 19.25 6.5ZM9.12002 6.75H8.16002C6.90002 6.75 5.88002 7.77 5.88002 9.03C5.88002 9.44 5.54002 9.78 5.13002 9.78C4.72002 9.78 4.38002 9.44 4.38002 9.03C4.38002 6.95 6.08002 5.25 8.16002 5.25H9.12002C9.53002 5.25 9.87002 5.59 9.87002 6C9.87002 6.41 9.53002 6.75 9.12002 6.75ZM15.22 8.24C15.2 8.32 15.18 8.4 15.15 8.47C15.12 8.55 15.08 8.62 15.03 8.69C14.99 8.76 14.94 8.82 14.88 8.88C14.65 9.11 14.32 9.24 13.99 9.24C13.91 9.24 13.83 9.24 13.75 9.22C13.67 9.2 13.59 9.18 13.52 9.15C13.44 9.12 13.37 9.08 13.3 9.03C13.23 8.99 13.17 8.94 13.11 8.88C12.88 8.65 12.74 8.32 12.74 8C12.74 7.67 12.88 7.34 13.11 7.11C13.17 7.05 13.23 7 13.3 6.96C13.37 6.91 13.44 6.87 13.52 6.84C13.59 6.81 13.67 6.78 13.75 6.77C14.15 6.69 14.59 6.82 14.88 7.11C15.11 7.34 15.24 7.67 15.24 8C15.24 8.08 15.23 8.16 15.22 8.24Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
