import TYPE from '../actions/TYPE';

const initialState = {
  editAddress: null,
  loginProvider: []
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.SELECT_ADDRESS_TO_EDIT:
      return {
        ...state,
        editAddress: action.address
      };

    case TYPE.CHECK_LOGIN_PROVIDER_SUCCESS:
      return {
        ...state,
        loginProvider: action.data
      };

    case TYPE.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        loginProvider: [...state.loginProvider, 'up']
      };

    default:
      return state;
  }
};

export default user;
