import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { array } from 'prop-types';
import StepperIcon from './StepperIcon';
import {
  BUY_DEAL_STEP,
  ORDER_BY_GIFTCARD_STEPS,
  Path,
  SHOPPING_STEPS
} from '../../../contents/Constants';

const useStyles = makeStyles(theme => ({
  stepper: {
    width: '100%',
    backgroundColor: 'transparent',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.25, 0)
    }
  },
  label: {
    whiteSpace: 'nowrap',
    marginTop: '8px !important',
    fontSize: 12,
    color: '#B0B1B8',
    [theme.breakpoints.down('sm')]: {
      marginTop: '4px !important',
      fontSize: 10
    }
  },
  completed: {
    '&.MuiStepLabel-completed': {
      color: '#252629'
    }
  }
}));

const CustomConnector = withStyles(theme => ({
  alternativeLabel: {
    left: 'calc(-50% + 32px)',
    right: 'calc(50% + 32px)',
    [theme.breakpoints.down('sm')]: {
      left: 'calc(-50% + 20px)',
      right: 'calc(50% + 20px)'
    }
  },
  active: {
    '& $line': {
      backgroundColor: '#252629'
    }
  },
  completed: {
    '& $line': {
      backgroundColor: '#252629'
    }
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: '#B0B1B8'
  }
}))(StepConnector);

export default function HeaderStepper({ steps }) {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [activeStep, setActiveStep] = useState(1);

  const handleChangeStep = step => () => {
    if (activeStep === steps.lenght) return;
    if (step + 1 < activeStep) {
      history.push(steps[step].path);
    }
  };

  useLayoutEffect(() => {
    if (steps === ORDER_BY_GIFTCARD_STEPS) {
      const index = steps.findIndex(item => pathname === item.path);
      setActiveStep((index < 0 ? 2 : index) + 1);
    }
    if (steps === SHOPPING_STEPS) {
      const index = steps.findIndex(item => pathname === item.path);
      setActiveStep((index < 0 ? 2 : index) + 1);
    }
    if (steps === BUY_DEAL_STEP) {
      if (pathname.includes(`${Path.REVIEW_BUY_DEAL_DEFAULT_PATH}/`))
        setActiveStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Stepper
      className={classes.stepper}
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
    >
      {steps.map(({ label }, index) => (
        <Step key={index}>
          <StepButton
            onClick={handleChangeStep(index)}
            style={{ padding: 8, margin: -8 }}
          >
            <StepLabel
              classes={{
                label: classes.label,
                active: classes.completed,
                completed: classes.completed
              }}
              StepIconComponent={StepperIcon}
            >
              {label}
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}

HeaderStepper.propTypes = {
  steps: array
};

HeaderStepper.defaultProps = {
  steps: []
};
