import { SvgIcon } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

export default function DeliveringIcon({ color, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_22700_7921)">
        <path
          d="M0.958333 4.16797C0.498333 4.16797 0.125 4.5413 0.125 5.0013C0.125 5.4613 0.498333 5.83464 0.958333 5.83464H5.125C5.585 5.83464 5.95833 6.20797 5.95833 6.66797C5.95833 7.12797 5.585 7.5013 5.125 7.5013H1.79167C1.33167 7.5013 0.958333 7.87464 0.958333 8.33464C0.958333 8.79464 1.33167 9.16797 1.79167 9.16797H3.45833C3.91833 9.16797 4.29167 9.5413 4.29167 10.0013C4.29167 10.4613 3.91833 10.8346 3.45833 10.8346H1.79167C1.33167 10.8346 0.958333 11.208 0.958333 11.668V14.168C0.958333 14.628 1.33167 15.0013 1.79167 15.0013H2.625C2.625 16.3821 3.74417 17.5013 5.125 17.5013C6.50583 17.5013 7.625 16.3821 7.625 15.0013H11.7917H12.625C12.625 16.3821 13.7442 17.5013 15.125 17.5013C16.5058 17.5013 17.625 16.3821 17.625 15.0013H18.4583C18.5733 15.0013 18.6825 14.9784 18.7822 14.9362C19.0813 14.8096 19.2917 14.513 19.2917 14.168V10.8346L17.0212 6.29525C16.8803 6.01275 16.5932 5.83464 16.2773 5.83464H13.4583C13.4583 4.9138 12.7125 4.16797 11.7917 4.16797H0.958333ZM13.4583 7.29297H15.89L17.6608 10.8346H13.4583V7.29297ZM5.125 13.9596C5.69917 13.9596 6.16667 14.4271 6.16667 15.0013C6.16667 15.5755 5.69917 16.043 5.125 16.043C4.55083 16.043 4.08333 15.5755 4.08333 15.0013C4.08333 14.4271 4.55083 13.9596 5.125 13.9596ZM15.125 13.9596C15.6992 13.9596 16.1667 14.4271 16.1667 15.0013C16.1667 15.5755 15.6992 16.043 15.125 16.043C14.5508 16.043 14.0833 15.5755 14.0833 15.0013C14.0833 14.4271 14.5508 13.9596 15.125 13.9596Z"
          fill="#1D50FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_22700_7921">
          <rect width="20" height="20" fill="white" transform="translate(0.125)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

DeliveringIcon.propTypes = {
  color: string
};

DeliveringIcon.defaultProps = {
  color: '#1D50FF'
};
