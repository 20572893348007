import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { any, func, oneOf, string } from 'prop-types';
import React, { useState } from 'react';
import EyeIcon from '../icons/new/EyeIcon';
import EyeSlash from '../icons/new/EyeSlash';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: theme.spacing(5),
    border: ({ error }) =>
      `1px solid ${error ? theme.palette.error.main : theme.palette.grey[200]}`,
    borderRadius: theme.spacing(1),
    '&:not(:first-of-type)': {
      marginTop: ({ size }) => theme.spacing(size === 'large' ? 2 : 1)
    }
  },
  icon: {
    width: theme.spacing(5.5)
  },

  input: {
    fontFamily: 'Nunito Sans',
    marginLeft: theme.spacing(1),
    width: '100%',
    height: theme.spacing(5),
    border: 'none',
    fontSize: theme.spacing(1.75),
    backgroundColor: 'transparent',

    '&:focus-visible, &:focus': {
      border: 'none',
      outline: 'none'
    },

    '&::-webkit-input-placeholder': {
      fontSize: theme.spacing(1.75),
      color: theme.palette.grey[300],
      fontFamily: 'Nunito Sans'
    },

    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus': {
      transition: 'background-color 5000s ease-in-out 0s'
    }
  },
  inputContainer: {
    width: '100%',
    position: 'relative'
  },

  passwordEye: {
    position: 'absolute',
    top: '50%',
    height: theme.spacing(2.5),
    right: 10,
    transform: 'translateY(-50%)',
    cursor: 'pointer'
  },
  error: {
    margin: 0,
    color: theme.palette.error.main
  }
}));

const FormInput = React.forwardRef(function Component(
  { name, onChange, placeholder, Icon, error, size, type, className, ...restProps },
  ref
) {
  const classes = useStyles({ error, size });
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(prevState => !prevState);
  };

  const typeInput = () => {
    if (type !== 'password') {
      return type;
    }
    if (showPassword) {
      return 'text';
    }
    return 'password';
  };

  return (
    <>
      <Grid className={classes.root} container wrap="nowrap" alignItems="center">
        {Icon && (
          <>
            <Grid item container justifyContent="center" className={classes.icon}>
              <Icon />
            </Grid>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        <Grid item className={classes.inputContainer}>
          <input
            ref={ref}
            type={typeInput()}
            name={name}
            className={clsx(classes.input, className)}
            onChange={onChange}
            placeholder={placeholder}
            {...restProps}
          />
          {type === 'password' && (
            <Box onClick={handleShowPassword} className={classes.passwordEye}>
              {showPassword ? <EyeSlash /> : <EyeIcon />}
            </Box>
          )}
        </Grid>
      </Grid>
      {error && (
        <Typography variant="caption" className={classes.error}>
          {error}
        </Typography>
      )}
    </>
  );
});

FormInput.propTypes = {
  name: string,
  onChange: func,
  placeholder: string,
  Icon: any,
  error: string,
  type: string,
  className: any,
  size: oneOf(['small', 'large'])
};

FormInput.defaultProps = {
  name: '',
  onChange: () => {},
  placeholder: '',
  Icon: null,
  error: '',
  type: '',
  className: null,
  size: 'large'
};

export default FormInput;
