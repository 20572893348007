import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function RowVertical(props) {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M13.2667 9H2.73334C1.73334 9 1.33334 9.42667 1.33334 10.4867V13.18C1.33334 14.24 1.73334 14.6667 2.73334 14.6667H13.2667C14.2667 14.6667 14.6667 14.24 14.6667 13.18V10.4867C14.6667 9.42667 14.2667 9 13.2667 9Z" />
      <path d="M13.2667 1.33301H2.73334C1.73334 1.33301 1.33334 1.75967 1.33334 2.81967V5.51301C1.33334 6.57301 1.73334 6.99967 2.73334 6.99967H13.2667C14.2667 6.99967 14.6667 6.57301 14.6667 5.51301V2.81967C14.6667 1.75967 14.2667 1.33301 13.2667 1.33301Z" />
    </SvgIcon>
  );
}
