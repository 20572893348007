import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { any, bool, object, string } from 'prop-types';
import React, { Suspense } from 'react';
import { getImagesNotification } from '../../../utils/helpers';
import Image from '../Image';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex'
  },
  imageContainer: {
    height: '100%',
    position: 'relative'
  },
  image: {
    objectFit: 'contain',
    borderRadius: 8
  },
  icon: {
    position: 'absolute',
    bottom: -6,
    right: -6
  },
  label: {
    fontWeight: 700,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  subLabel: {
    fontSize: 10,
    margin: '4px 0'
  },
  content: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  }
}));

export default function NotificationItem({
  highlight,
  image,
  Icon,
  label,
  subLabel,
  content,
  action,
  type,
  _id,
  url,
  size,
  read
}) {
  const classes = useStyles();

  const isSmallSize = size === 'small';
  const imageSpacing = isSmallSize ? 20 : 30;
  const imageSize = isSmallSize ? 64 : 96;
  const imageStyles = {
    width: imageSize,
    height: imageSize
  };
  const paddingX = isSmallSize ? 2 : 4;
  const paddingY = isSmallSize ? 2 : 3;
  const handleOpenDetail = () => {
    if (url) {
      action.onClick(_id, url);
      return;
    }
    read(_id);
  };

  return (
    <Box
      width={1}
      px={paddingX}
      py={paddingY}
      onClick={handleOpenDetail}
      className={classes.container}
      bgcolor={highlight ? '#EBF1FF80' : '#FFFFFF'}
    >
      <Box className={classes.imageContainer} marginRight={`${imageSpacing}px`}>
        <Image
          src={getImagesNotification(image, type)}
          alt="img-note"
          style={imageStyles}
          className={classes.image}
        />
        <Suspense fallback={<div />}>
          <Icon className={classes.icon} />
        </Suspense>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width={`calc(100% - ${imageSize}px - ${imageSpacing}px)`}
      >
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
        <Typography color="textSecondary" className={classes.subLabel}>
          {subLabel}
        </Typography>
        <Typography variant="caption" className={classes.content}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
}

NotificationItem.propTypes = {
  highlight: bool,
  image: string,
  Icon: any,
  label: string,
  subLabel: string,
  content: string,
  action: object,
  size: string,
  type: string,
  _id: string,
  url: string,
  read: any
};
NotificationItem.defaultProps = {
  highlight: false,
  image: '',
  Icon: 'div',
  label: '',
  subLabel: '',
  content: '',
  action: {
    onClick: () => {},
    icon: ''
  },
  size: 'default',
  type: '',
  _id: '',
  url: '',
  read: () => {}
};
