import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

const GTM_ID = process.env.REACT_APP_GTM_ID;

export default function GoogleTagManager() {
  useEffect(() => {
    ((w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTM_ID);
  }, []);

  return createPortal(
    <iframe
      title="gtm"
      src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
    />,
    document.body
  );
}
