import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import useResponsive from '../../hooks/useResponsive';
import SkeletonOrderTrackingItemRps from './SkeletonOrderTrackingItemRps';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    borderRadius: 8,
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  box: {
    width: '40%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      width: '100%'
    }
  },
  r4: { borderRadius: 4 },
  r6: { borderRadius: 6 },
  r8: { borderRadius: 8 },
  r16: { borderRadius: 16 }
}));

export default function SkeletonListVNTracking() {
  const classes = useStyles();
  const matches = useResponsive();
  const list = new Array(3).fill(0);

  if (matches) {
    return list.map((_, index) => (
      <Box key={index} mb={1}>
        <SkeletonOrderTrackingItemRps />
      </Box>
    ));
  }

  return list.map((item, index) => (
    <Paper elevation={0} key={index} className={classes.container}>
      <Box display="flex" alignItems="center">
        <Skeleton
          mr={3}
          component={Box}
          variant="rect"
          width={104}
          height={104}
          className={classes.r16}
        />

        <Box>
          <Skeleton
            variant="rect"
            width={167}
            height={24}
            className={classes.r6}
            component={Box}
            mb={1}
          />
          <Skeleton variant="rect" width={130} height={16} className={classes.r6} />
        </Box>
      </Box>
      <Box className={classes.box}>
        <Grid container>
          <Grid item xs={6} md={4}>
            <Box display="flex" alignItems="flex-end" flexDirection="column">
              <Skeleton
                component={Box}
                mb={1}
                variant="rect"
                width={132}
                height={16}
                className={classes.r6}
              />
              <Skeleton
                variant="rect"
                width={83}
                height={16}
                className={classes.r6}
              />
            </Box>
          </Grid>

          <Grid item xs={6} md={8} container justifyContent="flex-end">
            <Skeleton
              variant="rect"
              width={124}
              height={40}
              className={classes.r8}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  ));
}
