import { SvgIcon } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

export default function PromotionIcon({ color, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3 10.8394C21.69 10.8394 22 10.5294 22 10.1394V9.20938C22 5.10938 20.75 3.85938 16.65 3.85938H7.35C3.25 3.85937 2 5.10938 2 9.20938V9.67938C2 10.0694 2.31 10.3794 2.7 10.3794C3.6 10.3794 4.33 11.1094 4.33 12.0094C4.33 12.9094 3.6 13.6294 2.7 13.6294C2.31 13.6294 2 13.9394 2 14.3294V14.7994C2 18.8994 3.25 20.1494 7.35 20.1494H16.65C20.75 20.1494 22 18.8994 22 14.7994C22 14.4094 21.69 14.0994 21.3 14.0994C20.4 14.0994 19.67 13.3694 19.67 12.4694C19.67 11.5694 20.4 10.8394 21.3 10.8394ZM9 8.87938C9.55 8.87938 10 9.32938 10 9.87938C10 10.4294 9.56 10.8794 9 10.8794C8.45 10.8794 8 10.4294 8 9.87938C8 9.32938 8.44 8.87938 9 8.87938ZM15 15.8794C14.44 15.8794 13.99 15.4294 13.99 14.8794C13.99 14.3294 14.44 13.8794 14.99 13.8794C15.54 13.8794 15.99 14.3294 15.99 14.8794C15.99 15.4294 15.56 15.8794 15 15.8794ZM15.9 9.47937L9.17 16.2094C9.02 16.3594 8.83 16.4294 8.64 16.4294C8.45 16.4294 8.26 16.3594 8.11 16.2094C7.82 15.9194 7.82 15.4394 8.11 15.1494L14.84 8.41938C15.13 8.12938 15.61 8.12938 15.9 8.41938C16.19 8.70938 16.19 9.18937 15.9 9.47937Z"
        fill={color}
      />
    </SvgIcon>
  );
}

PromotionIcon.propTypes = {
  color: string
};

PromotionIcon.defaultProps = {
  color: '#00CCA7'
};

export function PromotionOutlinedIcon({ color, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3335 4.99984H13.1668C13.4402 4.99984 13.6668 4.77317 13.6668 4.49984V1.83317C13.6668 1.19317 13.1402 0.666504 12.5002 0.666504H1.50016C0.860163 0.666504 0.333496 1.19317 0.333496 1.83317V4.49984C0.333496 4.77317 0.560163 4.99984 0.833496 4.99984H1.66683C2.22016 4.99984 2.66683 5.4465 2.66683 5.99984C2.66683 6.2665 2.56016 6.51984 2.3735 6.7065C2.18683 6.89317 1.9335 6.99984 1.66683 6.99984H0.833496C0.560163 6.99984 0.333496 7.2265 0.333496 7.49984V10.1665C0.333496 10.8065 0.860163 11.3332 1.50016 11.3332H12.5002C13.1402 11.3332 13.6668 10.8065 13.6668 10.1665V7.49984C13.6668 7.2265 13.4402 6.99984 13.1668 6.99984H12.3335C11.7802 6.99984 11.3335 6.55317 11.3335 5.99984C11.3335 5.73317 11.4402 5.47984 11.6268 5.29317C11.8135 5.1065 12.0668 4.99984 12.3335 4.99984ZM12.3335 7.99984H12.6668V10.1665C12.6668 10.2598 12.5935 10.3332 12.5002 10.3332H6.66683V8.9665C6.66683 8.69317 6.44016 8.4665 6.16683 8.4665C5.8935 8.4665 5.66683 8.69317 5.66683 8.9665V10.3332H1.50016C1.40683 10.3332 1.3335 10.2598 1.3335 10.1665V7.99984H1.66683C2.20016 7.99984 2.70016 7.79317 3.08016 7.41317C3.46016 7.03317 3.66683 6.53317 3.66683 5.99984C3.66683 4.89984 2.76683 3.99984 1.66683 3.99984H1.3335V1.83317C1.3335 1.73984 1.40683 1.6665 1.50016 1.6665H5.66683V3.03317C5.66683 3.3065 5.8935 3.53317 6.16683 3.53317C6.44016 3.53317 6.66683 3.3065 6.66683 3.03317V1.6665H12.5002C12.5935 1.6665 12.6668 1.73984 12.6668 1.83317V3.99984H12.3335C11.8002 3.99984 11.3002 4.2065 10.9202 4.5865C10.5402 4.9665 10.3335 5.4665 10.3335 5.99984C10.3335 7.09984 11.2335 7.99984 12.3335 7.99984Z"
        fill="#1D50FF"
      />
      <path
        d="M6.16699 7.34665C5.89366 7.34665 5.66699 7.11999 5.66699 6.84665V5.15332C5.66699 4.87999 5.89366 4.65332 6.16699 4.65332C6.44033 4.65332 6.66699 4.87999 6.66699 5.15332V6.84665C6.66699 7.11999 6.44033 7.34665 6.16699 7.34665Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
PromotionOutlinedIcon.propTypes = {
  color: string
};

PromotionOutlinedIcon.defaultProps = {
  color: '#00CCA7'
};
