import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getDebtRecovery({ payload }) {
  const { id, callback } = payload;

  try {
    const result = yield call(services.debtRecovery.getDetail, id);
    if (!result?.success) {
      callback({ error: true, data: null });
      return;
    }
    callback({ error: false, data: result?.data });
  } catch (error) {
    yield put({ type: TYPE.DEBT_RECOVERY_FAILED, msg: error });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.DEBT_RECOVERY, getDebtRecovery)]);
}
