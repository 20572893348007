import TYPE from '../actions/TYPE';

const initialState = {
  theme: {},
  images: {},
  content: {},
  actions: {},
  error: null,
  website: {},
  banner: {},
  privacy: {},
  isUserAgentAccess: true
};

const config = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.SETTING:
    case TYPE.GET_CONFIGURATION_SUCCESS:
      return { ...state, ...action };

    default:
      return state;
  }
};

export default config;
