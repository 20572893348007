import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RoutePages from './routes';

import GoogleTagManager from '../components/2.2.0/Common/GoogleTagManager';
import Navigation from '../components/2.2.0/Layout/Navigation';

import useDevelopmentMode from '../hooks/2.2.0/useDevelopmentMode';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const RouteApp = () => {
  const isDevMode = useDevelopmentMode();
  return (
    <>
      <Navigation />
      {!isDevMode && <GoogleTagManager />}

      <Switch>
        {RoutePages.private.map(route => (
          <PrivateRoute
            key={route.path}
            path={route.path}
            exact
            component={props => (
              <route.Layout>
                <route.Page {...props} />
              </route.Layout>
            )}
          />
        ))}
        {RoutePages.public.map(route => (
          <PublicRoute
            key={route.path}
            path={route.path}
            exact
            component={props => (
              <route.Layout>
                <route.Page {...props} />
              </route.Layout>
            )}
          />
        ))}
        {RoutePages.normal.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact
            component={props => (
              <route.Layout>
                <route.Page {...props} />
              </route.Layout>
            )}
          />
        ))}
      </Switch>
    </>
  );
};

export default RouteApp;
