import { SvgIcon } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

export default function PackageIcon({ color, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.8409 6.51784L10.4242 10.2345C10.1659 10.3845 9.84087 10.3845 9.5742 10.2345L3.15754 6.51784C2.6992 6.25117 2.58254 5.62617 2.93254 5.23451C3.1742 4.95951 3.4492 4.73451 3.74087 4.57617L8.25754 2.07617C9.2242 1.53451 10.7909 1.53451 11.7575 2.07617L16.2742 4.57617C16.5659 4.73451 16.8409 4.96784 17.0825 5.23451C17.4159 5.62617 17.2992 6.25117 16.8409 6.51784Z"
        fill={color}
      />
      <path
        d="M9.52409 11.7831V17.4664C9.52409 18.0997 8.88242 18.5164 8.31575 18.2414C6.59909 17.3997 3.70742 15.8247 3.70742 15.8247C2.69076 15.2497 1.85742 13.7997 1.85742 12.6081V8.30807C1.85742 7.64974 2.54909 7.23307 3.11576 7.55807L9.10742 11.0331C9.35742 11.1914 9.52409 11.4747 9.52409 11.7831Z"
        fill={color}
      />
      <path
        d="M10.4746 11.7831V17.4664C10.4746 18.0997 11.1163 18.5164 11.6829 18.2414C13.3996 17.3997 16.2913 15.8247 16.2913 15.8247C17.3079 15.2497 18.1413 13.7997 18.1413 12.6081V8.30807C18.1413 7.64974 17.4496 7.23307 16.8829 7.55807L10.8913 11.0331C10.6413 11.1914 10.4746 11.4747 10.4746 11.7831Z"
        fill={color}
      />
    </SvgIcon>
  );
}

PackageIcon.propTypes = {
  color: string
};

PackageIcon.defaultProps = {
  color: 'white'
};
