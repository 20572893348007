import { Box } from '@material-ui/core';
import React from 'react';

export default function SkeletonUserInfoLayout() {
  return (
    <Box width={1} bgcolor="#FFF" borderRadius={8} height={500}>
      &nbsp;
    </Box>
  );
}
