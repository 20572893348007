import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { element } from 'prop-types';
import React from 'react';
import { BuyNgonContainer } from '../components/2.2.0/Common';
import SideBar from '../components/2.2.0/Layout/SideBar';
import useEmptyLayout from '../hooks/2.2.0/useEmptyLayout';
import useResponsive from '../hooks/useResponsive';
import MainLayout from './MainLayout';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(0)
  },
  tab: {
    maxWidth: 'none',
    '& > .MuiTab-wrapper': {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  },
  label: {
    margin: theme.spacing(0, 'auto', 0, 3.5)
  },
  box: {
    padding: theme.spacing(2, 1, 2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0)
    }
  }
}));

const UserInfoLayout = ({ children }) => {
  const classes = useStyles();
  const matches = useResponsive();

  const layout = (
    <MainLayout>
      <BuyNgonContainer fixed>
        <Grid container className={classes.container}>
          {!matches && (
            <Grid item md={3} className={classes.box}>
              <Paper elevation={0} style={{ padding: '24px' }}>
                <SideBar />
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} md={9} className={classes.box}>
            {/* <Paper elevation={0} className={classes.paper}> */}
            {children}
            {/* </Paper> */}
          </Grid>
        </Grid>
      </BuyNgonContainer>
    </MainLayout>
  );

  return useEmptyLayout(children, layout);
};

UserInfoLayout.propTypes = {
  children: element
};

UserInfoLayout.defaultProps = {
  children: null
};

export default UserInfoLayout;
