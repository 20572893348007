import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ACCESS_KEY, ANONYMOUS_ID } from '../../contents/Constants';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getListCategories() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListCategories' });
    const { data } = yield call(services.dealngon.getListCategories);
    yield put({ type: TYPE.GET_LIST_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_CATEGORIES_FAILED, msg: error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getListCategories'
    });
    // yield offLoading();
  }
}

function* getDetailCategory(action) {
  try {
    const { data } = yield call(services.dealngon.getDetailCategory, action.id);
    yield put({ type: TYPE.GET_DETAIL_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_CATEGORY_FAILED, msg: error });
  } finally {
    // yield offLoading();
  }
}

function* openVpnMobile() {
  try {
    const token =
      localStorage.getItem(ACCESS_KEY) || localStorage.getItem(ANONYMOUS_ID);
    yield call(services.dealngon.openVpnMobile, token);
  } catch (error) {
    yield put({ type: TYPE.OPEN_VPN_MOBILE_FAILED });
  }
}

function* getListPromotionUsWebsite() {
  try {
    const data = yield call(services.dealngon.getListPromotionUsWebsite);
    yield put({ type: TYPE.GET_LIST_PROMOTION_US_WEBSITE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_PROMOTION_US_WEBSITE_FAILED, msg: error });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_CATEGORIES, getListCategories),
    takeLatest(TYPE.GET_DETAIL_CATEGORY, getDetailCategory),
    takeLatest(TYPE.OPEN_VPN_MOBILE, openVpnMobile),
    takeLatest(TYPE.GET_LIST_PROMOTION_US_WEBSITE, getListPromotionUsWebsite)
  ]);
}
