import React from 'react';
import './style.css';

export default function LoadingSquare() {
  return (
    <div className="loader">
      <span> </span>
      <span> </span>
      <span> </span>
      <span> </span>
    </div>
  );
}
