import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ShoppingBagPrimaryIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0 0H24V24H0V0Z" fill="transparent" />
      <path d="M0 0H24V24H0V0Z" fill="transparent" />
      <path
        d="M20.4233 20.3519C20.0933 20.7418 19.6034 20.9718 19.0834 20.9718C19.0834 20.9718 14.1335 20.9668 12.1626 20.9668C9.42718 20.9668 4.96428 20.9718 4.96428 20.9718C4.4443 20.9718 3.95441 20.7418 3.62441 20.3519C3.29441 19.9519 3.15439 19.4319 3.23441 18.9219L4.01935 14.4299L4.8943 9.42259C5.03427 8.58264 5.76425 7.97266 6.61419 7.97266H8.02409V10.9725C8.02409 11.5224 8.47404 11.9724 9.02403 11.9724C9.57401 11.9724 10.024 11.5224 10.024 10.9725V7.97266H14.0237V10.9725C14.0237 11.5224 14.4737 11.9724 15.0236 11.9724C15.5736 11.9724 16.0236 11.5224 16.0236 10.9725V7.97266H17.4335C18.2834 7.97266 19.0134 8.58264 19.1534 9.42259C19.7067 12.5891 20.26 15.7555 20.8133 18.9219C20.8933 19.4319 20.7533 19.9519 20.4233 20.3519Z"
        fill="#1D50FF"
      />
      <path
        d="M16.0234 7.0324V7.97234H14.0235V7.0324C14.0235 5.8925 13.1236 4.97253 12.0237 4.97253C10.9237 4.97253 10.0238 5.8925 10.0238 7.0324V7.97234H8.02393V7.0324C8.02393 4.79253 9.8138 2.97266 12.0237 2.97266C14.2335 2.97266 16.0234 4.79253 16.0234 7.0324Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
