import DeliveringIcon from '../components/2.2.0/Icons/DeliveringIcon';
import DollarIcon from '../components/2.2.0/Icons/DollarIcon';
import DollarIconContained from '../components/2.2.0/Icons/DollarIconContained';
import HomeActiveIcon from '../components/2.2.0/Icons/HomeActiveIcon';
import HomeIcon from '../components/2.2.0/Icons/HomeIcon';
import ChangePasswordIcon from '../components/2.2.0/Icons/Improve/ChangePasswordIcon';
import CarIconStep from '../components/2.2.0/Icons/OrderTrackingIcon/CartIconStep';
import DoneIcon from '../components/2.2.0/Icons/OrderTrackingIcon/DoneIcon';
import OrderCreatedIcon from '../components/2.2.0/Icons/OrderTrackingIcon/OrderCreatedIcon';
import OrderIcon from '../components/2.2.0/Icons/OrderTrackingIcon/OrderIcon';
import OrderIconContained from '../components/2.2.0/Icons/OrderTrackingIcon/OrderIconContained';
import PackageIcon from '../components/2.2.0/Icons/OrderTrackingIcon/PackageIcon';
import PaidIcon from '../components/2.2.0/Icons/OrderTrackingIcon/PaidIcon';
import PurchasedIcon from '../components/2.2.0/Icons/OrderTrackingIcon/PurchasedIcon';
import SuccessIcon from '../components/2.2.0/Icons/OrderTrackingIcon/SuccessIcon';
import Uswarsehouse from '../components/2.2.0/Icons/OrderTrackingIcon/Uswarsehouse';
import VNTrackingIcon from '../components/2.2.0/Icons/OrderTrackingIcon/VNTrackingIcon';
import VNTrackingIconContained from '../components/2.2.0/Icons/OrderTrackingIcon/VNTrackingIconContained';
import PurchasingIcon from '../components/2.2.0/Icons/PurchasingIcon';
import TransferingIcon from '../components/2.2.0/Icons/TransferingIcon';
import WaitToConfirmIcon from '../components/2.2.0/Icons/WaitToConfirmIcon';
import EmailIcon from '../components/icons/new/EmailIcon';
import AccountIconHover from '../components/icons/new/improvement/animation/account-icon-hover.json';
import BanlanceIconHover from '../components/icons/new/improvement/animation/balance-icon-hover.json';
import homeAnimation from '../components/icons/new/improvement/animation/buyngon-home.json';
import blogAnimation from '../components/icons/new/improvement/animation/buyngon-blog-anim.json';
import DeliveryIconHover from '../components/icons/new/improvement/animation/delivery-icon-hover.json';
import ExtensionIconHover from '../components/icons/new/improvement/animation/extension-icon-hover.json';
import OrderIconHover from '../components/icons/new/improvement/animation/Order-icon-hover.json';
import ReferalIconHover from '../components/icons/new/improvement/animation/referal-icon-hover.json';
import shoppingAnimation from '../components/icons/new/improvement/animation/nav-shopping.json';
import ExtensionIcon from '../components/icons/new/improvement/ExtensionIcon';
import ProfileIcon from '../components/icons/new/improvement/ProfileIcon';
import ProfileIconContained from '../components/icons/new/improvement/ProfileIconContained';
import WalletIcon from '../components/icons/new/improvement/WalletIcon';
import WalletIconContained from '../components/icons/new/improvement/WalletIconContained';
import LockConfirmIcon from '../components/icons/new/LockConfirmIcon';
import LockIcon from '../components/icons/new/LockIcon';
import PersonIcon from '../components/icons/new/PersonIcon';
import PositionIcon from '../components/icons/new/PositionIcon';
import ProfileOutline from '../components/icons/new/ProfileOutline';
import SignOutIcon, { SignOutIconContained } from '../components/icons/SignOutIcon';
import { Path } from './Constants';
import { Labels } from './Labels';
import BlogIcon from '../components/2.2.0/Icons/Improve/BlogIcon';
import BlogActiveIcon from '../components/2.2.0/Icons/Improve/BlogActiveIcon';
import ShoppingBagIcon from '../components/2.2.0/Icons/ShoppingBagIcon';
import ShoppingBagPrimaryIcon from '../components/2.2.0/Icons/ShoppingBagPrimaryIcon';
import PlanIcon from '../components/2.2.0/Icons/PlanIcon';
import PlanPrimaryIcon from '../components/2.2.0/Icons/PlanPrimaryIcon';

export const NAV_TABS = [
  {
    label: 'Trang chủ',
    path: Path.HOME,
    icon: HomeIcon,
    activeIcon: HomeActiveIcon,
    animation: homeAnimation
  },
  {
    label: 'Mua hộ',
    path: Path.QUOTATION,
    icon: ShoppingBagIcon,
    activeIcon: ShoppingBagPrimaryIcon,
    animation: shoppingAnimation
  },
  {
    label: Labels.Shipping,
    path: Path.SHIPPING,
    icon: PlanIcon,
    activeIcon: PlanPrimaryIcon,
    animation: null
  },
  {
    label: 'Blog',
    path: Path.BLOG,
    icon: BlogIcon,
    activeIcon: BlogActiveIcon,
    animation: blogAnimation
  }
];

export const MenuProfiles = [
  {
    label: Labels.MyAccount,
    icon: ProfileIcon,
    activeIcon: ProfileIconContained,
    hoveringIcon: AccountIconHover,
    path: Path.USER_PROFILE,
    disabledResponsive: false,
    childrens: [
      {
        label: Labels.PersonalInformation,
        path: Path.USER_INFO
      },
      {
        label: Labels.ChangePassword,
        path: Path.CHANGE_PASSWORD
      },
      {
        label: Labels.AddressManagement,
        path: Path.USER_ADDRESS
      },
      {
        label: Labels.Notification,
        path: Path.NOTIFICATION
      }
    ]
  },
  {
    label: Labels.MyOrder,
    icon: OrderIcon,
    activeIcon: OrderIconContained,
    hoveringIcon: OrderIconHover,
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft`
  },
  {
    label: Labels.VNTracking,
    icon: VNTrackingIcon,
    activeIcon: VNTrackingIconContained,
    hoveringIcon: DeliveryIconHover,
    path: Path.LIST_VN_TRACKING
  },
  {
    label: Labels.Deposit,
    path: Path.USER_DEPOSIT,
    icon: WalletIcon,
    activeIcon: WalletIconContained,
    hoveringIcon: BanlanceIconHover,
    disabledResponsive: false
  },

  // {
  //   label: Labels.MyDeal,
  //   icon: DealOutlinedIcon,
  //   activeIcon: DealIconContained,
  //   path: Path.DEAL_MANAGEMENT
  // },

  {
    label: Labels.ExtensionInstall,
    path: Path.EXTENSION_INSTALL,
    icon: ExtensionIcon,
    hoveringIcon: ExtensionIconHover,
    disabledResponsive: true
  },
  {
    label: Labels.Referral,
    icon: DollarIcon,
    activeIcon: DollarIconContained,
    hoveringIcon: ReferalIconHover,
    path: Path.USER_REFERRAL,
    disabledResponsive: false
  },
  {
    label: Labels.Exit,
    path: Path.USER_LOGOUT,
    icon: SignOutIcon,
    activeIcon: SignOutIconContained,
    disabledResponsive: true
  }
];

export const TABS_ORDER_RES = [
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/draft`,
    label: 'Chờ xác nhận',
    icon: WaitToConfirmIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: 'draft'
  },
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/purchasing`,
    label: 'Đang mua',
    icon: PurchasingIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: 'purchasing'
  },
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/tracking`,
    label: 'Vận chuyển',
    icon: TransferingIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: 'tracking'
  },
  {
    path: `${Path.ORDER_MANAGEMENT_DEFAULT_PATH}/delivery`,
    label: 'Đang giao',
    icon: DeliveringIcon,
    bgcolor: '#EBF1FF',
    haveNoti: true,
    value: 'delivery'
  }
];

export const MENU_PROFILE_RES = [
  {
    path: Path.USER_INFO,
    label: Labels.PersonalInformation,
    icon: PersonIcon
  },
  {
    path: Path.USER_ADDRESS,
    label: Labels.AddressBook,
    icon: PositionIcon
  },
  {
    path: Path.CHANGE_PASSWORD,
    label: Labels.ChangePassword,
    icon: ChangePasswordIcon
  },
  {
    path: Path.USER_REFERRAL,
    label: Labels.Referral,
    icon: DollarIcon
  },
  {
    path: Path.USER_LOGOUT,
    label: Labels.Exit,
    icon: SignOutIcon
  }
];

export const FORM_SIGN_UP = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Họ và Tên của bạn',
    Icon: ProfileOutline
  },
  // {
  //   name: 'phone',
  //   type: 'text',
  //   placeholder: 'Nhập số điện thoại của bạn*',
  //   Icon: PhoneOutline
  // },
  {
    name: 'email',
    type: 'text',
    placeholder: 'Email của bạn',
    Icon: EmailIcon
  },
  {
    name: 'password',
    type: 'password',
    placeholder: 'Nhập mật khẩu*',
    Icon: LockIcon
  },
  {
    name: 'password2',
    type: 'password',
    placeholder: 'Nhập lại mật khẩu*',
    Icon: LockConfirmIcon
  }
];

export const JOURNEY_STEP = [
  {
    key: 'created',
    priority: 0,
    label: 'Đã tạo đơn hàng',
    icon: OrderCreatedIcon
  },
  {
    key: 'confirmed',
    priority: 1,
    label: 'Đã thanh toán',
    icon: PaidIcon
  },
  {
    key: 'purchased',
    priority: 2,
    label: 'Đã mua hàng',
    icon: PurchasedIcon
  },
  {
    key: 'arrived_us',
    priority: 3,
    label: 'Đã đến kho nước ngoài',
    icon: Uswarsehouse
  },
  // {
  //   key: '',
  //   priority: 4,
  //   label: 'Đang về VN',
  //   icon: PlaneIcon
  // },
  {
    key: 'clear',
    priority: 4,
    label: 'Đã thông quan',
    icon: DoneIcon
  },
  {
    key: 'buyngon_received',
    priority: 5,
    label: 'Đóng gói',
    icon: PackageIcon
  },
  {
    key: 'vn_shipping',
    priority: 6,
    label: 'Đang giao',
    icon: CarIconStep
  },
  {
    key: 'transferred',
    priority: 7,
    label: 'Hoàn thành',
    icon: SuccessIcon
  }
];
