import React from 'react';

export default function GiftIcon(props) {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 1C3.673 1 3 1.673 3 2.5C3 2.6762 3.03572 2.8428 3.0918 3H1V6H1.5V9.54395C1.5 10.0714 1.92855 10.5 2.45605 10.5H9.54395C10.0714 10.5 10.5 10.0714 10.5 9.54395V6H11V3H8.9082C8.96428 2.8428 9 2.6762 9 2.5C9 1.673 8.327 1 7.5 1C7.11464 1 6.76597 1.14991 6.5 1.38965V1.38281L6.46094 1.42188C6.44761 1.43472 6.43472 1.44761 6.42188 1.46094L6 1.88281L5.57812 1.46094C5.56528 1.44761 5.55239 1.43472 5.53906 1.42188L5.5 1.38281V1.38965C5.23403 1.14991 4.88536 1 4.5 1ZM4.5 2C4.776 2 5 2.2245 5 2.5C5 2.7755 4.776 3 4.5 3C4.224 3 4 2.7755 4 2.5C4 2.2245 4.224 2 4.5 2ZM7.5 2C7.776 2 8 2.2245 8 2.5C8 2.7755 7.776 3 7.5 3C7.224 3 7 2.7755 7 2.5C7 2.2245 7.224 2 7.5 2ZM2 4H10V5H2V4ZM2.5 6H5.5V9.5H2.5V6ZM6.5 6H9.5V9.5H6.5V6Z"
        fill="#00CC79"
      />
    </svg>
  );
}
