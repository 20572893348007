export const COMPANY = 'BuyNgon';

export const Labels = {
  Introduction: 'Ứng dụng mua hàng tại Mỹ nhanh chóng, an toàn, tiện lợi',
  SignInTitle: `Đăng nhập tài khoản ${COMPANY}`,
  SignUpTitle: `Đăng ký tài khoản ${COMPANY}`,
  NotHaveAccount: 'Bạn chưa có tài khoản?',
  HadAccount: 'Bạn đã có tài khoản?',
  SignIn: 'Đăng nhập',
  SignUp: 'Đăng kí',
  ContentSignInForm: 'Đăng ký / Đăng nhập nhanh bằng',
  Or: 'hoặc',
  UserName: 'Họ và tên',
  PhoneNumber: 'Số điện thoại',
  EmailInput: 'Email',
  PasswordInput: 'Password',
  ReplayPasswordInput: 'Nhập lại password',
  ForgotPassword: 'Quên mật khẩu?',
  RecoverPassword: 'Khôi phục mật khẩu',
  RecoverPasswordContent: [
    `Nhập email mà bạn đã đăng ký ${COMPANY}.`,
    'Chúng tôi sẽ giúp bạn lấy lại mật khẩu.',
    'Nếu bạn cần hỗ trợ, vui lòng liên hệ với chúng tôi'
  ].join(''),
  RecoverPasswordButton: 'Lấy lại mật khẩu',
  ComeBackHome: 'Trở về Trang chủ',
  PleaseCheckEmail: 'Vui lòng kiểm tra email !',
  CheckEmailContent: [
    'Email Khôi phục mật khẩu đã được gửi qua email.',
    'Vui lòng kiểm tra email và làm theo hướng dẫn để khôi phục mật khẩu.'
  ].join(''),
  ResetPassword: 'Đặt lại mật khẩu',
  ResetPasswordContent: 'Vui lòng nhập mật khẩu mới',
  Confirm: 'Xác nhận',
  QuotationRequest: 'Báo giá tự động',
  OrderManagement: 'Quản lý đơn hàng',
  MyOrder: 'Đơn hàng của tôi',
  MyDeal: 'Deal của tôi',
  VNTracking: 'Vận chuyển nội địa',
  Shipping: 'Ship hộ',
  Notification: 'Thông báo',
  Tracking: 'Vận chuyển',
  Help: 'Trợ giúp',
  MyAccount: 'Tài khoản của tôi',
  PersonalInformation: 'Thông tin cá nhân',
  ChangePassword: 'Đổi mật khẩu',
  Referral: 'Giới thiệu bạn bè',
  ReferralDescription:
    'Mỗi khi bạn giới thiệu cho bạn bè sử dụng BuyNgon thành công, bạn và bạn bè sẽ nhận được 50.000 điểm (tương đương 50.000đ) áp dụng mua hàng!',
  ReferralNotice:
    '*Lưu ý: chỉ khi người được giới thiệu áp dụng mã giới thiệu khi thanh toán cho đơn hàng đầu tiên thành công, bạn mới nhận được điểm thưởng.',
  AddressManagement: 'Sổ địa chỉ',
  Deposit: 'Số dư của tôi',
  DepositManagement: 'Quản lý dư nợ',
  DepositHistory: 'Lịch sử',
  Exit: 'Đăng xuất',
  Hello: 'Xin chào',
  Services: 'Dịch vụ chính',
  NotHaveQuotation: 'Bạn chưa có yêu cầu báo giá nào?',
  SendQuotationNow: 'Gửi yêu cầu báo giá ngay',
  NotHaveOrder: 'Bạn chưa có đơn hàng nào',
  ImportFile: 'Import file',
  Cart: 'Giỏ hàng',
  ConfirmCart: 'Xác nhận giỏ hàng',
  ConfirmRequest: 'Xác nhận đơn hàng',
  AddProduct: 'Thêm sản phẩm',
  DealNgon: 'Deal Ngon',
  ExtensionInstall: 'Cài đặt Tiện ích BuyNgon',
  USWarning:
    'Một số sản phẩm không hỗ trợ địa chỉ Mỹ, vui lòng kiểm tra lại giỏ hàng',
  EmptyAddress: 'Chưa chọn địa chỉ nhận hàng',
  SearchBy: 'Tìm kiếm với',
  ProductForYou: 'BuyNgon dành riêng cho bạn',
  UsingCoupon: 'Vui lòng đăng nhập để sử dụng mã khuyến mãi',
  UsingShopping: 'Vui lòng đăng nhập thanh toán đơn hàng',
  UsWebsite: 'Website Mỹ',
  BuyNgonPromotion: 'Mã khuyến mãi BuyNgon',

  TitleVNTracking: 'Đã gửi yêu cầu xác nhận nạp tiền',
  DescriptionVNTracking:
    'Giao dịch của Quý khách đang được kiểm tra. BuyNgon sẽ xác nhận thanh toán thành công sớm nhất!',
  PaymentInfo: 'Thông tin nạp tiền',
  PaymentAmount: 'Số tiền thanh toán',
  PaymentMethods: 'Phương thức thanh toán',
  AmountToDeposit: 'Số tiền cần nạp',
  MaximumWithdraw: 'Số điểm cần rút vượt quá số dư hiện tại',
  TransferRemind: 'Quý khách vui lòng chuyển khoản theo thông tin bên dưới.',
  DebtManagement: 'Quản lý số dư',
  AddressBook: 'Sổ địa chỉ'
};
