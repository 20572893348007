import { FACEBOOK_APP_ID } from './domain';

function loadFbLoginApi() {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: FACEBOOK_APP_ID,
      cookie: true,
      status: true,
      xfbml: true, // parse social plugins on this page
      version: 'v14.0'
    });
  };
  ((d, s, id) => {
    let js = d.getElementsByTagName(s)[0];
    const fjs = js;
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export { loadFbLoginApi };
