import Box from '@material-ui/core/Box';
import moment from 'moment';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { IS_AUTH, Path } from '../../../contents/Constants';
import Images from '../../../contents/Images';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import notifyAnimation from '../../icons/new/improvement/animation/BuyNgon-Notification.json';
import PopupButton from '../Common/PopupButton';
import NotificationIcon from '../Icons/Improve/NotificationIcon';
import NotifyListContainer from '../Notification/Container/NotifyListContainer';
import PopupLogin from './PopupLogin';

export default function NotificationButton() {
  const history = useHistory();
  const { pathname } = useLocation();
  const isAuth = localStorage.getItem(IS_AUTH);

  const handleShowNotification = () => {
    if (!isAuth) {
      history.push(Path.SIGN_IN);
      return;
    }

    if (pathname !== Path.NOTIFICATION) {
      history.push(Path.NOTIFICATION);
    }
  };

  const notificationResponse = useSelector(state => {
    return (state?.notification.list?.ALL || []).map(item => {
      return {
        ...item,
        image: item.avatar || Images.itemDefault,
        dateTime: moment(item.create_at).format('h:mm L '),
        maskAsRead: item.unread
      };
    });
  }, shallowEqual);
  const countNoti = notificationResponse.filter(item => item.unread).length;

  return (
    <PopupButton
      badge={countNoti}
      onRedirect={handleShowNotification}
      active={pathname === Path.NOTIFICATION}
      icon={NotificationIcon}
      animation={notifyAnimation}
    >
      {isAuth ? (
        <Box
          width={474}
          onMouseEnter={() => dispatch(actions.markAsReadNotification())}
        >
          <NotifyListContainer
            size="small"
            dataList={[...notificationResponse]?.splice(0, 5)}
            from="navigate"
            bottomAction={{
              label: 'Xem tất cả',
              iconRight: Images.arrowRight,
              onClick: handleShowNotification
            }}
          />
        </Box>
      ) : (
        <PopupLogin />
      )}
    </PopupButton>
  );
}
