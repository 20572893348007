/* eslint-disable no-unused-vars */

import get from 'lodash/get';
import size from 'lodash/size';
import { select } from 'redux-saga/effects';

const isChrome =
  !!window.chrome && (!!window.chrome?.webstore || !!window.chrome?.runtime);

export function* getExtensionId() {
  const extensionId = yield select(state =>
    get(state, 'config.actions.extension.id', '')
  );
  return extensionId;
}

export function* loginExtension(user) {
  const extensionId = yield getExtensionId();
  if (isChrome && extensionId) {
    window.chrome.runtime.sendMessage(extensionId, {
      type: 'LOGIN_WEB_APP',
      payload: user
    });
  }
}

export function* logoutExtension(id) {
  const extensionId = yield getExtensionId();
  if (isChrome && extensionId) {
    window.chrome.runtime.sendMessage(extensionId, {
      type: 'LOGOUT_WEB_APP',
      payload: id
    });
  }
}

export function* countProductNumber() {
  const extensionId = yield getExtensionId();
  const numProduct = yield select(state => state.saleOrder?.draft?.quotation_list);
  if (isChrome && extensionId) {
    window.chrome.runtime.sendMessage(extensionId, {
      type: 'COUNT_PRODUCT_NUMBER',
      payload: size(numProduct)
    });
  }
}
