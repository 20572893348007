import { SvgIcon } from '@material-ui/core';
import React from 'react';

function DoneIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9585 1.66602H8.0418C7.17513 1.66602 6.4668 2.36602 6.4668 3.23268V4.01602C6.4668 4.88268 7.1668 5.58268 8.03346 5.58268H11.9585C12.8251 5.58268 13.5251 4.88268 13.5251 4.01602V3.23268C13.5335 2.36602 12.8251 1.66602 11.9585 1.66602Z"
        fill="white"
      />
      <path
        d="M14.3672 4.01689C14.3672 5.34189 13.2839 6.42523 11.9589 6.42523H8.04219C6.71719 6.42523 5.63385 5.34189 5.63385 4.01689C5.63385 3.55023 5.13385 3.25856 4.71719 3.47523C3.54219 4.10023 2.74219 5.34189 2.74219 6.76689V14.6086C2.74219 16.6586 4.41719 18.3336 6.46719 18.3336H13.5339C15.5839 18.3336 17.2589 16.6586 17.2589 14.6086V6.76689C17.2589 5.34189 16.4589 4.10023 15.2839 3.47523C14.8672 3.25856 14.3672 3.55023 14.3672 4.01689ZM12.7839 10.6086L9.45052 13.9419C9.32552 14.0669 9.16719 14.1252 9.00885 14.1252C8.85052 14.1252 8.69219 14.0669 8.56719 13.9419L7.31719 12.6919C7.07552 12.4502 7.07552 12.0502 7.31719 11.8086C7.55885 11.5669 7.95885 11.5669 8.20052 11.8086L9.00885 12.6169L11.9005 9.72523C12.1422 9.48356 12.5422 9.48356 12.7839 9.72523C13.0255 9.96689 13.0255 10.3669 12.7839 10.6086Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default DoneIcon;
