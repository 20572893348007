import size from 'lodash/size';
import { USES_CASE_CONVERT_CURRENCY } from '../contents/Constants';

export const convertShortString = (str, length) => {
  if (size(str) > length) return `${str.slice(0, length)}...`;
  return str;
};

export const roundNumber = (number, scale) =>
  +`${Math.round(`${number}e+${scale}`)}e-${scale}`;

export const formatCurrency = currency =>
  currency.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const getSignedNumber = (price, usesCase) => {
  const usesCaseConvert = Object.keys(USES_CASE_CONVERT_CURRENCY);
  const isExistCase = usesCaseConvert.includes(`${usesCase}`.toLowerCase());
  if (!isExistCase) throw new Error('Trường hợp chuyển đổi không hợp lệ');

  const sign = (() => {
    if (price === 0) return '';

    switch (usesCase) {
      case USES_CASE_CONVERT_CURRENCY.positive:
        return '+ ';
      case USES_CASE_CONVERT_CURRENCY.negative:
        return '- ';
      case USES_CASE_CONVERT_CURRENCY.default:
        return price > 0 ? '' : '- ';
      case USES_CASE_CONVERT_CURRENCY.all:
        return price > 0 ? '+ ' : '- ';
      case USES_CASE_CONVERT_CURRENCY.hide:
        return '';

      default:
        return '';
    }
  })();

  return sign;
};

export const convertVND = (price, hideSuffix = false, usesCase = 'default') => {
  const sign = getSignedNumber(+price, usesCase);
  const suffix = hideSuffix ? '' : '₫';
  return `${sign}${suffix}${formatCurrency(roundNumber(Math.abs(+price), 0))}`;
};

export const convertDollar = (price, usesCase = 'default', suffix = '$') => {
  const sign = getSignedNumber(+price, usesCase);
  return `${sign}${suffix}${formatCurrency(roundNumber(Math.abs(+price), 2))}`;
};

export const nonAccentVietnamese = str => {
  str = str.replace(/\s+/g, ' ').trim();
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
  return str;
};

export function convertDeliveryAddress(address) {
  const { type_addr, city, district, ward, street } = address;

  if (type_addr === 'vietnam') {
    return [street, ward?.name, district?.name, city?.name].join(', ');
  }
  return street;
}

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(x => num >= x.value);
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}
