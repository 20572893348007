import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ChangeDevice(props) {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 14.6615H10C11.1067 14.6615 12 13.7681 12 12.6615V3.32812C12 2.22146 11.1067 1.32812 10 1.32812H6C4.89333 1.32812 4 2.22146 4 3.32812V12.6615C4 13.7681 4.89333 14.6615 6 14.6615Z"
        fill="#292D32"
      />
      <path
        d="M3.00065 3.94241V12.0358C3.00065 12.2491 2.80732 12.4024 2.60065 12.3624C1.61398 12.1824 1.33398 11.6091 1.33398 10.2091V5.76908C1.33398 4.36907 1.61398 3.79574 2.60065 3.61574C2.80732 3.57574 3.00065 3.72907 3.00065 3.94241Z"
        fill="#292D32"
      />
      <path
        d="M14.6667 5.76907V10.2091C14.6667 11.6091 14.3867 12.1824 13.4 12.3624C13.1933 12.4024 13 12.2424 13 12.0357V3.9424C13 3.72907 13.1933 3.57574 13.4 3.61574C14.3867 3.79574 14.6667 4.36907 14.6667 5.76907Z"
        fill="#292D32"
      />
    </SvgIcon>
  );
}
