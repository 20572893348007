import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function DownloadIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10196 11.0935L9.99635 9.18017C10.1878 8.98017 10.1878 8.66683 9.98975 8.4735C9.79833 8.28017 9.4881 8.28017 9.29668 8.4735L8.24718 9.54017V6.32683C8.24718 6.04683 8.02936 5.82683 7.75873 5.82683C7.4881 5.82683 7.26368 6.04683 7.26368 6.32683V9.54017L6.21417 8.4735C6.02276 8.28017 5.71252 8.28017 5.52111 8.4735C5.32969 8.66683 5.32969 8.98017 5.52111 9.18017L7.40889 11.0935C7.4551 11.1402 7.5079 11.1735 7.56731 11.2002C7.62672 11.2268 7.69272 11.2402 7.75873 11.2402C7.82474 11.2402 7.88414 11.2268 7.94355 11.2002C8.00295 11.1735 8.05576 11.1402 8.10196 11.0935ZM12.8851 6.01724C13.0403 6.01545 13.2092 6.0135 13.3627 6.0135C13.5343 6.0135 13.6663 6.14683 13.6663 6.3135V11.6735C13.6663 13.3268 12.333 14.6668 10.696 14.6668H5.44852C3.72575 14.6668 2.33301 13.2602 2.33301 11.5268V4.34016C2.33301 2.68683 3.65974 1.3335 5.30991 1.3335H8.83466C8.99967 1.3335 9.13829 1.4735 9.13829 1.64016V3.78683C9.13829 5.00683 10.1284 6.00683 11.3429 6.0135C11.6219 6.0135 11.8715 6.01561 12.0894 6.01746C12.2582 6.01889 12.408 6.02016 12.5376 6.02016C12.6316 6.02016 12.7534 6.01876 12.8851 6.01724ZM13.0693 5.04423C12.5274 5.04623 11.8878 5.04423 11.4277 5.03956C10.6977 5.03956 10.0964 4.43223 10.0964 3.6949V1.93756C10.0964 1.65023 10.4416 1.50756 10.6383 1.7149C11.1469 2.24849 11.9256 3.06639 12.5835 3.75744C12.8495 4.03683 13.0957 4.29548 13.2964 4.50623C13.4891 4.70823 13.3479 5.04356 13.0693 5.04423Z"
        fill="#252629"
      />
    </SvgIcon>
  );
}
