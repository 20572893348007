import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CartFilledIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.94 8.29L19.38 13.4C19.02 14.71 18.04 15.5 16.78 15.5H8.63C8.16 15.5 7.75 15.17 7.65 14.71L6.26 8.21V8.18L5.95 6.72C5.87 6.3 5.53 6 5.13 6H3C2.45 6 2 5.55 2 5C2 4.45 2.45 4 3 4H5.13C6.49 4 7.66 4.98 7.92 6.33L8.05 7H19.99C20.3 7 20.6 7.15 20.79 7.41C20.98 7.66 21.03 7.99 20.94 8.29Z" />
      <path d="M10 21C11.1046 21 12 20.1046 12 19C12 17.8954 11.1046 17 10 17C8.89543 17 8 17.8954 8 19C8 20.1046 8.89543 21 10 21Z" />
      <path d="M16.5 21C17.6046 21 18.5 20.1046 18.5 19C18.5 17.8954 17.6046 17 16.5 17C15.3954 17 14.5 17.8954 14.5 19C14.5 20.1046 15.3954 21 16.5 21Z" />
    </SvgIcon>
  );
}
