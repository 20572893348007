import { all, fork } from 'redux-saga/effects';
import bank from './bankSaga';
import campaign from './campaignSaga';
import catalog from './catalogSaga';
import category from './categorySaga';
import dealngon from './dealngonSaga';
import deal from './dealSaga';
import deposit from './depositSaga';
import location from './locationSaga';
import notification from './notificationSaga';
import profile from './profileSaga';
import referral from './referralSaga';
import saleOrder from './saleOrderSaga';
import saleOrderV2 from './saleOrderV2Saga';
import tracking from './trackingSaga';
import user from './userSaga';
import vnTracking from './vnTrackingSaga';
import website from './websiteSaga';
import debtRecovery from './debtRecovery';
import checkPriceProduct from './checkPriceProductSaga';

export default function* root() {
  yield all([
    fork(bank),
    fork(campaign),
    fork(catalog),
    fork(category),
    fork(dealngon),
    fork(deal),
    fork(deposit),
    fork(location),
    fork(notification),
    fork(profile),
    fork(referral),
    fork(saleOrder),
    fork(saleOrderV2),
    fork(tracking),
    fork(user),
    fork(vnTracking),
    fork(website),
    fork(debtRecovery),
    fork(checkPriceProduct)
  ]);
}
