import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function BlogIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H24V24H0V0Z" fill="white" />
      <path d="M0 0H24V24H0V0Z" fill="white" />
      <path d="M18.2491 21.9968H5.75104C4.79123 21.9968 4.00134 21.2069 4.00134 20.2471V3.7497C4.00134 2.78989 4.79123 2 5.75104 2H18.2491C19.209 2 19.9989 2.78989 19.9989 3.7497V20.2471C19.9989 21.207 19.209 21.9968 18.2491 21.9968V21.9968ZM5.75104 3.49976C5.61107 3.49976 5.5011 3.60973 5.5011 3.7497V20.2471C5.5011 20.3871 5.61107 20.4971 5.75104 20.4971H18.2491C18.3892 20.4971 18.4991 20.3871 18.4991 20.2471V3.7497C18.4991 3.60973 18.3892 3.49976 18.2491 3.49976H5.75104Z" />
      <path d="M11.7602 10.5091H7.75081C7.34089 10.5091 7.0009 10.1692 7.0009 9.75925V5.7499C7.0009 5.33998 7.34084 5 7.75081 5H11.7602C12.1701 5 12.51 5.33994 12.51 5.7499V9.75925C12.51 10.1692 12.1701 10.5091 11.7602 10.5091ZM8.50066 9.00934H11.0102V6.49976H8.50066V9.00934ZM16.2494 13.4986H7.75071C7.34079 13.4986 7.00085 13.1587 7.00085 12.7487C7.00085 12.3388 7.34079 11.9989 7.75071 11.9989H16.2494C16.6594 11.9989 16.9994 12.3388 16.9994 12.7487C16.9994 13.1587 16.6594 13.4986 16.2494 13.4986ZM16.2494 16.2582H7.75071C7.34079 16.2582 7.00085 15.9182 7.00085 15.5083C7.00085 15.0984 7.34079 14.7584 7.75071 14.7584H16.2494C16.6594 14.7584 16.9994 15.0984 16.9994 15.5083C16.9994 15.9182 16.6594 16.2582 16.2494 16.2582ZM11.7501 18.9978H7.75076C7.34084 18.9978 7.0009 18.6578 7.0009 18.2479C7.0009 17.8379 7.34084 17.498 7.75076 17.498H11.7501C12.16 17.498 12.5 17.8379 12.5 18.2479C12.5 18.6578 12.1601 18.9978 11.7501 18.9978Z" />
    </SvgIcon>
  );
}
