import TYPE from '../actions/TYPE';

const initialState = {
  provinceList: [],
  districtList: [],
  wardList: []
};

const location = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_PROVINCE_SUCCESS:
      return {
        ...state,
        provinceList: action.data.map(item => ({
          id: item.province_id,
          name: item.province_name
        }))
      };

    case TYPE.GET_DISTRICT_SUCCESS:
      return {
        ...state,
        districtList: action.data.map(item => ({
          id: item.district_id,
          name: item.district_name
        }))
      };

    case TYPE.GET_WARD_SUCCESS:
      return {
        ...state,
        wardList: action.data.map(item => ({
          id: item.ward_id,
          name: item.ward_name
        }))
      };

    case TYPE.CLEAR_LOCATION:
      return {
        ...state,
        districtList: [],
        wardList: []
      };

    default:
      return state;
  }
};

export default location;
