import get from 'lodash/get';
import { filterTrackingList } from '../../utils/helpers';
import TYPE from '../actions/TYPE';

const initialState = {
  list: [],
  listFilter: [],
  detail: null,
  notification: {
    isLoading: false,
    saleOrderOdooId: '',
    trackingId: '',
    type: ''
  }
};

const tracking = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.TRACKING_LIST_SUCCESS:
      return {
        ...state,
        list: get(action, 'data', []),
        listFilter: get(action, 'data', [])
      };
    case TYPE.TRACKING_LIST_FAILED:
      return { ...state, list: [] };
    case TYPE.TRACKING_LIST_FILTER:
      return {
        ...state,
        list: filterTrackingList(action.filter, state.listFilter)
      };
    case TYPE.TRACKING_DETAIL_SUCCESS:
      return {
        ...state,
        detail: get(action, 'data', [])
      };
    case TYPE.TRACKING_DETAIL_FAILED:
      return { ...state, detail: null };
    case TYPE.NOTIFICATION_TRACKING_SUCCESS:
      const saleOrderOdooId = get(action, 'saleOrderOdooId', '');
      const trackingId = get(action, 'trackingId', '');

      return {
        ...state,
        notification: {
          isLoading: !state.notification.isLoading,
          trackingId,
          saleOrderOdooId
        }
      };
    default:
      return state;
  }
};

export default tracking;
