import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CancelIcon({ ...restProps }) {
  return (
    <SvgIcon {...restProps}>
      <circle cx="12" cy="12" r="10" fill="#F5F5F7" />
      <path
        d="M15 9L9 15"
        stroke="#252629"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15L9 9"
        stroke="#252629"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
