import {
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  Typography,
  withStyles
} from '@material-ui/core';
import filter from 'lodash/filter';
import get from 'lodash/get';
import React, { Suspense, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Path } from '../../../contents/Constants';
import { MenuProfiles } from '../../../contents/DynamicConstant';
import { Labels } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import Animation from '../../icons/new/improvement/animation/Animation';

const useStyles = makeStyles({
  aniamtionIcon: {
    height: 24,
    width: 24
  }
});

const StyledMenuList = withStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    color: '#252629'
  }
}))(MenuList);

const StyledMenuItem = withStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    '&.MuiListItem-button:hover': {
      backgroundColor: 'transparent'
    }
  },
  selected: {
    color: '#1D50FF',
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: 'transparent'
    }
  }
})(MenuItem);

const StyledListItemIcon = withStyles(theme => ({
  root: {
    color: '#81838B',
    paddingLeft: theme.spacing(1)
  }
}))(ListItemIcon);

export default function MenuLogin() {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [hovering, setHover] = useState('');
  const isWholesale = useSelector(state =>
    get(state, 'profile.deposit.is_wholesale', false)
  );

  const MenuProfileList = filter(MenuProfiles, item => {
    if (!isWholesale) return item.label !== Labels.MyDeal;
    return item;
  });

  const onNavigateHandler = path => () => {
    switch (path) {
      case Path.EXTENSION_INSTALL:
        return window.open(path, '_blank');

      case Path.USER_LOGOUT:
        return dispatch(actions.logout());

      default:
        return history.push(path);
    }
  };

  return (
    <StyledMenuList>
      {MenuProfileList.map(item => (
        <StyledMenuItem
          button
          selected={item.path === pathname || hovering === item.path}
          key={item.path}
          onClick={onNavigateHandler(item.path)}
          onMouseOver={() => {
            setHover(item.path);
          }}
          onMouseLeave={() => setHover('')}
        >
          <StyledListItemIcon>
            {hovering === item.path ? (
              <>
                {item?.hoveringIcon ? (
                  <Animation
                    className={classes.aniamtionIcon}
                    animationData={item.hoveringIcon}
                  />
                ) : (
                  <item.activeIcon fontSize="small" />
                )}
              </>
            ) : (
              <>
                {item.path === pathname ? (
                  <>
                    {item.activeIcon ? (
                      <Suspense fallback={<div />}>
                        <item.activeIcon fontSize="small" />
                      </Suspense>
                    ) : null}
                  </>
                ) : (
                  <Suspense fallback={<div />}>
                    <item.icon fontSize="small" />
                  </Suspense>
                )}
              </>
            )}
          </StyledListItemIcon>

          <Typography variant="subtitle2">{item.label}</Typography>
        </StyledMenuItem>
      ))}
    </StyledMenuList>
  );
}
