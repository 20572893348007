import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import size from 'lodash/size';
import { array, bool, object, string } from 'prop-types';
import React from 'react';
import useResponsive from '../../../hooks/useResponsive';
import SkeletonNotification from '../../Skeleton/SkeletonNotification';
import AutoHeightList from '../../Virtualized/AutoHeightList';
import Animation from '../../icons/new/improvement/animation/Animation';
import EmptyNotification from '../../icons/new/improvement/animation/no-notifications.json';
import NotifyContainer from './Container/NotifyContainer';

const defaultLabel = 'Xem tất cả';

const useStyles = makeStyles(theme => ({
  list: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 8,
      paddingBottom: 8
    }
  },
  btn: {
    fontWeight: 400,
    color: '#252629',
    textDecoration: 'underline'
  }
}));

export default function NotificationItemList({
  dataList,
  bottomAction,
  loading,
  from,
  ...props
}) {
  const classes = useStyles();
  const matches = useResponsive();
  const isNavigate = from === 'navigate';
  const heightContainer = isNavigate ? 272 : 352;

  if (size(dataList) < 1) {
    return (
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: matches ? 'calc(100vh - 200px)' : heightContainer }}
      >
        <Animation
          animationData={EmptyNotification}
          style={{ width: 340, height: 174 }}
        />

        <Typography align="center" variant="subtitle1">
          Chưa có thông báo nào
        </Typography>
        <Typography
          align="center"
          variant="caption"
          color="textSecondary"
          component="p"
        >
          Hãy mua sắm gì đó nhé!
        </Typography>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Box bgcolor="#FFF" paddingTop={matches ? 0 : 3}>
        {dataList.map((_, index) => (
          <SkeletonNotification key={index} />
        ))}
      </Box>
    );
  }

  return (
    <>
      <AutoHeightList list={dataList} minHeight={matches ? 101 : 115}>
        {itemProps => <NotifyContainer {...props} {...itemProps.item} />}
      </AutoHeightList>

      {bottomAction && isNavigate && (
        <Box
          borderTop="1px solid #E9ECF4"
          p={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            size="small"
            color="primary"
            onClick={bottomAction?.onClick}
            className={classes.btn}
          >
            {bottomAction?.label || defaultLabel}
          </Button>
        </Box>
      )}
    </>
  );
}

NotificationItemList.propTypes = {
  dataList: array,
  bottomAction: object,
  loading: bool,
  from: string
};
NotificationItemList.defaultProps = {
  dataList: [],
  bottomAction: {},
  loading: false,
  from: 'navigate'
};
