import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { any } from 'prop-types';
import React from 'react';
import Images from '../contents/Images';
import { Path } from '../contents/Constants';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(6),
    width: '100%',
    height: '100%',
    minWidth: '100vw',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.common.white,

    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3)
    }
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },

  image: {
    width: theme.spacing(24),

    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(19.5)
    }
  }
}));

export default function NothingLayout({ children }) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <img
        src={Images.buyngonLogoColor}
        alt="Logo Buyngon"
        className={classes.image}
      />
      {children}
      <a href={Path.HOME} className={classes.link}>
        <Typography variant="subtitle2" color="primary">
          Trở về Trang chủ
        </Typography>
      </a>
    </Box>
  );
}

NothingLayout.propTypes = {
  children: any
};

NothingLayout.defaultProps = {
  children: null
};
