import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import isEmpty from 'lodash/isEmpty';
import { bool, object, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NotifyTypes } from '../../../../contents/Constants';
import Images from '../../../../contents/Images';
import actions from '../../../../redux/actions';
import dispatch from '../../../../utils/dispatch';
import { typeIconAndRedirect } from '../../../../utils/dynamicHelpers';
import LoadingSquare from '../../LoadingSquare';
import NotificationItem from '../NotificationItem';

const useStyles = makeStyles({
  opaci: {
    position: 'absolute',
    width: '100%',
    left: 0,
    background: '#0000002e'
  }
});
export default function NotifyContainer({
  image,
  title,
  type,
  dateTime,
  description,
  maskAsRead,
  isInDetail,
  _id,
  action,
  ...props
}) {
  const classes = useStyles();
  const history = useHistory();
  const handleMarkAsRead = id => {
    if (maskAsRead) {
      dispatch(actions.markAsReadNotification({ id }));
    }
  };
  const onViewDetail = (id, url) => {
    handleMarkAsRead(id);
    const paymentId = action?.payment_id;
    if (
      [NotifyTypes.RECHARGE_SUCCESS, NotifyTypes.WITHDRAWL_SUCCESS].includes(type) &&
      paymentId
    ) {
      dispatch(actions.getDetailTransaction(paymentId));
      return;
    }
    history.push(url);
  };

  const actionProps = {
    label: type === NotifyTypes.PROMOTION ? 'Dùng ngay' : 'Xem chi tiết',
    icon: type === NotifyTypes.PROMOTION ? Images.voucherBlack : Images.shapeBlack,
    onClick: onViewDetail,
    isShow: !isEmpty(action)
  };

  const { Image, redirect } = typeIconAndRedirect(type, action);

  const loadLine = useSelector(state => {
    return state.common?.loadingImprove?.[action?.payment_id];
  });

  return (
    <>
      <NotificationItem
        {...props}
        image={image}
        Icon={Image}
        label={title}
        subLabel={dateTime}
        content={description}
        action={actionProps}
        highlight={maskAsRead}
        type={type}
        read={handleMarkAsRead}
        _id={_id}
        url={redirect}
      />
      {loadLine && (
        <Box className={classes.opaci} height={isInDetail ? 102 : 152}>
          <LoadingSquare />
        </Box>
      )}
    </>
  );
}

NotifyContainer.propTypes = {
  image: string,
  title: string,
  type: string,
  dateTime: string,
  description: string,
  maskAsRead: bool,
  isInDetail: bool,
  _id: string,
  action: object
};
NotifyContainer.defaultProps = {
  image: '',
  title: '',
  type: '',
  dateTime: '',
  description: '',
  maskAsRead: false,
  isInDetail: false,
  _id: '',
  action: {}
};
