import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Images from '../../../contents/Images';

const useStyles = makeStyles(theme => ({
  normal: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: -1,
    height: theme.spacing(12),
    width: theme.spacing(12),
    '&::before': {
      content: `''`,
      display: 'block',
      borderRadius: '50%',
      height: theme.spacing(12),
      width: theme.spacing(12),
      opacity: 0,
      backgroundImage: `url("${Images.backgroundEmpty}")`,
      backgroundSize: 'cover'
    }
  },
  animation: {
    animation: `$xAxis 1s forwards cubic-bezier(1, 0.44, 0.84, 0.165)`,
    '&::before': {
      animation: `$yAxis 1s alternate forwards cubic-bezier(0.165, 0.84, 0.44, 1)`
    }
  },
  '@keyframes xAxis': {
    '0%': {
      zIndex: 99999
    },
    '100%': {
      transform: `translateX(calc(50vw - 20px))`
    }
  },
  '@keyframes yAxis': {
    '0%': {
      opacity: 1
    },
    '95%': {
      opacity: 0.5
    },
    '100%': {
      transform: `translateY(calc(-50vh + 40px))`,
      height: 30,
      width: 30
    }
  }
}));

export default function AddCartAnimation() {
  const classes = useStyles();
  const imageRef = useRef();
  const isAddCartSuccess = useSelector(state => state.saleOrder.isAddCartSuccess);

  useEffect(() => {
    if (isAddCartSuccess) {
      setTimeout(() => {
        imageRef.current.classList.add(classes.animation);
      }, 500);

      setTimeout(() => {
        imageRef.current.classList.remove(classes.animation);
      }, 1400);
    }
  }, [isAddCartSuccess, classes.animation]);

  return <div ref={imageRef} className={classes.normal} />;
}
