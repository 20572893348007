import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import FilterDepositIcon from '../Icons/Improve/FilterDepositIcon';

const useStyles = makeStyles({
  container: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#F5F5F7'
  }
});

export default function FilterButton() {
  const classes = useStyles();
  const handleOpenFilterRp = () => dispatch(actions.activeFilter());

  return (
    <IconButton
      size="small"
      onClick={handleOpenFilterRp}
      className={classes.container}
    >
      <FilterDepositIcon />
    </IconButton>
  );
}
