import { Box, IconButton, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import size from 'lodash/size';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { BUY_DEAL_STEP, Path, SHOPPING_STEPS } from '../../../contents/Constants';
import { MenuProfiles, NAV_TABS } from '../../../contents/DynamicConstant';
import Images from '../../../contents/Images';
import useResponsive from '../../../hooks/useResponsive';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import MenuHeader from '../../MenuHeader';
import ArrowIcon from '../Icons/Improve/ArrowIcon';
import BuyNgonIcon from '../Icons/Improve/BuyNgonIcon';
import FilterButton from './FilterButton';
import HeaderStepper from './HeaderStepper';
import NavTab from './NavTab';
import NotificationButton from './NotificationButton';
import ProfileButton from './ProfileButton';
import SearchInput from './SearchInput';
import ShoppingCartButton from './ShoppingCartButton';
import { isProductDetailPath } from '../../../utils/helpers';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'sticky',
    padding: theme.spacing(1, 2),
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 500,
    height: theme.spacing(7),
    boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      height: theme.spacing(9),
      padding: theme.spacing(0, 4)
    }
  },
  logo: {
    height: theme.spacing(4),
    cursor: 'pointer'
  },
  nav: {
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 0)
    }
  },
  boxLogo: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex'
  },
  leftSection: {
    display: 'flex',
    alignItems: 'center'
  },
  middleSection: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 387px - 277px - 80px)'
  },
  rightSection: {
    display: 'flex',
    height: 1,
    alignItems: 'center',
    width: 277,
    justifyContent: 'flex-end'
  },
  arrow: {
    position: 'absolute',
    left: 16,
    transform: 'translateX(-12px)'
  },
  stepper: {
    width: '70%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)'
  },
  stepperGiftCard: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '70%',
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'
    }
  }
}));

function checkPathBuyDeal(pathname) {
  const dealReviewPath = pathname.startsWith(
    `${Path.REVIEW_BUY_DEAL_DEFAULT_PATH}/`
  );
  if (dealReviewPath) return true;

  const splitPathname = pathname.split('/');

  if (size(splitPathname) !== 5) return false;

  return splitPathname.reduce((acc, cur, index) => {
    if (index === 1 || index === 4) {
      return acc && (cur === 'deals' || cur === 'order');
    }
    return acc;
  }, true);
}

function checkPathShopping(pathname) {
  return pathname.startsWith(Path.TRANSACTION_DEFAULT_PATH);
}

export default function Navigation() {
  const classes = useStyles();
  const matches = useResponsive();
  const history = useHistory();
  const { pathname, search } = useLocation();

  // const isOrderByGiftCard = !!search && pathname.includes(Path.QUOTATION);

  const onBackHome = () => {
    if (pathname !== Path.HOME) {
      window.location.href = Path.HOME;
    }
  };

  const isAuth = useSelector(state => !!state.profile?._id);

  useEffect(() => {
    if (isAuth) dispatch(actions.getNotification());
  }, [isAuth]);

  const isShoppingTransactionRoute = checkPathShopping(pathname);
  const isBuyDealTransactionRoute = checkPathBuyDeal(pathname);
  const isTransactionRoute = isShoppingTransactionRoute || isBuyDealTransactionRoute;
  const transactionRoute = (() => {
    if (isShoppingTransactionRoute) return SHOPPING_STEPS;
    if (isBuyDealTransactionRoute) return BUY_DEAL_STEP;
    return [];
  })();

  const isEmptyRoute = [Path.SIGN_IN, Path.SIGN_UP].some(path =>
    pathname.startsWith(path)
  );

  const handleGoBack = () => {
    if (pathname.includes(`${Path.REVIEW_BUY_DEAL_DEFAULT_PATH}/`)) {
      history.push(Path.DEAL_MANAGEMENT);
      return;
    }
    if (pathname.includes(Path.SHOPPING_ORDER)) {
      history.push(`${Path.SHOPPING_CART}${search}`);
      return;
    }
    if (pathname.includes(Path.SHOPPING_CART)) {
      window.location.href = Path.HOME;
      return;
    }
    history.goBack();
  };

  if (isEmptyRoute) return null;

  // if (isOrderByGiftCard)
  //   return (
  //     <Paper elevation={0} className={classes.header}>
  //       {matches ? (
  //         <>
  //           <Box height={48} />
  //           <IconButton onClick={history.goBack} className={classes.arrow}>
  //             <ArrowIcon />
  //           </IconButton>
  //         </>
  //       ) : (
  //         <Button
  //           startIcon={<KeyboardArrowLeftIcon />}
  //           onClick={() => history.goBack()}
  //         >
  //           Trở về
  //         </Button>
  //       )}
  //       <Box className={classes.stepperGiftCard}>
  //         <HeaderStepper steps={ORDER_BY_GIFTCARD_STEPS} />
  //       </Box>
  //     </Paper>
  //   );

  // if (hideHeader) return null;

  if (matches) {
    const isEmptyMobileRoute = [
      Path.SIGN_IN,
      Path.SIGN_UP,
      Path.NOTIFICATION,
      Path.VN_TRACKING_DEFAULT_PATH,
      Path.ORDER_MANAGEMENT_DEFAULT_PATH,
      Path.USER_ADDRESS,
      Path.USER_INFO,
      Path.USER_DEPOSIT,
      Path.CHANGE_PASSWORD
    ].some(path => pathname.startsWith(path));

    const isProfilePageResponsive = pathname === Path.USER_PROFILE;

    const isHomePage = pathname === Path.HOME;
    const isCatalogDetail = isProductDetailPath();
    const isCatalogResRoute = !isHomePage && !isCatalogDetail;

    if (isEmptyMobileRoute) return null;

    if (isTransactionRoute) {
      return (
        <Paper elevation={0} className={classes.header}>
          <Box height={48} />
          <IconButton onClick={handleGoBack} className={classes.arrow}>
            <ArrowIcon />
          </IconButton>

          <Box className={classes.stepper}>
            <HeaderStepper steps={transactionRoute} />
          </Box>
        </Paper>
      );
    }
    if (isProfilePageResponsive) {
      return (
        <Paper elevation={0} className={classes.header}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width={48}
          >
            <IconButton onClick={handleGoBack} className={classes.arrow}>
              <ArrowIcon />
            </IconButton>
          </Box>

          <Box display="flex" width={48} justifyContent="flex-end">
            <ShoppingCartButton />
          </Box>
        </Paper>
      );
    }

    return (
      <Paper elevation={0} className={classes.header}>
        <Box display="flex" width={72} justifyContent="space-between">
          <MenuHeader navTabs={NAV_TABS} profileMenus={MenuProfiles} />

          <Box onClick={onBackHome}>
            <BuyNgonIcon style={{ width: 32, height: 32 }} />
          </Box>
        </Box>

        <Box width="calc(100% - 144px - 16px)">
          <SearchInput />
        </Box>

        <Box display="flex" width={72} justifyContent="space-between">
          {isCatalogResRoute ? <FilterButton /> : <NotificationButton />}

          <ShoppingCartButton />
        </Box>
      </Paper>
    );
  }

  if (isTransactionRoute) {
    return (
      <Paper elevation={0} className={classes.header}>
        <Box display="flex" alignItems="center">
          <Box onClick={onBackHome}>
            <img
              alt="logo"
              className={classes.logo}
              src={Images.newBuyngonLogoColor}
            />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" width="40%">
          <HeaderStepper steps={transactionRoute} />
        </Box>

        <Box display="flex" height={1} alignItems="center">
          <ProfileButton />
        </Box>
      </Paper>
    );
  }

  return (
    <Paper elevation={0} className={classes.header}>
      <Box className={classes.leftSection}>
        <Box onClick={onBackHome}>
          <img
            alt="logo"
            className={classes.logo}
            src={Images.newBuyngonLogoColor}
          />
        </Box>

        <NavTab />
      </Box>

      <Box className={classes.middleSection}>
        <SearchInput />
      </Box>

      <Box className={classes.rightSection}>
        <NotificationButton />

        <Box mx={2.75}>
          <ShoppingCartButton />
        </Box>

        <ProfileButton />
      </Box>
    </Paper>
  );
}
