const PUBLIC = `${process.env.PUBLIC_URL}`;

export default {
  coinIcon: `${PUBLIC}/images/icon/ic-coin.svg`,
  iconNoti: `${PUBLIC}/images/icon/ic-danger.svg`,
  deleteIcon: `${PUBLIC}/images/icon/ic-delete.svg`,
  arrowDown: `${PUBLIC}/images/icon/ic-down.svg`,
  v3LogoCircle: `${PUBLIC}/images/icon/ic-logo-circle.svg`,
  orderIcon: `${PUBLIC}/images/icon/order.svg`,
  rechargeIcon: `${PUBLIC}/images/icon/recharge.svg`,
  refundIcon: `${PUBLIC}/images/icon/refund.svg`,
  shippingIcon: `${PUBLIC}/images/icon/shipping.svg`,
  withdrawIcon: `${PUBLIC}/images/icon/withdraw.svg`
};
