import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import GiftIcon from '../Icons/GiftIcon';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: '#E6FAF6',
    color: '#00CCA7',
    border: '1px solid #00CCA7',
    padding: theme.spacing(0.25, 1),
    width: 'fit-content',
    fontSize: 10,
    borderRadius: 4
  }
}));

export default function GiftLabel() {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" className={classes.container}>
      <GiftIcon style={{ fontSize: 10 }} />
      &nbsp;Quà tặng
    </Box>
  );
}
