import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { any } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  alert: {
    backgroundColor: '#FFFAEA',
    borderColor: '#FFAA00',
    borderRadius: theme.spacing(1.5)
  },
  alertNew: {
    backgroundColor: '#FFEBEC',
    borderColor: '#F51D2C',
    borderRadius: theme.spacing(1),
    '&.MuiAlert-message': {
      color: '#F51D2C'
    },
    '&.MuiAlert-root': {
      padding: theme.spacing(0, 2)
    }
  }
}));

export default function AlertContent({ icon, children }) {
  const classes = useStyles();

  return (
    <Alert
      variant="outlined"
      severity="error"
      icon={icon}
      className={classes.alertNew}
    >
      {children}
    </Alert>
  );
}

AlertContent.propTypes = {
  icon: any,
  children: any
};

AlertContent.defaultProps = {
  icon: null,
  children: null
};
