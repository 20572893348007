import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function VNTrackingIconContained(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.61 7.75078L18.37 8.19078C18.25 8.23078 18.14 8.25078 18.03 8.25078C17.62 8.25078 17.23 8.00078 17.09 7.59078C16.9 7.07078 17.17 6.49078 17.69 6.31078L18.87 5.89078C17.35 2.97078 14.43 0.890781 11 0.550781V2.00078C11 2.55078 10.56 3.00078 10 3.00078C9.45 3.00078 9 2.55078 9 2.00078V0.550781C5.57 0.890781 2.64 2.98078 1.13 5.90078L2.28 6.31078C2.79 6.49078 3.07 7.07078 2.88 7.59078C2.74 8.00078 2.35 8.25078 1.94 8.25078C1.83 8.25078 1.71 8.23078 1.6 8.19078L0.39 7.76078C0.13 8.63078 0 9.55078 0 10.5008V12.2508C0 13.2108 0.79 14.0008 1.75 14.0008H18.25C19.21 14.0008 20 13.2108 20 12.2508V10.5008C20 9.55078 19.87 8.62078 19.61 7.75078ZM11.47 10.2908C11.45 10.3908 11.42 10.4808 11.38 10.5708C11.34 10.6608 11.3 10.7508 11.24 10.8308C11.19 10.9208 11.12 10.9908 11.06 11.0608C10.77 11.3408 10.39 11.5008 10 11.5008C9.6 11.5008 9.21 11.3408 8.94 11.0608C8.87 10.9908 8.8 10.9208 8.75 10.8308C8.69 10.7608 8.66 10.6708 8.62 10.5908L6.67 9.92078C6.15 9.74078 5.88 9.17078 6.05 8.65078C6.23 8.12078 6.8 7.84078 7.33 8.02078L9.29 8.69078C9.85 8.40078 10.6 8.48078 11.06 8.94078C11.33 9.22078 11.5 9.60078 11.5 10.0008C11.5 10.1008 11.48 10.2008 11.47 10.2908Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
