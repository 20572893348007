import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function WalletIconContained(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 5.75C12.35 5.75 11 7.1 11 8.75C11 10.4 12.35 11.75 14 11.75H20V5.75H14ZM15.02 10C14.33 10 13.76 9.44 13.76 8.75C13.76 8.06 14.32 7.5 15.01 7.5H15.02C15.71 7.5 16.27 8.06 16.27 8.75C16.27 9.44 15.71 10 15.02 10Z"
        fill="#1D50FF"
      />
      <path
        d="M20 2.25V3.75H14C11.24 3.75 9 5.99 9 8.75C9 11.51 11.24 13.75 14 13.75H20V14.25C20 15.35 19.1 16.25 18 16.25H2C0.9 16.25 0 15.35 0 14.25V2.25C0 1.15 0.9 0.25 2 0.25H18C19.1 0.25 20 1.15 20 2.25Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
