import { SvgIcon } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

export default function ExtensionIcon({ fill, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.750129 18C0.550129 18 0.360129 17.92 0.220129 17.78C0.020129 17.58 -0.0498711 17.28 0.0401289 17.01L3.54013 6.51C3.62013 6.26 3.83013 6.08 4.08013 6.02C4.33013 5.96 4.60013 6.04 4.78013 6.22L11.7801 13.22C11.9601 13.4 12.0401 13.67 11.9801 13.92C11.9201 14.17 11.7301 14.38 11.4901 14.46L0.990129 17.96C0.910129 17.99 0.830129 18 0.750129 18ZM4.58013 8.14L1.94013 16.07L9.87013 13.43L4.58013 8.14Z"
        fill={fill}
      />
      <path
        d="M10.04 8.04001C9.84998 8.04001 9.65998 7.97001 9.50998 7.82001C9.21998 7.53001 9.21998 7.05001 9.50998 6.76001C10.07 6.20001 10.7 6.23001 11.08 6.24001C11.41 6.26001 11.44 6.24001 11.52 6.16001C11.6 6.08001 11.61 6.05001 11.6 5.72001C11.58 5.34001 11.56 4.71001 12.11 4.16001C12.67 3.60001 13.3 3.63001 13.68 3.64001C14.01 3.65001 14.04 3.64001 14.12 3.56001C14.2 3.48001 14.21 3.44001 14.2 3.12001C14.18 2.74001 14.16 2.11001 14.72 1.55001C15.28 0.990014 15.91 1.02001 16.29 1.03001C16.62 1.05001 16.65 1.03001 16.73 0.950013C17.02 0.660013 17.5 0.660013 17.79 0.950013C18.08 1.24001 18.08 1.72001 17.79 2.01001C17.23 2.57001 16.6 2.54001 16.22 2.53001C15.89 2.52001 15.86 2.53001 15.78 2.61001C15.7 2.69001 15.69 2.73001 15.7 3.05001C15.72 3.43001 15.74 4.06001 15.18 4.62001C14.62 5.18001 13.99 5.15001 13.62 5.14001C13.29 5.13001 13.26 5.14001 13.18 5.22001C13.1 5.30001 13.09 5.33001 13.1 5.66001C13.12 6.04001 13.14 6.67001 12.59 7.22001C12.03 7.78001 11.41 7.75001 11.02 7.74001C10.69 7.73001 10.66 7.74001 10.58 7.82001C10.43 7.97001 10.24 8.04001 10.04 8.04001Z"
        fill={fill}
      />
      <path
        d="M7.25 6C6.84 6 6.5 5.66 6.5 5.25V3.25C6.5 2.29 5.71 1.5 4.75 1.5C4.34 1.5 4 1.16 4 0.75C4 0.34 4.34 0 4.75 0C6.54 0 8 1.46 8 3.25V5.25C8 5.66 7.66 6 7.25 6Z"
        fill={fill}
      />
      <path
        d="M17.25 14C16.84 14 16.5 13.66 16.5 13.25C16.5 12.29 15.71 11.5 14.75 11.5H12.75C12.34 11.5 12 11.16 12 10.75C12 10.34 12.34 10 12.75 10H14.75C16.54 10 18 11.46 18 13.25C18 13.66 17.66 14 17.25 14Z"
        fill={fill}
      />
      <path
        d="M9.75 2.49999C9.65 2.49999 9.56 2.47999 9.46 2.43999C9.37 2.39999 9.29 2.34999 9.22 2.27999C9.08 2.13999 9 1.94999 9 1.74999C9 1.54999 9.08 1.35999 9.22 1.21999C9.43 1.00999 9.76 0.939989 10.04 1.05999C10.13 1.09999 10.21 1.14999 10.28 1.21999C10.35 1.28999 10.4 1.36999 10.44 1.45999C10.48 1.55999 10.5 1.64999 10.5 1.74999C10.5 1.94999 10.42 2.13999 10.28 2.27999C10.21 2.34999 10.13 2.39999 10.04 2.43999C9.94 2.47999 9.85 2.49999 9.75 2.49999Z"
        fill={fill}
      />
      <path
        d="M17.25 9C17.05 9 16.86 8.92 16.72 8.78C16.65 8.71 16.6 8.63 16.56 8.54C16.52 8.45 16.5 8.35 16.5 8.25C16.5 8.05 16.58 7.86 16.72 7.72C16.79 7.65 16.87 7.6 16.96 7.56C17.15 7.48 17.35 7.48 17.54 7.56C17.63 7.6 17.71 7.65 17.78 7.72C17.92 7.86 18 8.05 18 8.25C18 8.35 17.98 8.45 17.94 8.54C17.9 8.63 17.85 8.71 17.78 8.78C17.64 8.92 17.45 9 17.25 9Z"
        fill={fill}
      />
    </SvgIcon>
  );
}

ExtensionIcon.propTypes = {
  fill: string
};

ExtensionIcon.defaultProps = {
  fill: '#121331'
};
