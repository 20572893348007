import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function BuyNgonIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#1D50FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83379 9.49444C8.31151 10.1588 8 10.9966 8 11.9072V20.0932C8 22.251 9.74922 24.0002 11.907 24.0002H20.0931C22.2508 24.0002 24 22.251 24 20.0932V11.9072C24 9.7494 22.2508 8.00018 20.0931 8.00018H11.907C11.8482 8.00018 11.7897 8.00148 11.7315 8.00405C12.0408 8.39949 12.2918 8.84719 12.4697 9.3366L14.1174 13.8717C14.4538 14.7975 15.3336 15.4139 16.3186 15.4139C17.2217 15.4139 17.9537 14.6818 17.9537 13.7788V13.7136C17.9537 12.8599 17.2617 12.1679 16.408 12.1679V9.0764C18.9691 9.0764 21.0452 11.1525 21.0452 13.7136V13.7788C21.0452 16.3892 18.929 18.5053 16.3186 18.5053C14.0333 18.5053 11.9922 17.0753 11.2118 14.9274L9.56405 10.3923C9.42531 10.0105 9.16382 9.6985 8.83379 9.49444ZM14.4983 20.8822V21.5074C16.4414 21.9195 18.4806 21.6457 19.984 20.6837C20.2634 20.9572 20.6459 21.1259 21.0679 21.1259C21.9237 21.1259 22.6175 20.4321 22.6175 19.5762C22.6175 18.7204 21.9237 18.0266 21.0679 18.0266C20.212 18.0266 19.5182 18.7204 19.5182 19.5762C19.5182 19.7896 19.5613 19.9928 19.6393 20.1777C18.2684 21.0469 16.3583 21.3079 14.4983 20.8822ZM10.9841 21.1259C11.84 21.1259 12.5338 20.4321 12.5338 19.5762C12.5338 18.7204 11.84 18.0266 10.9841 18.0266C10.1283 18.0266 9.43452 18.7204 9.43452 19.5762C9.43452 20.4321 10.1283 21.1259 10.9841 21.1259Z"
        fill="#F2F4F7"
      />
    </SvgIcon>
  );
}
