import { combineReducers } from 'redux';
import bank from './bankReducer';
import campaign from './campaignReducer';
import catalog from './catalogReducer';
import category from './categoryReducer';
import common from './commonReducer';
import config from './configReducer';
import dealngon from './dealngonReducer';
import deal from './dealReducer';
import deposit from './depositReducer';
import location from './locationReducer';
import notification from './notification';
import profile from './profileReducer';
import referral from './referralReducer';
import saleOrder from './saleOrderReducer';
import saleOrderV2 from './saleOrderV2Reducer';
import tracking from './trackingReducer';
import user from './userReducer';
import vnTracking from './vnTracking';
import website from './websiteReducer';

export default combineReducers({
  bank,
  campaign,
  catalog,
  category,
  common,
  config,
  dealngon,
  deal,
  deposit,
  location,
  notification,
  profile,
  referral,
  saleOrder,
  saleOrderV2,
  tracking,
  user,
  vnTracking,
  website
});
