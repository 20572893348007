import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function FinishedIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#00CCA7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.442 9.31357L10.4375 16.318L6.55811 12.4386L7.44199 11.5547L10.4375 14.5502L16.5581 8.42969L17.442 9.31357Z"
        fill="white"
      />
    </SvgIcon>
  );
}
