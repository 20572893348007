import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function EditIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 11H1.5C1.295 11 1.125 10.83 1.125 10.625C1.125 10.42 1.295 10.25 1.5 10.25H10.5C10.705 10.25 10.875 10.42 10.875 10.625C10.875 10.83 10.705 11 10.5 11Z"
        fill="#292D32"
      />
      <path
        d="M9.51004 1.74081C8.54004 0.770811 7.59004 0.745811 6.59504 1.74081L5.99004 2.34581C5.94004 2.39581 5.92004 2.47581 5.94004 2.54581C6.32004 3.87081 7.38004 4.93081 8.70504 5.31081C8.72504 5.31581 8.74504 5.32081 8.76504 5.32081C8.82004 5.32081 8.87004 5.30081 8.91004 5.26081L9.51004 4.65581C10.005 4.16581 10.245 3.69081 10.245 3.21081C10.25 2.71581 10.01 2.23581 9.51004 1.74081Z"
        fill="#292D32"
      />
      <path
        d="M7.80504 5.76539C7.66004 5.69539 7.52004 5.62539 7.38504 5.54539C7.27504 5.48039 7.17004 5.41039 7.06504 5.33539C6.98004 5.28039 6.88004 5.20039 6.78504 5.12039C6.77504 5.11539 6.74004 5.08539 6.70004 5.04539C6.53504 4.90539 6.35004 4.72539 6.18504 4.52539C6.17004 4.51539 6.14504 4.48039 6.11004 4.43539C6.06004 4.37539 5.97504 4.27539 5.90004 4.16039C5.84004 4.08539 5.77004 3.97539 5.70504 3.86539C5.62504 3.73039 5.55504 3.59539 5.48504 3.45539C5.39326 3.25872 5.13513 3.2003 4.98167 3.35376L2.17004 6.16539C2.10504 6.23039 2.04504 6.35539 2.03004 6.44039L1.76004 8.35539C1.71004 8.69539 1.80504 9.01539 2.01504 9.23039C2.19504 9.40539 2.44504 9.50039 2.71504 9.50039C2.77504 9.50039 2.83504 9.49539 2.89504 9.48539L4.81504 9.21539C4.90504 9.20039 5.03004 9.14039 5.09004 9.07539L7.90631 6.25912C8.0567 6.10873 8.00019 5.84996 7.80504 5.76539Z"
        fill="#292D32"
      />
    </SvgIcon>
  );
}

export function EditIconOutlined() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.37378 11.22H11.0001"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89 2.89544C7.27783 2.43194 7.97499 2.36397 8.44811 2.74391C8.47427 2.76453 9.31474 3.41744 9.31474 3.41744C9.83449 3.73164 9.99599 4.3996 9.6747 4.90934C9.65764 4.93664 4.90597 10.8803 4.90597 10.8803C4.74789 11.0775 4.50792 11.1939 4.25145 11.1967L2.43176 11.2196L2.02177 9.4842C1.96433 9.2402 2.02177 8.98393 2.17985 8.78672L6.89 2.89544Z"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.0105 3.99805L8.73662 6.09161"
        stroke="#252629"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
