import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import isEmpty from 'lodash/isEmpty';
import { SnackbarProvider } from 'notistack';
import React, { lazy, Suspense, useEffect, useLayoutEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import './App.css';
import { IS_AUTH } from './contents/Constants';
import actions from './redux/actions';
import store from './redux/store';
import RouteApp from './routes';
import services from './services';
import connectSocket from './socket';
import theme from './utils/theme';
import dispatch from './utils/dispatch';
import { loadFbLoginApi } from './utils/facebook';

const AddCartAnimation = lazy(() =>
  import('./components/2.2.0/Common/AddCartAnimation')
);
const AlertMessage = lazy(() => import('./components/AlertMessage'));
const DepositDetailModalGlobal = lazy(() =>
  import('./components/Improvements/Deposit/DepositDetailModalGlobal')
);
const NotificationModal = lazy(() =>
  import('./components/modals/NotificationModal')
);

export function getStore() {
  return store;
}

export const history = createBrowserHistory();

export default function App() {
  // useEffect(() => {
  //   const dunsIframe = document.querySelector(
  //     `script[src="https://dunsregistered.dnb.com/"]`
  //   );
  //   const dunsContainer = document.querySelector('iframe#Iframe1');
  //   dunsIframe.remove();
  //   dunsContainer.remove();

  //   const container = document.createElement('div');
  //   container.style = 'position: absolute;bottom: 0;z-index: -1;opacity:0;';
  //   container.appendChild(dunsIframe);
  //   container.appendChild(dunsContainer);
  //   document.body.appendChild(container);
  // });

  useLayoutEffect(() => {
    (async () => {
      try {
        const response = await services.getConfiguration();
        if (isEmpty(response)) {
          throw new Error('Vui lòng bổ sung theme configuration');
        }

        const { data } = response;
        dispatch(actions.setting({ ...data, theme }));
      } catch (error) {
        throw new Error(error);
      }
    })();
  }, []);

  useEffect(() => {
    const isAuth = Boolean(localStorage.getItem(IS_AUTH));
    if (isAuth) {
      dispatch(actions.getUserProfile());
    }
  }, []);

  // useGetListDealFlashSale()

  useEffect(() => {
    connectSocket();
    loadFbLoginApi();
  }, []);

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <CssBaseline />

      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <Router history={history}>
            <RouteApp />
          </Router>

          <Suspense fallback={<div />}>
            <AddCartAnimation />
          </Suspense>

          <Suspense fallback={<div />}>
            <AlertMessage />
          </Suspense>

          <Suspense fallback={<div />}>
            <NotificationModal />
          </Suspense>

          <Suspense fallback={<div />}>
            <DepositDetailModalGlobal />
          </Suspense>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}
