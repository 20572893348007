import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import TYPE from '../actions/TYPE';

const initialState = null;

const profile = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.SIGN_IN_MANUAL_SUCCESS:
    case TYPE.SIGN_IN_SUCCESS:
      return action.payload;

    case TYPE.SIGN_IN_MANUAL_FAILED:
    case TYPE.SIGN_IN_FAILED:
    case TYPE.LOGOUT_SUCCESS:
      return initialState;

    case TYPE.REGISTER_SHIPPING_SERVICE_SUCCESS:
    case TYPE.NEW_EDIT_DELIVERY_ADDRESS_SUCCESS:
    case TYPE.CREATE_DELIVERY_ADDRESS_SUCCESS:
    case TYPE.EDIT_ADDRESS_IMPROVEMENT_SUCCESS:
    case TYPE.CREATE_NEW_ADDRESS_IMPROVEMENT_SUCCESS:
    case TYPE.EDIT_DELIVERY_ADDRESS_SUCCESS:
    case TYPE.VERIFY_EMAIL_SUCCESS:
    case TYPE.GET_USER_PROFILE_SUCCESS:
    case TYPE.POST_CREATE_NEW_ADDRESS_SUCCESS:
    case TYPE.UPDATE_USER_PROFILE_SUCCESS:
    case TYPE.UPDATE_FMC_TOKEN_SUCCESS:
    case TYPE.VERIFY_EMAIL_AND_AUTO_LOGIN_SUCCESS:
    case TYPE.IGNORE_VERIFY_PHONE_SUCCESS:
    case TYPE.NOTIFICATION_DEPOSIT_SUCCESS:
      return { ...state, ...action.data };

    case TYPE.RESET_DELIVERY_ADDRESS_SUCCESS:
    case TYPE.PUT_SELECT_ADDRESS_SUCCESS: {
      const { addressId } = action;
      const addressManagement = map(get(state, 'address_management'), item => {
        if (item._id === addressId) {
          return {
            ...item,
            selected: true
          };
        }

        return {
          ...item,
          selected: false
        };
      });
      return {
        ...state,
        address_management: addressManagement
      };
    }

    case TYPE.UPDATE_USER_PROFILE_PREFIX_SUCCESS:
      return {
        ...state,
        is_email_verify: true,
        verifyEmail: { email: action.email, type: action.type }
      };

    case TYPE.RESEND_EMAIL:
    case TYPE.SIGN_UP_MANUAL_SUCCESS:
    case TYPE.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        verifyEmail: { email: action.email, type: action.type }
      };

    case TYPE.DELETE_ADDRESS_SUCCESS:
      const { addressId } = action;
      return {
        ...state,
        address_management: filter(
          state.address_management,
          item => item._id !== addressId
        )
      };

    case TYPE.VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        phone: action.phone,
        is_phone_verify: true,
        phone_ignore: true
      };

    case TYPE.CLOSE_EXTENSION_SUCCESS:
      return {
        ...state,
        extension_ignore: true
      };

    case TYPE.COUPON_VERIFY_PHONE:
      return {
        ...state,
        couponVerifyPhone: action?.coupon
      };
    case TYPE.UPDATE_BALANCE:
      return {
        ...state,
        deposit: {
          ...state.deposit,
          balance: state?.deposit?.balance - action?.payload
        }
      };

    default:
      return state;
  }
};

export default profile;
