import { SvgIcon } from '@material-ui/core';
import { bool } from 'prop-types';
import React from 'react';

export default function SubtractIcon({ disable, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="2"
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 0.447715 0.447715 0 1 0H14.9992C15.5515 0 15.9992 0.447715 15.9992 1C15.9992 1.55228 15.5515 2 14.9992 2H1C0.447715 2 0 1.55228 0 1Z"
        fill={disable ? '#B0B1B8' : '#252629'}
      />
    </SvgIcon>
  );
}

SubtractIcon.propTypes = {
  disable: bool
};

SubtractIcon.defaultProps = {
  disable: false
};
