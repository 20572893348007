import React from 'react';

export default function FacebookIcon() {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.833984" width="32" height="32" rx="16" fill="#337FFF" />
      <path
        d="M19.915 17.8766L20.3709 14.9797H17.5623V13.0968C17.5623 12.3046 17.9544 11.5306 19.2083 11.5306H20.5031V9.06377C19.7491 8.94353 18.9871 8.87847 18.2234 8.86914C15.9118 8.86914 14.4027 10.2587 14.4027 12.7709V14.9797H11.8403V17.8766H14.4027V24.8834H17.5623V17.8766H19.915Z"
        fill="white"
      />
    </svg>
  );
}
