import { call, all, takeLatest, put } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getProvince() {
  try {
    const { data } = yield call(services.location.getProvince);
    yield put({
      type: TYPE.GET_PROVINCE_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_PROVINCE_FAILED,
      message: error
    });
  }
}

function* getDistrict(action) {
  try {
    const { provinceId } = action;
    const { data } = yield call(services.location.getDistrict, provinceId);
    yield put({
      type: TYPE.GET_DISTRICT_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_DISTRICT_FAILED,
      message: error
    });
  }
}

function* getWard(action) {
  try {
    const { provinceId, districtId } = action;
    const { data } = yield call(services.location.getWard, provinceId, districtId);
    yield put({
      type: TYPE.GET_WARD_SUCCESS,
      data
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_WARD_FAILED,
      message: error
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_PROVINCE, getProvince),
    takeLatest(TYPE.GET_DISTRICT, getDistrict),
    takeLatest(TYPE.GET_WARD, getWard)
  ]);
}
