import React, { lazy, Suspense } from 'react';
import SkeletonOrderManagement from '../components/Skeleton/SkeletonOrderManagement';
import SkeletonUserInfoLayout from '../components/Skeleton/SkeletonUserInfoLayout';
import SkeletonVNTracking from '../components/Skeleton/SkeletonVNTracking';
import { Path } from '../contents/Constants';

import NothingLayout from '../layouts/NothingLayout';
import OutSideLayout from '../layouts/OutSideLayout';
import MainLayout from '../layouts/MainLayout';
import UserInfoLayout from '../layouts/UserInfoLayout';

// import DealNgon from '../pages/DealNgon';

// const DealNgon = lazy(() => import('../pages/DealNgon'));
const FlashSale = lazy(() => import('../pages/FlashSale'));
const Catalog = lazy(() => import('../pages/DealNgon/Catalog'));
const CatalogDetail = lazy(() => import('../pages/DealNgon/CatalogDetail'));
const WebsiteUS = lazy(() => import('../pages/WebsiteUS'));
const Policy = lazy(() => import('../pages/Policy'));
const Contact = lazy(() => import('../pages/Policy/Contact'));
const PromotionUsPage = lazy(() => import('../pages/PromotionUsPage'));
const Quotation = lazy(() => import('../pages/Quotation'));
const Cart = lazy(() => import('../pages/Cart'));
const Privacy = lazy(() => import('../pages/Privacy'));
const DebtRecovery = lazy(() => import('../pages/DebtRecovery'));

const OrderConfirm = lazy(() => import('../pages/OrderConfirm'));
const OrderManagement = lazy(() => import('../pages/OrderManagement'));
const OrderManagementDetail = lazy(() => import('../pages/OrderManagementDetail'));
const OrderManagementJourney = lazy(() => import('../pages/OrderManagementJourney'));
const VNTracking = lazy(() => import('../pages/VNTracking'));
const VNTrackingDetail = lazy(() => import('../pages/VNTrackingDetail'));
const UserProfile = lazy(() => import('../pages/UserProfile'));
const UserInfo = lazy(() => import('../pages/UserInfo'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const AddressManagement = lazy(() => import('../pages/AddressManagement'));
const Deposit = lazy(() => import('../pages/Deposit'));
const Notification = lazy(() => import('../pages/Notification'));
const Referral = lazy(() => import('../pages/Referral'));
const Shipping = lazy(() => import('../pages/Shipping'));
const Help = lazy(() => import('../pages/Help'));
const Welcome = lazy(() => import('../pages/Welcome'));

const SignUp = lazy(() => import('../pages/SignUp'));
const SignIn = lazy(() => import('../pages/SignIn'));
const Identify = lazy(() => import('../pages/Identify'));
const Recovery = lazy(() => import('../pages/Recovery'));
const VerifyEmail = lazy(() => import('../pages/VerifyEmail'));
const AutoLogin = lazy(() => import('../pages/AutoLogin'));
const ValidateReferral = lazy(() => import('../pages/Referral/ValidateReferral'));

const RoutePages = {
  normal: [
    // {
    //   path: Path.HOME,
    //   Layout: MainLayout,
    //   Page: () => (
    //     // <Suspense fallback={<div />}>
    //     <DealNgon />
    //     // </Suspense>
    //   )
    // },
    {
      path: Path.VERIFY_EMAIL,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <VerifyEmail />
        </Suspense>
      )
    },
    {
      path: Path.FLASH_SALE,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <FlashSale />
        </Suspense>
      )
    },
    {
      path: Path.WEBSITE_US,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <WebsiteUS />
        </Suspense>
      )
    },
    {
      path: Path.POLICY,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Policy />
        </Suspense>
      )
    },
    {
      path: Path.CONTACT,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Contact />
        </Suspense>
      )
    },
    {
      path: Path.PROMOTION_US,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <PromotionUsPage />
        </Suspense>
      )
    },
    {
      path: Path.QUOTATION,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Quotation />
        </Suspense>
      )
    },
    {
      path: Path.SHOPPING_CART,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Cart />
        </Suspense>
      )
    },
    {
      path: Path.PRIVACY_POLICY,
      Layout: ({ children }) => children,
      Page: () => (
        <Suspense fallback={<div />}>
          <Privacy />
        </Suspense>
      )
    },
    {
      path: Path.DEBT_RECOVERY,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <DebtRecovery />
        </Suspense>
      )
    },
    {
      path: Path.PRODUCT_DETAIL,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <CatalogDetail />
        </Suspense>
      )
    },
    {
      path: Path.PRODUCTS,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Catalog />
        </Suspense>
      )
    }
  ],
  private: [
    {
      path: Path.SHOPPING_ORDER,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <OrderConfirm />
        </Suspense>
      )
    },
    {
      path: Path.ORDER_MANAGEMENT,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonOrderManagement />}>
          <OrderManagement />
        </Suspense>
      )
    },
    {
      path: Path.TRACKING_ORDER_DETAIL,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <OrderManagementDetail />
        </Suspense>
      )
    },
    {
      path: Path.ORDER_MANAGEMENT_DETAIL_TRACKING,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <OrderManagementJourney />
        </Suspense>
      )
    },
    {
      path: Path.LIST_VN_TRACKING,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonVNTracking />}>
          <VNTracking />
        </Suspense>
      )
    },
    {
      path: Path.DETAIL_VN_TRACKING,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <VNTrackingDetail />
        </Suspense>
      )
    },
    {
      path: Path.USER_PROFILE,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <UserProfile />
        </Suspense>
      )
    },
    {
      path: Path.USER_INFO,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <UserInfo />
        </Suspense>
      )
    },
    {
      path: Path.CHANGE_PASSWORD,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <ChangePassword />
        </Suspense>
      )
    },
    {
      path: Path.USER_ADDRESS,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <AddressManagement />
        </Suspense>
      )
    },
    {
      path: Path.USER_DEPOSIT,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <Deposit />
        </Suspense>
      )
    },
    {
      path: Path.NOTIFICATION,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <Notification />
        </Suspense>
      )
    },
    {
      path: Path.USER_REFERRAL,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<SkeletonUserInfoLayout />}>
          <Referral />
        </Suspense>
      )
    },
    {
      path: Path.SHIPPING,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Shipping />
        </Suspense>
      )
    },
    {
      path: Path.HELP,
      Layout: UserInfoLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Help />
        </Suspense>
      )
    },
    {
      path: Path.WELCOME,
      Layout: MainLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Welcome />
        </Suspense>
      )
    }

    // {
    //   path: Path.SHOPPING_DEAL,
    //   Layout: MainLayout,
    //   Page: DealConfirm
    // },
    // {
    //   path: Path.SHOPPING_DEAL_COMPLETE,
    //   Layout: MainLayout,
    //   Page: CompleteDeal
    // },
    // {
    //   path: Path.DEAL_MANAGEMENT,
    //   Layout: UserInfoLayout,
    //   Page: DealManagement
    // },
    // {
    //   path: Path.DEAL_DETAIL_MANAGEMENT,
    //   Layout: UserInfoLayout,
    //   Page: DealDetailManagement
    // },
    // {
    //   path: Path.REVIEW_BUY_DEAL,
    //   Layout: MainLayout,
    //   Page: BuyDealReview
    // }
  ],
  public: [
    {
      path: Path.SIGN_UP,
      Layout: OutSideLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <SignUp />
        </Suspense>
      )
    },
    {
      path: Path.SIGN_IN,
      Layout: OutSideLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <SignIn />
        </Suspense>
      )
    },
    {
      path: Path.IDENTIFY,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Identify />
        </Suspense>
      )
    },
    {
      path: Path.RECOVERY,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <Recovery />
        </Suspense>
      )
    },
    {
      path: Path.RECOVERY_EMAIL,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <VerifyEmail />
        </Suspense>
      )
    },
    {
      path: Path.VERIFY_EMAIL_CONFIRMED,
      Layout: NothingLayout,
      Page: () => (
        <Suspense fallback={<div />}>
          <AutoLogin />
        </Suspense>
      )
    },
    {
      path: Path.REFERRAL,
      Layout: ({ children }) => children,
      Page: () => (
        <Suspense fallback={<div />}>
          <ValidateReferral />
        </Suspense>
      )
    }

    // {
    //   path: Path.DEAL_DETAIL,
    //   Layout: MainLayout,
    //   Page: DealDetail
    // },
    // {
    //   path: Path.SELLER_PROFILE,
    //   Layout: MainLayout,
    //   Page: SellerProfile
    // }
  ]
};

export default RoutePages;
