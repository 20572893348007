import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { any, array, func, object } from 'prop-types';
import React from 'react';
import { Path, VN_TRACKING_STATE } from '../../../../../contents/Constants';
import HeaderTrackingRps from '../../../OrderManagement/Responsive/HeaderTrackingRps';

const useStyles = makeStyles({
  fw700: { fontWeight: 700 }
});

export default function Header({ countList, tab, onChangeTab, tabList }) {
  const classes = useStyles();

  const handleGoBack = () => {
    window.location.href = Path.HOME;
  };

  return (
    <HeaderTrackingRps
      title={
        <Typography variant="body2" className={classes.fw700}>
          Vận chuyển nội địa
        </Typography>
      }
      onLeftAction={handleGoBack}
    >
      <Paper elevation={0}>
        <Divider />
        <Tabs
          variant="scrollable"
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onChangeTab}
        >
          {tabList.map((item, index) => {
            const { label } = VN_TRACKING_STATE[item];
            const countItem = countList[item];
            const countLabel = countItem > 0 ? ` (${countItem})` : '';

            return <Tab value={item} key={index} label={`${label}${countLabel}`} />;
          })}
        </Tabs>
      </Paper>
      <Divider />
    </HeaderTrackingRps>
  );
}

Header.propTypes = {
  countList: object.isRequired,
  tab: any,
  tabList: array,
  onChangeTab: func
};

Header.defaultProps = {
  tab: 0,
  tabList: [],
  onChangeTab: () => {}
};
