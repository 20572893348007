import { Box, Grid, IconButton, makeStyles } from '@material-ui/core';
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { any, element, func, object } from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.spacing(7)
  },
  box: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  sticky: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
    minHeight: theme.spacing(7)
    // display: 'flex',
    // alignItems: 'center'
  },
  leftButton: {
    position: 'absolute',
    left: 0,
    zIndex: 1
  },
  rightButton: {
    position: 'absolute',
    right: 0
  }
}));

export default function HeaderTrackingRps({
  children,
  className,
  style,
  onLeftAction,
  onRightAction,
  title,
  rightActionProps,
  subTitle,
  rightIcon,
  leftIcon
}) {
  const classes = useStyles();

  return (
    <Box style={style} className={clsx(className, classes.sticky)}>
      <Grid container className={classes.container}>
        <Box className={classes.leftButton}>
          {onLeftAction && (
            <IconButton onClick={onLeftAction}>{leftIcon}</IconButton>
          )}
        </Box>

        <Box className={classes.box}>
          {title}
          {subTitle}
        </Box>

        <Box className={classes.rightButton}>
          {onRightAction && (
            <IconButton {...rightActionProps} onClick={onRightAction}>
              {rightIcon}
            </IconButton>
          )}
        </Box>
      </Grid>

      {children}
    </Box>
  );
}

HeaderTrackingRps.propTypes = {
  children: any,
  className: any,
  style: any,
  onLeftAction: func,
  onRightAction: func,
  title: element,
  rightActionProps: object,
  rightIcon: element,
  leftIcon: element,
  subTitle: element
};

HeaderTrackingRps.defaultProps = {
  children: null,
  className: null,
  style: null,
  onLeftAction: null,
  onRightAction: null,
  rightIcon: null,
  leftIcon: <KeyboardBackspaceIcon />,
  title: null,
  rightActionProps: {},
  subTitle: null
};
