import TextField from '@material-ui/core/TextField';
import { string } from 'prop-types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export default function TextFieldInput({ name, ...props }) {
  const methods = useFormContext();

  if (!methods?.control) return <TextField name={name} {...props} />;

  const {
    control,
    formState: { errors }
  } = methods;
  const error = errors?.[name];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <TextField
          innerRef={ref}
          {...field}
          {...props}
          {...(error && {
            error: Boolean(error),
            helperText: error.message
          })}
        />
      )}
    />
  );
}

TextFieldInput.propTypes = {
  name: string.isRequired
};
