const theme = {
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 900
    },
    h2: {
      fontSize: 24,
      fontWeight: 700
    },
    h3: {
      fontSize: 20,
      fontWeight: 900
    },
    h4: {
      fontSize: 18,
      fontWeight: 700
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 700
    },
    body1: {
      fontSize: 18,
      fontWeight: 400
    },
    body2: {
      fontSize: 14,
      fontWeight: 400
    },
    overline: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: 'inherit'
    },
    caption: {
      fontSize: 12,
      fontWeight: 400
    },
    fontFamily: ['Mulish', 'Nunito Sans', 'sans-serif'],
    button: {
      textTransform: 'none',
      fontSize: 14,
      fontWeight: 700,
      borderRadius: 8
    },
    select: {
      fontSize: 14,
      fontWeight: 700,
      borderRadius: 8,
      border: '1px solid #E1E2E5'
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)'
      },
      outlined: {
        boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)'
      },
      rounded: {
        boxShadow: '0px 4px 16px -4px rgba(169, 171, 184, 0.24)'
      }
    },
    MuiButton: {
      root: {},
      contained: {
        backgroundColor: '#252629',
        color: '#FFFFFF'
      },
      containedPrimary: {
        backgroundColor: '#1D50FF',
        color: '#FFFFFF'
      },
      containedSecondary: {
        backgroundColor: '#00CC79',
        color: '#FFFFFF'
      },
      outlined: {
        borderColor: '#252629',
        color: '#252629'
      },
      outlinedPrimary: {
        borderColor: '#1D50FF',
        color: '#1D50FF'
      },
      outlinedSecondary: {
        borderColor: '#00CC79',
        color: '#00CC79'
      },
      text: {
        color: '#252629'
      },
      textPrimary: {
        color: '#1D50FF'
      },
      textSecondary: {
        color: '#00CC79'
      }
    }
  },
  palette: {
    primary: {
      100: '#EBF1FF',
      200: '#D6E1FF',
      300: '#99B4FF',
      400: '#5C81FF',
      main: '#1D50FF'
    },
    secondary: {
      100: '#E5FAF2',
      200: '#CCF5E4',
      300: '#99EBC9',
      400: '#33D694',
      main: '#00CC79'
    },
    grey: {
      100: '#F5F5F7',
      200: '#E1E2E5',
      300: '#B0B1B8',
      400: '#86878F',
      500: '#252629'
    },
    error: {
      main: '#F51D2C'
    },
    warning: {
      main: '#FFAA00',
      light: '#FFFAEA',
      dark: '#FADA5A'
    }
  }
};

export default theme;
