import TYPE from '../actions/TYPE';

const initialState = {
  extremely_tasty_deal: null
};

const campaign = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_EXTREMELY_TASTY_DEAL_CAMPAIGN_SUCCESS:
      return {
        ...state,
        extremely_tasty_deal: {
          ...state.extremely_tasty_deal,
          list: action.data
        }
      };

    default:
      return state;
  }
};

export default campaign;
