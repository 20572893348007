import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router';
import { Path } from '../../../contents/Constants';
import Images from '../../../contents/Images';
import useResponsive from '../../../hooks/useResponsive';
import { BuyNgonContainer } from '../Common';

const useStyles = makeStyles(theme => ({
  advertisement: {
    position: 'relative',
    alignItems: 'center',
    background: theme.palette.primary.main,
    height: theme.spacing(19),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center'
    }
  },
  flashSignup: {
    backgroundColor: 'white',
    width: theme.spacing(42.25),
    height: theme.spacing(6.5),
    color: theme.palette.primary.main,
    borderRadius: theme.spacing(2),
    fontSize: 16,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(32.25)
    }
  },
  white: {
    color: theme.palette.common.white
  },
  bold: {
    fontWeight: 900,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    }
  },
  box: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'static'
    }
  }
}));

const TITLE = 'BuyNgon tặng bạn khuyến mãi 50k';
const DESCRIPTION = 'Đăng ký và xác minh 2 bước đơn giản để nhận quà ngay';

export default function Advertisement() {
  const matches = useResponsive();
  const classes = useStyles();
  const history = useHistory();
  const onClick = () => {
    history.push(Path.SIGN_UP);
  };

  if (matches) {
    return (
      <Grid container className={classes.advertisement}>
        <Typography className={clsx(classes.white, classes.bold)}>
          {TITLE}
        </Typography>
        <Button
          color="default"
          variant="contained"
          className={classes.flashSignup}
          onClick={onClick}
        >
          Đăng ký ngay
        </Button>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.advertisement}>
      <Box className={classes.box}>
        <img src={Images.advertisement} alt="Advertisement" />

        <Grid container direction="column" justifyContent="center">
          <Typography className={clsx(classes.white, classes.bold)}>
            {TITLE}
          </Typography>

          <Typography className={classes.white} variant="body2">
            {DESCRIPTION}
          </Typography>
        </Grid>
      </Box>

      <BuyNgonContainer fixed style={{ position: 'relative' }}>
        <Grid container justifyContent="flex-end">
          <Button
            color="default"
            variant="contained"
            className={classes.flashSignup}
            onClick={onClick}
          >
            Đăng ký ngay
          </Button>
        </Grid>
      </BuyNgonContainer>
    </Grid>
  );
}
