import { SvgIcon } from '@material-ui/core';
import { string } from 'prop-types';
import React from 'react';

export default function WaitToConfirmIcon({ color, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8646 1.66797C15.8646 2.12797 15.4913 2.5013 15.0312 2.5013C14.5712 2.5013 14.1979 2.12797 14.1979 1.66797H12.5312C12.5312 2.12797 12.1579 2.5013 11.6979 2.5013C11.2379 2.5013 10.8646 2.12797 10.8646 1.66797H9.19792C9.19792 2.12797 8.82458 2.5013 8.36458 2.5013C7.90458 2.5013 7.53125 2.12797 7.53125 1.66797H5.86458C5.86458 2.12797 5.49125 2.5013 5.03125 2.5013C4.57125 2.5013 4.19792 2.12797 4.19792 1.66797H3.36458C2.90458 1.66797 2.53125 2.0413 2.53125 2.5013V16.668C2.53125 17.5888 3.27708 18.3346 4.19792 18.3346H15.8646C16.7854 18.3346 17.5312 17.5888 17.5312 16.668V2.5013C17.5312 2.0413 17.1579 1.66797 16.6979 1.66797H15.8646ZM10.8646 15.0013H5.86458C5.40458 15.0013 5.03125 14.628 5.03125 14.168C5.03125 13.708 5.40458 13.3346 5.86458 13.3346H10.8646C11.3246 13.3346 11.6979 13.708 11.6979 14.168C11.6979 14.628 11.3246 15.0013 10.8646 15.0013ZM10.8646 11.668H5.86458C5.40458 11.668 5.03125 11.2946 5.03125 10.8346C5.03125 10.3746 5.40458 10.0013 5.86458 10.0013H10.8646C11.3246 10.0013 11.6979 10.3746 11.6979 10.8346C11.6979 11.2946 11.3246 11.668 10.8646 11.668ZM14.6146 15.0013H13.7812C13.5512 15.0013 13.3646 14.8146 13.3646 14.5846V13.7513C13.3646 13.5213 13.5512 13.3346 13.7812 13.3346H14.6146C14.8446 13.3346 15.0312 13.5213 15.0312 13.7513V14.5846C15.0312 14.8146 14.8446 15.0013 14.6146 15.0013ZM14.6146 11.668H13.7812C13.5512 11.668 13.3646 11.4813 13.3646 11.2513V10.418C13.3646 10.188 13.5512 10.0013 13.7812 10.0013H14.6146C14.8446 10.0013 15.0312 10.188 15.0312 10.418V11.2513C15.0312 11.4813 14.8446 11.668 14.6146 11.668ZM14.1979 7.5013H5.86458C5.40458 7.5013 5.03125 7.12797 5.03125 6.66797C5.03125 6.20797 5.40458 5.83464 5.86458 5.83464H14.1979C14.6579 5.83464 15.0312 6.20797 15.0312 6.66797C15.0312 7.12797 14.6579 7.5013 14.1979 7.5013Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}

WaitToConfirmIcon.propTypes = {
  color: string
};

WaitToConfirmIcon.defaultProps = {
  color: '#1D50FF'
};
