import get from 'lodash/get';
import { convertListMobile, filterCategoryCatalog } from '../../utils/helpers';
import TYPE from '../actions/TYPE';

const initialState = {
  filter: {
    original: { price: [0, 100], star: null },
    current: { price: [0, 100], star: null }
  },
  filterRemake: {
    original: { price: ['', ''], star: null, sortBy: 'default', website: '' },
    current: { price: ['', ''], star: null, sortBy: 'default', website: '' }
  },
  category: {
    list: [],
    filter: [],
    page: {},
    list_mobile: [],
    category_id: ''
  },
  forYou: {
    list: [],
    filter: [],
    page: {},
    list_mobile: []
  },
  looking: {
    list: [],
    filter: [],
    page: {},
    list_mobile: []
  },
  favorite: {
    list: [],
    filter: [],
    page: {},
    list_mobile: []
  },
  flashSale: {
    list: null,
    filter: null
  },
  productDealHome: [],
  categories: [],
  detail: {
    original: [],
    list: [],
    selected: {},
    backup: null
  },
  searching: '',
  openFilter: false
};

const catalog = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.HANDLE_FILTER_CATALOG: {
      return {
        ...state,
        filterRemake: {
          ...state.filterRemake,
          current: { ...state.filterRemake.current, ...action.payload }
        }
      };
    }

    case TYPE.HANDLE_CHANGE_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          current: { ...state.filter.current, ...action.payload }
        }
      };
    }

    case TYPE.SAVE_BACKUP_PRODUCT_CATALOG: {
      return {
        ...state,
        detail: {
          ...state.detail,
          backup: action.payload
        }
      };
    }

    case TYPE.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: action.data
      };
    }

    case TYPE.FILTER_CATEGORY_CATALOG:
      return {
        ...state,
        ...filterCategoryCatalog(state, action.payload)
      };

    case TYPE.GET_PRODUCT_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        category: {
          category_id: action.category_id,
          list: action.data,
          filter: action.data,
          page: action.page,
          list_mobile: !action.page.previous
            ? action.data
            : convertListMobile(state.category.list_mobile, action.data)
        }
      };

    case TYPE.CLEAR_CATEGORY_CATALOG_MOBILE:
      return {
        ...state,
        category: { ...state.category, list_mobile: [] },
        forYou: { ...state.forYou, list_mobile: [] },
        looking: { ...state.looking, list_mobile: [] }
      };

    case TYPE.CLEAR_CATEGORY_CATALOG:
      return {
        ...state,
        category: {
          list: [],
          filter: [],
          page: {},
          list_mobile: [],
          category_id: ''
        }
      };

    case TYPE.ACTIVE_FILTER:
      return {
        ...state,
        openFilter: true
      };

    case TYPE.CLEAR_FILTER:
      return { ...state, openFilter: false };

    case TYPE.GET_LIST_CATALOG_AWS_PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.page,
          list: state.filter.filter((_, i) => {
            const start = (action.page - 1) * state.pagination.limit;
            const end = start + state.pagination.limit;
            return i >= start && i < end;
          })
        }
      };
    }

    case TYPE.GET_LIST_CATALOG_LOOKING_SUCCESS: {
      return {
        ...state,
        looking: {
          list: action.payload?.list,
          filter: action.payload?.list,
          page: action.payload,
          list_mobile: !action.payload?.previous
            ? action.payload?.list
            : convertListMobile(state.looking.list_mobile, action.payload?.list)
        }
      };
    }

    case TYPE.GET_LIST_FOR_YOU_SUCCESS: {
      return {
        ...state,
        forYou: {
          list: action.payload,
          filter: action.payload,
          page: action.page,
          list_mobile: !action.page.previous
            ? action.payload
            : convertListMobile(state.forYou.list_mobile, action.payload)
        }
      };
    }

    case TYPE.GET_LIST_FAVORITE_SUCCESS: {
      const isPrevious = action.payload?.previous;
      const listProduct = action.payload?.list || [];
      const currentList = state.favorite.list_mobile;
      return {
        ...state,
        favorite: {
          list: listProduct,
          filter: listProduct,
          page: { ...action.payload, list: listProduct.length },
          list_mobile: !isPrevious
            ? listProduct
            : convertListMobile(currentList, listProduct)
        }
      };
    }

    case TYPE.GET_DETAIL_CATALOG_AWS_SUCCESS: {
      const original = action.payload.variants.map(item => ({
        ...item,
        breadcrumb: action.payload.breadcrumb,
        variants: action.payload.variants,
        related_products: action.payload.related_products,
        favorite_products: action.payload.favorite_products
      }));
      return {
        ...state,
        detail: {
          ...state.detail,
          defaultDetail: action.payload,
          original,
          selected: original.find(
            item =>
              item.attributes.color === action.payload.attributes.color &&
              item.attributes.size === action.payload.attributes.size
          )
        }
      };
    }

    case TYPE.CLEAR_CATALOG_DETAIL: {
      return {
        ...state,
        detail: {
          original: [],
          list: [],
          selected: {},
          backup: null
        }
      };
    }

    case TYPE.CHANGE_VARIANT_PRODUCT: {
      const { size, color } = action;
      const itemIncludeSizeAndColor = state.detail.original.find(item => {
        return (
          get(item, 'attributes.color', '') === color &&
          get(item, 'attributes.size', '') === size
        );
      });
      const itemExcludeSizeAndColor = state.detail.original.find(item => {
        return get(item, 'attributes.color', '') === color;
      });
      const selected = itemIncludeSizeAndColor || itemExcludeSizeAndColor;

      return {
        ...state,
        detail: { ...state.detail, selected }
      };
    }

    case TYPE.GET_FIRST_PRODUCT:
      const product = state.detail.list.filter(
        item =>
          item.attributes.color === action.color &&
          item.attributes.size === action.sizeProduct
      );
      return {
        ...state,
        detail: {
          ...state.detail,
          selected: product
        }
      };

    case TYPE.CATALOG_SEARCHING:
      return {
        ...state,
        searching: action.payload
      };

    case TYPE.GET_LIST_FLASH_SALE_SUCCESS: {
      const listProduct = action.payload?.list || [];
      return {
        ...state,
        flashSale: {
          list: listProduct,
          filter: listProduct
        }
      };
    }

    case TYPE.GET_LIST_PRODUCT_DEAL_HOME_SUCCESS:
      return {
        ...state,
        productDealHome: action.payload
      };
    default:
      return state;
  }
};

export default catalog;
