import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ShoppingBagIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M24 0H0V24H24V0Z" fill="transparent" />
      <path d="M24 0H0V24H24V0Z" fill="transparent" />
      <path d="M3.21358 18.95L4.86579 9.45008C4.93584 9.04347 5.14747 8.67475 5.46323 8.40917C5.77898 8.14358 6.17851 7.99825 6.5911 7.9989H7.9988V7.00026C7.9988 4.79398 9.79376 2.99902 12 2.99902C14.2063 2.99902 16.0012 4.79398 16.0012 7.00026V7.9989H17.4089C17.8215 7.99824 18.221 8.14356 18.5368 8.40914C18.8525 8.67472 19.0642 9.04343 19.1342 9.45003L20.7863 18.95C20.8304 19.2016 20.8187 19.4598 20.7522 19.7064C20.6857 19.953 20.5659 20.182 20.4013 20.3773C20.2371 20.5729 20.032 20.7302 19.8004 20.838C19.5688 20.9458 19.3165 21.0015 19.061 21.0013H4.9389C4.68349 21.0016 4.43114 20.9458 4.1996 20.838C3.96806 20.7302 3.76297 20.573 3.59878 20.3773C3.43416 20.182 3.31435 19.953 3.2478 19.7064C3.18125 19.4598 3.16957 19.2016 3.21358 18.95ZM14.4988 7.00026C14.4988 5.62242 13.3778 4.50142 12 4.50142C10.6221 4.50142 9.5012 5.62242 9.5012 7.00026V7.9989H14.4988V7.00026ZM4.74853 19.4102C4.77637 19.4433 4.83809 19.4989 4.9389 19.4989H19.061C19.1619 19.4989 19.2236 19.4433 19.2515 19.4102C19.2793 19.3771 19.3234 19.3068 19.3062 19.2075L17.654 9.70746C17.644 9.6497 17.614 9.59732 17.5691 9.55959C17.5243 9.52186 17.4675 9.50121 17.4089 9.5013H16.0012V12.2503C16.0012 12.6652 15.6649 13.0015 15.25 13.0015C14.8351 13.0015 14.4988 12.6652 14.4988 12.2503V9.5013H9.5012V12.2503C9.5012 12.6652 9.16491 13.0015 8.75 13.0015C8.33509 13.0015 7.9988 12.6652 7.9988 12.2503V9.5013H6.5911C6.53249 9.5012 6.47573 9.52185 6.43088 9.55958C6.38602 9.59731 6.35596 9.64969 6.34601 9.70746L4.69381 19.2075C4.67653 19.3068 4.72069 19.3772 4.74853 19.4102Z" />
    </SvgIcon>
  );
}
