import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function DollarIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.25 6.125H17.06C16.53 5.295 15.83 4.605 15 4.095V0.875C15 0.465 14.66 0.125 14.25 0.125C12.4 0.125 10.82 1.405 10.37 3.135H8.43C5.23 3.135 2.58 5.465 2.09 8.525C1.76 8.175 1.5 7.625 1.5 7.125V5.875C1.5 5.465 1.16 5.125 0.75 5.125C0.34 5.125 0 5.465 0 5.875V7.125C0 8.375 0.84 9.805 2.02 10.235C2.17 12.035 3.07 13.675 4.5 14.765V17.375C4.5 17.785 4.84 18.125 5.25 18.125H8.25C8.66 18.125 9 17.785 9 17.375V16.125H11V17.375C11 17.785 11.34 18.125 11.75 18.125H14.75C15.16 18.125 15.5 17.785 15.5 17.375V14.825C16.58 14.025 17.36 12.915 17.77 11.625H19.25C19.66 11.625 20 11.285 20 10.875V6.875C20 6.465 19.66 6.125 19.25 6.125ZM18.5 10.125H17.19C16.84 10.125 16.53 10.375 16.46 10.715C16.18 12.005 15.43 13.095 14.34 13.805C14.13 13.945 14 14.185 14 14.435V16.625H12.5V15.375C12.5 14.965 12.16 14.625 11.75 14.625H8.25C7.84 14.625 7.5 14.965 7.5 15.375V16.625H6V14.375C6 14.125 5.87 13.885 5.66 13.745C4.31 12.855 3.5 11.335 3.5 9.695V9.565C3.5 6.845 5.71 4.635 8.43 4.635H11.01C11.4 4.635 11.73 4.335 11.76 3.935C11.83 2.895 12.55 2.045 13.5 1.745V4.535C13.5 4.815 13.66 5.075 13.91 5.205C14.78 5.655 15.5 6.355 15.97 7.235C16.1 7.475 16.35 7.625 16.63 7.625H18.5V10.125Z"
        fill="#121331"
      />
    </SvgIcon>
  );
}
