import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import { messageError } from '../../utils/alertMessage';
import { offLoading } from '../../utils/dispatch';
import TYPE from '../actions/TYPE';

function* getReferral() {
  try {
    const { data } = yield call(services.referral.getReferral);
    yield put({ type: TYPE.GET_REFERRAL_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_REFERRAL_FAILED });
  }
}

function* exchangePoint(action) {
  try {
    const { amount } = action;
    yield call(services.referral.exchangePoint, { amount });
  } catch (error) {
    yield put({ type: TYPE.EXCHANGE_POINT_FAILED });
    messageError('Đổi điểm thất bại! Vui lòng thử lại sau');
  } finally {
    yield offLoading();
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_REFERRAL, getReferral),
    takeLatest(TYPE.EXCHANGE_POINT, exchangePoint)
  ]);
}
