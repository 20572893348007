import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CarIconStep(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833984 2.16667C0.833984 1.54167 1.20898 0.5 2.50065 0.5H10.0007C11.2923 0.5 11.6673 1.54167 11.6673 2.16667V3H14.9681C15.8348 3 16.6419 3.45807 17.1003 4.19141L18.793 6.94206C19.043 7.34206 19.1673 7.79212 19.1673 8.25879V12.1667C19.1673 12.625 18.7923 13 18.334 13H17.4583C17.2541 14.41 16.0497 15.5 14.584 15.5C13.1182 15.5 11.9138 14.41 11.7096 13H7.45833C7.25413 14.41 6.04972 15.5 4.58398 15.5C3.11824 15.5 1.91383 14.41 1.70964 13H1.66732C1.20898 13 0.833984 12.625 0.833984 12.1667V2.16667ZM5.83398 12.5833C5.83398 11.8942 5.27315 11.3333 4.58398 11.3333C3.89482 11.3333 3.33398 11.8942 3.33398 12.5833C3.33398 13.2725 3.89482 13.8333 4.58398 13.8333C5.27315 13.8333 5.83398 13.2725 5.83398 12.5833ZM15.834 12.5833C15.834 11.8942 15.2732 11.3333 14.584 11.3333C13.8948 11.3333 13.334 11.8942 13.334 12.5833C13.334 13.2725 13.8948 13.8333 14.584 13.8333C15.2732 13.8333 15.834 13.2725 15.834 12.5833ZM14.5138 5.89406L15.7937 7.81291C15.9878 8.10386 16.3808 8.17201 16.6709 7.97853C16.968 7.78034 17.049 7.39601 16.8549 7.10507L15.5751 5.18621C15.381 4.89527 14.995 4.82239 14.6979 5.02059C14.4078 5.21407 14.3198 5.60311 14.5138 5.89406Z"
        fill="white"
      />
    </SvgIcon>
  );
}
