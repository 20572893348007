import { any, array, number } from 'prop-types';
import ResizeObserver from 'rc-resize-observer';
import React from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  WindowScroller
} from 'react-virtualized';
import 'react-virtualized/styles.css';

export default function AutoHeightList({ list, children, minHeight }) {
  const _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight
  });

  const _rowRenderer = ({ index, key, parent, style }) => {
    return (
      <CellMeasurer
        key={key}
        cache={_cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        {({ measure, registerChild }) => (
          <div key={key} style={style} ref={registerChild}>
            <ResizeObserver onResize={measure}>
              {children({ item: list[index] })}
            </ResizeObserver>
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <WindowScroller>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <List
              deferredMeasurementCache={_cache}
              height={height}
              isScrolling={isScrolling}
              onChildScroll={onChildScroll}
              scrollTop={scrollTop}
              overscanRowCount={10}
              autoHeight
              rowCount={list.length}
              rowHeight={_cache.rowHeight}
              rowRenderer={_rowRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
}

AutoHeightList.propTypes = {
  list: array.isRequired,
  children: any.isRequired,
  minHeight: number.isRequired
};
