import find from 'lodash/find';
import TYPE from '../actions/TYPE';

const initialState = {
  order: {},
  paymentTransaction: {},
  editQuotation: null
};

const saleOrderV2 = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.ORDER_BY_GIFTCARD_SUCCESS:
      return {
        ...state,
        order: action.payload
      };
    case TYPE.DELETE_QUOTATION_BY_DEAL_SUCCESS:
      return {
        ...state,
        order: action.payload
      };
    case TYPE.CONFIRM_SALE_ORDER_V2_SUCCESS:
      return {
        ...state,
        paymentTransaction: action.payload
      };
    case TYPE.GET_CURRENT_ORDER_DEAL_LIST_SUCCESS:
      return {
        ...state,
        order: action.payload
      };
    case TYPE.CLEAR_TRANSACTION:
      return {
        ...state,
        paymentTransaction: {}
      };
    case TYPE.EDIT_QUOTATION_GIFT_CARD:
      return {
        ...state,
        editQuotation: find(state.order.items, item => item._id === action.payload)
      };
    case TYPE.EDIT_QUOTATION_V2_SUCCESS:
      return {
        ...state,
        order: action.payload ? action.payload[0] : state.order,
        editQuotation: null
      };
    default:
      return state;
  }
};

export default saleOrderV2;
