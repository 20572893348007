import { call, all, takeLatest, put } from 'redux-saga/effects';
import get from 'lodash/get';
import services from '../../services';
import TYPE from '../actions/TYPE';
import { MessageContent } from '../../contents/Constants';

function* handleTrackingList(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'tracking' });
    const typeTracking = get(action, 'typeTracking', 'us');
    const { data } = yield call(services.tracking.list, typeTracking);
    yield put({ type: TYPE.TRACKING_LIST_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.TRACKING_LIST_FAILED, error });
  } finally {
    // yield offLoading();
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'tracking' });
  }
}

function* handleTrackingDetail(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'tracking' });
    const { trackingId, typeTracking } = action;
    const { data } = yield call(services.tracking.detail, trackingId, typeTracking);
    yield put({ type: TYPE.TRACKING_DETAIL_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.TRACKING_DETAIL_FAILED, error });
  } finally {
    // yield offLoading();
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'tracking' });
  }
}

function* notificationTracking(action) {
  try {
    const { saleOrderOdooId, trackingId } = action;
    yield put({
      type: TYPE.ALERT_MESSAGE_SUCCESS,
      message: MessageContent.notificationTracking
    });
    yield put({
      type: TYPE.NOTIFICATION_TRACKING_SUCCESS,
      saleOrderOdooId,
      trackingId
    });
  } catch (err) {
    yield put({
      type: TYPE.NOTIFICATION_TRACKING_FAILED
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.TRACKING_LIST, handleTrackingList),
    takeLatest(TYPE.TRACKING_DETAIL, handleTrackingDetail),
    takeLatest(TYPE.NOTIFICATION_TRACKING, notificationTracking)
  ]);
}
