import { any, string } from 'prop-types';
import React from 'react';
import Tag from './Tag';

export default function TagSalePrice({ off, suffix }) {
  if (off <= 0) return null;

  return (
    <Tag
      content={`Giảm ${Math.round(off)}${suffix}`}
      style={{ color: '#FFFFFF', backgroundColor: '#F51D2C' }}
    />
  );
}

TagSalePrice.propTypes = {
  off: any,
  suffix: string
};

TagSalePrice.defaultProps = {
  off: 0,
  suffix: '%'
};
