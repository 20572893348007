import React, { useEffect, useState } from 'react';
import { Popover } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { any, bool, func, number } from 'prop-types';
import useActiveMode from '../../../hooks/2.2.0/useActiveMode';
import { ACCESS_KEY } from '../../../contents/Constants';

const StyledIconButton = withStyles(theme => ({
  root: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: '#252629',
    padding: theme.spacing(1),
    backgroundColor: '#F5F5F7',
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      '& svg': { fontSize: 16 }
    }
  },
  colorPrimary: {
    color: theme.palette.primary.main
  }
}))(props => <IconButton {...props} />);

const StyledBadge = withStyles(theme => ({
  badge: {
    fontSize: 10,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      fontSize: 8,
      minWidth: theme.spacing(1.75),
      height: theme.spacing(1.75)
    }
  }
}))(props => <Badge color="error" {...props} overlap="circular" />);

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      '&:hover #hover': {
        visibility: 'visible',
        opacity: 1
      }
    }
  },

  popover: {
    '& > dropdown': {},
    '& .MuiPopover-paper': {
      borderRadius: 16
    }
  },
  dropdown: {
    position: 'absolute',
    left: '0%',
    transform: 'translateX(calc(-100% + 75px))',
    zIndex: 1
  },
  hover: {
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    zIndex: 100,
    left: '50%',
    transition: 'opacity 0.5s',
    transform: 'translateX(-50%)',
    '&::before': {
      content: `''`,
      clipPath: 'polygon(50% 20%, 15% 100%, 85% 100%)',
      backgroundColor: '#FFFFFF',
      display: 'block',
      width: 22,
      height: 8,
      top: 16,
      left: '50%',
      position: 'absolute',
      transform: 'translateX(-50%)'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  paper: {
    borderRadius: 16,
    // marginTop: theme.spacing(3),
    boxShadow: '0px 24px 56px -16px rgba(134, 135, 143, 0.3)'
  }
}));

export default function PopupButton({
  active,
  icon,
  button,
  badge,
  onRedirect,
  children,
  className,
  popover,
  ...props
}) {
  const classes = useStyles();
  const { IconComponent, onActiveMode, onInactiveMode } = useActiveMode({
    icon,
    ...props
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isAuth = localStorage.getItem(ACCESS_KEY);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handlePopoverClose();
  }, [isAuth]);

  const body = (
    <Paper elevation={0} className={classes.paper}>
      {children}
    </Paper>
  );

  return (
    <>
      <div className={clsx(classes.root, className)}>
        {icon && (
          <StyledBadge badgeContent={badge}>
            <StyledIconButton
              onMouseEnter={onActiveMode}
              onMouseLeave={onInactiveMode}
              onClick={onRedirect}
              color={active ? 'primary' : 'default'}
            >
              <IconComponent />
            </StyledIconButton>
          </StyledBadge>
        )}

        {button && (
          <Button onClick={handlePopoverOpen} fullWidth>
            {button}
          </Button>
        )}

        {children && !popover && (
          <div id="hover" className={classes.hover}>
            <div className={classes.dropdown}>{body}</div>
          </div>
        )}
      </div>
      {children && popover && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{ paper: { borderRadius: 16 } }}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          {body}
        </Popover>
      )}
    </>
  );
}

PopupButton.propTypes = {
  icon: any,
  button: any,
  children: any,
  badge: number,
  active: bool,
  onRedirect: func,
  className: any,
  popover: bool
};

PopupButton.defaultProps = {
  icon: null,
  button: null,
  onRedirect: () => {},
  badge: 0,
  active: false,
  children: null,
  className: null,
  popover: false
};
