import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getListWebsiteDisableUS() {
  try {
    const { data } = yield call(services.website.getListWebsiteDisableUS);
    yield put({
      type: TYPE.GET_LIST_WEBSITE_DISABLE_US_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_WEBSITE_DISABLE_US_FAILED, msg: error });
  } finally {
    // yield offLoading();
  }
}

function* getListWebsiteCrawler() {
  try {
    const { data } = yield call(services.website.getListWebsiteCrawler);
    yield put({ type: TYPE.GET_LIST_WEBSITE_CRAWLER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_WEBSITE_CRAWLER_FAILED, msg: error });
  } finally {
    // yield offLoading();
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_WEBSITE_DISABLE_US, getListWebsiteDisableUS),
    takeLatest(TYPE.GET_LIST_WEBSITE_CRAWLER, getListWebsiteCrawler)
  ]);
}
