import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function OrderIconContained(props) {
  return (
    <SvgIcon
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 12V16C8 17.1 7.1 18 6 18H2C0.9 18 0 17.1 0 16V12C0 10.9 0.9 10 2 10H6C7.1 10 8 10.9 8 12Z"
        fill="#1D50FF"
      />
      <path
        d="M8 2V6C8 7.1 7.1 8 6 8H2C0.9 8 0 7.1 0 6V2C0 0.9 0.9 0 2 0H6C7.1 0 8 0.9 8 2Z"
        fill="#1D50FF"
      />
      <path
        d="M18 2V6C18 7.1 17.1 8 16 8H12C10.9 8 10 7.1 10 6V2C10 0.9 10.9 0 12 0H16C17.1 0 18 0.9 18 2Z"
        fill="#1D50FF"
      />
      <path
        d="M18 14C18 14.55 17.55 15 17 15H15V17C15 17.55 14.55 18 14 18C13.45 18 13 17.55 13 17V15H11C10.45 15 10 14.55 10 14C10 13.45 10.45 13 11 13H13V11C13 10.45 13.45 10 14 10C14.55 10 15 10.45 15 11V13H17C17.55 13 18 13.45 18 14Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
