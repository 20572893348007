import get from 'lodash/get';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { Path } from '../../contents/Constants';

export default function useRedirectTransaction(children) {
  const { pathname } = useLocation();
  const checkPath = pathname.includes(Path.SHOPPING_ORDER);
  const isTransaction = useSelector(state =>
    get(state, 'common.isTransaction', false)
  );

  return !isTransaction && checkPath ? (
    <Redirect to={Path.SHOPPING_CART} />
  ) : (
    children
  );
}
