import { delay } from 'redux-saga/effects';
import { getStore } from '../App';
import actions from '../redux/actions';

const dispatch = (action, isLoading = false) => {
  if (isLoading) {
    getStore().dispatch(actions.enableLoading());
  }
  getStore().dispatch(action);
};

export function* offLoading() {
  yield getStore().dispatch(actions.disableLoading());
}

export function* disableLoading() {
  yield delay(500);
  yield getStore().dispatch(actions.disableLoading());
}

export default dispatch;
