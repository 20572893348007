import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';

const useStyles = makeStyles({
  radius8: {
    borderRadius: 8
  },
  radius4: {
    borderRadius: 4
  }
});
export default function SkeletonProductInfoRps() {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Skeleton
        variant="rect"
        width={74}
        height={74}
        className={classes.radius8}
        style={{
          // minWidth: 104,
          marginRight: 24
        }}
      />

      <Grid
        container
        direction="column"
        justifyContent="center"
        style={{ width: 'auto' }}
      >
        <Skeleton
          variant="rect"
          width={160}
          height={20}
          className={classes.radius4}
          style={{ marginBottom: 16 }}
        />

        <Skeleton
          variant="rect"
          width={80}
          height={12}
          className={classes.radius4}
          style={{ marginBottom: 4 }}
        />
        <Skeleton
          variant="rect"
          width={104}
          height={12}
          className={classes.radius4}
        />
      </Grid>
    </Box>
  );
}
