import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import moment from 'moment';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import { messageError } from '../../utils/alertMessage';
import TYPE from '../actions/TYPE';

function* handleSearchDeposit(action) {
  const { type, startDate, endDate } = action.params;
  const list = yield select(state => get(state, 'deposit.originalList', []));

  const newList = list
    .filter(item => {
      if (type === 'all') return true;
      return (item.type || '').includes(type);
    })
    // .filter(item => {
    //   const content = nonAccentVietnamese(item.content.toLowerCase());
    //   const searchStr = nonAccentVietnamese(search.toLowerCase());
    //   const memo = nonAccentVietnamese(item?.memo || '');

    //   const includeContent = content.includes(searchStr);
    //   const includeMemo = memo.includes(searchStr);
    //   const includeDetails = item.details.some(x => {
    //     const detailContent = nonAccentVietnamese(x.content.toLowerCase());
    //     return detailContent.includes(searchStr);
    //   });

    //   return includeContent || includeMemo || includeDetails;
    // })
    .filter(item => {
      const date = moment(item.date);
      const start = moment(startDate, 'DD/MM/yyyy');
      const end = moment(endDate, 'DD/MM/yyyy');

      if (!isEmpty(startDate) && isEmpty(endDate)) {
        return date.isSameOrAfter(start);
      }

      if (isEmpty(startDate) && !isEmpty(endDate)) {
        return date.isSameOrBefore(end);
      }

      if (!isEmpty(startDate) && !isEmpty(endDate)) {
        return date.isBetween(start, end, undefined, '[]');
      }

      return true;
    });

  yield put({
    type: TYPE.SEARCH_DEPOSIT_SUCCESS,
    list: newList,
    filter: action.params,
    pagination: { page: 1, total: size(newList) }
  });
}

function* getListHistoryMoneyRequest(action) {
  try {
    const { data } = yield call(services.deposit.getListMoneyRequest, {
      ...action.payload
    });

    yield put({
      type: TYPE.GET_LIST_HISTORY_MONEY_REQUEST_SUCCESS,
      history: data?.results
    });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_HISTORY_MONEY_REQUEST_FAILED, error });
  }
}

function* sendMoneyTranferRequest(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'sendMoneyTranferRequest'
    });

    const { data } = yield call(
      services.deposit.sendMoneyTranferRequest,
      action.payload
    );

    yield put({
      type: TYPE.SEND_MONEY_TRANFER_REQUEST_SUCCESS,
      payload: action.payload,
      result: data
    });
  } catch (error) {
    yield put({ type: TYPE.SEND_MONEY_TRANFER_REQUEST_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'sendMoneyTranferRequest'
    });
    yield put({
      type: TYPE.RESET_LOADING,
      target: 'sendMoneyTranferRequest'
    });
  }
}

function* sendMoneyWithdrawRequest(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'sendMoneyWithdrawRequest'
    });

    const { success, message, data } = yield call(
      services.deposit.sendMoneyWithdrawRequest,
      action.payload
    );

    if (success === false) throw new Error(message);
    yield put({
      type: TYPE.SEND_MONEY_WITHDRAW_REQUEST_SUCCESS,
      payload: action.payload,
      result: data
    });
  } catch (error) {
    yield put({
      type: TYPE.SEND_MONEY_WITHDRAW_REQUEST_FAILED,
      target: 'sendMoneyWithdrawRequest'
    });
    messageError('Tài khoản đã được sử dụng, vui lòng chọn tài khoản khác');
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'sendMoneyWithdrawRequest'
    });
    yield put({
      type: TYPE.RESET_LOADING,
      target: 'sendMoneyWithdrawRequest'
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.SEARCH_DEPOSIT, handleSearchDeposit),
    takeLatest(TYPE.GET_LIST_HISTORY_MONEY_REQUEST, getListHistoryMoneyRequest),
    takeLatest(TYPE.SEND_MONEY_TRANFER_REQUEST, sendMoneyTranferRequest),
    takeLatest(TYPE.SEND_MONEY_WITHDRAW_REQUEST, sendMoneyWithdrawRequest)
  ]);
}
