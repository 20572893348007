import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

export default function AmericaIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29341_368990)">
        <path d="M22.999 5H0.999023V18.5H22.999V5Z" fill="#ECEFF1" />
        <path d="M23 5H1V6.5H23V5Z" fill="#F44336" />
        <path d="M23 8H1V9.5H23V8Z" fill="#F44336" />
        <path d="M23 11H1V12.5H23V11Z" fill="#F44336" />
        <path d="M23 14H1V15.5H23V14Z" fill="#F44336" />
        <path d="M23 17H1V18.5H23V17Z" fill="#F44336" />
        <path d="M11.5 5H1V12.5H11.5V5Z" fill="#3F51B5" />
        <path
          d="M2.125 6L2.3565 6.494L2.875 6.573L2.5 6.958L2.589 7.5L2.125 7.244L1.661 7.5L1.75 6.958L1.375 6.573L1.8935 6.494L2.125 6Z"
          fill="white"
        />
        <path
          d="M4.125 6L4.3565 6.494L4.875 6.573L4.5 6.958L4.589 7.5L4.125 7.244L3.661 7.5L3.75 6.958L3.375 6.573L3.8935 6.494L4.125 6Z"
          fill="white"
        />
        <path
          d="M6.125 6L6.3565 6.494L6.875 6.573L6.5 6.958L6.589 7.5L6.125 7.244L5.661 7.5L5.75 6.958L5.375 6.573L5.8935 6.494L6.125 6Z"
          fill="white"
        />
        <path
          d="M8.125 6L8.3565 6.494L8.875 6.573L8.5 6.958L8.589 7.5L8.125 7.244L7.661 7.5L7.75 6.958L7.375 6.573L7.8935 6.494L8.125 6Z"
          fill="white"
        />
        <path
          d="M10 6L10.2315 6.494L10.75 6.573L10.375 6.958L10.464 7.5L10 7.244L9.536 7.5L9.625 6.958L9.25 6.573L9.7685 6.494L10 6Z"
          fill="white"
        />
        <path
          d="M2.125 10L2.3565 10.494L2.875 10.573L2.5 10.958L2.589 11.5L2.125 11.244L1.661 11.5L1.75 10.958L1.375 10.573L1.8935 10.494L2.125 10Z"
          fill="white"
        />
        <path
          d="M4.125 10L4.3565 10.494L4.875 10.573L4.5 10.958L4.589 11.5L4.125 11.244L3.661 11.5L3.75 10.958L3.375 10.573L3.8935 10.494L4.125 10Z"
          fill="white"
        />
        <path
          d="M6.125 10L6.3565 10.494L6.875 10.573L6.5 10.958L6.589 11.5L6.125 11.244L5.661 11.5L5.75 10.958L5.375 10.573L5.8935 10.494L6.125 10Z"
          fill="white"
        />
        <path
          d="M8.125 10L8.3565 10.494L8.875 10.573L8.5 10.958L8.589 11.5L8.125 11.244L7.661 11.5L7.75 10.958L7.375 10.573L7.8935 10.494L8.125 10Z"
          fill="white"
        />
        <path
          d="M10 10L10.2315 10.494L10.75 10.573L10.375 10.958L10.464 11.5L10 11.244L9.536 11.5L9.625 10.958L9.25 10.573L9.7685 10.494L10 10Z"
          fill="white"
        />
        <path
          d="M2.625 8L2.8565 8.494L3.375 8.573L3 8.958L3.089 9.5L2.625 9.244L2.161 9.5L2.25 8.958L1.875 8.573L2.3935 8.494L2.625 8Z"
          fill="white"
        />
        <path
          d="M4.625 8L4.8565 8.494L5.375 8.573L5 8.958L5.089 9.5L4.625 9.244L4.161 9.5L4.25 8.958L3.875 8.573L4.3935 8.494L4.625 8Z"
          fill="white"
        />
        <path
          d="M6.625 8L6.8565 8.494L7.375 8.573L7 8.958L7.089 9.5L6.625 9.244L6.161 9.5L6.25 8.958L5.875 8.573L6.3935 8.494L6.625 8Z"
          fill="white"
        />
        <path
          d="M8.625 8L8.8565 8.494L9.375 8.573L9 8.958L9.089 9.5L8.625 9.244L8.161 9.5L8.25 8.958L7.875 8.573L8.3935 8.494L8.625 8Z"
          fill="white"
        />
        <path
          d="M10.5 8L10.7315 8.494L11.25 8.573L10.875 8.958L10.964 9.5L10.5 9.244L10.036 9.5L10.125 8.958L9.75 8.573L10.2685 8.494L10.5 8Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_29341_368990">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
