import size from 'lodash/size';
import get from 'lodash/get';
import find from 'lodash/find';

import {
  searchListSaleOrder,
  filterOrderManagement,
  isCouponMeetTheCondition,
  pushQuotationToStorage,
  choosePromotion,
  simpleSortByCreatedAt,
  getGroupQuotation
  // updateGroupQuotationList
} from '../../utils/helpers';
import TYPE from '../actions/TYPE';
import { CRAWL_DATA_VALUES, ORDER_TRACKING_STATE } from '../../contents/Constants';

const initialState = {
  listQuotationImport: [],
  listIdQuotationImport: [],
  listCartPromotion: [],
  draft: null,
  cartSaleOrder: [],
  listSaleOrder: {
    list: [],
    count: 0
  },
  quotationFromHome: null,
  isAddCartSuccess: false,
  filterListSaleOrder: [],
  notification: {
    saleOrderId: '',
    saleOrderOdooId: '',
    isLoading: false
  },
  currentSaleOrder: {},
  editQuotation: null,
  cartOrderChecked: [],
  editAddressID: '',
  isNewUserCreateAddress: false,
  scraperSaleOrder: null,
  crawledData: CRAWL_DATA_VALUES,
  shippingCategories: [],
  loadLine: null,
  listingPromotionOriginal: [],
  listingPromotion: [],
  listingChoosePromotion: [],
  pushAnonymous: true,
  quotationList: [],
  quotationCrawler: [],
  orders: {
    [ORDER_TRACKING_STATE.DRAFT.value]: { list: [], filter: [] },
    [ORDER_TRACKING_STATE.CONFIRMED.value]: { list: [], filter: [] },
    [ORDER_TRACKING_STATE.PURCHASING.value]: { list: [], filter: [] },
    [ORDER_TRACKING_STATE.TRACKING.value]: { list: [], filter: [] },
    [ORDER_TRACKING_STATE.DELIVERY.value]: { list: [], filter: [] },
    [ORDER_TRACKING_STATE.DONE.value]: { list: [], filter: [] },
    [ORDER_TRACKING_STATE.CANCEL.value]: { list: [], filter: [] }
  },
  orderDetail: {},
  groupQuotation: [],
  orderTracking: {},
  trackingJourney: [],
  paymentOption: 0,
  isReviewPayment: false,
  foundPromotion: 'no-set',
  saleOrderConfirmed: {},
  saleOrderConfirmedPrice: 0
};

const saleOrder = (state = initialState, action = {}) => {
  const currentChoosePromotion = state.listingChoosePromotion;
  const currentPromotion = state.listingPromotion;
  const currentDraft = state.draft;

  switch (action.type) {
    case TYPE.GET_PROMOTION_SUCCESS: {
      return {
        ...state,
        listCartPromotion: action.data
      };
    }

    case TYPE.PUSH_TO_QUOTATION_LIST:
      return {
        ...state,
        quotationCrawler: [],
        quotationList: [...action.payload, ...state.quotationList]
      };

    case TYPE.RESET_QUOTATION_LIST:
      return {
        ...state,
        quotationCrawler: [],
        quotationList: []
      };

    case TYPE.REPLACE_QUOTATION_LIST:
      return {
        ...state,
        quotationCrawler: [],
        quotationList: action.payload
      };

    case TYPE.UPDATE_QUOTATION_LIST:
      return {
        ...state,
        quotationCrawler: [],
        quotationList: action.payload.reduce(
          (acc, cur) => {
            const itemIndex = acc.findIndex(item => item.id === cur.id);
            if (itemIndex < 0) {
              acc.push(cur);
            } else {
              acc[itemIndex] = cur;
            }
            return acc;
          },
          [...state.quotationList]
        )
      };

    case TYPE.UPDATE_WAITING_SALE_ORDER_SUCCESS: {
      const updateCallback = item => {
        if (item.id !== action.payload.id) return item;
        return { ...item, status: 'customer_confirmed' };
      };
      const listDraft = state.orders[ORDER_TRACKING_STATE.DRAFT.value];
      return {
        ...state,
        orders: {
          ...state.orders,
          [ORDER_TRACKING_STATE.DRAFT.value]: {
            list: listDraft.list.map(updateCallback),
            filter: listDraft.filter.map(updateCallback)
          }
        },
        orderDetail: { ...state.orderDetail, status: 'customer_confirmed' }
      };
    }

    case TYPE.LOGOUT_SUCCESS:
      return initialState;

    case TYPE.GET_TRACKING_JOURNEY_SUCCESS: {
      return {
        ...state,
        trackingJourney: action?.data || []
      };
    }

    case TYPE.FILTER_ORDER_MANAGEMENT: {
      return {
        ...state,
        orders: filterOrderManagement(state.orders, action.filter)
      };
    }

    case TYPE.GET_DETAIL_TRACKING_SUCCESS: {
      return {
        ...state,
        orderTracking: action.data
      };
    }

    case TYPE.SAVE_QUOTATION_FROM_HOME: {
      return {
        ...state,
        quotationFromHome: action.payload
      };
    }

    case TYPE.CLEAR_QUOTATION_FROM_HOME: {
      return {
        ...state,
        quotationFromHome: null
      };
    }

    case TYPE.GET_LIST_ORDER_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        orders: {
          ...state.orders,
          [action.status]: {
            list: action.data,
            filter: action.data
          }
        }
      };
    }

    case TYPE.CRAWL_DATA_SUCCESS:
    case TYPE.CRAWL_DATA_FAILED: {
      return {
        ...state,
        crawledData: action.data
      };
    }

    case TYPE.POST_SCRAPER_SUCCESS:
      return {
        ...state,
        scraperSaleOrder: action.data
      };

    case TYPE.POST_SCRAPER_COMPLETED:
      return {
        ...state,
        scraperSaleOrder: null
      };

    case TYPE.GET_SHIPPING_CATEGORIES_SUCCESS:
      return {
        ...state,
        shippingCategories: action.data
      };

    case TYPE.REORDER_SALE_ORDER_SUCCESS:
      return {
        ...state,
        draft: action.data
      };

    case TYPE.UPDATE_QUOTATION_SUCCESS:
      return {
        ...state,
        draft: action.data
      };

    case TYPE.EDIT_QUOTATION:
      return {
        ...state,
        editQuotation: action.data
      };
    case TYPE.UPDATE_SALE_ORDER_SUCCESS:
      return {
        ...state,
        draft: action.data,
        listQuotationImport: []
      };

    case TYPE.DELETE_QUOTATION:
      return {
        ...state,
        loadLine: action?.id
      };

    case TYPE.CREATE_SALE_ORDER_SUCCESS:
      return {
        ...state,
        draft: { ...state.draft, ...action.data },
        website: action.data.website,
        loadLine: null
      };

    case TYPE.PUT_TO_LOGGED_CART_SUCCESS:
    case TYPE.GET_SHOPPING_CART_ANONYMOUS_SUCCESS:
    case TYPE.GET_CURRENT_SALE_ORDER_SUCCESS: {
      const listSorted = simpleSortByCreatedAt(action.data.quotation_list);
      return {
        ...state,
        draft: {
          ...state.draft,
          ...action.data,
          quotation_list: listSorted
        },
        groupQuotation: getGroupQuotation(listSorted),
        loadLine: null
      };
    }

    case TYPE.GET_LIST_SALE_ORDER_SUCCESS:
      return {
        ...state,
        listSaleOrder: {
          ...state.listSaleOrder,
          list: get(action, 'data', []),
          count: get(action, 'count', 0)
        },
        filterListSaleOrder: get(action, 'data', [])
      };

    case TYPE.CANCEL_SALE_ORDER_SUCCESS: {
      const newList = state.orders[ORDER_TRACKING_STATE.DRAFT.value].list.filter(
        item => item.id !== action.id
      );
      const newFilter = state.orders[ORDER_TRACKING_STATE.DRAFT.value].filter.filter(
        item => item.id !== action.id
      );
      const cancelSO = {
        ...state.orders[ORDER_TRACKING_STATE.DRAFT.value].list.find(
          item => item.id === action.id
        ),
        status: 'customer_rejected'
      };
      return {
        ...state,
        currentSaleOrder: action.data,
        orders: {
          ...state.orders,
          [ORDER_TRACKING_STATE.DRAFT.value]: {
            list: newList,
            filter: newFilter
          },
          [ORDER_TRACKING_STATE.CANCEL.value]: {
            list: [
              cancelSO,
              ...state.orders[ORDER_TRACKING_STATE.CANCEL.value].list
            ],
            filter: [
              cancelSO,
              ...state.orders[ORDER_TRACKING_STATE.CANCEL.value].filter
            ]
          }
        }
      };
    }

    case TYPE.GET_SALE_ORDER_SUCCESS:
      return {
        ...state,
        currentSaleOrder: action.data
      };

    case TYPE.NOTIFICATION_SALE_ORDER_SUCCESS:
      return {
        ...state,
        notification: {
          saleOrderId: action.saleOrderId,
          saleOrderOdooId: action.saleOrderOdooId,
          isLoading: !state.notification.isLoading
        }
      };
    case TYPE.SEARCH_LIST_SALE_ORDER:
      const filterList = get(state, 'filterListSaleOrder', []);
      const params = get(action, 'params', {});
      const result = searchListSaleOrder(params, filterList);
      return {
        ...state,
        listSaleOrder: {
          ...state.listSaleOrder,
          list: result,
          count: size(result)
        }
      };

    case TYPE.IMPORT_QUOTATION_FILE_SUCCESS:
    case TYPE.UPLOAD_FILE_QUOTATION_SUCCESS:
      return {
        ...state,
        listQuotationImport: action.data
      };

    case TYPE.CLEAR_QUOTATION_FILE:
      return {
        ...state,
        listQuotationImport: []
      };

    case TYPE.CHECK_SALE_ORDER_DRAFT: {
      return {
        ...state,
        cartOrderChecked: action.data
      };
    }
    // improvement
    case TYPE.CONFIRM_SHOPPING_CART_SUCCESS:
      return {
        ...state,
        draft: action.data
      };
    case TYPE.GET_SELECTED_SALE_ORDER_SUCCESS:
      return {
        ...state,
        draft: action.data
      };

    case TYPE.EDIT_SALE_ORDER_IMPROVEMENT:
    case TYPE.EDIT_SALE_ORDER_ANONYMOUS:
      return {
        ...state,
        loadLine: action?.id
      };

    case TYPE.ADD_SHOPPING_CART_SUCCESS:
      return {
        ...state,
        draft: action.data,
        // groupQuotation: updateGroupQuotationList(
        //   state.groupQuotation,
        //   getGroupQuotation(action.data.quotation_list)
        // ),
        scraperSaleOrder: null,
        isAddCartSuccess: true,
        listQuotationImport: []
      };

    case TYPE.ADD_SHOPPING_CART_FAILED:
    case TYPE.ADD_SHOPPING_CART_COMPLETE:
      return {
        ...state,
        isAddCartSuccess: false
      };
    case TYPE.ADD_SHOPPING_CART_ANONYMOUS_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          quotation_list: action.data
        },
        // groupQuotation: updateGroupQuotationList(
        //   state.groupQuotation,
        //   getGroupQuotation(action.data)
        // ),
        isAddCartSuccess: true
      };
    case TYPE.ADD_SHOPPING_CART_ANONYMOUS_COMPLETE:
      return {
        ...state,
        isAddCartSuccess: false,
        pushAnonymous: !state.pushAnonymous,
        listQuotationImport: []
      };

    case TYPE.CREATE_NEW_PROMOTION_SUCCESS: {
      const listSorted = simpleSortByCreatedAt(action.data.cart_items);
      const promotionId = action.data.store_promotion._id;
      return {
        ...state,
        draft: {
          ...state.draft,
          quotation_list: listSorted
        },
        groupQuotation: getGroupQuotation(listSorted).map(item => {
          const isExist = item.list.some(
            i => i?.store_promotion?._id === promotionId
          );
          if (!isExist) return item;

          const newList = [
            ...item.list.filter(i => i?.store_promotion?._id === promotionId),
            ...item.list.filter(i => i?.store_promotion?._id !== promotionId)
          ];
          return { ...item, list: newList };
        }),
        loadLine: null
      };
    }

    case TYPE.DELETE_PROMOTION_SUCCESS:
    case TYPE.DELETE_QUOTATION_SUCCESS:
    case TYPE.DELETE_QUOTATION_ANONYMOUS_SUCCESS:
    case TYPE.EDIT_SALE_ORDER_IMPROVEMENT_SUCCESS:
    case TYPE.EDIT_SALE_ORDER_ANONYMOUS_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          quotation_list: state.draft.quotation_list.reduce((acc, cur) => {
            const currentItem = action.data.find(item => item._id === cur._id);
            if (currentItem) return [...acc, currentItem];
            return acc;
          }, [])
        },
        groupQuotation: state.groupQuotation.reduce((acc, cur) => {
          const currentItemList = cur.list.reduce((_acc, _cur) => {
            const _currentItem = action.data.find(item => item._id === _cur._id);
            if (_currentItem) return [..._acc, _currentItem];
            return _acc;
          }, []);
          if (currentItemList.length === 0) return acc;
          const currentItem = { ...cur, list: currentItemList };
          return [...acc, currentItem];
        }, []),
        loadLine: null
      };

    case TYPE.EDIT_SALE_ORDER_IMPROVEMENT_FAILED:
    case TYPE.EDIT_SALE_ORDER_ANONYMOUS_FAILED:
      return {
        ...state,
        loadLine: null
      };

    case TYPE.EDIT_QUOTATION_IMPROVEMENT:
      return {
        ...state,
        editQuotation: {
          isEdit: find(state.draft.quotation_list, item => item._id === action.id),
          isGift: action.isGift
        }
      };
    case TYPE.SET_ADDRESS_TO_EDIT:
      return {
        ...state,
        editAddressID: action.id
      };
    case TYPE.NEW_USER_CREATE_ADDRESS_SUCCESS:
      return {
        ...state,
        isNewUserCreateAddress: true
      };
    case TYPE.UN_CHECK_IS_USER_CREATE_NEW_ADDRESS:
      return {
        ...state,
        isNewUserCreateAddress: false
      };
    case TYPE.SET_LIST_QUOTATION_IMPORT:
      return {
        ...state,
        listIdQuotationImport: [...state.listIdQuotationImport, action.id]
      };

    case TYPE.TOGGLE_CAMPAIGN_PROMOTION:
      return {
        ...state,
        listingPromotion: get(action, 'data', [])
      };

    case TYPE.GET_LIST_PROMOTION_SUCCESS:
      const list = get(action, 'data', []);
      return {
        ...state,
        listingPromotionOriginal: list,
        listingPromotion: list
      };

    case TYPE.CHOOSE_PROMOTION: {
      const { listingChoosePromotion } = choosePromotion(
        state.listingChoosePromotion,
        action
      );
      return {
        ...state,
        listingChoosePromotion
      };
    }

    case TYPE.CONFIRM_SALE_ORDER_SUCCESS:
      return {
        ...state,
        saleOrderConfirmed: action.data,
        listingPromotion: [],
        listingChoosePromotion: [],
        isReviewPayment: true,
        saleOrderConfirmedPrice: action.amount
      };

    case TYPE.CLEAR_REVIEW_PAYMENT_PAGE:
      return {
        ...state,
        isReviewPayment: false
      };

    case TYPE.INPUT_PROMOTION_SUCCESS:
      let isCouponExist = false;
      let newListingChoosePromotion = currentChoosePromotion;

      if (currentPromotion.length > 0) {
        const duplicate = currentPromotion.filter(
          item => item.code === action.data[0]?.code
        );
        if (duplicate.length > 0) {
          isCouponExist = true;
        }
      }

      if (currentChoosePromotion.length > 0) {
        const duplicate = currentChoosePromotion.filter(
          item => item?.code === action.data[0].code
        );
        if (
          duplicate.length === 0 &&
          action.data[0]?.type === 'multi' &&
          isCouponMeetTheCondition(action.data[0]?.group, currentChoosePromotion)
        ) {
          newListingChoosePromotion = currentChoosePromotion.concat([
            { ...action.data[0], name: action.data[0]?.program_name }
          ]);
        }
      } else {
        newListingChoosePromotion = currentChoosePromotion.concat([
          { ...action.data[0], name: action.data[0]?.program_name }
        ]);
      }
      const newListingPromotion = isCouponExist
        ? currentPromotion
        : currentPromotion.concat([action.data[0]]);

      return {
        ...state,
        listingPromotion: newListingPromotion,
        listingChoosePromotion: newListingChoosePromotion
      };

    case TYPE.EDIT_SELECTED_DELIVERY_ADDRESS:
    case TYPE.CREATE_DELIVERY_ADDRESS_SUCCESS:
    case TYPE.CREATE_NEW_ADDRESS_IMPROVEMENT_SUCCESS:
    case TYPE.PUT_SELECT_ADDRESS_SUCCESS: {
      const newSO = get(action, 'data.saleOrder', {});
      return {
        ...state,
        draft: newSO ? { ...state.draft, ...newSO } : currentDraft
      };
    }
    case TYPE.RESET_DELIVERY_ADDRESS_SUCCESS: {
      const newSO = get(action, 'data.saleOrder', {});
      return {
        ...state,
        draft: newSO
          ? {
              ...state.draft,
              ...newSO,
              quotation_list: state.draft.quotation_list.map(item => {
                const quotation = newSO.quotation_list.find(i => item._id === i._id);
                return quotation;
              })
            }
          : currentDraft
      };
    }

    case TYPE.SOCKET_UPDATE_SHOPPING_CART_ANONYMOUS:
      pushQuotationToStorage(action.payload);
      return {
        ...state,
        pushAnonymous: !state.pushAnonymous
      };

    case TYPE.GET_DETAIL_ORDER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        orderDetail: action.data
      };
    case TYPE.CLEAR_DETAIL_ORDER_MANAGEMENT:
      return {
        ...state,
        orderDetail: {}
      };
    case TYPE.CHANGE_PAYMENT_OPTION:
      return {
        ...state,
        paymentOption: action.payload
      };
    case TYPE.RESET_PAYMENT_OPTION:
      return {
        ...state,
        paymentOption: initialState.paymentOption
      };
    case TYPE.INPUT_PROMOTION_FAILED:
      return {
        ...state,
        foundPromotion: action.payload.foundPromotion
      };
    case TYPE.CLEAR_FIND_PROMOTION:
      return {
        ...state,
        foundPromotion: 'no-set'
      };
    default:
      return state;
  }
};

export default saleOrder;
