import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function WarehouseIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#1D50FF" />
      <g clipPath="url(#clip0_12588_121839)">
        <path
          d="M6.83333 10.1641C6.37333 10.1641 6 10.5374 6 10.9974C6 11.4574 6.37333 11.8307 6.83333 11.8307H11C11.46 11.8307 11.8333 12.2041 11.8333 12.6641C11.8333 13.1241 11.46 13.4974 11 13.4974H7.66667C7.20667 13.4974 6.83333 13.8707 6.83333 14.3307C6.83333 14.7907 7.20667 15.1641 7.66667 15.1641H9.33333C9.79333 15.1641 10.1667 15.5374 10.1667 15.9974C10.1667 16.4574 9.79333 16.8307 9.33333 16.8307H7.66667C7.20667 16.8307 6.83333 17.2041 6.83333 17.6641V20.1641C6.83333 20.6241 7.20667 20.9974 7.66667 20.9974H8.5C8.5 22.3782 9.61917 23.4974 11 23.4974C12.3808 23.4974 13.5 22.3782 13.5 20.9974H17.6667H18.5C18.5 22.3782 19.6192 23.4974 21 23.4974C22.3808 23.4974 23.5 22.3782 23.5 20.9974H24.3333C24.4483 20.9974 24.5575 20.9745 24.6572 20.9323C24.9563 20.8057 25.1667 20.5091 25.1667 20.1641V16.8307L22.8962 12.2913C22.7553 12.0088 22.4682 11.8307 22.1523 11.8307H19.3333C19.3333 10.9099 18.5875 10.1641 17.6667 10.1641H6.83333ZM19.3333 13.2891H21.765L23.5358 16.8307H19.3333V13.2891ZM11 19.9557C11.5742 19.9557 12.0417 20.4232 12.0417 20.9974C12.0417 21.5716 11.5742 22.0391 11 22.0391C10.4258 22.0391 9.95833 21.5716 9.95833 20.9974C9.95833 20.4232 10.4258 19.9557 11 19.9557ZM21 19.9557C21.5742 19.9557 22.0417 20.4232 22.0417 20.9974C22.0417 21.5716 21.5742 22.0391 21 22.0391C20.4258 22.0391 19.9583 21.5716 19.9583 20.9974C19.9583 20.4232 20.4258 19.9557 21 19.9557Z"
          fill="white"
        />
      </g>
    </SvgIcon>
  );
}
