import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import Gifs from '../../contents/Gifs';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { InputLabel } from '../2.2.0/Common';
import TextFieldInput from '../FormControlInput/TextFieldInput';

const schema = yup.object().shape({
  name: yup.string().required('Vui lòng nhập họ tên'),
  email: yup.string().required('Vui lòng nhập email').email('Email không hợp lệ'),
  phone: yup.string()
});

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%'
  },
  coupon: {
    color: theme.palette.secondary.main,
    fontWeight: 700
  },
  image: {
    marginTop: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  submit: {
    marginTop: 32,
    height: theme.spacing(6),
    borderRadius: theme.spacing(1.5)
  },
  label: {
    color: '#86878F',
    fontWeight: 600,
    paddingLeft: 8,
    display: 'inline-block'
  },
  input: {
    '& > div': {
      height: 48
    },
    '& input': {
      fontSize: 14,
      fontWeight: 600
    }
  },
  logout: {
    cursor: 'pointer',
    marginTop: theme.spacing(3)
  }
}));

export default function ConfirmEmail() {
  const classes = useStyles();
  const profile = useSelector(state => state?.profile);
  const methods = useForm({
    defaultValues: { name: '', email: '', phone: '' },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, setValue } = methods;

  useEffect(() => {
    setValue('name', profile?.name);
    setValue('email', profile?.email);
    setValue('phone', profile?.phone);
  }, [profile, setValue]);

  const handleConfirmPhone = data => {
    // TODO
    // Call api confirm email
    dispatch(
      actions.updateUserProfilePreFix({
        referral_code: window.sessionStorage.getItem('ref'),
        email: data.email,
        name: data.name,
        phone: data.phone
      }),
      true
    );
  };

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        Vui lòng kiểm tra <br /> và nhập thông tin để xác nhận
      </Typography>
      <Typography variant="body2">
        Nhập đủ thông tin để nhận được{' '}
        <span className={classes.coupon}>Mã giảm giá</span> <br /> cho đơn hàng đầu
        tiên và hàng nghìn ưu đãi dành riêng cho bạn
      </Typography>

      <img
        src={Gifs.verifyEmail}
        className={classes.image}
        alt="verify phone"
        width="156px"
      />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(handleConfirmPhone)} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel label={<span className={classes.label}>Họ và tên*</span>}>
                <TextFieldInput
                  name="name"
                  placeholder="Nhập họ và tên của bạn"
                  variant="outlined"
                  fullWidth
                  className={classes.input}
                />
              </InputLabel>
            </Grid>

            <Grid item xs={12}>
              <InputLabel label={<span className={classes.label}>Email*</span>}>
                <TextFieldInput
                  name="email"
                  placeholder="Nhập email của bạn"
                  variant="outlined"
                  fullWidth
                  className={classes.input}
                />
              </InputLabel>
            </Grid>
          </Grid>

          <Button
            className={classes.submit}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Xác nhận
          </Button>
        </form>
      </FormProvider>

      <Typography
        onClick={handleLogout}
        align="center"
        className={classes.logout}
        variant="subtitle2"
        color="primary"
      >
        Đăng xuất
      </Typography>
    </>
  );
}
