import { Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import omit from 'lodash/omit';
import { string } from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router';
import useActiveMode from '../../../hooks/2.2.0/useActiveMode';

const StyledTab = withStyles({
  root: {
    minWidth: 'fit-content'
  }
})(props => <Tab disableRipple {...props} />);

export default function NavTabItem(props) {
  const { IconComponent, onActiveMode, onInactiveMode, activeMode } =
    useActiveMode(props);

  const { pathname } = useLocation();
  const { path } = props;
  const active = activeMode || path === pathname;

  return (
    <StyledTab
      {...omit(props, ['activeIcon'])}
      style={{ color: active ? '#1D50FF' : 'initial' }}
      onMouseEnter={onActiveMode}
      onMouseLeave={onInactiveMode}
      icon={<IconComponent />}
    />
  );
}

NavTabItem.propTypes = {
  path: string.isRequired
};
