import size from 'lodash/size';
import TYPE from '../actions/TYPE';
import { LOGS_TYPE } from '../../contents/Constants';

const initialState = {
  originalList: [],
  list: [],
  detail: null,
  history: [],
  request: {
    isShow: false,
    type: null,
    title: null,
    amount: null,
    status: null
  },
  pagination: {
    page: 1,
    limit: 10,
    total: 0
  },
  filter: {
    type: 'all',
    search: '',
    startDate: '',
    endDate: ''
  }
};

const deposit = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_LIST_TRANSACTION_SUCCESS:
      return {
        ...state,
        originalList: action.data,
        list: action.data,
        filterList: action.data,
        pagination: {
          ...state.pagination,
          total: size(action.data)
        }
      };

    case TYPE.GET_DETAIL_TRANSACTION_SUCCESS:
      return {
        ...state,
        detail: action.data
      };

    case TYPE.CLEAR_DETAIL_TRANSACTION:
      return {
        ...state,
        detail: null
      };

    case TYPE.CHANGE_PAGE_DEPOSIT:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };

    case TYPE.SEARCH_DEPOSIT_SUCCESS:
      return {
        ...state,
        list: action.list,
        filter: action.filter,
        pagination: {
          ...state.pagination,
          ...action.pagination
        }
      };

    case TYPE.GET_LIST_HISTORY_MONEY_REQUEST_SUCCESS:
      return {
        ...state,
        history: action.history
      };

    case TYPE.SEND_MONEY_TRANFER_REQUEST_SUCCESS:
      return {
        ...state,
        history: [action.result, ...state.history],
        request: {
          isShow: !state.request.isShow,
          type: LOGS_TYPE.moneyTranferRequest,
          title: 'Số điểm yêu cầu nạp',
          amount: action.payload.amount,
          status: 'Đang xử lý'
        }
      };

    case TYPE.SEND_MONEY_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        history: [action.result, ...state.history],
        request: {
          isShow: !state.request.isShow,
          type: LOGS_TYPE.moneyWithdrawlRequest,
          title: 'Số điểm yêu cầu rút',
          amount: action.payload.amount,
          status: 'Đang xử lý'
        }
      };

    case TYPE.CLEAR_MONEY_REQUEST:
      return {
        ...state,
        request: {
          type: null,
          title: null,
          amount: null,
          status: null
        }
      };
    default:
      return state;
  }
};

export default deposit;
