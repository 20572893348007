import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function WalletIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.25 0.75H1.75C0.79 0.75 0 1.53 0 2.5V15C0 15.96 0.79 16.75 1.75 16.75H18.25C19.21 16.75 20 15.96 20 15V2.5C20 1.53 19.21 0.75 18.25 0.75ZM18.5 11.25H14C12.62 11.25 11.5 10.13 11.5 8.75C11.5 7.37 12.62 6.25 14 6.25H18.5V11.25ZM18.5 4.75H14C11.79 4.75 10 6.54 10 8.75C10 10.96 11.79 12.75 14 12.75H18.5V15C18.5 15.14 18.39 15.25 18.25 15.25H1.75C1.61 15.25 1.5 15.14 1.5 15V2.5C1.5 2.36 1.61 2.25 1.75 2.25H18.25C18.39 2.25 18.5 2.36 18.5 2.5V4.75Z"
        fill="#121331"
      />
    </SvgIcon>
  );
}
