import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import SkeletonProductInfoRps from './SkeletonProductInfoRps';

const useStyles = makeStyles({
  radius16: {
    borderRadius: 16
  },
  radius6: {
    borderRadius: 6
  }
});
export default function SkeletonOrderTrackingItemRps() {
  const classes = useStyles();
  return (
    <Box bgcolor="white" mb={2} borderRadius={8}>
      <Box px={2} py={1}>
        <Skeleton
          variant="rect"
          width={104}
          height={20}
          className={classes.radius6}
        />
      </Box>

      <Divider />

      <Box px={2} py={1} mt={1} display="flex" justifyContent="space-between">
        <SkeletonProductInfoRps />
      </Box>
    </Box>
  );
}
