import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function DeletePromotionIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.85355 4.14645C8.04881 4.34171 8.04881 4.65829 7.85355 4.85355L4.85355 7.85355C4.65829 8.04882 4.34171 8.04882 4.14645 7.85355C3.95118 7.65829 3.95118 7.34171 4.14645 7.14645L7.14645 4.14645C7.34171 3.95118 7.65829 3.95118 7.85355 4.14645Z"
        fill="#252629"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.14645 4.14645C4.34171 3.95118 4.65829 3.95118 4.85355 4.14645L7.85355 7.14645C8.04881 7.34171 8.04881 7.65829 7.85355 7.85355C7.65829 8.04882 7.34171 8.04882 7.14645 7.85355L4.14645 4.85355C3.95118 4.65829 3.95118 4.34171 4.14645 4.14645Z"
        fill="#252629"
      />
    </SvgIcon>
  );
}
