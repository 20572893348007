import React from 'react';

export default function EyeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9833 9.99994C12.9833 11.6499 11.65 12.9833 10 12.9833C8.35 12.9833 7.01666 11.6499 7.01666 9.99994C7.01666 8.34993 8.35 7.0166 10 7.0166C11.65 7.0166 12.9833 8.34993 12.9833 9.99994Z"
        stroke="#E1E2E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 16.8918C12.9417 16.8918 15.6833 15.1584 17.5917 12.1584C18.3417 10.9834 18.3417 9.00843 17.5917 7.83343C15.6833 4.83343 12.9417 3.1001 9.99999 3.1001C7.05832 3.1001 4.31666 4.83343 2.40833 7.83343C1.65833 9.00843 1.65833 10.9834 2.40833 12.1584C4.31666 15.1584 7.05832 16.8918 9.99999 16.8918Z"
        stroke="#E1E2E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
