/* eslint-disable max-len */
import React from 'react';

const EmailIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M14.9188 7.37598L11.2161 10.3868C10.5165 10.9418 9.5322 10.9418 8.83262 10.3868L5.09863 7.37598"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.091 17.5C16.6255 17.507 18.3337 15.4246 18.3337 12.8653V7.14168C18.3337 4.58235 16.6255 2.5 14.091 2.5H5.90961C3.37515 2.5 1.66699 4.58235 1.66699 7.14168V12.8653C1.66699 15.4246 3.37515 17.507 5.90961 17.5H14.091Z"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmailIcon;
