import React from 'react';

export default function DeleteIcon(props) {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7493 14.6673H5.24999C4.58199 14.6673 4.01666 14.1727 3.92799 13.51L2.63399 3.80198C2.60132 3.55398 2.79399 3.33398 3.04399 3.33398H12.9567C13.2067 3.33398 13.3993 3.55398 13.366 3.80198L12.0713 13.5106C11.9827 14.1727 11.4173 14.6673 10.7493 14.6673Z"
        fill="#94A3B8"
      />
    </svg>
  );
}
