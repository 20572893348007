/* eslint-disable max-len */
import React from 'react';

const LockIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="none"
      d="M13.6868 7.87321V6.08404C13.6868 3.98987 11.9884 2.29154 9.89427 2.29154C7.8001 2.28237 6.0951 3.97237 6.08594 6.06737V6.08404V7.87321"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0695 17.7081H6.70199C4.95699 17.7081 3.54199 16.294 3.54199 14.5481V10.974C3.54199 9.22813 4.95699 7.81396 6.70199 7.81396H13.0695C14.8145 7.81396 16.2295 9.22813 16.2295 10.974V14.5481C16.2295 16.294 14.8145 17.7081 13.0695 17.7081Z"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill="none"
      d="M9.88542 11.8354V13.6863"
      stroke="#252629"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LockIcon;
