import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function HomeActiveIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 19V9.87C21 9.22 20.68 8.6 20.14 8.23L13.14 3.36C12.46 2.88 11.54 2.88 10.86 3.36L3.86 8.23C3.32 8.6 3 9.22 3 9.87V19C3 20.1 3.9 21 5 21H6.98H17.02H19C20.1 21 21 20.1 21 19ZM8.75 16.5C8.33579 16.5 8 16.8358 8 17.25C8 17.6642 8.33579 18 8.75 18H15.25C15.6642 18 16 17.6642 16 17.25C16 16.8358 15.6642 16.5 15.25 16.5H8.75Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
