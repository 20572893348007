import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useResponsive from '../../hooks/useResponsive';
import ConfirmEmail from './ConfirmEmail';

const useStyles = makeStyles(theme => ({
  modal: {
    zIndex: '999 !important'
  },
  box: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  container: {
    background: theme.palette.common.white,
    width: theme.spacing(57),
    padding: theme.spacing(5),
    borderRadius: theme.spacing(1),
    position: 'relative'
  },
  body: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  resBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  resContainer: {
    background: theme.palette.common.white,
    width: theme.spacing(43),
    padding: theme.spacing(3, 2),
    borderRadius: theme.spacing(1),
    position: 'relative',
    maxHeight: '90vh',
    overflow: 'scroll',
    '@media (max-width: 350px)': {
      width: '100vw'
    }
  },
  resBody: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& > h2': {
      marginTop: theme.spacing(0)
    },
    '& > .MuiBox-root': {
      marginTop: theme.spacing(0)
    }
  }
}));

export default function VerifyEmailPopup() {
  const matches = useResponsive();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const profile = useSelector(state => get(state, 'profile', {}));
  const isEmailVerify = get(profile, 'is_email_verify', true);

  useLayoutEffect(() => {
    if (!isEmailVerify) {
      setOpen(true);
    }
  }, [isEmailVerify]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      disableAutoFocus
      disableEnforceFocus
    >
      <Box className={matches ? classes.resBox : classes.box}>
        <Box className={matches ? classes.resContainer : classes.container}>
          <Box className={matches ? classes.resBody : classes.body}>
            <ConfirmEmail />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
