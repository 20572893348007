import map from 'lodash/map';
import toNumber from 'lodash/toNumber';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { history } from '../../App';
import { Path } from '../../contents/Constants';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* orderByGiftCard(action) {
  const items = map(action.payload.quotation_list, item => ({
    link: item.link,
    image: item.image,
    name: item.name,
    color: item.color,
    size: item.size,
    quantity: toNumber(item.quantity),
    price: toNumber(item.price),
    sale_price: toNumber(item.sale_price),
    sale_price_type: item.sale_price_type,
    promotion_code: item.promotion_code
  }));
  const request = { items };
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'orderByGiftcard' });
    const { data } = yield call(services.saleOrderV2.orderByGiftCard, request);
    yield put({ type: TYPE.ORDER_BY_GIFTCARD_SUCCESS, payload: data });
    history.push(`${Path.SHOPPING_CART}?gc=${action.payload.giftCardId}`);
  } catch (error) {
    yield put({ type: TYPE.ORDER_BY_GIFTCARD_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'orderByGiftcard' });
  }
}

function* editQuotation(actions) {
  try {
    const item = actions.payload;
    const request = {
      items: [
        {
          link: item?.link,
          name: item?.name,
          image: item?.image,
          price: item?.price,
          color: item?.color,
          size: item?.size,
          quantity: item?.quantity,
          sale_price: +item?.sale_price,
          _id: item?._id
        }
      ]
    };
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'editQuotation' });
    const { data } = yield call(services.saleOrderV2.editQuotation, request);
    yield put({ type: TYPE.EDIT_QUOTATION_V2_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.EDIT_QUOTATION_V2_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'editQuotation' });
  }
}

function* deleteQuotation(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'deleteQuotation' });
    const { data } = yield call(
      services.saleOrderV2.deleteQuotation,
      action.payload
    );
    yield put({ type: TYPE.DELETE_QUOTATION_BY_DEAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.DELETE_QUOTATION_BY_DEAL_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'deleteQuotation' });
  }
}

function* getCurrentOrder() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getCurrentOrder' });
    const { data } = yield call(services.saleOrderV2.getCurrentOrder);
    yield put({ type: TYPE.GET_CURRENT_ORDER_DEAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_CURRENT_ORDER_DEAL_LIST_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getCurrentOrder' });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.ORDER_BY_GIFTCARD, orderByGiftCard),
    takeLatest(TYPE.DELETE_QUOTATION_BY_DEAL, deleteQuotation),
    takeLatest(TYPE.GET_CURRENT_ORDER_DEAL_LIST, getCurrentOrder),
    takeLatest(TYPE.EDIT_QUOTATION_V2, editQuotation)
  ]);
}
