import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Path } from '../../../contents/Constants';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import CancelIcon from '../../icons/new/improvement/CancelIcon';
import ExtensionIcon from '../../icons/new/improvement/ExtensionIcon';
import Images from '../../../contents/Images';

const useStyle = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1, 3),
    position: 'relative',
    boxShadow: '0px 4px 24px 0px #A9ABB829'
  },
  cancelIcon: {
    position: 'absolute',
    right: theme.spacing(1.25),
    top: theme.spacing(1.25),
    cursor: 'pointer'
  },
  button: {
    borderRadius: theme.spacing(5),
    width: theme.spacing(27.75),
    marginTop: theme.spacing(1),
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0px 6px 6px #ccfff6'
    }
  }
}));

const ExtensionSnackBar = () => {
  const classes = useStyle();
  const profile = useSelector(state => get(state, 'profile', {}));
  const extensionIgnore = get(profile, 'extension_ignore', false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isEmpty(profile)) {
      setOpen(!extensionIgnore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
  };

  const handleWatchExtension = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(actions.closeExtension(), false);
    setOpen(false);
  };
  return (
    <Snackbar anchorOrigin={anchorOrigin} open={open} onClose={handleWatchExtension}>
      <Grid
        container
        wrap="nowrap"
        className={classes.container}
        alignItems="center"
        spacing={2}
      >
        <div onClick={handleWatchExtension} className={classes.cancelIcon}>
          <CancelIcon />
        </div>
        <Grid item>
          <img src={Images.extensionInstall} alt="Extension Install" width={132} />
        </Grid>
        <Grid item>
          <Typography display="block" variant="h4">
            Tiện ích BuyNgon
          </Typography>
          <Typography variant="body2">Mua sắm từ Mỹ về VN dễ dàng hơn</Typography>
          <Button
            href={Path.EXTENSION_INSTALL}
            target="_blank"
            variant="contained"
            className={classes.button}
            color="secondary"
            startIcon={<ExtensionIcon fill="#FFF" />}
          >
            Cài đặt tại đây
          </Button>
        </Grid>
      </Grid>
    </Snackbar>
  );
};

export default React.memo(ExtensionSnackBar);
