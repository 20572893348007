import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowRight as KeyboardArrowRightIcon
} from '@material-ui/icons';
import clsx from 'clsx';
import includes from 'lodash/includes';
import { bool, func, object, string } from 'prop-types';
import React, { Suspense, useState } from 'react';
import Animation from '../../icons/new/improvement/animation/Animation';

const useStyles = makeStyles(theme => ({
  listItem: {
    cursor: 'pointer'
  },
  listItemText: {
    '& .MuiTypography-body1': {
      fontSize: 14,
      fontWeight: 700
    }
  },
  active: {
    color: '#1D50FF'
  },
  startIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: theme.spacing(6)
    }
  },
  animation: {
    height: 24,
    width: 24
  }
}));

export default function SideBarItem({ item, currentPath, onChange, open }) {
  const classes = useStyles();
  const activeParentEle =
    item.childrens &&
    includes(
      item.childrens.map(i => i.path),
      currentPath
    );
  const [hovering, setHover] = useState('');

  return (
    <ListItem
      className={classes.listItem}
      onMouseOver={() => setHover(item.path)}
      onMouseLeave={() => setHover('')}
      onClick={() => onChange(item.path)}
    >
      <ListItemIcon className={classes.startIcon}>
        {((item.path === currentPath && item.activeIcon && item.path !== hovering) ||
          (activeParentEle && item.path !== hovering)) && (
          <Suspense fallback={<div />}>
            <item.activeIcon style={{ fontSize: 20 }} />
          </Suspense>
        )}
        {item.path !== currentPath && !activeParentEle && item.path !== hovering && (
          <Suspense fallback={<div />}>
            <item.icon style={{ fontSize: 20 }} />
          </Suspense>
        )}
        {item.path === hovering && (
          <>
            {item.hoveringIcon ? (
              <Animation
                animationData={item.hoveringIcon}
                className={classes.animation}
              />
            ) : (
              <item.activeIcon style={{ fontSize: 20 }} />
            )}
          </>
        )}
      </ListItemIcon>
      <ListItemText
        className={clsx([
          classes.listItemText,
          (item.path === currentPath || activeParentEle || hovering === item.path) &&
            classes.active
        ])}
        primary={item.label}
      />
      {item.childrens && (
        <>
          {currentPath === item?.path && !open && (
            <ExpandMore color="primary" style={{ fontSize: 20 }} />
          )}
          {((currentPath === item?.path && open) || activeParentEle) && (
            <ExpandLess color="primary" style={{ fontSize: 20 }} />
          )}
          {currentPath !== item?.path && !activeParentEle && (
            <ExpandMore
              style={{ fontSize: 20 }}
              color={hovering === item.path ? 'primary' : 'inherit'}
            />
          )}
        </>
      )}
      {!item.childrens && (
        <KeyboardArrowRightIcon
          style={{ fontSize: 20 }}
          color={
            item.path === currentPath || hovering === item.path
              ? 'primary'
              : 'inherit'
          }
        />
      )}
    </ListItem>
  );
}

SideBarItem.propTypes = {
  open: bool,
  item: object,
  currentPath: string,
  onChange: func
};

SideBarItem.defaultProps = {
  open: true,
  item: {},
  currentPath: '',
  onChange: () => {}
};
