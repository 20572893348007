import { Box, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Path } from '../../../contents/Constants';
import Images from '../../../contents/Images';
import useResponsive from '../../../hooks/useResponsive';
import { FACEBOOK_URL, HOMEPAGE_URL, INSTAGRAM_URL } from '../../../utils/domain';
import { BuyNgonContainer } from '../Common';
import FacebookIcon from '../Icons/Improve/FacebookIcon';
import InstagramIcon from '../Icons/Improve/InstagramIcon';
import { isProductDetailPath } from '../../../utils/helpers';

const useStyles = makeStyles(theme => ({
  listInfo: {
    listStyle: 'none',
    '& li': {
      marginBottom: theme.spacing(2.5),
      '&:first-child': {
        marginBottom: theme.spacing(3),
        fontWeight: 700,
        fontSize: 16
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      '& li': {
        marginBottom: theme.spacing(1.5),
        '&:first-child': {
          marginBottom: theme.spacing(2)
        }
      }
    }
  },
  link: {
    textDecoration: 'none',
    color: '#000',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  image: {
    width: 165,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  mainTitle: {
    fontSize: 14,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 24
    }
  },
  title: {
    width: 275,
    color: '#86878F',
    display: 'inline-block',
    marginTop: theme.spacing(1)
  },
  iconIns: {
    marginLeft: theme.spacing(1)
  },
  container: {
    // margin: theme.spacing(5, 0),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  }
}));

export default function Footer() {
  const matches = useResponsive();
  const classes = useStyles();
  const activePurchaseButton = isProductDetailPath();

  return (
    <Box bgcolor="#FFF" py={5}>
      <BuyNgonContainer fixed>
        <Grid container className={classes.container}>
          <Grid item xs={12} md={4}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                className={classes.image}
                src={Images.buyngonLogoColor}
                alt="logo"
              />
              <Typography variant="body2" className={classes.mainTitle}>
                Mua hàng Quốc Tế giá Ngon!
              </Typography>
            </div>
            {!matches && (
              <Typography variant="caption" className={classes.title}>
                BuyNgon là nền tảng công nghệ giúp khách hàng mua sắm từ khắp nơi
                trên thế giới về Việt Nam một cách dễ dàng, trực tiếp, chuyên nghiệp.
              </Typography>
            )}
          </Grid>
          <Grid item container xs={12} md={8}>
            <Grid item sm={3} xs={6}>
              <ul className={classes.listInfo}>
                <li>Company</li>
                <li>
                  <Link target="_blank" color="inherit" href={HOMEPAGE_URL}>
                    Về chúng tôi
                  </Link>
                </li>
                <li>
                  <Link color="inherit" href={Path.BLOG}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className={classes.link} href={Path.CONTACT}>
                    Liên hệ
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item sm={3} xs={6}>
              <ul className={classes.listInfo}>
                <li>Chính sách</li>
                <li>
                  <Link
                    className={classes.link}
                    href={`${Path.POLICY_DEFAULT_PATH}/privacy_policy`}
                  >
                    Bảo mật
                  </Link>
                </li>
                <li>
                  <Link
                    className={classes.link}
                    href={`${Path.POLICY_DEFAULT_PATH}/order_service_policy`}
                  >
                    Mua hàng hộ
                  </Link>
                </li>
                <li>
                  <Link
                    className={classes.link}
                    href={`${Path.POLICY_DEFAULT_PATH}/shipping_service_policy`}
                  >
                    Ship hộ
                  </Link>
                </li>
                <li>
                  <Link
                    className={classes.link}
                    href={`${Path.POLICY_DEFAULT_PATH}/insurance_policy`}
                  >
                    Bảo hiểm
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item sm={3} xs={6}>
              <ul className={classes.listInfo}>
                <li>Hướng dẫn</li>
                <li>
                  <Link
                    className={classes.link}
                    href={`${Path.POLICY_DEFAULT_PATH}/order`}
                  >
                    Mua hàng
                  </Link>
                </li>
                <li>
                  <Link
                    className={classes.link}
                    href={`${Path.POLICY_DEFAULT_PATH}/order_management`}
                  >
                    Quản lý đơn hàng
                  </Link>
                </li>
                <li>
                  <Link
                    className={classes.link}
                    href={`${Path.POLICY_DEFAULT_PATH}/price_list`}
                  >
                    Bảng giá
                  </Link>
                </li>
              </ul>
            </Grid>
            <Grid item sm={3} xs={6}>
              <ul className={classes.listInfo}>
                <li>Hỗ trợ</li>
                <li>0938.926.648</li>
                <li>
                  <Link target="_blank" color="inherit" href={FACEBOOK_URL}>
                    <FacebookIcon />
                  </Link>
                  <Link
                    target="_blank"
                    color="inherit"
                    href={INSTAGRAM_URL}
                    className={classes.iconIns}
                  >
                    <InstagramIcon />
                  </Link>
                  {/* <Link target="_blank" color="inherit" className={classes.iconIns}>
                  <YoutubeIcon />
                </Link>
                <Link target="_blank" color="inherit" className={classes.iconIns}>
                  <TikTokIcon />
                </Link> */}
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        {activePurchaseButton && matches && <Box style={{ height: '80px' }} />}
      </BuyNgonContainer>
    </Box>
  );
}
