import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CreateAddressIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.00004" cy="8.00065" r="6.66667" fill="#F5F5F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99979 5.55078C8.99979 4.9985 8.55208 4.55078 7.99979 4.55078C7.44751 4.55078 6.99979 4.9985 6.99979 5.55078V6.99294H5.55603C5.00375 6.99294 4.55603 7.44065 4.55603 7.99294C4.55603 8.54522 5.00375 8.99294 5.55603 8.99294H6.99979V10.435C6.99979 10.9873 7.44751 11.435 7.99979 11.435C8.55208 11.435 8.99979 10.9873 8.99979 10.435V8.99294H10.4449C10.9972 8.99294 11.4449 8.54522 11.4449 7.99294C11.4449 7.44065 10.9972 6.99294 10.4449 6.99294H8.99979V5.55078Z"
        fill="#252629"
      />
    </SvgIcon>
  );
}
