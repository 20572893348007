import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function PlanIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M0 0H24V24H0V0Z" fill="transparent" />
      <path d="M0 0H24V24H0V0Z" fill="transparent" />
      <path d="M21.2503 20.0014H2.75027C2.33536 20.0014 1.99902 19.6651 1.99902 19.2502C1.99902 18.8354 2.33536 18.499 2.75027 18.499H21.2503C21.6652 18.499 22.0016 18.8354 22.0016 19.2502C22.0016 19.6651 21.6652 20.0014 21.2503 20.0014Z" />
      <path d="M7.18123 16.6661C6.35794 16.6661 5.54549 16.4502 4.81335 16.0275C3.71429 15.3929 2.9281 14.3684 2.59964 13.1425L2.02464 10.9965C1.9991 10.9012 1.99257 10.8019 2.00544 10.704C2.01831 10.6062 2.05031 10.5119 2.09963 10.4265C2.14895 10.3411 2.21462 10.2662 2.29288 10.2061C2.37115 10.1461 2.46047 10.102 2.55576 10.0765L5.56066 9.27131C5.71671 9.22952 5.88208 9.23914 6.03224 9.29874C6.18239 9.35835 6.30933 9.46478 6.39423 9.60222L7.41327 11.2523L9.3938 10.7216L6.65664 5.99373C6.59887 5.89395 6.56485 5.7822 6.5572 5.66716C6.54956 5.55211 6.5685 5.43685 6.61255 5.3303C6.65661 5.22375 6.7246 5.12877 6.81127 5.05272C6.89793 4.97667 7.00094 4.92159 7.11231 4.89174L10.3483 4.02462C10.4869 3.98748 10.6333 3.99082 10.7701 4.03424C10.9068 4.07767 11.0283 4.15936 11.1201 4.26966L15.1967 9.16677L17.5061 8.54795C19.4356 8.03104 21.4261 9.18011 21.9431 11.1096C22.1869 12.0196 21.645 12.9581 20.7351 13.202L8.41872 16.5022C8.01517 16.6107 7.59912 16.6658 7.18123 16.6661ZM3.67028 11.3332L4.05092 12.7537C4.27551 13.5919 4.81306 14.2924 5.5646 14.7264C6.31613 15.1603 7.1917 15.2755 8.02983 15.0509L20.3462 11.7508C20.3989 11.7366 20.4439 11.7021 20.4712 11.6548C20.4985 11.6075 20.5059 11.5513 20.4918 11.4985C20.1892 10.3691 19.024 9.69659 17.895 9.99918L15.1212 10.7424C14.9826 10.7795 14.8362 10.7762 14.6995 10.7328C14.5627 10.6893 14.4412 10.6077 14.3494 10.4974L10.2729 5.60022L8.44796 6.08925L11.1851 10.8172C11.2429 10.9169 11.2769 11.0287 11.2845 11.1437C11.2922 11.2588 11.2733 11.374 11.2292 11.4806C11.1851 11.5871 11.1171 11.6821 11.0305 11.7582C10.9438 11.8342 10.8408 11.8893 10.7294 11.9191L7.26231 12.8482C7.10626 12.89 6.94088 12.8804 6.79072 12.8208C6.64056 12.7612 6.51361 12.6548 6.42874 12.5173L5.40965 10.8672L3.67028 11.3332Z" />
    </SvgIcon>
  );
}
