/* eslint-disable no-unused-vars */
/* global chrome */

import get from 'lodash/get';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { history } from '../../App';
import {
  ACCESS_KEY,
  ANONYMOUS_ID,
  IS_AUTH,
  MessageContent,
  Path,
  PROVIDER_ID,
  REFRESH_TOKEN
} from '../../contents/Constants';
import services from '../../services';
import connectSocket from '../../socket';
import { messageError, messageSuccess } from '../../utils/alertMessage';
import { offLoading } from '../../utils/dispatch';
import { loginExtension, logoutExtension } from '../../utils/extension';
import { checkTypePath, generateUidUserAccount } from '../../utils/helpers';
import actions from '../actions';
import TYPE from '../actions/TYPE';

const PASSWORD_WRONG = 1001;
const ACCOUNT_NOT_VERIFY_CODE = 1002;
const ACCOUNT_HAVE_BEEN_DEACTIVE = 1004;

function* signInManual(action) {
  try {
    const { username, password } = action;
    const { data } = yield call(
      services.profile.signInManual,
      username.toLowerCase(),
      password
    );
    if (data.code === ACCOUNT_NOT_VERIFY_CODE) {
      history.push(Path.VERIFY_EMAIL);
      return;
    }

    if (data.code === PASSWORD_WRONG) {
      throw new Error(JSON.stringify(data));
    }

    if (data.code === ACCOUNT_HAVE_BEEN_DEACTIVE) {
      messageError(data.message);
      return;
    }

    yield put({ type: TYPE.LOGIN, data });
    yield put({ type: TYPE.SIGN_IN_MANUAL_SUCCESS, payload: data });

    window.sessionStorage.removeItem('ref');
  } catch (error) {
    yield put({
      type: TYPE.SIGN_IN_MANUAL_FAILED,
      message: error
    });
    messageError('Tên đăng nhập hoặc mật khẩu không đúng');
  } finally {
    yield offLoading();
  }
}

function* signUpManual(action) {
  try {
    const referral_code = window.sessionStorage.getItem('ref');
    const { data } = yield call(services.profile.signUpManual, {
      ...action.payload,
      referral_code: action.payload.referral_code || referral_code
    });
    if (data?.code === 1004) {
      messageError(data?.message);
      return;
    }
    if (get(data, 'code', 2000) === 2001) {
      yield put({
        type: TYPE.SIGN_UP_MANUAL_SUCCESS,
        email: action.payload.email
      });
      messageSuccess('Đăng kí thành công');
      history.push(Path.VERIFY_EMAIL);
      window.sessionStorage.removeItem('ref');
    } else {
      yield put({ type: TYPE.SIGN_UP_MANUAL_FAILED, msg: data.message });
      messageError('Email đã được sử dụng');
    }
  } catch (error) {
    yield put({ type: TYPE.SIGN_UP_MANUAL_FAILED, msg: error });
  } finally {
    yield offLoading();
  }
}

function* signInWithOAuth2({ token, provider }) {
  try {
    const referral_code = window.sessionStorage.getItem('ref');
    const { data } = yield call(services.profile.signInWithOAuth2, {
      token,
      referral_code,
      provider
    });

    if (data?.code === ACCOUNT_HAVE_BEEN_DEACTIVE) {
      messageError(data.message);
      return;
    }

    yield put({ type: TYPE.LOGIN, data });
    yield put({ type: TYPE.SIGN_IN_SUCCESS, payload: data });

    if (data?.is_email_verify || provider !== PROVIDER_ID.fb) {
      window.sessionStorage.removeItem('ref');
    }
  } catch (error) {
    yield put({
      type: TYPE.SIGN_IN_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* signIn(action) {
  try {
    const { access_token } = action;
    const referral_code = window.sessionStorage.getItem('ref');

    const { data } = yield call(services.profile.signIn, {
      access_token,
      referral_code
    });

    if (data?.code === ACCOUNT_HAVE_BEEN_DEACTIVE) {
      messageError(data.message);
      return;
    }

    yield put({ type: TYPE.LOGIN, data });
    yield put({ type: TYPE.SIGN_IN_SUCCESS, payload: data });

    window.sessionStorage.removeItem('ref');
  } catch (error) {
    yield put({
      type: TYPE.SIGN_IN_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* moveQuotationAnonymousToOwner() {
  const anonymous_id = window.localStorage.getItem(ANONYMOUS_ID);
  if (!anonymous_id) return;
  window.localStorage.removeItem(ANONYMOUS_ID);
  yield put({ type: TYPE.PUT_TO_LOGGED_CART, anonymous_id });
}

function* getUserProfile() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getUserProfile'
    });
    const { data } = yield call(services.profile.getUserProfile);
    yield put({ type: TYPE.GET_USER_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({
      type: TYPE.GET_USER_PROFILE_FAILED,
      message: error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getUserProfile'
    });
  }
}

function* updateUserProfile(action) {
  try {
    const { userProfile } = action;
    const { data } = yield call(services.profile.updateUserProfile, userProfile);
    yield put({ type: TYPE.UPDATE_USER_PROFILE_SUCCESS, data });
    messageSuccess('Cập nhât thông tin khách hàng thành công');
  } catch (error) {
    yield put({
      type: TYPE.UPDATE_SALE_ORDER_FAILED,
      message: error
    });
    messageError('Cập nhât thông tin khách hàng thất bại');
  } finally {
    yield offLoading();
  }
}

function* updateConfiguration() {
  try {
    const { data } = yield call(services.getConfiguration);
    yield put(actions.setting(data));
  } catch (error) {
    yield put({ type: TYPE.UPDATE_CONFIGURATION_FAILED, msg: error });
  } finally {
    yield offLoading();
  }
}

function* updateFMCToken(action) {
  try {
    const { data } = yield call(services.profile.updateFCMToken, action.token);
    yield put({ type: TYPE.UPDATE_FMC_TOKEN_SUCCESS, data });
  } catch (error) {
    yield put({
      type: TYPE.UPDATE_FMC_TOKEN_FAILED,
      message: error
    });
  } finally {
    // yield offLoading();
  }
}

function* handleChangeLocation(action) {
  const { name, provinceId, districtId } = action;
  if (name === 'city') {
    yield put({ type: TYPE.GET_DISTRICT, provinceId });
  }
  if (name === 'district') {
    yield put({
      type: TYPE.GET_WARD,
      provinceId,
      districtId
    });
  }
}

function generateRedirectPathname(pathname, searchParams) {
  if (!pathname) return null;
  return `${decodeURIComponent(pathname)}${searchParams}`;
}

function* login({ data }) {
  localStorage.setItem(IS_AUTH, 1);
  localStorage.setItem(ACCESS_KEY, data?.authentication?.idToken);
  localStorage.setItem(REFRESH_TOKEN, data?.authentication?.refreshToken);

  yield put({ type: TYPE.LOGIN_SUCCESS });

  // Sync login web app with Chrome Extension
  yield loginExtension(data);

  const isChrome =
    !!window.chrome && (!!window.chrome?.webstore || !!window.chrome?.runtime);

  if (process.env.NODE_ENV !== 'development' && isChrome) {
    window.chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      title: 'web_app_login',
      ack: data.authentication.idToken,
      reAck: data.authentication.refreshToken,
      userInfo: data
    });
  }

  connectSocket();
  messageSuccess('Đăng nhập thành công');

  yield moveQuotationAnonymousToOwner();

  const isFirstLogin = data?.is_first_login;
  if (isFirstLogin) {
    yield fork(services.profile.updateFirstLogin);
    history.push({
      pathname: Path.WELCOME,
      state: { prevPath: data?.redirect }
    });
    return;
  }

  if (history.location.state?.prevPath) {
    history.push(history.location.state.prevPath);
    return;
  }

  if (![Path.SIGN_IN, Path.SIGN_UP].includes(window.location.pathname)) return;

  window.location.href = Path.HOME;
}

function* logout() {
  yield put({ type: TYPE.LOGOUT_SUCCESS });
  localStorage.removeItem(IS_AUTH);
  localStorage.removeItem(ACCESS_KEY);
  localStorage.removeItem(REFRESH_TOKEN);
  window.location.href = Path.HOME;

  const anonymous_id = generateUidUserAccount();
  localStorage.setItem(ANONYMOUS_ID, anonymous_id);

  // Sync login web app with Chrome Extension
  yield logoutExtension(anonymous_id);

  const isChrome =
    !!window.chrome && (!!window.chrome?.webstore || !!window.chrome?.runtime);

  if (process.env.NODE_ENV !== 'development' && isChrome) {
    window.chrome.runtime.sendMessage(process.env.REACT_APP_EXTENSION_ID, {
      title: 'web_app_logout'
    });
  }

  yield connectSocket();
}

function* handleNotificationDeposit({ data }) {
  yield put({ type: TYPE.NOTIFICATION_DEPOSIT_SUCCESS, data });
  yield messageSuccess(MessageContent.notificationDeposit);
}

function* updateUserProfilePreFix(action) {
  try {
    const CODE_SUCCESS = 1004;
    const { data } = yield call(
      services.profile.updateUserProfilePreFix,
      action.data
    );
    if (data.code === CODE_SUCCESS) {
      yield logout();
      yield put({
        type: TYPE.UPDATE_USER_PROFILE_PREFIX_SUCCESS,
        email: action.data.email
      });
      history.push(Path.VERIFY_EMAIL);
    }
  } catch (error) {
    yield put({
      type: TYPE.UPDATE_USER_PROFILE_PREFIX_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* verifyEmail(action) {
  try {
    const { data } = yield call(services.profile.verifyEmail, action.token);

    switch (data.code) {
      case 1003:
        messageError('Mã xác minh không chính xác');
        break;
      case 1006:
        messageSuccess('Cập nhật thông tin thành công ');
        yield put({ type: TYPE.STEP_LOGIN, stepLogin: 1 });
        yield put({
          type: TYPE.VERIFY_EMAIL_SUCCESS,
          data: data.profile
        });
        break;

      default:
        break;
    }
  } catch (error) {
    yield put({
      type: TYPE.VERIFY_EMAIL_FAILED,
      message: error
    });
  } finally {
    yield offLoading();
  }
}

function* forgotPassword(action) {
  try {
    const { data } = yield call(services.profile.forgotPassword, action.body);
    if (data?.code === 1004) {
      messageError(data?.message);
      return;
    }

    if (data.code === 3000) {
      yield put({
        type: TYPE.FORGOT_PASSWORD_FAILED,
        msg: get(data, 'message', '')
      });
      messageError('Tài khoản không tồn tại');
    } else if (data.code === 3005) {
      messageError(
        'Tài khoản chưa được xác thực, vui lòng kiểm tra email để hoàn tất'
      );
    } else {
      yield put({
        type: TYPE.FORGOT_PASSWORD_SUCCESS,
        email: action.body.email
      });
      yield history.push(Path.RECOVERY_EMAIL);
    }
  } catch (error) {
    yield put({ type: TYPE.FORGOT_PASSWORD_FAILED, msg: error });
  } finally {
    yield offLoading();
  }
}

function* changePassword(action) {
  try {
    const { data } = yield call(services.profile.changePassword, action.body);
    switch (data.code) {
      case 3004:
        yield put({ type: TYPE.CHANGE_PASSWORD_SUCCESS });
        yield messageSuccess('Đổi mật khẩu thành công');
        window.location.href = Path.HOME;
        return;
      case 3003:
        yield put({ type: TYPE.CHANGE_PASSWORD_FAILED, msg: data.message });
        yield messageError('Vui lòng gửi lại yêu cầu thay đổi mật khẩu mới');
        return;
      default:
        return;
    }
  } catch (error) {
    yield put({ type: TYPE.CHANGE_PASSWORD_FAILED, msg: error });
  } finally {
    yield offLoading();
  }
}

function* verifyEmailAndAutoLogin({ payload }) {
  try {
    const AUTO_VERIFY_SUCCESS = 2003;
    const { referral_code, ...rest } = payload;
    // const AUTO_VERIFY_FAILED = 2002;
    const { data } = yield call(services.profile.verifyEmailAndAutoLogin, {
      referral_code
    });
    const code = get(data, 'code', null);
    if (code !== AUTO_VERIFY_SUCCESS) throw new Error(JSON.stringify(data));

    yield put({ type: TYPE.LOGIN, data: { ...data, ...rest } });
    yield put({ type: TYPE.VERIFY_EMAIL_AND_AUTO_LOGIN_SUCCESS, data });
    messageSuccess('Xác thực email thành công');
  } catch (error) {
    yield put({ type: TYPE.VERIFY_EMAIL_AND_AUTO_LOGIN_FAILED, msg: error });
    localStorage.removeItem(ACCESS_KEY);
    messageError('Xác thực email thất bại');
    window.location.href = Path.HOME;
  }
}

function* ignoreVerifyPhone() {
  try {
    const { data } = yield call(services.profile.ignoreVerifyPhone);
    yield put({ type: TYPE.IGNORE_VERIFY_PHONE_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.IGNORE_VERIFY_PHONE_FAILED, msg: error });
  }
}

function* getListTransaction() {
  try {
    const { data } = yield call(services.profile.getListTransaction);
    yield put({ type: TYPE.GET_LIST_TRANSACTION_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_TRANSACTION_FAILED, error });
  }
}

function* getDetailTransaction({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: payload });
    const { data } = yield call(services.profile.getDetailTransaction, payload);
    yield put({ type: TYPE.GET_DETAIL_TRANSACTION_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_TRANSACTION_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: payload });
  }
}

function* registerShippingService({ payload }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.REGISTER_SHIPPING_SERVICE
    });
    const { data } = yield call(services.shipping.registerShippingService);
    yield put({ type: TYPE.REGISTER_SHIPPING_SERVICE_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.REGISTER_SHIPPING_SERVICE_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.REGISTER_SHIPPING_SERVICE
    });
    payload?.callback?.();
  }
}

function* closeExtension() {
  yield call(services.profile.closeExtension);
  yield put({ type: TYPE.CLOSE_EXTENSION_SUCCESS });
}

export default function* root() {
  yield all([
    takeLatest(TYPE.SIGN_IN, signIn),
    // takeLatest(TYPE.SIGN_IN_ONE_TAB, signInOneTab),
    takeLatest(TYPE.SIGN_IN_MANUAL, signInManual),
    takeLatest(TYPE.SIGN_UP_MANUAL, signUpManual),
    takeLatest(TYPE.GET_USER_PROFILE, getUserProfile),
    takeLatest(TYPE.LOGIN, login),
    takeLatest(TYPE.LOGOUT, logout),
    takeLatest(TYPE.UPDATE_USER_PROFILE, updateUserProfile),
    takeLatest(TYPE.UPDATE_FMC_TOKEN, updateFMCToken),
    takeLatest(TYPE.CHANGE_LOCATION, handleChangeLocation),
    takeLatest(TYPE.NOTIFICATION_DEPOSIT, handleNotificationDeposit),
    takeLatest(TYPE.UPDATE_USER_PROFILE_PREFIX, updateUserProfilePreFix),
    takeLatest(TYPE.VERIFY_EMAIL, verifyEmail),
    takeLatest(TYPE.UPDATE_CONFIGURATION, updateConfiguration),
    takeLatest(TYPE.FORGOT_PASSWORD, forgotPassword),
    takeLatest(TYPE.CHANGE_PASSWORD, changePassword),
    takeLatest(TYPE.VERIFY_EMAIL_AND_AUTO_LOGIN, verifyEmailAndAutoLogin),
    takeLatest(TYPE.IGNORE_VERIFY_PHONE, ignoreVerifyPhone),
    takeLatest(TYPE.CLOSE_EXTENSION, closeExtension),
    takeLatest(TYPE.GET_LIST_TRANSACTION, getListTransaction),
    takeLatest(TYPE.GET_DETAIL_TRANSACTION, getDetailTransaction),
    takeLatest(TYPE.REGISTER_SHIPPING_SERVICE, registerShippingService),
    takeLatest(TYPE.SIGN_IN_WITH_OAUTH_2, signInWithOAuth2)
  ]);
}
