/* eslint-disable no-console */
import React from 'react';
import { useLocation } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { bool } from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useGoogleLogin } from '@react-oauth/google';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import { FacebookIcon, GoogleIcon } from '../Icons';
import { Path, PROVIDER_ID } from '../../../contents/Constants';
import './style.css';

const useStyles = makeStyles(theme => ({
  btn: {
    height: theme.spacing(5),
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.grey[200],
    fontSize: theme.spacing(1.75)
  }
}));

export default function AuthGoogleFacebook({ isFastLogin }) {
  const classes = useStyles();
  const { pathname } = useLocation();
  const isSignUp = pathname === Path.SIGN_UP;
  const onGoogle = useGoogleLogin({
    onSuccess: response => {
      dispatch(actions.signIn({ access_token: response?.access_token }), true);
    }
  });

  const statusChangeCallback = response => {
    const userId = response?.authResponse?.userID || null;
    if (!userId) {
      return null;
    }
    console.log('fb response: ', response);
    if (response.status === 'connected') {
      console.log('connected: ');
      dispatch(
        actions.signInWithOAuth2({
          token: response?.authResponse?.accessToken,
          provider: PROVIDER_ID.fb
        }),
        true
      );
    } else if (response.status === 'not_authorized') {
      console.log('Please log into this app.');
    } else console.log('Please log into this facebook.');
    return true;
  };

  const checkLoginState = () => {
    window.FB.getLoginStatus(response => {
      statusChangeCallback(response);
    });
  };

  const handleSignIn = () => {
    window.FB.login(checkLoginState, {
      scope: 'email,public_profile',
      return_scopes: true,
      auth_type: 'rerequest'
    });
  };
  const info = {
    google: {
      xs: isFastLogin ? 6 : 12,
      title: isFastLogin ? 'Google' : `Đăng ${isSignUp ? 'ký' : 'nhập'} bằng Google`
    },
    facebook: {
      xs: isFastLogin ? 6 : 12,
      title: isFastLogin
        ? 'Facebook'
        : `Đăng ${isSignUp ? 'ký' : 'nhập'} bằng Facebook`
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={info.google.xs}>
        <Button
          variant="outlined"
          startIcon={<GoogleIcon />}
          fullWidth
          onClick={onGoogle}
          className={classes.btn}
        >
          {info.google.title}
        </Button>
      </Grid>
      <Grid item xs={info.facebook.xs}>
        <Button
          variant="outlined"
          startIcon={<FacebookIcon />}
          fullWidth
          onClick={handleSignIn}
          className={classes.btn}
        >
          {info.facebook.title}
        </Button>
      </Grid>
    </Grid>
  );
}

AuthGoogleFacebook.propTypes = {
  isFastLogin: bool
};

AuthGoogleFacebook.defaultProps = {
  isFastLogin: false
};
