import { SvgIcon } from '@material-ui/core';
import React from 'react';

function PurchasedIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0105 9.16602C13.7446 9.16602 13.483 9.03935 13.3213 8.80268L10.0205 3.97102L6.67881 8.80602C6.41798 9.18435 5.89798 9.27768 5.51964 9.01768C5.14131 8.75602 5.04631 8.23685 5.30798 7.85852L9.33881 2.02518C9.49464 1.80018 9.75048 1.66602 10.0238 1.66602C10.0246 1.66602 10.0255 1.66602 10.0263 1.66602C10.3005 1.66685 10.5571 1.80268 10.7121 2.02935L14.6971 7.86268C14.9571 8.24268 14.8588 8.76102 14.4788 9.02102C14.3355 9.11852 14.1721 9.16602 14.0105 9.16602Z"
        fill="white"
      />
      <path
        d="M13.9238 19.4114L10.9371 16.4247C10.6121 16.0997 10.6121 15.5714 10.9371 15.2464C11.2621 14.9214 11.7904 14.9214 12.1154 15.2464L14.5129 17.6439L18.5771 13.5797C18.9021 13.2547 19.4304 13.2547 19.7554 13.5797C20.0804 13.9047 20.0804 14.433 19.7554 14.758L15.1021 19.4114C14.7771 19.7364 14.2496 19.7364 13.9238 19.4114Z"
        fill="white"
      />
      <path
        d="M9.75919 14.0675C10.2317 13.595 10.8592 13.335 11.5267 13.335C12.1942 13.335 12.8225 13.595 13.2942 14.0675L14.5134 15.2867L17.3992 12.4008C17.66 12.14 17.97 11.9517 18.3059 11.8283L19.1375 8.555C19.2059 8.30417 19.1534 8.03583 18.9967 7.82917C18.8384 7.62167 18.5942 7.5 18.3334 7.5L10 7.50083L1.66752 7.5C1.40669 7.5 1.16252 7.62167 1.00419 7.82917C0.847522 8.03583 0.795022 8.30417 0.863355 8.555L2.99669 16.2775C3.19669 17 3.85419 17.5 4.60336 17.5H9.67502C8.79419 16.52 8.81669 15.01 9.75919 14.0675Z"
        fill="white"
      />
    </SvgIcon>
  );
}

export default PurchasedIcon;
