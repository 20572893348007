import qs from 'qs';
import { useLocation } from 'react-router';

export default function useGetParamsLookingCatalog() {
  const params = qs.parse(useLocation().search, { ignoreQueryPrefix: true });

  return {
    keyword: params?.q || ''
  };
}
