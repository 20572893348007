import isNumber from 'lodash/isNumber';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { IS_AUTH } from '../../contents/Constants';
import services from '../../services';
import TYPE from '../actions/TYPE';
import { shuffle } from '../../utils/helpers';

function filterProduct(data) {
  return (data || []).filter(item => item.type === 'product');
}

function* getOdooCustomerId() {
  const isAuth = localStorage.getItem(IS_AUTH);

  if (!isAuth) return '';

  const isExistId = yield select(state =>
    isNumber(state?.profile?.odoo_customer_id)
  );
  if (!isExistId) {
    yield take(TYPE.GET_USER_PROFILE_SUCCESS);
  }
  return yield select(state => state?.profile?.odoo_customer_id || '');
}

function* getListCatalogLooking(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getListCatalogLooking'
    });
    const data = yield call(services.catalog.getListCatalogLooking, action.payload);
    yield put({
      type: TYPE.GET_LIST_CATALOG_LOOKING_SUCCESS,
      payload: data
    });
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getListCatalogLooking'
    });
    // Check price looking product
    yield put({ type: TYPE.CHECK_PRICE_LOOKING_PRODUCT });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_CATALOG_LOOKING_FAILED, msg: error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getListCatalogLooking'
    });
  }
}

function* getListForYou(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListForYou' });
    const odooCustomerId = yield getOdooCustomerId();
    const data = yield call(services.catalog.getListForYou, {
      ...action.payload,
      ...(odooCustomerId && { user_id: odooCustomerId })
    });
    yield put({
      type: TYPE.GET_LIST_FOR_YOU_SUCCESS,
      payload: data?.list,
      page: data
    });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListForYou' });
    // Check price for you product
    yield put({ type: TYPE.CHECK_PRICE_FOR_YOU_PRODUCT });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_FOR_YOU_FAILED, msg: error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListForYou' });
    // yield offLoading();
  }
}

function* getDetailCatalog(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getDetailCatalog' });
    const odooCustomerId = yield getOdooCustomerId();
    const data = yield call(
      services.catalog.getDetailCatalog,
      action.payload.params,
      { ...action.payload.query, ...(odooCustomerId && { user_id: odooCustomerId }) }
    );
    yield put({ type: TYPE.GET_DETAIL_CATALOG_AWS_SUCCESS, payload: data });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getDetailCatalog' });
    // Check price detail product
    yield put({ type: TYPE.CHECK_PRICE_DETAIL_PRODUCT });
    // Check price relative product
    yield put({ type: TYPE.CHECK_PRICE_RELATIVE_PRODUCT });
    // Check price favorite product
    yield put({ type: TYPE.CHECK_PRICE_FAVORITE_PRODUCT });
  } catch (error) {
    yield put({ type: TYPE.GET_DETAIL_CATALOG_AWS_FAILED, msg: error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getDetailCatalog' });
  }
}

function* getListFlashSale(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListFlashSale' });
    const payload = yield call(services.catalog.getListFlashSale, action.payload);
    const listProduct = shuffle(payload.list || []);
    yield put({
      type: TYPE.GET_LIST_FLASH_SALE_SUCCESS,
      payload: { ...payload, list: listProduct }
    });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListFlashSale' });
    // Check price flash sale product
    yield put({ type: TYPE.CHECK_PRICE_FLASH_SALE_PRODUCT });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_FLASH_SALE_FAILED, msg: error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListFlashSale' });
  }
}

function* getCategories(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getCategories' });
    const data = yield call(services.catalog.getCategories, action.payload);
    yield put({ type: TYPE.GET_CATEGORIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_CATEGORIES_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getCategories' });
  }
}

function* getProductByCategory(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getProductByCategory' });
    const data = yield call(
      services.catalog.getProductByCategory,
      action.payload.params,
      action.payload.query
    );
    yield put({
      type: TYPE.GET_PRODUCT_BY_CATEGORY_SUCCESS,
      data: filterProduct(data?.list),
      category_id: data?.category_id,
      page: data
    });
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getProductByCategory'
    });
    // Check price category product
    yield put({ type: TYPE.CHECK_PRICE_CATEGORY_PRODUCT });
  } catch (error) {
    yield put({ type: TYPE.GET_PRODUCT_BY_CATEGORY_FAILED, error });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getProductByCategory'
    });
  }
}

function* getListProductDealHome({ payload: params }) {
  try {
    const data = yield call(services.catalog.getListProductDealHome, params);
    const payload = data.map(item => ({
      category_id: item.category_id,
      list: item.list,
      filter: item.list
    }));
    yield put({ type: TYPE.GET_LIST_PRODUCT_DEAL_HOME_SUCCESS, payload });
    // Check price home product
    yield put({ type: TYPE.CHECK_PRICE_HOME_PRODUCT });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_PRODUCT_DEAL_HOME_FAILED, msg: error });
  }
}

function* getListFavorite({ payload }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: TYPE.GET_LIST_FAVORITE
    });
    const data = yield call(services.catalog.getListFavorite, payload);
    yield put({
      type: TYPE.GET_LIST_FAVORITE_SUCCESS,
      payload: data
    });
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.GET_LIST_FAVORITE
    });
    // Check price list favorite product
    yield put({ type: TYPE.CHECK_PRICE_LIST_FAVORITE_PRODUCT });
  } catch (error) {
    yield put({
      type: TYPE.GET_LIST_FAVORITE_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: TYPE.GET_LIST_FAVORITE
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_CATALOG_LOOKING, getListCatalogLooking),
    takeLatest(TYPE.GET_DETAIL_CATALOG_AWS, getDetailCatalog),
    takeLatest(TYPE.GET_LIST_FLASH_SALE, getListFlashSale),
    takeLatest(TYPE.GET_LIST_FAVORITE, getListFavorite),
    takeLatest(TYPE.GET_CATEGORIES, getCategories),
    takeLatest(TYPE.GET_PRODUCT_BY_CATEGORY, getProductByCategory),
    takeLatest(TYPE.GET_LIST_FOR_YOU, getListForYou),
    takeLatest(TYPE.GET_LIST_PRODUCT_DEAL_HOME, getListProductDealHome)
  ]);
}
