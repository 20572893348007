import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React from 'react';
import useResponsive from '../../hooks/useResponsive';

const useStyles = makeStyles(theme => ({
  br6: { borderRadius: 6 },
  br8: { borderRadius: 8 },
  br10: { borderRadius: 10 },
  br12: { borderRadius: 12 },
  icon: {
    backgroundColor: '#EEEEF2',
    position: 'absolute',
    bottom: -8,
    right: -8,
    width: 32,
    height: 32,
    border: '2px solid #FFFFFF',
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24,
      bottom: -4,
      right: -4
    }
  },
  container: {
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3.5, 3, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2, 0, 2),
      '&:last-child': { paddingBottom: 16 }
    },
    [theme.breakpoints.up('md')]: {
      '&:last-child': { paddingBottom: 24 },
      '&:first-child': { paddingTop: 0 }
    }
  },
  img: {
    width: 96,
    height: 96,
    [theme.breakpoints.down('xs')]: {
      width: 64,
      height: 64
    }
  }
}));

export default function SkeletonNotification() {
  const matches = useResponsive();
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Box display="flex">
        <Box position="relative" mr={matches ? 1.5 : 4}>
          <Skeleton variant="rect" className={clsx(classes.img, classes.br12)} />

          <Skeleton
            animation={false}
            variant="rect"
            className={clsx(classes.icon, classes[matches ? 'br8' : 'br10'])}
          />
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent={matches ? 'flex-start' : 'center'}
        >
          <Skeleton
            variant="rect"
            width={matches ? 200 : 280}
            height={matches ? 16 : 20}
            className={classes.br6}
          />
          <Skeleton
            variant="rect"
            width={83}
            height={matches ? 12 : 16}
            className={classes.br6}
            component={Box}
            my={matches ? 0.5 : 1}
          />
          <Skeleton
            variant="rect"
            width={matches ? 148 : 170}
            height={matches ? 12 : 16}
            className={classes.br6}
          />
        </Box>
      </Box>

      {!matches && (
        <Skeleton variant="rect" width={124} height={40} className={classes.br8} />
      )}
    </Grid>
  );
}
