import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ANONYMOUS_ID, IS_AUTH } from './contents/Constants';
import { generateUidUserAccount } from './utils/helpers';
import dispatch from './utils/dispatch';
import actions from './redux/actions';

const anonymous_id = window.localStorage.getItem(ANONYMOUS_ID);
const isAuth = window.localStorage.getItem(IS_AUTH);
if (!isAuth && !anonymous_id) {
  window.localStorage.setItem(ANONYMOUS_ID, generateUidUserAccount());
}

dispatch(actions.checkAuth(isAuth));

ReactDOM.render(
  <GoogleOAuthProvider clientId="259156509098-bb8jae1nn0t69go0u7v8lcujmmempgfn.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
