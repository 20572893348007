import { any } from 'prop-types';
import React, { lazy, Suspense } from 'react';

const Lottie = lazy(() => import('lottie-react'));

export default function Animation({ animationData, className, ...props }) {
  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData
  };

  return (
    <div className={className}>
      <Suspense fallback={<div />}>
        <Lottie {...defaultOption} {...props} />
      </Suspense>
    </div>
  );
}

Animation.propTypes = {
  animationData: any,
  className: any
};

Animation.defaultProps = {
  animationData: null,
  className: null
};
