import { SvgIcon } from '@material-ui/core';
import React from 'react';

function PaidIcon() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconly/Bold/Shield Done">
        <g id="Shield Done">
          <path
            id="Shield Done_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.77276 18.2607C9.86505 18.3089 9.96824 18.3335 10.0714 18.3326C10.1746 18.3318 10.277 18.3065 10.3701 18.2575L13.3433 16.6681C14.1865 16.2186 14.8467 15.7161 15.3618 15.131C16.4818 13.8562 17.0934 12.2292 17.0825 10.5515L17.0473 5.01767C17.0439 4.38028 16.6253 3.81152 16.0061 3.60478L10.475 1.74899C10.1419 1.63622 9.77696 1.63867 9.44977 1.75471L3.93955 3.67669C3.32376 3.89161 2.91268 4.46444 2.91604 5.10265L2.95127 10.6324C2.96218 12.3125 3.59475 13.9322 4.7332 15.1939C5.25335 15.7708 5.91947 16.266 6.77184 16.7081L9.77276 18.2607ZM8.98565 11.7568C9.10981 11.8761 9.27089 11.935 9.43197 11.9333C9.59304 11.9325 9.75328 11.8721 9.87577 11.7511L13.125 8.5478C13.3692 8.30674 13.3666 7.9194 13.12 7.6816C12.8725 7.44381 12.474 7.44544 12.2299 7.6865L9.42274 10.4534L8.27338 9.34863C8.02589 9.11083 7.62823 9.11328 7.38325 9.35435C7.13912 9.59542 7.14164 9.98275 7.38913 10.2206L8.98565 11.7568Z"
            fill="white"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default PaidIcon;
