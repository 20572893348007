import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ProfileIconContained(props) {
  return (
    <SvgIcon
      {...props}
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9999 0H10.7599C5.2399 0.14 0.869897 4.72 0.999897 10.24C1.0599 12.93 2.1799 15.35 3.9599 17.1C4.1899 17.32 4.4299 17.54 4.6799 17.74C4.9299 17.94 5.1799 18.13 5.4499 18.31C5.5799 18.4 5.7199 18.48 5.8499 18.57C6.2599 18.81 6.6899 19.03 7.1399 19.22C7.2699 19.27 7.3999 19.32 7.5299 19.38C7.5499 19.38 7.5699 19.39 7.5899 19.4C7.7199 19.45 7.8599 19.5 7.9999 19.54C8.1399 19.58 8.2799 19.62 8.4199 19.66C8.5699 19.7 8.7199 19.74 8.8699 19.77C8.9199 19.78 8.9699 19.79 9.0199 19.8C9.0899 19.82 9.1699 19.83 9.2399 19.84C9.3799 19.87 9.5299 19.89 9.6799 19.91C10.0999 19.97 10.5399 20 10.9799 20H11.2399C11.5799 19.99 11.9199 19.96 12.2499 19.92C12.5599 19.88 12.8699 19.83 13.1699 19.76C13.1899 19.75 13.1999 19.75 13.2199 19.75C13.6699 19.64 14.1099 19.51 14.5299 19.35C14.6799 19.29 14.8199 19.23 14.9699 19.17C15.0699 19.13 15.1699 19.08 15.2699 19.04C15.6299 18.87 15.9799 18.67 16.3199 18.46C16.4599 18.38 16.5899 18.29 16.7199 18.2C16.8499 18.11 16.9799 18.02 17.0999 17.92C17.4299 17.67 17.7399 17.39 18.0399 17.1C19.9299 15.23 21.0699 12.62 20.9999 9.76C20.8699 4.32 16.4199 0 10.9999 0ZM17.1699 15.08C16.2599 13.82 14.7999 13 13.1499 13H8.8399C7.1999 13 5.7399 13.81 4.8299 15.07C3.7299 13.73 3.0499 12.03 2.9999 10.19C2.8999 5.78 6.3999 2.11 10.8099 2H10.9999C15.3099 2 18.8899 5.5 18.9999 9.81C19.0499 11.81 18.3499 13.65 17.1699 15.08Z"
        fill="#1D50FF"
      />
      <path
        d="M14 6.52V10.02C14 10.56 13.56 11 13.02 11H10.52C9.13 11 8 9.87 8 8.48V6.52C8 5.13 9.13 4 10.52 4H11.48C12.87 4 14 5.13 14 6.52Z"
        fill="#1D50FF"
      />
    </SvgIcon>
  );
}
