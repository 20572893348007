import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import { useHistory, useLocation } from 'react-router';
import { NAV_TABS } from '../../../contents/DynamicConstant';
import NavTabItem from './NavTabItem';
import { Path } from '../../../contents/Constants';

const useStyles = makeStyles({
  label: { fontSize: 10, fontWeight: 600 }
});

const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 48,
      height: 2,
      width: '100%',
      backgroundColor: theme.palette.primary.main
    }
  }
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

export default function NavTab() {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();

  const handleChange = (_, routePath) => {
    if ([Path.HOME, Path.BLOG, Path.SHIPPING].includes(routePath)) {
      window.location.href = routePath;
      return;
    }
    history.push(routePath);
  };

  return (
    <StyledTabs
      value={pathname}
      variant="fullWidth"
      textColor="primary"
      onChange={handleChange}
      indicatorColor="primary"
    >
      {NAV_TABS.map((tab, index) => (
        <NavTabItem
          {...tab}
          key={index}
          value={tab.path}
          label={<span className={classes.label}>{tab.label}</span>}
        />
      ))}
    </StyledTabs>
  );
}
