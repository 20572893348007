import TYPE from '../actions/TYPE';

const initialState = {
  point: null,
  referral: null
};

const referral = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_REFERRAL_SUCCESS:
      return {
        ...state,
        ...action.data
      };

    default:
      return state;
  }
};

export default referral;
