import { SvgIcon } from '@material-ui/core';
import React from 'react';

function Uswarsehouse() {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.416 10H9.58268C9.35268 10 9.16602 10.1867 9.16602 10.4167V11.6667H10.8327V10.4167C10.8327 10.1867 10.646 10 10.416 10Z"
        fill="white"
      />
      <path
        d="M16.5108 5.31437L10.3392 2.57187C10.1233 2.47604 9.8775 2.47604 9.6625 2.57187L3.49 5.31437C2.88833 5.58271 2.5 6.17938 2.5 6.83854V15.8335C2.5 16.7544 3.24583 17.5002 4.16667 17.5002H5.83333V10.0002C5.83333 9.54021 6.20667 9.16687 6.66667 9.16687H13.3333C13.7933 9.16687 14.1667 9.54021 14.1667 10.0002V17.5002H15.8333C16.7542 17.5002 17.5 16.7544 17.5 15.8335V6.83771C17.5 6.17854 17.1125 5.58187 16.5108 5.31437Z"
        fill="white"
      />
      <path d="M15 10.834H5V12.5007H15V10.834Z" fill="white" />
    </SvgIcon>
  );
}

export default Uswarsehouse;
