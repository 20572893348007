import { any } from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import { IS_AUTH, Path } from '../contents/Constants';

export default function PublicRoute({ component: Component, ...rest }) {
  const isAuth = localStorage.getItem(IS_AUTH);
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) {
          window.location.href = Path.HOME;
          return null;
        }

        return <Component {...props} />;
        // return (
        //   <Redirect
        //     to={{
        //       pathname: Path.HOME,
        //       state: { prevPath: rest.path }
        //     }}
        //   />
        // );
      }}
    />
  );
}

PublicRoute.propTypes = {
  component: any
};

PublicRoute.defaultProps = {
  component: null
};
