const PUBLIC = `${process.env.PUBLIC_URL}`;

export default {
  advertisement: `${PUBLIC}/images/image/advertisement.png`,
  userAvatar: `${PUBLIC}/images/image/avatar-default.png`,
  avatarSeller: `${PUBLIC}/images/image/avatarSeller.png`,
  backgroundSeller: `${PUBLIC}/images/image/backgroundSeller.png`,
  ACBIcon: `${PUBLIC}/images/image/bank-acb.png`,
  TCBIcon: `${PUBLIC}/images/image/bank-tcb.png`,
  VCBIcon: `${PUBLIC}/images/image/bank-vcb.png`,
  verifyBannerDesktop: `${PUBLIC}/images/image/banner-verify-desktop.png`,
  verifyBanner: `${PUBLIC}/images/image/banner-verify.png`,
  backgroundEmpty: `${PUBLIC}/images/image/bg-empty.png`,
  backgroundSignIn: `${PUBLIC}/images/image/bg-signin.png`,
  buyngonLogoWhite: `${PUBLIC}/images/image/buyngon-logo-white.png`,
  buyngonLogoColor: `${PUBLIC}/images/image/buyngon-logo.svg`,
  contactBackground: `${PUBLIC}/images/image/contact-bg.png`,
  debtDefault: `${PUBLIC}/images/image/debt-default.png`,
  defaultImageQuotations: `${PUBLIC}/images/image/default-image-quotation.png`,
  dollarCoupon: `${PUBLIC}/images/image/dollar-coupon.svg`,
  emptyBox: `${PUBLIC}/images/image/empty-box.png`,
  extensionInstall: `${PUBLIC}/images/image/extension-install.png`,
  guideBox: `${PUBLIC}/images/image/guide-box.png`,
  guideBoxRes: `${PUBLIC}/images/image/guide-box-res.png`,
  haveNotDiscount: `${PUBLIC}/images/image/have-not-discount.png`,
  heroBackground: `${PUBLIC}/images/image/hero-background.png`,
  hourGlass: `${PUBLIC}/images/image/hourglass.png`,
  addressInfo: `${PUBLIC}/images/image/ic-address-info.png`,
  address: `${PUBLIC}/images/image/ic-address.png`,
  arrowRight: `${PUBLIC}/images/image/ic-arrow-right.png`,
  shapeBlack: `${PUBLIC}/images/image/ic-shape-black.png`,
  shape: `${PUBLIC}/images/image/ic-shape.png`,
  voucherBlack: `${PUBLIC}/images/image/ic-voucher-black.png`,
  itemDefault: `${PUBLIC}/images/image/item-default.png`,
  mainLogo: `${PUBLIC}/images/image/main-logo.png`,
  mascot: `${PUBLIC}/images/image/mascot.png`,
  newBuyngonLogoColor: `${PUBLIC}/images/image/new-buyngon-logo.svg`,
  quotationHome: `${PUBLIC}/images/image/quotation-home.png`,
  verifyPhoneCoupon: `${PUBLIC}/images/image/verify-phone-coupon.svg`,
  referralCoupon: `${PUBLIC}/images/image/referral-coupon.svg`,
  referralImage: `${PUBLIC}/images/image/referral-image.jpg`,
  shippingService: `${PUBLIC}/images/image/shipping-feature.png`,
  v3BgSignInRes: `${PUBLIC}/images/image/v3-bg-signin-res.svg`,
  v3BnFullLogo: `${PUBLIC}/images/image/v3-bn-full-logo.svg`,
  v3EmptyCart: `${PUBLIC}/images/image/v3-empty-cart.svg`,
  v3PartnerBrand: `${PUBLIC}/images/image/v3-partner-brand.svg`,
  verifyPhone: `${PUBLIC}/images/image/verify-phone.png`,
  freeDeliveryIcon: `${PUBLIC}/images/image/freeDeliveryIcon.png`
};
