import TYPE from '../actions/TYPE';

const initialState = {
  paymentInfo: null,
  list: {
    DRAFT: [],
    SHIPPING: [],
    DELIVERED: []
  },
  detail: null,
  debtLog: [],
  loading: {
    detail: false,
    list: false
  },
  detailConfirm: null
};

const vnTracking = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_TRANSFER_REQUEST_SUCCESS:
      return {
        ...state,
        debtLog: action.data
      };

    case TYPE.DETAIL_CONFIRM_VN_TRACKING_SUCCESS: {
      return {
        ...state,
        detailConfirm: action.data
      };
    }

    case TYPE.PAYMENT_VN_TRACKING:
      return {
        ...state,
        paymentInfo: action.payload
      };

    case TYPE.GET_DETAIL_VN_TRACKING:
      return {
        ...state,
        loading: {
          ...state.loading,
          detail: true
        }
      };

    case TYPE.GET_DETAIL_VN_TRACKING_SUCCESS:
      return {
        ...state,
        detail: action.data,
        loading: {
          ...state.loading,
          detail: false
        }
      };

    case TYPE.GET_LIST_VN_TRACKING_SUCCESS:
      return {
        ...state,
        list: action.data
      };

    default:
      return state;
  }
};

export default vnTracking;
