import { useHistory, useLocation } from 'react-router-dom';
import { Path } from '../../contents/Constants';

export function getRedirectObj(location, pathname) {
  const currentPath = `${window.location.pathname}${window.location.search}`;
  const redirectObj = {
    state: {
      prevPath: location.state?.prevPath || currentPath
    },
    pathname
  };
  return redirectObj;
}

export default function useRedirectToAuthPage(pathname = Path.SIGN_UP) {
  const history = useHistory();
  const location = useLocation();

  const redirectObj = getRedirectObj(location, pathname);
  const onRedirect = () => history.push(redirectObj);

  return {
    redirectObj,
    onRedirect
  };
}
