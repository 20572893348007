import size from 'lodash/size';
import moment from 'moment';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getExtremelyTastyDealCampaign() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'getExtremelyTastyDealCampaign'
    });
    const { data } = yield call(services.campaign.getExtremelyTastyDealCampaign);
    yield put({ type: TYPE.GET_EXTREMELY_TASTY_DEAL_CAMPAIGN_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_EXTREMELY_TASTY_DEAL_CAMPAIGN_FAILED });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'getExtremelyTastyDealCampaign'
    });
  }
}

function* checkoutProductOfETDCampaign(campaignId) {
  const listQuotationChecked = yield select(state => {
    const originalList = state.saleOrder.draft?.quotation_list || [];
    const checkedList = state.saleOrder.cartOrderChecked;
    return checkedList.map(id => originalList.find(item => item._id === id));
  });

  if (size(listQuotationChecked) !== 1) return false;

  const campaignInfo = yield select(state =>
    (state.campaign.extremely_tasty_deal?.list || []).find(
      item => item._id === campaignId
    )
  );

  if (campaignInfo?.state !== 'activate') return false;

  if (campaignInfo?.condition?.quantity < 1) return false;

  const promotionQuotation = listQuotationChecked.find(item =>
    (campaignInfo?.condition?.websites || []).includes(item.link)
  );

  if (!promotionQuotation) return false;

  if (promotionQuotation.quantity !== 1) return false;

  // if (
  //   !(campaignInfo?.condition?.websites || []).some(web =>
  //     listQuotationChecked.some(item => item.link === web)
  //   )
  // )
  //   return false;

  if (
    campaignInfo?.end_date &&
    !moment().isBetween(
      moment(campaignInfo.start_date),
      moment(campaignInfo.end_date)
    )
  )
    return false;

  return true;
}

function* toggleETDCoupon(isShowCoupon) {
  const listCoupon = yield select(state =>
    state.saleOrder.listingPromotionOriginal.filter(item => {
      if (!item?.is_liquidation) return true;
      if (isShowCoupon) return true;
      return false;
    })
  );

  yield put({
    type: TYPE.TOGGLE_CAMPAIGN_PROMOTION,
    data: listCoupon
  });
}

function* autoAddCouponForETDCampaign() {
  const campaignCoupon = yield select(state =>
    state.saleOrder.listingPromotionOriginal.find(item => item?.is_liquidation)
  );
  if (!campaignCoupon) return;

  const isProductOfCampaign = yield checkoutProductOfETDCampaign(
    campaignCoupon.ctx.id
  );

  yield put({
    type: TYPE.CHOOSE_PROMOTION,
    coupon: campaignCoupon,
    isAdd: isProductOfCampaign
  });

  yield toggleETDCoupon(isProductOfCampaign);
}

export default function* root() {
  yield all([
    takeLatest(
      TYPE.GET_EXTREMELY_TASTY_DEAL_CAMPAIGN,
      getExtremelyTastyDealCampaign
    ),
    takeEvery(TYPE.AUTO_ADD_COUPON_FOR_ETD_CAMPAIGN, autoAddCouponForETDCampaign)
  ]);
}
