import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ImportIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path d="M7.48738 4.86018V2.18751C7.48738 1.90332 7.71405 1.6665 8.00072 1.6665C8.25738 1.6665 8.47484 1.8655 8.5093 2.11756L8.51405 2.18751V4.86018L11.7007 4.86039C13.2873 4.86039 14.5909 6.15969 14.6641 7.78011L14.6673 7.9239V11.2834C14.6673 12.9152 13.4091 14.2546 11.846 14.3298L11.7073 14.3332H4.29398C2.70732 14.3332 1.41004 13.0404 1.33721 11.414L1.33398 11.2697L1.33398 7.91701C1.33398 6.28524 2.58592 4.93931 4.14865 4.86373L4.28732 4.86039H7.48732V9.12865L6.42065 8.02716C6.22065 7.82063 5.89398 7.82063 5.69398 8.02716C5.59398 8.13043 5.54732 8.26811 5.54732 8.4058C5.54732 8.51044 5.57718 8.61948 5.64033 8.71179L5.69398 8.77755L7.63398 10.7878C7.72732 10.891 7.86065 10.9461 8.00065 10.9461C8.11176 10.9461 8.22287 10.9079 8.31084 10.8353L8.36065 10.7878L10.3007 8.77755C10.5007 8.57102 10.5007 8.23369 10.3007 8.02716C10.1188 7.83941 9.83233 7.82234 9.63148 7.97596L9.57398 8.02716L8.51398 9.12865V4.86039L7.48738 4.86018Z" />
    </SvgIcon>
  );
}
