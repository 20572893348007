import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function HomeIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.25 20.9998H16.25C14.73 20.9998 13.5 19.7698 13.5 18.2498V14.7498C13.5 14.6098 13.39 14.4998 13.25 14.4998H10.75C10.61 14.4998 10.5 14.6098 10.5 14.7498V18.2498C10.5 19.7698 9.27 20.9998 7.75 20.9998H4.75C3.79 20.9998 3 20.2098 3 19.2498V9.76984C3 9.19984 3.28 8.65984 3.75 8.33984L11 3.27984C11.6 2.85984 12.4 2.85984 13 3.27984L20.25 8.32984C20.72 8.65984 21 9.19984 21 9.76984V19.2498C21 20.2098 20.21 20.9998 19.25 20.9998ZM10.75 12.9998H13.25C14.21 12.9998 15 13.7898 15 14.7498V18.2498C15 18.9398 15.56 19.4998 16.25 19.4998H19.25C19.39 19.4998 19.5 19.3898 19.5 19.2498V9.76984C19.5 9.68984 19.46 9.60984 19.39 9.55984L12.14 4.50984C12.05 4.44984 11.94 4.44984 11.85 4.50984L4.61 9.56984C4.54 9.60984 4.5 9.68984 4.5 9.76984V19.2498C4.5 19.3898 4.61 19.4998 4.75 19.4998H7.75C8.44 19.4998 9 18.9398 9 18.2498V14.7498C9 13.7898 9.79 12.9998 10.75 12.9998Z" />
    </SvgIcon>
  );
}
