import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Navigation from '../2.2.0/OrderManagement/Navigation';
import HeaderTrackingRps from '../2.2.0/OrderManagement/Responsive/HeaderTrackingRps';
import SkeletonOrderTrackingItemRps from './SkeletonOrderTrackingItemRps';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  title: {
    fontWeight: 700
  }
}));

export default function SkeletonOrderManagement() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <HeaderTrackingRps
          onLeftAction={() => {}}
          title={
            <Typography variant="body2" align="center" className={classes.title}>
              Đơn hàng của tôi
            </Typography>
          }
        >
          <Box style={{ backgroundColor: 'white' }}>
            <Divider />
            <Navigation />

            <Divider />
          </Box>
        </HeaderTrackingRps>

        <Box bgcolor="#F5F5F5" margin={0} paddingTop="12px">
          <SkeletonOrderTrackingItemRps />
        </Box>
      </div>
    </div>
  );
}
