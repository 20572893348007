import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function NotificationIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.8504 17.5189C19.6704 17.8189 19.3504 17.9989 19.0004 17.9989H5.00044C4.65044 17.9989 4.33044 17.8189 4.15044 17.5189C3.97044 17.2289 3.95044 16.8589 4.11044 16.5489L5.83044 13.1189C5.90044 12.9889 5.93044 12.8489 5.93044 12.7089V10.0389C5.93044 7.04891 8.13044 4.55891 11.0004 4.08891V2.87891C11.0004 2.31891 11.4504 1.87891 12.0004 1.87891C12.5504 1.87891 13.0004 2.31891 13.0004 2.87891V4.08891C15.8704 4.55891 18.0704 7.04891 18.0704 10.0389V12.7089C18.0704 12.8489 18.1004 12.9889 18.1704 13.1189L19.8904 16.5489C20.0504 16.8589 20.0304 17.2289 19.8504 17.5189Z" />
      <path d="M8.85059 19.5H15.1506C14.8206 20.93 13.5306 22 12.0006 22C10.4706 22 9.18059 20.93 8.85059 19.5Z" />
    </SvgIcon>
  );
}
