import TYPE from '../actions/TYPE';

const initialState = {
  disableUS: [],
  crawler: []
};

const website = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_LIST_WEBSITE_DISABLE_US_SUCCESS:
      return {
        ...state,
        disableUS: action.payload
      };
    case TYPE.GET_LIST_WEBSITE_CRAWLER_SUCCESS:
      return {
        ...state,
        crawler: action.payload
      };
    default:
      return { ...state };
  }
};

export default website;
