import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function UncheckedIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9.25" stroke="#E1E2E5" strokeWidth="1.5" />
    </SvgIcon>
  );
}
