export default {
  REORDER_SALE_ORDER: 'REORDER_SALE_ORDER',
  REORDER_SALE_ORDER_SUCCESS: 'REORDER_SALE_ORDER_SUCCESS',
  REORDER_SALE_ORDER_FAILED: 'REORDER_SALE_ORDER_FAILED',

  SAVE_QUOTATION_FROM_HOME: 'SAVE_QUOTATION_FROM_HOME',
  CLEAR_QUOTATION_FROM_HOME: 'CLEAR_QUOTATION_FROM_HOME',

  ACCESS_FROM_MOBILE_APP: 'ACCESS_FROM_MOBILE_APP',
  ACCESS_FROM_MOBILE_APP_DN: 'ACCESS_FROM_MOBILE_APP_DN',

  ENABLE_LOADING: 'ENABLE_LOADING',
  DISABLE_LOADING: 'DISABLE_LOADING',

  PUSH_TO_QUOTATION_LIST: 'PUSH_TO_QUOTATION_LIST',
  RESET_QUOTATION_LIST: 'RESET_QUOTATION_LIST',
  REPLACE_QUOTATION_LIST: 'REPLACE_QUOTATION_LIST',
  UPDATE_QUOTATION_LIST: 'UPDATE_QUOTATION_LIST',

  ALERT_MESSAGE_SUCCESS: 'ALERT_MESSAGE_SUCCESS',
  ALERT_MESSAGE_ERROR: 'ALERT_MESSAGE_ERROR',
  ALERT_MESSAGE_CLEAR: 'ALERT_MESSAGE_CLEAR',

  BLOCK_LOGIN_INAPP: 'BLOCK_LOGIN_INAPP',

  HANDLE_CHANGE_FILTER: 'HANDLE_CHANGE_FILTER',

  SAVE_BACKUP_DEAL_CATALOG: 'SAVE_BACKUP_DEAL_CATALOG',

  HANDLE_FILTER_CATALOG: 'HANDLE_FILTER_CATALOG',

  TOGGLE_POPUP_DEBT: 'TOGGLE_POPUP_DEBT',

  GET_LIST_TRANSACTION: 'GET_LIST_TRANSACTION',
  GET_LIST_TRANSACTION_SUCCESS: 'GET_LIST_TRANSACTION_SUCCESS',
  GET_LIST_TRANSACTION_FAILED: 'GET_LIST_TRANSACTION_FAILED',

  GET_DETAIL_TRANSACTION: 'GET_DETAIL_TRANSACTION',
  GET_DETAIL_TRANSACTION_SUCCESS: 'GET_DETAIL_TRANSACTION_SUCCESS',
  GET_DETAIL_TRANSACTION_FAILED: 'GET_DETAIL_TRANSACTION_FAILED',

  UPDATE_WAITING_SALE_ORDER: 'UPDATE_WAITING_SALE_ORDER',
  UPDATE_WAITING_SALE_ORDER_SUCCESS: 'UPDATE_WAITING_SALE_ORDER_SUCCESS',
  UPDATE_WAITING_SALE_ORDER_FAILED: 'UPDATE_WAITING_SALE_ORDER_FAILED',

  CLEAR_DETAIL_TRANSACTION: 'CLEAR_DETAIL_TRANSACTION',

  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILED: 'SIGN_IN_FAILED',

  EXCHANGE_POINT: 'EXCHANGE_POINT',
  EXCHANGE_POINT_SUCCESS: 'EXCHANGE_POINT_SUCCESS',
  EXCHANGE_POINT_FAILED: 'EXCHANGE_POINT_FAILED',

  SIGN_IN_MANUAL: 'SIGN_IN_MANUAL',
  SIGN_IN_MANUAL_SUCCESS: 'SIGN_IN_MANUAL_SUCCESS',
  SIGN_IN_MANUAL_FAILED: 'SIGN_IN_MANUAL_FAILED',

  GET_PROMOTION: 'GET_PROMOTION',
  GET_PROMOTION_SUCCESS: 'GET_PROMOTION_SUCCESS',
  GET_PROMOTION_FAILED: 'GET_PROMOTION_FAILED',

  CREATE_NEW_PROMOTION: 'CREATE_NEW_PROMOTION',
  CREATE_NEW_PROMOTION_SUCCESS: 'CREATE_NEW_PROMOTION_SUCCESS',
  CREATE_NEW_PROMOTION_FAILED: 'CREATE_NEW_PROMOTION_FAILED',

  DELETE_PROMOTION: 'DELETE_PROMOTION',
  DELETE_PROMOTION_SUCCESS: 'DELETE_PROMOTION_SUCCESS',
  DELETE_PROMOTION_FAILED: 'DELETE_PROMOTION_FAILED',

  GET_REFERRAL: 'GET_REFERRAL',
  GET_REFERRAL_SUCCESS: 'GET_REFERRAL_SUCCESS',
  GET_REFERRAL_FAILED: 'GET_REFERRAL_FAILED',

  GET_TRANSFER_REQUEST: 'GET_TRANSFER_REQUEST',
  GET_TRANSFER_REQUEST_SUCCESS: 'GET_TRANSFER_REQUEST_SUCCESS',
  GET_TRANSFER_REQUEST_FAILED: 'GET_TRANSFER_REQUEST_FAILED',

  SIGN_IN_ONE_TAB: 'SIGN_IN_ONE_TAB',
  SIGN_IN_ONE_TAB_SUCCESS: 'SIGN_IN_ONE_TAB_SUCCESS',
  SIGN_IN_ONE_TAB_FAILED: 'SIGN_IN_ONE_TAB_FAILED',

  SIGN_UP_MANUAL: 'SIGN_UP_MANUAL',
  SIGN_UP_MANUAL_SUCCESS: 'SIGN_UP_MANUAL_SUCCESS',
  SIGN_UP_MANUAL_FAILED: 'SIGN_UP_MANUAL_FAILED',

  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',

  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  CHECK_AUTH: 'CHECK_AUTH',

  GET_USER_PROFILE: 'GET_USER_PROFILE',
  GET_USER_PROFILE_SUCCESS: 'GET_USER_PROFILE_SUCCESS',
  GET_USER_PROFILE_FAILED: 'GET_USER_PROFILE_FAILED',

  GET_CURRENT_SALE_ORDER: 'GET_CURRENT_SALE_ORDER',
  GET_CURRENT_SALE_ORDER_SUCCESS: 'GET_CURRENT_SALE_ORDER_SUCCESS',
  GET_CURRENT_SALE_ORDER_FAILED: 'GET_CURRENT_SALE_ORDER_FAILED',

  CREATE_SALE_ORDER: 'CREATE_SALE_ORDER',
  CREATE_SALE_ORDER_SUCCESS: 'CREATE_SALE_ORDER_SUCCESS',
  CREATE_SALE_ORDER_FAILED: 'CREATE_SALE_ORDER_FAILED',

  UPDATE_SALE_ORDER: 'UPDATE_SALE_ORDER',
  UPDATE_SALE_ORDER_SUCCESS: 'UPDATE_SALE_ORDER_SUCCESS',
  UPDATE_SALE_ORDER_FAILED: 'UPDATE_SALE_ORDER_FAILED',

  CONFIRM_SALE_ORDER: 'CONFIRM_SALE_ORDER',
  CONFIRM_SALE_ORDER_SUCCESS: 'CONFIRM_SALE_ORDER_SUCCESS',
  CONFIRM_SALE_ORDER_FAILED: 'CONFIRM_SALE_ORDER_FAILED',
  CLEAR_REVIEW_PAYMENT_PAGE: 'CLEAR_REVIEW_PAYMENT_PAGE',

  GET_PROVINCE: 'GET_PROVINCE',
  GET_PROVINCE_SUCCESS: 'GET_PROVINCE_SUCCESS',
  GET_PROVINCE_FAILED: 'GET_PROVINCE_FAILED',

  GET_DISTRICT: 'GET_DISTRICT',
  GET_DISTRICT_SUCCESS: 'GET_DISTRICT_SUCCESS',
  GET_DISTRICT_FAILED: 'GET_DISTRICT_FAILED',

  GET_WARD: 'GET_WARD',
  GET_WARD_SUCCESS: 'GET_WARD_SUCCESS',
  GET_WARD_FAILED: 'GET_WARD_FAILED',

  DELETE_QUOTATION: 'DELETE_QUOTATION',
  DELETE_QUOTATION_SUCCESS: 'DELETE_QUOTATION_SUCCESS',
  DELETE_QUOTATION_FAILED: 'DELETE_QUOTATION_FAILED',

  DELETE_QUOTATION_ANONYMOUS: 'DELETE_QUOTATION_ANONYMOUS',
  DELETE_QUOTATION_ANONYMOUS_SUCCESS: 'DELETE_QUOTATION_ANONYMOUS_SUCCESS',
  DELETE_QUOTATION_ANONYMOUS_FAILED: 'DELETE_QUOTATION_ANONYMOUS_FAILED',

  POST_CREATE_NEW_ADDRESS: 'POST_CREATE_NEW_ADDRESS',
  POST_CREATE_NEW_ADDRESS_SUCCESS: 'POST_CREATE_NEW_ADDRESS_SUCCESS',
  POST_CREATE_NEW_ADDRESS_FAILED: 'POST_CREATE_NEW_ADDRESS_FAILED',

  PUT_SELECT_ADDRESS: 'PUT_SELECT_ADDRESS',
  PUT_SELECT_ADDRESS_SUCCESS: 'PUT_SELECT_ADDRESS_SUCCESS',
  PUT_SELECT_ADDRESS_FAILED: 'PUT_SELECT_ADDRESS_FAILED',

  DELETE_ADDRESS: 'DELETE_ADDRESS',
  DELETE_ADDRESS_SUCCESS: 'DELETE_ADDRESS_SUCCESS',
  DELETE_ADDRESS_FAILED: 'DELETE_ADDRESS_FAILED',

  GET_LIST_SALE_ORDER: 'GET_LIST_SALE_ORDER',
  GET_LIST_SALE_ORDER_SUCCESS: 'GET_LIST_SALE_ORDER_SUCCESS',
  GET_LIST_SALE_ORDER_FAILED: 'GET_LIST_SALE_ORDER_FAILED',

  GET_SALE_ORDER: 'GET_SALE_ORDER',
  GET_SALE_ORDER_SUCCESS: 'GET_SALE_ORDER_SUCCESS',
  GET_SALE_ORDER_FAILED: 'GET_SALE_ORDER_FAILED',

  GET_SALE_ORDER_DETAIL: 'GET_SALE_ORDER_DETAIL',

  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE_FAILED: 'UPDATE_USER_PROFILE_FAILED',

  NOTIFICATION_SALE_ORDER: 'NOTIFICATION_SALE_ORDER',
  NOTIFICATION_SALE_ORDER_SUCCESS: 'NOTIFICATION_SALE_ORDER_SUCCESS',
  NOTIFICATION_SALE_ORDER_FAILED: 'NOTIFICATION_SALE_ORDER_FAILED',

  NOTIFICATION_TRACKING: 'NOTIFICATION_TRACKING',
  NOTIFICATION_TRACKING_SUCCESS: 'NOTIFICATION_TRACKING_SUCCESS',
  NOTIFICATION_TRACKING_FAILED: 'NOTIFICATION_TRACKING_FAILED',

  NOTIFICATION_DEPOSIT: 'NOTIFICATION_DEPOSIT',
  NOTIFICATION_DEPOSIT_SUCCESS: 'NOTIFICATION_DEPOSIT_SUCCESS',
  NOTIFICATION_DEPOSIT_FAILED: 'NOTIFICATION_DEPOSIT_FAILED',

  UPDATE_PRICE_IN_USED: 'UPDATE_PRICE_IN_USED',

  SEARCH_LIST_SALE_ORDER: 'SEARCH_LIST_SALE_ORDER',

  CHANGE_LOCATION: 'CHANGE_LOCATION',

  UPDATE_FMC_TOKEN: 'UPDATE_FMC_TOKEN',
  UPDATE_FMC_TOKEN_SUCCESS: 'UPDATE_FMC_TOKEN_SUCCESS',
  UPDATE_FMC_TOKEN_FAILED: 'UPDATE_FMC_TOKEN_FAILED',

  TRACKING_LIST: 'TRACKING_LIST',
  TRACKING_LIST_SUCCESS: 'TRACKING_LIST_SUCCESS',
  TRACKING_LIST_FAILED: 'TRACKING_LIST_FAILED',

  TRACKING_LIST_FILTER: 'TRACKING_LIST_FILTER',

  TRACKING_DETAIL: 'TRACKING_DETAIL',
  TRACKING_DETAIL_SUCCESS: 'TRACKING_DETAIL_SUCCESS',
  TRACKING_DETAIL_FAILED: 'TRACKING_DETAIL_FAILED',

  VALIDATE_FORM: 'VALIDATE_FORM',

  RESET_DELIVERY_ADDRESS: 'RESET_DELIVERY_ADDRESS',
  RESET_DELIVERY_ADDRESS_SUCCESS: 'RESET_DELIVERY_ADDRESS_SUCCESS',
  RESET_DELIVERY_ADDRESS_FAILED: 'RESET_DELIVERY_ADDRESS_FAILED',

  CLEAR_VALIDATE: 'CLEAR_VALIDATE',

  SETTING: 'SETTING',
  SETTING_ERROR: 'SETTING_ERROR',

  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILED: 'REFRESH_T_FAILEDOKEN',

  GET_CONFIGURATION: 'GET_CONFIGURATION',
  GET_CONFIGURATION_SUCCESS: 'GET_CONFIGURATION_SUCCESS',
  GET_CONFIGURATION_FAILED: 'GET_CONFIGURATION_FAILED',

  UPLOAD_FILE_QUOTATION: 'UPLOAD_FILE_QUOTATION',
  UPLOAD_FILE_QUOTATION_SUCCESS: 'UPLOAD_FILE_QUOTATION_SUCCESS',
  UPLOAD_FILE_QUOTATION_FAILED: 'UPLOAD_FILE_QUOTATION_FAILED',

  EDIT_QUOTATION: 'EDIT_QUOTATION',

  UPDATE_QUOTATION: 'UPDATE_QUOTATION',
  UPDATE_QUOTATION_SUCCESS: 'UPDATE_QUOTATION_SUCCESS',
  UPDATE_QUOTATION_FAILED: 'UPDATE_QUOTATION_FAILED',

  CANCEL_SALE_ORDER: 'CANCEL_SALE_ORDER',
  CANCEL_SALE_ORDER_SUCCESS: 'CANCEL_SALE_ORDER_SUCCESS',
  CANCEL_SALE_ORDER_FAILED: 'CANCEL_SALE_ORDER_FAILED',

  UPDATE_USER_PROFILE_PREFIX: 'UPDATE_USER_PROFILE_PREFIX',
  UPDATE_USER_PROFILE_PREFIX_SUCCESS: 'UPDATE_USER_PROFILE_PREFIX_SUCCESS',
  UPDATE_USER_PROFILE_PREFIX_FAILED: 'UPDATE_USER_PROFILE_PREFIX_FAILED',

  STEP_LOGIN: 'STEP_LOGIN',

  VERIFY_EMAIL: 'VERIFY_EMAIL',
  VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILED: 'VERIFY_EMAIL_FAILED',

  CHECK_SALE_ORDER_DRAFT: 'CHECK_SALE_ORDER_DRAFT',

  // improvement
  CONFIRM_SHOPPING_CART: 'CONFIRM_SHOPPING_CART',
  CONFIRM_SHOPPING_CART_SUCCESS: 'CONFIRM_SHOPPING_CART_SUCCESS',
  CONFIRM_SHOPPING_CART_FAILED: 'CONFIRM_SHOPPING_CART_FAILED',

  BUYNOW: 'BUYNOW',

  BUYNOW_ANONYMOUS: 'BUYNOW_ANONYMOUS',

  GET_SELECTED_SALE_ORDER: 'GET_SELECTED_SALE_ORDER',
  GET_SELECTED_SALE_ORDER_SUCCESS: 'GET_SELECTED_SALE_ORDER_SUCCESS',
  GET_SELECTED_SALE_ORDER_FAILED: 'GET_SELECTED_SALE_ORDER_FAILED',

  ADD_SHOPPING_CART_ANONYMOUS: 'ADD_SHOPPING_CART_ANONYMOUS',
  ADD_SHOPPING_CART_ANONYMOUS_SUCCESS: 'ADD_SHOPPING_CART_ANONYMOUS_SUCCESS',
  ADD_SHOPPING_CART_ANONYMOUS_FAILED: 'ADD_SHOPPING_CART_ANONYMOUS_FAILED',
  ADD_SHOPPING_CART_ANONYMOUS_COMPLETE: 'ADD_SHOPPING_CART_ANONYMOUS_COMPLETE',

  ADD_SHOPPING_CART: 'ADD_SHOPPING_CART',
  ADD_SHOPPING_CART_SUCCESS: 'ADD_SHOPPING_CART_SUCCESS',
  ADD_SHOPPING_CART_FAILED: 'ADD_SHOPPING_CART_FAILED',
  ADD_SHOPPING_CART_COMPLETE: 'ADD_SHOPPING_CART_COMPLETE',

  EDIT_SALE_ORDER_IMPROVEMENT: 'EDIT_SALE_ORDER_IMPROVEMENT',
  EDIT_SALE_ORDER_IMPROVEMENT_SUCCESS: 'EDIT_SALE_ORDER_IMPROVEMENT_SUCCESS',
  EDIT_SALE_ORDER_IMPROVEMENT_FAILED: 'EDIT_SALE_ORDER_IMPROVEMENT_FAILED',

  EDIT_SALE_ORDER_ANONYMOUS: 'EDIT_SALE_ORDER_ANONYMOUS',
  EDIT_SALE_ORDER_ANONYMOUS_SUCCESS: 'EDIT_SALE_ORDER_ANONYMOUS_SUCCESS',
  EDIT_SALE_ORDER_ANONYMOUS_FAILED: 'EDIT_SALE_ORDER_ANONYMOUS_FAILED',

  EDIT_QUOTATION_IMPROVEMENT: 'EDIT_QUOTATION_IMPROVEMENT',

  IS_TRANSACTION: 'IS_TRANSACTION',

  SET_ADDRESS_TO_EDIT: 'SET_ADDRESS_TO_EDIT',

  EDIT_DELIVERY_ADDRESS: 'EDIT_DELIVERY_ADDRESS',
  EDIT_DELIVERY_ADDRESS_SUCCESS: 'EDIT_DELIVERY_ADDRESS_SUCCESS',
  EDIT_DELIVERY_ADDRESS_FAILED: 'EDIT_DELIVERY_ADDRESS_FAILED',

  CREATE_NEW_ADDRESS_IMPROVEMENT: 'CREATE_NEW_ADDRESS_IMPROVEMENT',
  CREATE_NEW_ADDRESS_IMPROVEMENT_SUCCESS: 'CREATE_NEW_ADDRESS_IMPROVEMENT_SUCCESS',
  CREATE_NEW_ADDRESS_IMPROVEMENT_FAILED: 'CREATE_NEW_ADDRESS_IMPROVEMENT_FAILED',

  CLEAR_LOCATION: 'CLEAR_LOCATION',

  EDIT_ADDRESS_IMPROVEMENT: 'EDIT_ADDRESS_IMPROVEMENT',
  EDIT_ADDRESS_IMPROVEMENT_SUCCESS: 'EDIT_ADDRESS_IMPROVEMENT_SUCCESS',
  EDIT_ADDRESS_IMPROVEMENT_FAILED: 'EDIT_ADDRESS_IMPROVEMENT_FAILED',

  NEW_USER_CREATE_ADDRESS_SUCCESS: 'NEW_USER_CREATE_ADDRESS_SUCCESS',
  UN_CHECK_IS_USER_CREATE_NEW_ADDRESS: 'UN_CHECK_IS_USER_CREATE_NEW_ADDRESS',

  UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
  UPDATE_CONFIGURATION_SUCCESS: 'UPDATE_CONFIGURATION_SUCCESS',
  UPDATE_CONFIGURATION_FAILED: 'UPDATE_CONFIGURATION_FAILED',

  GET_LIST_WEBSITE_DISABLE_US: 'GET_LIST_WEBSITE_DISABLE_US',
  GET_LIST_WEBSITE_DISABLE_US_SUCCESS: 'GET_LIST_WEBSITE_DISABLE_US_SUCCESS',
  GET_LIST_WEBSITE_DISABLE_US_FAILED: 'GET_LIST_WEBSITE_DISABLE_US_FAILED',

  GET_LIST_WEBSITE_CRAWLER: 'GET_LIST_WEBSITE_CRAWLER',
  GET_LIST_WEBSITE_CRAWLER_SUCCESS: 'GET_LIST_WEBSITE_CRAWLER_SUCCESS',
  GET_LIST_WEBSITE_CRAWLER_FAILED: 'GET_LIST_WEBSITE_CRAWLER_FAILED',

  GET_LIST_CATEGORIES: 'GET_LIST_CATEGORIES',
  GET_LIST_CATEGORIES_SUCCESS: 'GET_LIST_CATEGORIES_SUCCESS',
  GET_LIST_CATEGORIES_FAILED: 'GET_LIST_CATEGORIES_FAILED',

  GET_DETAIL_CATEGORY: 'GET_DETAIL_CATEGORY',
  GET_DETAIL_CATEGORY_SUCCESS: 'GET_DETAIL_CATEGORY_SUCCESS',
  GET_DETAIL_CATEGORY_FAILED: 'GET_DETAIL_CATEGORY_FAILED',

  SET_LIST_QUOTATION_IMPORT: 'SET_LIST_QUOTATION_IMPORT',

  ENABLE_LOADING_IMPROVE: 'ENABLE_LOADING_IMPROVE',
  DISABLE_LOADING_IMPROVE: 'DISABLE_LOADING_IMPROVE',

  RESET_LOADING: 'RESET_LOADING',

  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'CHANGE_PASSWORD_FAILED',

  VERIFY_EMAIL_AND_AUTO_LOGIN: 'VERIFY_EMAIL_AND_AUTO_LOGIN',
  VERIFY_EMAIL_AND_AUTO_LOGIN_SUCCESS: 'VERIFY_EMAIL_AND_AUTO_LOGIN_SUCCESS',
  VERIFY_EMAIL_AND_AUTO_LOGIN_FAILED: 'VERIFY_EMAIL_AND_AUTO_LOGIN_FAILED',

  IGNORE_VERIFY_PHONE: 'IGNORE_VERIFY_PHONE',
  IGNORE_VERIFY_PHONE_SUCCESS: 'IGNORE_VERIFY_PHONE_SUCCESS',
  IGNORE_VERIFY_PHONE_FAILED: 'IGNORE_VERIFY_PHONE_FAILED',

  VERIFY_PHONE_SUCCESS: 'VERIFY_PHONE_SUCCESS',
  RESEND_EMAIL: 'RESEND_EMAIL',

  HANDLE_VERIFY_PHONE: 'HANDLE_VERIFY_PHONE',

  REFERRAL_CODE: 'REFERRAL_CODE',

  HANDLE_VERIFY_PHONE_FROM_REFERRAL: 'HANDLE_VERIFY_PHONE_FROM_REFERRAL',

  CLOSE_EXTENSION: 'CLOSE_EXTENSION',
  CLOSE_EXTENSION_SUCCESS: 'CLOSE_EXTENSION_SUCCESS',

  IMPORT_QUOTATION_FILE: 'IMPORT_QUOTATION_FILE',
  IMPORT_QUOTATION_FILE_SUCCESS: 'IMPORT_QUOTATION_FILE_SUCCESS',
  IMPORT_QUOTATION_FILE_FAILED: 'IMPORT_QUOTATION_FILE_FAILED',

  CLEAR_QUOTATION_FILE: 'CLEAR_QUOTATION_FILE',

  GET_SHIPPING_CATEGORIES: 'GET_SHIPPING_CATEGORIES',
  GET_SHIPPING_CATEGORIES_SUCCESS: 'GET_SHIPPING_CATEGORIES_SUCCESS',
  GET_SHIPPING_CATEGORIES_FAILED: 'GET_SHIPPING_CATEGORIES_FAILED',

  GET_LIST_PROMOTION: 'GET_LIST_PROMOTION',
  GET_LIST_PROMOTION_SUCCESS: 'GET_LIST_PROMOTION_SUCCESS',
  GET_LIST_PROMOTION_FAILED: 'GET_LIST_PROMOTION_FAILED',

  CHOOSE_PROMOTION: 'CHOOSE_PROMOTION',

  INPUT_PROMOTION: 'INPUT_PROMOTION',
  INPUT_PROMOTION_SUCCESS: 'INPUT_PROMOTION_SUCCESS',
  INPUT_PROMOTION_FAILED: 'INPUT_PROMOTION_FAILED',

  CHANGE_PAGE_DEPOSIT: 'CHANGE_PAGE_DEPOSIT',

  SEARCH_DEPOSIT: 'SEARCH_DEPOSIT',
  SEARCH_DEPOSIT_SUCCESS: 'SEARCH_DEPOSIT_SUCCESS',

  POST_SCRAPER: 'POST_SCRAPER',
  POST_SCRAPER_LOADING: 'POST_SCRAPER_LOADING',
  POST_SCRAPER_SUCCESS: 'POST_SCRAPER_SUCCESS',
  POST_SCRAPER_FAILED: 'POST_SCRAPER_FAILED',
  POST_SCRAPER_COMPLETED: 'POST_SCRAPER_COMPLETED',

  CRAWL_DATA: 'CRAWL_DATA',
  CRAWL_DATA_SUCCESS: 'CRAWL_DATA_SUCCESS',
  CRAWL_DATA_FAILED: 'CRAWL_DATA_FAILED',

  COUPON_VERIFY_PHONE: 'COUPON_VERIFY_PHONE',

  GET_LIST_CATALOG_AWS_PAGINATION: 'GET_LIST_CATALOG_AWS_PAGINATION',

  GET_LIST_CATALOG_LOOKING: 'GET_LIST_CATALOG_LOOKING',
  GET_LIST_CATALOG_LOOKING_SUCCESS: 'GET_LIST_CATALOG_LOOKING_SUCCESS',
  GET_LIST_CATALOG_LOOKING_FAILED: 'GET_LIST_CATALOG_LOOKING_FAILED',

  GET_LIST_FOR_YOU: 'GET_LIST_FOR_YOU',
  GET_LIST_FOR_YOU_SUCCESS: 'GET_LIST_FOR_YOU_SUCCESS',
  GET_LIST_FOR_YOU_FAILED: 'GET_LIST_FOR_YOU_FAILED',

  GET_DETAIL_CATALOG_AWS: 'GET_DETAIL_CATALOG_AWS',
  GET_DETAIL_CATALOG_AWS_SUCCESS: 'GET_DETAIL_CATALOG_AWS_SUCCESS',
  GET_DETAIL_CATALOG_AWS_FAILED: 'GET_DETAIL_CATALOG_AWS_FAILED',

  CLEAR_CATALOG_DETAIL: 'CLEAR_CATALOG_DETAIL',
  CLEAR_DEAL_DETAIL: 'CLEAR_DEAL_DETAIL',

  CHECK_PRICE_FLASH_SALE_PRODUCT: 'CHECK_PRICE_FLASH_SALE_PRODUCT',
  CHECK_PRICE_LIST_FAVORITE_PRODUCT: 'CHECK_PRICE_LIST_FAVORITE_PRODUCT',
  CHECK_PRICE_FOR_YOU_PRODUCT: 'CHECK_PRICE_FOR_YOU_PRODUCT',
  CHECK_PRICE_LOOKING_PRODUCT: 'CHECK_PRICE_LOOKING_PRODUCT',
  CHECK_PRICE_CATEGORY_PRODUCT: 'CHECK_PRICE_CATEGORY_PRODUCT',
  CHECK_PRICE_HOME_PRODUCT: 'CHECK_PRICE_HOME_PRODUCT',
  CHECK_PRICE_DETAIL_PRODUCT: 'CHECK_PRICE_DETAIL_PRODUCT',
  CHECK_PRICE_RELATIVE_PRODUCT: 'CHECK_PRICE_RELATIVE_PRODUCT',
  CHECK_PRICE_FAVORITE_PRODUCT: 'CHECK_PRICE_FAVORITE_PRODUCT',

  SELECT_ADDRESS_TO_EDIT: 'SELECT_ADDRESS_TO_EDIT',

  CREATE_DELIVERY_ADDRESS: 'CREATE_DELIVERY_ADDRESS',
  CREATE_DELIVERY_ADDRESS_SUCCESS: 'CREATE_DELIVERY_ADDRESS_SUCCESS',
  CREATE_DELIVERY_ADDRESS_FAILED: 'CREATE_DELIVERY_ADDRESS_FAILED',

  NEW_EDIT_DELIVERY_ADDRESS: 'NEW_EDIT_DELIVERY_ADDRESS',
  NEW_EDIT_DELIVERY_ADDRESS_SUCCESS: 'NEW_EDIT_DELIVERY_ADDRESS_SUCCESS',
  NEW_EDIT_DELIVERY_ADDRESS_FAILED: 'NEW_EDIT_DELIVERY_ADDRESS_FAILED',

  EDIT_SELECTED_DELIVERY_ADDRESS: 'EDIT_SELECTED_DELIVERY_ADDRESS',

  ENABLE_LOADING_CATEGORY: 'ENABLE_LOADING_CATEGORY',
  DISABLE_LOADING_CATEGORY: 'DISABLE_LOADING_CATEGORY',

  CATALOG_SEARCHING: 'CATALOG_SEARCHING',

  FILTER_CATEGORY_CATALOG: 'FILTER_CATEGORY_CATALOG',

  CLEAR_CATEGORY_CATALOG_MOBILE: 'CLEAR_CATEGORY_CATALOG_MOBILE',

  CLEAR_CATEGORY_CATALOG: 'CLEAR_CATEGORY_CATALOG',

  GET_LIST_FLASH_SALE: 'GET_LIST_FLASH_SALE',
  GET_LIST_FLASH_SALE_SUCCESS: 'GET_LIST_FLASH_SALE_SUCCESS',
  GET_LIST_FLASH_SALE_FAILED: 'GET_LIST_FLASH_SALE_FAILED',

  SIGN_IN_WITH_OAUTH_2: 'SIGN_IN_WITH_OAUTH_2',
  SIGN_IN_WITH_OAUTH_2_SUCCESS: 'SIGN_IN_WITH_OAUTH_2_SUCCESS',
  SIGN_IN_WITH_OAUTH_2_FAILED: 'SIGN_IN_WITH_OAUTH_2_FAILED',

  GET_SHOPPING_CART_ANONYMOUS: 'GET_SHOPPING_CART_ANONYMOUS',
  GET_SHOPPING_CART_ANONYMOUS_SUCCESS: 'GET_SHOPPING_CART_ANONYMOUS_SUCCESS',
  GET_SHOPPING_CART_ANONYMOUS_FAILED: 'GET_SHOPPING_CART_ANONYMOUS_FAILED',

  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILED: 'GET_CATEGORIES_FAILED',

  GET_PRODUCT_BY_CATEGORY: 'GET_PRODUCT_BY_CATEGORY',
  GET_PRODUCT_BY_CATEGORY_SUCCESS: 'GET_PRODUCT_BY_CATEGORY_SUCCESS',
  GET_PRODUCT_BY_CATEGORY_FAILED: 'GET_PRODUCT_BY_CATEGORY_FAILED',

  GET_LIST_FAVORITE: 'GET_LIST_FAVORITE',
  GET_LIST_FAVORITE_SUCCESS: 'GET_LIST_FAVORITE_SUCCESS',
  GET_LIST_FAVORITE_FAILED: 'GET_LIST_FAVORITE_FAILED',

  ACTIVE_FILTER: 'ACTIVE_FILTER',
  CLEAR_FILTER: 'CLEAR_FILTER',

  GET_FIRST_PRODUCT: 'GET_FIRST_PRODUCT',

  SOCKET_UPDATE_SHOPPING_CART_ANONYMOUS: 'SOCKET_UPDATE_SHOPPING_CART_ANONYMOUS',

  COUPON_ANONYMOUS: 'COUPON_ANONYMOUS',

  CHANGE_VARIANT_PRODUCT: 'CHANGE_VARIANT_PRODUCT',

  GET_LIST_ORDER_MANAGEMENT: 'GET_LIST_ORDER_MANAGEMENT',
  GET_LIST_ORDER_MANAGEMENT_SUCCESS: 'GET_LIST_ORDER_MANAGEMENT_SUCCESS',
  GET_LIST_ORDER_MANAGEMENT_FAILED: 'GET_LIST_ORDER_MANAGEMENT_FAILED',

  FILTER_ORDER_MANAGEMENT: 'FILTER_ORDER_MANAGEMENT',

  PUT_TO_LOGGED_CART: 'PUT_TO_LOGGED_CART',
  PUT_TO_LOGGED_CART_SUCCESS: 'PUT_TO_LOGGED_CART_SUCCESS',
  PUT_TO_LOGGED_CART_FAILED: 'PUT_TO_LOGGED_CART_FAILED',

  GET_DETAIL_TRACKING: 'GET_DETAIL_TRACKING',
  GET_DETAIL_TRACKING_SUCCESS: 'GET_DETAIL_TRACKING_SUCCESS',
  GET_DETAIL_TRACKING_FAILED: 'GET_DETAIL_TRACKING_FAILED',

  GET_DETAIL_ORDER_MANAGEMENT: 'GET_DETAIL_ORDER_MANAGEMENT',
  GET_DETAIL_ORDER_MANAGEMENT_SUCCESS: 'GET_DETAIL_ORDER_MANAGEMENT_SUCCESS',
  GET_DETAIL_ORDER_MANAGEMENT_FAILED: 'GET_DETAIL_ORDER_MANAGEMENT_FAILED',
  CLEAR_DETAIL_ORDER_MANAGEMENT: 'CLEAR_DETAIL_ORDER_MANAGEMENT',

  GET_TRACKING_JOURNEY: 'GET_TRACKING_JOURNEY',
  GET_TRACKING_JOURNEY_SUCCESS: 'GET_TRACKING_JOURNEY_SUCCESS',
  GET_TRACKING_JOURNEY_FAILED: 'GET_TRACKING_JOURNEY_FAILED',

  GET_LIST_VN_TRACKING: 'GET_LIST_VN_TRACKING',
  GET_LIST_VN_TRACKING_SUCCESS: 'GET_LIST_VN_TRACKING_SUCCESS',
  GET_LIST_VN_TRACKING_FAILED: 'GET_LIST_VN_TRACKING_FAILED',

  GET_DETAIL_VN_TRACKING: 'GET_DETAIL_VN_TRACKING',
  GET_DETAIL_VN_TRACKING_SUCCESS: 'GET_DETAIL_VN_TRACKING_SUCCESS',
  GET_DETAIL_VN_TRACKING_FAILED: 'GET_DETAIL_VN_TRACKING_FAILED',

  PAYMENT_VN_TRACKING: 'PAYMENT_VN_TRACKING',

  CONFIRM_VN_TRACKING: 'CONFIRM_VN_TRACKING',
  CONFIRM_VN_TRACKING_SUCCESS: 'CONFIRM_VN_TRACKING_SUCCESS',
  CONFIRM_VN_TRACKING_FAILED: 'CONFIRM_VN_TRACKING_FAILED',

  DETAIL_CONFIRM_VN_TRACKING: 'DETAIL_CONFIRM_VN_TRACKING',
  DETAIL_CONFIRM_VN_TRACKING_SUCCESS: 'DETAIL_CONFIRM_VN_TRACKING_SUCCESS',
  DETAIL_CONFIRM_VN_TRACKING_FAILED: 'DETAIL_CONFIRM_VN_TRACKING_FAILED',

  GET_NOTIFICATION: 'GET_NOTIFICATION',
  GET_NOTIFICATION_SUCCESS: 'GET_NOTIFICATION_SUCCESS',
  GET_NOTIFICATION_FAILED: 'GET_NOTIFICATION_FAILED',

  MARK_AS_READ_NOTIFICATION: 'MARK_AS_READ_NOTIFICATION',
  MARK_AS_READ_NOTIFICATION_SUCCESS: 'MARK_AS_READ_NOTIFICATION_SUCCESS',
  MARK_AS_READ_NOTIFICATION_FAILED: 'MARK_AS_READ_NOTIFICATION_FAILED',

  GET_LIST_BANK: 'GET_LIST_BANK',
  GET_LIST_BANK_SUCCESS: 'GET_LIST_BANK_SUCCESS',
  GET_LIST_CUSTOMER_BANK_SUCCESS: 'GET_LIST_CUSTOMER_BANK_SUCCESS',
  GET_LIST_GLOBAL_BANK_SUCCESS: 'GET_LIST_GLOBAL_BANK_SUCCESS',
  GET_LIST_BANK_FAILED: 'GET_LIST_BANK_FAILED',

  RESET_BANK: 'RESET_BANK',

  SYNC_LIST_BANK: 'SYNC_LIST_BANK',
  SYNC_LIST_BANK_SUCCESS: 'SYNC_LIST_BANK_SUCCESS',
  SYNC_LIST_BANK_FAILED: 'GET_LIST_BANK_FAILED',

  GET_CATEGORY_LIST: 'GET_CATEGORY_LIST',
  GET_CATEGORY_LIST_SUCCESS: 'GET_CATEGORY_LIST_SUCCESS',
  GET_CATEGORY_LIST_FAILED: 'GET_CATEGORY_LIST_FAILED',

  RECEIVE_NOTIFICATION: 'RECEIVE_NOTIFICATION',
  CLEAR_RECEIVE_NOTIFICATION: 'CLEAR_RECEIVE_NOTIFICATION',

  GET_CATEGORY_DETECT: 'GET_CATEGORY_DETECT',
  GET_CATEGORY_DETECT_SUCCESS: 'GET_CATEGORY_DETECT_SUCCESS',
  GET_CATEGORY_DETECT_FAILED: 'GET_CATEGORY_DETECT_FAILED',

  GET_LIST_HISTORY_MONEY_REQUEST: 'GET_LIST_HISTORY_MONEY_REQUEST',
  GET_LIST_HISTORY_MONEY_REQUEST_SUCCESS: 'GET_LIST_HISTORY_MONEY_REQUEST_SUCCESS',
  GET_LIST_HISTORY_MONEY_REQUEST_FAILED: 'GET_LIST_HISTORY_MONEY_REQUEST_FAILED',

  SEND_MONEY_TRANFER_REQUEST: 'SEND_MONEY_TRANFER_REQUEST',
  SEND_MONEY_TRANFER_REQUEST_SUCCESS: 'SEND_MONEY_TRANFER_REQUEST_SUCCESS',
  SEND_MONEY_TRANFER_REQUEST_FAILED: 'SEND_MONEY_TRANFER_REQUEST_FAILED',

  SEND_MONEY_WITHDRAW_REQUEST: 'SEND_MONEY_WITHDRAW_REQUEST',
  SEND_MONEY_WITHDRAW_REQUEST_SUCCESS: 'SEND_MONEY_WITHDRAW_REQUEST_SUCCESS',
  SEND_MONEY_WITHDRAW_REQUEST_FAILED: 'SEND_MONEY_WITHDRAW_REQUEST_FAILED',

  CLEAR_MONEY_REQUEST: 'CLEAR_MONEY_REQUEST',

  OPEN_VPN_MOBILE: 'OPEN_VPN_MOBILE',
  OPEN_VPN_MOBILE_SUCCESS: 'OPEN_VPN_MOBILE_SUCCESS',
  OPEN_VPN_MOBILE_FAILED: 'OPEN_VPN_MOBILE_FAILED',

  SYNC_MOBILE_OPENED_VPN: 'SYNC_MOBILE_OPENED_VPN',

  GET_LIST_PROMOTION_US_WEBSITE: 'GET_LIST_PROMOTION_US_WEBSITE',
  GET_LIST_PROMOTION_US_WEBSITE_SUCCESS: 'GET_LIST_PROMOTION_US_WEBSITE_SUCCESS',
  GET_LIST_PROMOTION_US_WEBSITE_FAILED: 'GET_LIST_PROMOTION_US_WEBSITE_FAILED',

  CHANGE_PAYMENT_OPTION: 'CHANGE_PAYMENT_OPTION',

  RESET_PAYMENT_OPTION: 'RESET_PAYMENT_OPTION',

  GET_LIST_PRODUCT_DEAL_HOME: 'GET_LIST_PRODUCT_DEAL_HOME',
  GET_LIST_PRODUCT_DEAL_HOME_SUCCESS: 'GET_LIST_PRODUCT_DEAL_HOME_SUCCESS',
  GET_LIST_PRODUCT_DEAL_HOME_FAILED: 'GET_LIST_PRODUCT_DEAL_HOME_FAILED',

  CHECK_PRICE_PRODUCT: 'CHECK_PRICE_PRODUCT',
  CHECK_PRICE_PRODUCT_SUCCESS: 'CHECK_PRICE_PRODUCT_SUCCESS',
  CHECK_PRICE_PRODUCT_FAILED: 'CHECK_PRICE_PRODUCT_FAILED',

  SAVE_BACKUP_PRODUCT_CATALOG: 'SAVE_BACKUP_PRODUCT_CATALOG',

  GET_LIST_PRODUCT_ORDER_DEAL: 'GET_LIST_PRODUCT_ORDER_DEAL',
  GET_LIST_PRODUCT_ORDER_DEAL_SUCCESS: 'GET_LIST_PRODUCT_ORDER_DEAL_SUCCESS',
  GET_LIST_PRODUCT_ORDER_DEAL_FAILED: 'GET_LIST_PRODUCT_ORDER_DEAL_FAILED',

  GET_LIST_DEAL_MANAGEMENT: 'GET_LIST_DEAL_MANAGEMENT',
  GET_LIST_DEAL_MANAGEMENT_SUCCESS: 'GET_LIST_DEAL_MANAGEMENT_SUCCESS',
  GET_LIST_DEAL_MANAGEMENT_FAILED: 'GET_LIST_DEAL_MANAGEMENT_FAILED',

  GET_DETAIL_DEAL_ORDER: 'GET_DETAIL_DEAL_ORDER',
  GET_DETAIL_DEAL_ORDER_SUCCESS: 'GET_DETAIL_DEAL_ORDER_SUCCESS',
  GET_DETAIL_DEAL_ORDER_FAILED: 'GET_DETAIL_DEAL_ORDER_FAILED',

  CLEAR_DETAIL_DEAL_ORDER: 'CLEAR_DETAIL_DEAL_ORDER',

  ADD_PRODUCT_TO_ORDER_DEAL: 'ADD_PRODUCT_TO_ORDER_DEAL',
  ADD_PRODUCT_TO_ORDER_DEAL_SUCCESS: 'ADD_PRODUCT_TO_ORDER_DEAL_SUCCESS',
  ADD_PRODUCT_TO_ORDER_DEAL_FAILED: 'ADD_PRODUCT_TO_ORDER_DEAL_FAILED',

  EDIT_PRODUCT_TO_ORDER_DEAL: 'EDIT_PRODUCT_TO_ORDER_DEAL',
  EDIT_PRODUCT_TO_ORDER_DEAL_SUCCESS: 'EDIT_PRODUCT_TO_ORDER_DEAL_SUCCESS',
  EDIT_PRODUCT_TO_ORDER_DEAL_FAILED: 'EDIT_PRODUCT_TO_ORDER_DEAL_FAILED',

  FILTER_ORDER_DEAL_MANAGEMENT: 'FILTER_ORDER_DEAL_MANAGEMENT',

  ADD_DEAL_QUOTATION: 'ADD_DEAL_QUOTATION',
  CLEAR_DEAL_QUOTATION: 'CLEAR_DEAL_QUOTATION',
  CONFIRM_DEAL: 'CONFIRM_DEAL',
  CONFIRM_DEAL_SUCCESS: 'CONFIRM_DEAL_SUCCESS',
  CONFIRM_DEAL_FAILED: 'CONFIRM_DEAL_FAILED',

  GET_BOUGHT_DEAL_DETAIL: 'GET_BOUGHT_DEAL_DETAIL',
  GET_BOUGHT_DEAL_DETAIL_SUCCESS: 'GET_BOUGHT_DEAL_DETAIL_SUCCESS',
  GET_BOUGHT_DEAL_DETAIL_FAILED: 'GET_BOUGHT_DEAL_DETAIL_FAILED',

  GET_GIFT_CARD_UPDATE: 'GET_GIFT_CARD_UPDATE',
  GET_GIFT_CARD_REJECT: 'GET_GIFT_CARD_REJECT',
  CLEAR_GIFT_CARD_UPDATE: 'CLEAR_GIFT_CARD_UPDATE',
  CLEAR_DEAL_PURCHASE_REJECT: 'CLEAR_DEAL_PURCHASE_REJECT',

  GET_LIST_DEAL: 'GET_LIST_DEAL',
  GET_LIST_DEAL_SUCCESS: 'GET_LIST_DEAL_SUCCESS',
  GET_LIST_DEAL_FLASH_SALE_SUCCESS: 'GET_LIST_DEAL_FLASH_SALE_SUCCESS',
  GET_LIST_DEAL_FAILED: 'GET_LIST_DEAL_FAILED',

  GET_DETAIL_DEAL: 'GET_DETAIL_DEAL',
  GET_DETAIL_DEAL_SUCCESS: 'GET_DETAIL_DEAL_SUCCESS',
  GET_DETAIL_DEAL_FAILED: 'GET_DETAIL_DEAL_FAILED',

  GET_PAYMENT_CODE: 'GET_PAYMENT_CODE',
  GET_PAYMENT_CODE_SUCCESS: 'GET_PAYMENT_CODE_SUCCESS',
  GET_PAYMENT_CODE_FAILED: 'GET_PAYMENT_CODE_FAILED',

  ORDER_BY_GIFTCARD: 'ORDER_BY_GIFTCARD',
  ORDER_BY_GIFTCARD_SUCCESS: 'ORDER_BY_GIFTCARD_SUCCESS',
  ORDER_BY_GIFTCARD_FAILED: 'ORDER_BY_GIFTCARD_FAILED',

  EDIT_QUOTATION_GIFT_CARD: 'EDIT_QUOTATION_GIFT_CARD',

  EDIT_QUOTATION_V2: 'EDIT_QUOTATION_V2',
  EDIT_QUOTATION_V2_SUCCESS: 'EDIT_QUOTATION_V2_SUCCESS',
  EDIT_QUOTATION_V2_FAILED: 'EDIT_QUOTATION_V2_FAILED',

  CONFIRM_SALE_ORDER_V2: 'CONFIRM_SALE_ORDER_V2',
  CONFIRM_SALE_ORDER_V2_SUCCESS: 'CONFIRM_SALE_ORDER_V2_SUCCESS',
  CONFIRM_SALE_ORDER_V2_FAILED: 'CONFIRM_SALE_ORDER_V2_FAILED',

  CLEAR_TRANSACTION: 'CLEAR_TRANSACTION',

  DELETE_QUOTATION_BY_DEAL: 'DELETE_QUOTATION_BY_DEAL',
  DELETE_QUOTATION_BY_DEAL_SUCCESS: 'DELETE_QUOTATION_BY_DEAL_SUCCESS',
  DELETE_QUOTATION_BY_DEAL_FAILED: 'DELETE_QUOTATION_BY_DEAL_FAILED',

  GET_CURRENT_ORDER_DEAL_LIST: 'GET_CURRENT_ORDER_DEAL_LIST',
  GET_CURRENT_ORDER_DEAL_LIST_SUCCESS: 'GET_CURRENT_ORDER_DEAL_LIST_SUCCESS',
  GET_CURRENT_ORDER_DEAL_LIST_FAILED: 'GET_CURRENT_ORDER_DEAL_LIST_FAILED',

  UPDATE_BALANCE: 'UPDATE_BALANCE',

  CLEAR_FIND_PROMOTION: 'CLEAR_FIND_PROMOTION',

  GET_EXTREMELY_TASTY_DEAL_CAMPAIGN: 'GET_EXTREMELY_TASTY_DEAL_CAMPAIGN',
  GET_EXTREMELY_TASTY_DEAL_CAMPAIGN_SUCCESS:
    'GET_EXTREMELY_TASTY_DEAL_CAMPAIGN_SUCCESS',
  GET_EXTREMELY_TASTY_DEAL_CAMPAIGN_FAILED:
    'GET_EXTREMELY_TASTY_DEAL_CAMPAIGN_FAILED',

  AUTO_ADD_COUPON_FOR_ETD_CAMPAIGN: 'AUTO_ADD_COUPON_FOR_ETD_CAMPAIGN',

  TOGGLE_CAMPAIGN_PROMOTION: 'TOGGLE_CAMPAIGN_PROMOTION',

  CHECK_LOGIN_PROVIDER: 'CHECK_LOGIN_PROVIDER',
  CHECK_LOGIN_PROVIDER_SUCCESS: 'CHECK_LOGIN_PROVIDER_SUCCESS',
  CHECK_LOGIN_PROVIDER_FAILED: 'CHECK_LOGIN_PROVIDER_FAILED',

  CHANGE_USER_PASSWORD: 'CHANGE_USER_PASSWORD',
  CHANGE_USER_PASSWORD_SUCCESS: 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_FAILED: 'CHANGE_USER_PASSWORD_FAILED',

  REGISTER_SHIPPING_SERVICE: 'REGISTER_SHIPPING_SERVICE',
  REGISTER_SHIPPING_SERVICE_SUCCESS: 'REGISTER_SHIPPING_SERVICE_SUCCESS',
  REGISTER_SHIPPING_SERVICE_FAILED: 'REGISTER_SHIPPING_SERVICE_FAILED',

  DEBT_RECOVERY: 'DEBT_RECOVERY',
  DEBT_RECOVERY_SUCCESS: 'DEBT_RECOVERY_SUCCESS',
  DEBT_RECOVERY_FAILED: 'DEBT_RECOVERY_FAILED',

  CALL_SALE_ORDER_PAGINATION: 'CALL_SALE_ORDER_PAGINATION'
};
