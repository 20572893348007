import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getCategoryDetect(action) {
  try {
    const items = action.payload.map(item => ({
      url: item?.link,
      name: item?.name
    }));
    const data = yield call(services.category.detect, items);

    yield put({
      type: TYPE.GET_CATEGORY_DETECT_SUCCESS,
      payload: data.map((item, key) => ({
        ...item,
        quotation_id: action.payload[key]._id
      }))
    });
  } catch (error) {
    yield put({ type: TYPE.GET_CATEGORY_DETECT_FAILED, msg: error });
  }
}

function* getCategoryList() {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      payload: TYPE.GET_CATEGORY_LIST
    });
    const params = [{ url: '' }];
    const data = yield call(services.category.detect, params);
    yield put({
      type: TYPE.GET_CATEGORY_LIST_SUCCESS,
      payload: data?.[0]?.categories
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_CATEGORY_LIST_FAILED,
      error
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      payload: TYPE.GET_CATEGORY_LIST
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_CATEGORY_DETECT, getCategoryDetect),
    takeLatest(TYPE.GET_CATEGORY_LIST, getCategoryList)
  ]);
}
