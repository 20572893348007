import { useTheme } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';

const useResponsive = (breakpoint = 'sm') => {
  const theme = useTheme();
  const listBreakpoint = Object.keys(theme.breakpoints.values);
  const breakpointIndex = listBreakpoint.indexOf(breakpoint) + 1;
  const currentBreakpoint = listBreakpoint[breakpointIndex];
  const width = theme.breakpoints.values[currentBreakpoint];

  const [targetReached, setTargetReached] = useState(
    window.matchMedia(`(max-width: ${width}px)`).matches
  );

  const updateTarget = useCallback(e => {
    if (e.matches) setTargetReached(true);
    else setTargetReached(false);
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addEventListener('change', updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) setTargetReached(true);

    return () => media.removeEventListener('change', updateTarget);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return targetReached;
};

export default useResponsive;
