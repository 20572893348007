import { useLocation } from 'react-router';
import useResponsive from '../useResponsive';
import { Path } from '../../contents/Constants';

export default function useEmptyLayout(children, layout) {
  const matches = useResponsive();
  const { pathname } = useLocation();
  const emptyLayout = [
    Path.VN_TRACKING_DEFAULT_PATH,
    Path.ORDER_MANAGEMENT_DEFAULT_PATH,
    Path.NOTIFICATION,
    Path.USER_ADDRESS,
    Path.USER_PROFILE,
    Path.CHANGE_PASSWORD
  ];
  const isEmptyLayout = emptyLayout.some(item => pathname.includes(item));

  if (matches && isEmptyLayout) return children;

  return layout;
}
