import { SvgIcon } from '@material-ui/core';
import { bool, string } from 'prop-types';
import React from 'react';

export default function PlusIcon({ color, disable, ...props }) {
  return (
    <SvgIcon
      {...props}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89032 1C8.89032 0.447715 8.4426 0 7.89032 0C7.33803 0 6.89032 0.447715 6.89032 1V7.01851H1C0.447715 7.01851 0 7.46623 0 8.01851C0 8.5708 0.447715 9.01851 1 9.01851H6.89032V15.0028C6.89032 15.5551 7.33803 16.0028 7.89032 16.0028C8.4426 16.0028 8.89032 15.5551 8.89032 15.0028V9.01851H15.0044C15.5567 9.01851 16.0044 8.5708 16.0044 8.01851C16.0044 7.46623 15.5567 7.01851 15.0044 7.01851H8.89032V1Z"
        fill={disable ? '#B0B1B8' : color}
      />
    </SvgIcon>
  );
}

PlusIcon.propTypes = {
  disable: bool,
  color: string
};

PlusIcon.defaultProps = {
  disable: false,
  color: '#252629'
};
