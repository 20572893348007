import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function ArrowIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      fillOpacity="0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25 12.2743L19.25 12.2743"
        stroke="#252629"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2998 18.2987L4.2498 12.2747L10.2998 6.2497"
        stroke="#252629"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
