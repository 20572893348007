import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function CancelledIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F51D2C" />
      <g clipPath="url(#clip0_24444_33155)">
        <circle cx="12" cy="12" r="7.5" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3169 9.68306C14.561 9.92714 14.561 10.3229 14.3169 10.5669L10.5669 14.3169C10.3229 14.561 9.92714 14.561 9.68306 14.3169C9.43898 14.0729 9.43898 13.6771 9.68306 13.4331L13.4331 9.68306C13.6771 9.43898 14.0729 9.43898 14.3169 9.68306Z"
          fill="#F51D2C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.68306 9.68306C9.92714 9.43898 10.3229 9.43898 10.5669 9.68306L14.3169 13.4331C14.561 13.6771 14.561 14.0729 14.3169 14.3169C14.0729 14.561 13.6771 14.561 13.4331 14.3169L9.68306 10.5669C9.43898 10.3229 9.43898 9.92714 9.68306 9.68306Z"
          fill="#F51D2C"
        />
      </g>
    </SvgIcon>
  );
}
