import { makeStyles } from '@material-ui/core/styles';
import keys from 'lodash/keys';
import size from 'lodash/size';
import React from 'react';
import { VN_TRACKING_STATE } from '../../contents/Constants';
import Header from '../2.2.0/VNTracking/ListVNTracking/Mobile/Header';
import SkeletonListVNTracking from './SkeletonListVNTracking';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default function SkeletonVNTracking() {
  const classes = useStyles();
  const countList = (() => {
    const listVNTracking = {
      DRAFT: [],
      SHIPPING: [],
      DELIVERED: []
    };
    const listName = keys(listVNTracking);

    const result = listName.reduce((acc, cur) => {
      return { ...acc, [cur]: size(listVNTracking[cur]) };
    }, {});

    return result;
  })();
  const tabList = keys(VN_TRACKING_STATE);

  return (
    <div className={classes.container}>
      <Header countList={countList} tabList={tabList} />

      <SkeletonListVNTracking />
    </div>
  );
}
