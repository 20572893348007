import { SvgIcon } from '@material-ui/core';
import React from 'react';

export default function VNTrackingIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.44 7.23L19.43 7.22C19.41 7.16 19.38 7.09 19.36 7.03C19.35 7.02 19.35 7.01 19.34 7C17.93 3.21 14.28 0.5 10 0.5C10 0.5 10 0.5 9.99 0.5C5.66 0.51 1.98 3.26 0.6 7.11C0.58 7.15 0.57 7.19 0.56 7.23C0.19 8.25 0 9.35 0 10.5V13.25C0 13.66 0.33 14 0.75 14H19.25C19.66 14 20 13.66 20 13.25V10.5C20 9.36 19.8 8.26 19.44 7.23ZM18.5 12.5H1.5V10.5C1.5 9.75 1.6 9.02 1.78 8.33L2.81 8.6C2.87 8.62 2.94 8.63 3 8.63C3.33 8.63 3.63 8.41 3.72 8.07C3.83 7.67 3.59 7.26 3.19 7.16L2.29 6.92C3.55 4.23 6.16 2.3 9.25 2.03V3.32C9.26 3.73 9.59 4.06 10 4.06C10.01 4.06 10.01 4.06 10.01 4.06C10.42 4.06 10.76 3.72 10.75 3.31V2.03C13.83 2.3 16.45 4.23 17.71 6.92L16.81 7.16C16.41 7.26 16.17 7.67 16.28 8.07C16.37 8.41 16.67 8.63 17 8.63C17.06 8.63 17.13 8.62 17.19 8.6L18.22 8.33C18.4 9.02 18.5 9.75 18.5 10.5V12.5Z"
        fill="#121331"
      />
      <path
        d="M11.2501 9.75C11.2501 10.44 10.7001 11 10.0001 11C9.47008 11 9.02008 10.67 8.84008 10.21L6.50008 9.59C6.10008 9.48 5.86008 9.07 5.97008 8.67C6.08008 8.27 6.48008 8.03 6.89008 8.14L9.23008 8.77C9.44008 8.6 9.71008 8.5 10.0001 8.5C10.7001 8.5 11.2501 9.06 11.2501 9.75Z"
        fill="#121331"
      />
    </SvgIcon>
  );
}
